import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Prescriptions from 'components/patients/PatientLog/Prescriptions';
import FollowUps from 'components/patients/PatientLog/FollowUps';
import MedicalTests from 'components/patients/PatientLog/MedicalTests';
import { useState } from 'react';
import PatientLogEntities from 'components/patients/PatientLog/PatientLogEntities';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import './style.css';
import services from 'services';
import Diagnoses from 'components/patients/PatientLog/Diagnoses';
import AllPatientDetails from 'components/patients/PatientLog/PatientDetails';
var PatientLog = function (props) {
    var _a = useState(false), showFollowUp = _a[0], setShowFollowUp = _a[1];
    var _b = useState(false), showMedicalTests = _b[0], setShowMedicalTests = _b[1];
    var _c = useState(false), showPrescriptions = _c[0], setShowPrescriptions = _c[1];
    var _d = useState(false), showDiagnoses = _d[0], setShowDiagnoses = _d[1];
    var _e = useState(false), showMedicalMachineUsages = _e[0], setShowMedicalMachineUsages = _e[1];
    var _f = useState(false), showImages = _f[0], setShowImages = _f[1];
    var _g = useState(false), showPatientMedicalDetails = _g[0], setShowPatientMedicalDetails = _g[1];
    var patientService = services.patientService, prescriptionService = services.prescriptionService, userService = services.userService, medicalTestService = services.medicalTestService, followUpService = services.followUpService, diagnoseService = services.diagnoseService;
    var patient = patientService.useGetPatient(props.patientId).data;
    var prescriptions = prescriptionService.usePatientPrescriptions(props.patientId).data;
    var medicalTests = medicalTestService.usePatientMedicalTests(props.patientId).data;
    var followUps = followUpService.usePatientFollowUps(props.patientId).data;
    var diagnoses = diagnoseService.usePatientDiagnoses(props.patientId).data;
    // const {data: images} = patientService.useGetPatientVisitImages(props.patientId);
    // const {data: medicalMachineUsages} = patientService.useGetPatientMedicalMachineUsages(props.patientId);
    var currentUSer = userService.useCurrentUser().data;
    var createDiagnose = diagnoseService.useCreateDiagnose().mutate;
    var createFollowUp = followUpService.useCreateFollowUp().mutate;
    var createMedicalTest = medicalTestService.useCreateMedicalTests().mutate;
    // const {mutate: createImage} = clinicVisitService.useCreatePatientImages();
    var createPrescription = prescriptionService.useCreatePrescription().mutate;
    var updatePatientDetails = patientService.useUpdatePatient().mutate;
    var onAddDiagnose = function (diagnose) {
        diagnose.setAppointmentId(props.appointmentId);
        diagnose.setPatientId(props.patientId);
        createDiagnose({ diagnose: diagnose, successMessage: 'diagnoseAddedSuccessfully' });
    };
    var onAddFollowUp = function (followUp) {
        followUp.setAppointmentId(props.appointmentId);
        followUp.setPatientId(props.patientId);
        createFollowUp({ followUp: followUp, successMessage: 'followUpAddedSuccessfully' });
    };
    var onAddMedicalTest = function (medicalTest) {
        medicalTest.setAppointmentId(props.appointmentId);
        medicalTest.setPatientId(props.patientId);
        createMedicalTest({ medicalTest: medicalTest, successMessage: 'medicalTestAddedSuccessfully' });
    };
    // const onAddImage = (image: Image) => createImage({
    //   reservationVisitId: props.reservationVisitId,
    //   image,
    //   successMessage: 'Image added Successfully',
    //   patientId: props.patientId
    // });
    var onUpdatePatientDetails = function (patient) { return updatePatientDetails({
        patient: patient,
        successMessage: 'Image added Successfully'
    }); };
    var onAddPrescription = function (prescription) {
        prescription.setAppointmentId(props.appointmentId);
        prescription.setPatientId(props.patientId);
        createPrescription({ prescription: prescription, successMessage: 'prescriptionAddedSuccessfully' });
    };
    var showPatientLogEntities = function () { return !showFollowUp && !showMedicalTests &&
        !showPrescriptions && !showDiagnoses && !showMedicalMachineUsages && !showImages &&
        !showPatientMedicalDetails; };
    // const showPatientLogEntities = () => !showPrescriptions 
    var getIcon = function (lng) {
        if (lng === void 0) { lng = 'en'; }
        return (currentUSer === null || currentUSer === void 0 ? void 0 : currentUSer.getLanguage()) != lng ?
            _jsx(RightOutlined, { style: { fontSize: '12px' } }) : _jsx(LeftOutlined, { style: { fontSize: '12px' } });
    };
    var getDirectionClass = function () { return (currentUSer === null || currentUSer === void 0 ? void 0 : currentUSer.getLanguage()) != 'en' ?
        'rtl-log' : 'ltr-log'; };
    return (_jsxs("div", { className: "patient-log ".concat(getDirectionClass()), children: [showFollowUp && followUps &&
                _jsx(FollowUps, { setShowFollowUp: setShowFollowUp, followUps: followUps, createFollowUp: onAddFollowUp, arrowIcon: getIcon() }), showMedicalTests && medicalTests &&
                _jsx(MedicalTests, { setShowMedicalTest: setShowMedicalTests, medicalTests: medicalTests, createMedicalTest: onAddMedicalTest, arrowIcon: getIcon() }), showPrescriptions && prescriptions &&
                _jsx(Prescriptions, { setShowPrescriptions: setShowPrescriptions, prescriptions: prescriptions, createPrescription: onAddPrescription, arrowIcon: getIcon() }), showDiagnoses && diagnoses &&
                _jsx(Diagnoses, { setShowDiagnoses: setShowDiagnoses, diagnoses: diagnoses, createDiagnose: onAddDiagnose, arrowIcon: getIcon() }), showPatientMedicalDetails && patient &&
                _jsx(AllPatientDetails, { setShowPatientDetails: setShowPatientMedicalDetails, updatePatientDetails: onUpdatePatientDetails, patient: patient, arrowIcon: getIcon() }), showPatientLogEntities() &&
                _jsx(PatientLogEntities, { setShowMedicalTests: setShowMedicalTests, setShowFollowUps: setShowFollowUp, setShowPrescriptions: setShowPrescriptions, setShowDiagnoses: setShowDiagnoses, setShowMedicalMachineUsages: setShowMedicalMachineUsages, setShowImages: setShowImages, setShowPatientMedicalDetails: setShowPatientMedicalDetails, arrowIcon: getIcon('ar') })] }));
};
export default PatientLog;
