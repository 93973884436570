import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Row, Col, Divider, Space } from 'antd';
import Header from 'pages/ShowPackage/components/Header';
import { useState } from 'react';
import AddPackagePriceDrawer from './components/drawers/AddPackagePriceDrawer';
import ChangePackagePriceAvailabilityDrawer from './components/drawers/ChangePackagePriceAvailabilityDrawer';
import Loader from 'components/AntEnhancement/Loader';
import { PackagePrice } from 'models';
import PackagePrices from 'pages/ShowPackage/components/Tables/PackagePrices';
import OtherInfo from 'pages/ShowPackage/components/Informations/OtherInfo';
import MainInfo from 'pages/ShowPackage/components/Informations/MainInfo';
import { useParams } from 'react-router';
import services from 'services';
import './style.css';
var PatientProfile = function () {
    var _a = useState(new PackagePrice()), selectedPackagePrice = _a[0], setSelectedPackagePrice = _a[1];
    var id = useParams().id;
    var packageService = services.packageService, unitMeasurementService = services.unitMeasurementService;
    var _b = unitMeasurementService.useUnitMeasurements(), unitMeasurements = _b.data, isUnitMeasurementLoading = _b.isLoading;
    var _c = packageService.useGetPackage(Number(id)), currentPackage = _c.data, isPackageLoading = _c.isLoading;
    var _d = useState(0), packagePriceDrawerKey = _d[0], setPackagePriceDrawerKey = _d[1];
    var _e = useState(0), packagePriceAvailabilityDrawerKey = _e[0], setPackagePriceAvailabilityDrawerKey = _e[1];
    var _f = useState(false), showPackagePriceDrawer = _f[0], setShowPackagePriceDrawer = _f[1];
    var onClosePackagePriceDrawer = function () {
        setShowPackagePriceDrawer(false);
        setPackagePriceDrawerKey(packagePriceDrawerKey + 1);
    };
    var _g = useState(false), showPackagePriceAvailabilityDrawer = _g[0], setShowPackagePriceAvailabilityDrawer = _g[1];
    var onClosePackagePriceAvailabilityDrawer = function () {
        setShowPackagePriceAvailabilityDrawer(false);
        setPackagePriceAvailabilityDrawerKey(packagePriceAvailabilityDrawerKey + 1);
    };
    var packageUnitMeasurement = function (cPackage) { return unitMeasurementService.getUnitMeasurementDataByID(unitMeasurements, cPackage.getUnitMeasurementId()); };
    return (_jsx("div", { className: "loader-container-height", children: _jsx(Loader, { renderSpinner: isPackageLoading && isUnitMeasurementLoading, children: _jsxs("div", { children: [_jsxs(Space, { direction: 'vertical', size: 50, children: [_jsx("div", { className: "page-header remove-padding", children: _jsx(Header, { setShowPackagePriceDrawer: setShowPackagePriceDrawer }) }), _jsx("div", { className: "new-pages-content", children: _jsx(Space, { direction: "vertical", size: 24, children: _jsxs(Row, { children: [_jsx(Col, { xl: 12, children: currentPackage && _jsx(MainInfo, { currentPackage: currentPackage }) }), _jsx(Col, { sm: 1 }), _jsx(Col, { xl: 11, children: currentPackage && unitMeasurements &&
                                                    _jsx(OtherInfo, { currentPackage: currentPackage, unitMeasurement: packageUnitMeasurement(currentPackage) }) })] }) }) })] }), _jsx(Divider, {}), currentPackage &&
                        _jsx(PackagePrices, { setShowPackagePriceAvailabilityDrawer: setShowPackagePriceAvailabilityDrawer, setPackagePrice: setSelectedPackagePrice }), _jsx(AddPackagePriceDrawer, { onCloseDrawer: onClosePackagePriceDrawer, visiable: showPackagePriceDrawer }, 'package-price-' + packagePriceDrawerKey), _jsx(ChangePackagePriceAvailabilityDrawer, { onCloseDrawer: onClosePackagePriceAvailabilityDrawer, visiable: showPackagePriceAvailabilityDrawer, packagePrice: selectedPackagePrice }, 'package-price-availability-' + packagePriceAvailabilityDrawerKey)] }) }) }));
};
export default PatientProfile;
