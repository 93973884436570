var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { showErrorToastr, showSuccessToastr } from 'library/utilities/ToastrService';
import ApiService from './ApiService';
var ToastrService = /** @class */ (function (_super) {
    __extends(ToastrService, _super);
    function ToastrService() {
        return _super.call(this) || this;
    }
    ToastrService.prototype.displayErrorToastr = function (failure, icon) {
        var errors = failure.getDetails();
        if (errors) {
            errors.forEach(function (error) {
                showErrorToastr(failure.getTranslatedType(), error.getMessage(), icon);
            });
        }
    };
    ToastrService.prototype.displaySuccessToastr = function (successMessage, icon) {
        showSuccessToastr(successMessage, '', icon);
    };
    ToastrService.getInstance = function () {
        if (!ToastrService.toastrInstance) {
            ToastrService.toastrInstance = new ToastrService();
        }
        return ToastrService.toastrInstance;
    };
    return ToastrService;
}(ApiService));
export default ToastrService;
