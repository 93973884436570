import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Form, Space } from 'antd';
import FinishPackagePrices from 'components/forms/packages/FinishPackagePricesForm';
import localeTranslator from 'library/Hocs/LocalTranslator';
import { Appointment } from 'models';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import services from 'services';
import { plainToInstance } from 'class-transformer';
import Header from './components/Header';
var FinishVisit = function (props) {
    var _a = useState(), appointmentDetails = _a[0], setAppointmentDetails = _a[1];
    var _b = useState(0), packagesDrawerKey = _b[0], setPackagesDrawerKey = _b[1];
    var _c = useState(false), showPackagesDrawer = _c[0], setShowPackagesDrawer = _c[1];
    var onClosePackagesDrawer = function () {
        setShowPackagesDrawer(false);
        setPackagesDrawerKey(packagesDrawerKey + 1);
    };
    var onAddPackagePress = function () { return setShowPackagesDrawer(true); };
    var appointmentService = services.appointmentService;
    var id = useParams().id;
    var appointment = appointmentService.useGetAppointment(Number(id)).data;
    var finishAppointment = appointmentService.useFinishAppointment().mutate;
    var form = Form.useForm()[0];
    var submit = function (values) {
        var settlements = [];
        appointmentDetails && appointmentDetails.forEach(function (ad) {
            var used = values["used-".concat(ad.getId())];
            ad.setQuantity(used);
            ad.getSettlement().setAppointmentDetailId(ad.getId());
            settlements.push(ad.getSettlement());
        });
        appointmentDetails && (appointment === null || appointment === void 0 ? void 0 : appointment.replaceAppointmentDetails(appointmentDetails));
        appointment && finishAppointment({ appointment: plainToInstance(Appointment, JSON.parse(JSON.stringify(appointment))) });
    };
    useEffect(function () {
        !appointmentDetails && setAppointmentDetails(appointment === null || appointment === void 0 ? void 0 : appointment.getAppointmentDetails());
    }, [appointment]);
    return (_jsxs(Space, { direction: 'vertical', size: 100, children: [_jsx("div", { className: "page-header remove-padding", children: _jsx(Header, { setShowReservationPackagesDrawer: function () { }, setShowAppointmentDrawer: function () { }, setShowTransactionDrawer: function () { } }) }), _jsx("div", { style: { margin: 20, marginTop: 36 }, children: _jsx(Form, { layout: 'vertical', form: form, initialValues: { layout: 'vertical' }, size: 'large', onFinish: submit, children: _jsxs(Space, { direction: "vertical", size: 15, children: [appointmentDetails &&
                                appointmentDetails.map(function (appointmentDetail) {
                                    return (_jsx(FinishPackagePrices, { appointmentDetail: appointmentDetail }, "appointmentDetail-".concat(appointmentDetail.getId())));
                                }), _jsx(Form.Item, { children: _jsx(Button, { type: "primary", htmlType: "submit", className: "normal-button Header-16-M", children: props.translate('common:buttons.submit') }) })] }) }) })] }));
};
export default localeTranslator(FinishVisit, 'finishAppointment');
