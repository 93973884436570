var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import Model from 'models/Model';
var ItemCategory = /** @class */ (function (_super) {
    __extends(ItemCategory, _super);
    function ItemCategory() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ItemCategory.prototype.getId = function () {
        return Number(this.id);
    };
    ItemCategory.prototype.getName = function () {
        return this.name;
    };
    ItemCategory.prototype.getDefaultUnitPrice = function () {
        return 0;
    };
    return ItemCategory;
}(Model));
export default ItemCategory;
