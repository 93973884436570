import { jsx as _jsx } from "react/jsx-runtime";
import ClinicReports from 'pages/Reports/ClinicReports';
import PatientReports from 'pages/Reports/PatientReports';
import DoctorReports from 'pages/Reports/DoctorReports';
import PackageReports from 'pages/Reports/PackageReports';
import { Route } from 'react-router';
import AuthorizationService from '../AuthorizationService';
import { ClinicReports as ClinicReportsAction, PatientReports as PatientReportsAction, DoctorReports as DoctorReportsAction, PackageReports as PackageReportsAction, } from 'constants/actions';
import { Reports as ReportsResource } from 'constants/resources';
var ReportsRoutes = function () {
    return ([
        _jsx(Route, { caseSensitive: true, path: '/reports/clinic', element: AuthorizationService.getAuthorizedElement(_jsx(ClinicReports, {}), ClinicReportsAction, ReportsResource) }, 'clinic-reports-route'),
        _jsx(Route, { caseSensitive: true, path: '/reports/patient', element: AuthorizationService.getAuthorizedElement(_jsx(PatientReports, {}), PatientReportsAction, ReportsResource) }, 'patient-reports-route'),
        _jsx(Route, { caseSensitive: true, path: '/reports/doctor', element: AuthorizationService.getAuthorizedElement(_jsx(DoctorReports, {}), DoctorReportsAction, ReportsResource) }, 'doctor-reports-route'),
        _jsx(Route, { caseSensitive: true, path: '/reports/package', element: AuthorizationService.getAuthorizedElement(_jsx(PackageReports, {}), PackageReportsAction, ReportsResource) }, 'package-reports-route')
    ]);
};
export default ReportsRoutes;
