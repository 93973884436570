import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Menu } from 'antd';
import { useState } from 'react';
import './style.css';
var TabHeader = function (props) {
    var _a = useState(props.currentTab), current = _a[0], setCurrent = _a[1];
    var onClick = function (e) {
        props.setCurrentTab(e.key);
        setCurrent(e.key);
    };
    var items = props.items;
    return (_jsxs("div", { className: "tab-page-header", children: [_jsx("div", { className: "Header-24-bold title", children: props.title }), _jsx(Menu, { onClick: onClick, selectedKeys: [current], mode: "horizontal", items: items })] }));
};
export default TabHeader;
