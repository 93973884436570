import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Row, Space, Col } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import localeTranslator from 'library/Hocs/LocalTranslator';
import '../../style.css';
var OtherInfo = function (props) {
    var currentPackage = props.currentPackage, unitMeasurement = props.unitMeasurement;
    return (_jsxs("div", { className: "patient-info", children: [_jsx("div", { className: "Header-18-M form-title", children: props.translate('header.otherInfo') }), _jsx(Space, { direction: 'vertical', size: 40, children: _jsxs(Row, { children: [_jsx(Col, { xs: 24, lg: 10, xl: 10, children: _jsx(Space, { size: 27, children: _jsxs(Space, { direction: 'vertical', children: [_jsx("div", { className: 'body---14pt-R description-text', children: props.translate('packageInfo.packageElement') }), _jsx("div", { className: 'body---14pt-R', children: currentPackage.getEntityName() })] }) }) }), _jsx(Col, { xs: 15, lg: 4, xl: 4 }), _jsx(Col, { xs: 24, lg: 10, xl: 10, children: _jsx(Space, { size: 27, children: _jsxs(Space, { direction: 'vertical', children: [_jsx("div", { className: 'body---14pt-R description-text', children: props.translate('packageInfo.available') }), _jsx("div", { className: 'body---14pt-R', children: currentPackage.getIsEnabled() ? _jsx(CheckOutlined, {}) : _jsx(CloseOutlined, {}) })] }) }) })] }) })] }));
};
export default localeTranslator(OtherInfo, 'showPackage');
