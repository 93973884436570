import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { Button, Drawer, Form } from 'antd';
import ChangeAvailability from 'components/forms/packagePrices/ChangeAvailability';
import localeTranslator from 'library/Hocs/LocalTranslator';
import services from 'services';
import '../../style.css';
var ChangePackagePriceAvailabilityDrawer = function (props) {
    var form = Form.useForm()[0];
    var onClose = function () { return props.onCloseDrawer(); };
    var packagePriceService = services.packagePriceService;
    var _a = packagePriceService.useUpdatePackagePriceAvailability(), updateAvailability = _a.mutate, isAvailabilityUpdated = _a.isSuccess;
    useEffect(function () {
        isAvailabilityUpdated && onClose();
    }, [isAvailabilityUpdated]);
    var onSubmit = function () {
        updateAvailability({ packagePrice: props.packagePrice, successMessage: 'Visit Created Successfully' });
    };
    return (_jsx(Drawer, { title: props.translate('drawers.changeAvailability'), placement: "bottom", open: props.visiable, size: "default", onClose: onClose, className: "transaction-drawer", children: _jsxs(Form, { layout: 'vertical', form: form, initialValues: { layout: 'vertical' }, size: 'large', onFinish: onSubmit, children: [_jsx(ChangeAvailability, { packagePrice: props.packagePrice }), _jsx(Form.Item, { children: _jsx(Button, { type: "primary", htmlType: "submit", className: "add-submit-button Header-16-M", children: props.translate('common:buttons.submit') }) })] }) }, "bottom"));
};
export default localeTranslator(ChangePackagePriceAvailabilityDrawer, 'showPackage');
