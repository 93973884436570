import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Space } from 'antd';
import moment from 'moment';
import DateHeader from 'components/PageHeader/DateHeader';
import services from 'services';
import DoctorQueue from './Queue';
import DoctorPicker from './DoctorPicker';
import './style.css';
var index = function () {
    var doctorService = services.doctorService;
    var _a = doctorService.useDoctors(), data = _a.data, isSuccess = _a.isSuccess;
    var _b = useState(moment()), date = _b[0], setDate = _b[1];
    var _c = useState(data), doctors = _c[0], setDoctors = _c[1];
    useEffect(function () {
        setDoctors(data);
    }, [isSuccess]);
    return (_jsxs(Space, { direction: 'vertical', size: 150, children: [_jsx("div", { className: 'page-header remove-padding', children: _jsx(DateHeader, { date: date, setDate: setDate }) }), _jsx("div", { className: 'queue', children: _jsxs(Space, { direction: 'horizontal', size: 50, style: { alignItems: 'flex-start' }, children: [doctors === null || doctors === void 0 ? void 0 : doctors.slice(0, 3).map(function (doctor, index) {
                            return _jsxs(_Fragment, { children: [_jsx(DoctorQueue, { doctor_id: doctor.getId(), doctor_name: doctor.getUser().getName(), date: date }, "doctor-".concat(doctor.getId(), "-").concat(new Date().getTime())), index < 2 && _jsx("div", { style: { borderLeft: '1px solid #d1dbe6', height: '100vh' } })] });
                        }), doctors && isSuccess &&
                            _jsx(DoctorPicker, { doctors: doctors, setDoctors: setDoctors })] }) })] }));
};
export default index;
