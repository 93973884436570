var packageEntities = {
    '1': 'medicalMachine',
    '2': 'item',
    '3': 'itemCategory',
    '4': 'visit',
    medicalMachine: 1,
    item: 2,
    itemCategory: 3,
    visit: 4
};
export default Object.freeze(packageEntities);
