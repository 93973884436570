import { jsx as _jsx } from "react/jsx-runtime";
import MedicalMachines from 'pages/MedicalMachines';
import NewMedicalMachine from 'pages/NewMedicalMachine';
import { Route } from 'react-router';
import AuthorizationService from '../AuthorizationService';
import { Index, Create } from '../../constants/actions';
import { Patient as PatientsResource } from '../../constants/resources';
var MedicalMachineRoutes = function () {
    return ([
        _jsx(Route, { caseSensitive: true, path: '/medical-machines/new', element: AuthorizationService.getAuthorizedElement(_jsx(NewMedicalMachine, {}), Create, PatientsResource) }, 'new-machines-route'),
        _jsx(Route, { caseSensitive: true, path: '/medical-machines', element: AuthorizationService.getAuthorizedElement(_jsx(MedicalMachines, {}), Index, PatientsResource) }, 'medical-machines-route')
    ]);
};
export default MedicalMachineRoutes;
