var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { classToPlain, plainToClass } from 'class-transformer';
import queries from 'constants/queries';
import { PaginationMetaData, Reservation, ReservationPackagePayment, ReservationTransaction, ReservationVisit, Transaction, Visit } from 'models';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import ApiService from './ApiService';
import DoctorService from './DoctorService';
var ReservationService = /** @class */ (function (_super) {
    __extends(ReservationService, _super);
    function ReservationService() {
        var _this = _super.call(this) || this;
        _this.reservationsRoot = 'reservations';
        _this.successCreationMessage = '';
        _this.doctorService = DoctorService.getInstance();
        return _this;
    }
    ReservationService.prototype.useReservations = function (pageNumber, filters) {
        var _this = this;
        if (pageNumber === void 0) { pageNumber = 1; }
        if (filters === void 0) { filters = {}; }
        return useQuery([queries.reservations, pageNumber, filters], function () {
            return _this.get(_this.reservationsRoot, __assign(__assign({}, filters), { page: pageNumber }));
        }, {
            keepPreviousData: true,
            select: function (response) {
                var data = response.data;
                var reservations = plainToClass(Reservation, data === null || data === void 0 ? void 0 : data.reservations);
                var meta = plainToClass(PaginationMetaData, data === null || data === void 0 ? void 0 : data.meta);
                return { reservations: reservations, meta: meta };
            }
        });
    };
    ReservationService.prototype.useCreateReservation = function () {
        var _this = this;
        var queryClient = useQueryClient();
        var navigate = useNavigate();
        var needToNavigate = true;
        return useMutation(function (_a) {
            var reservation = _a.reservation, successMessage = _a.successMessage, navigateToReservation = _a.navigateToReservation;
            _this.successCreationMessage = successMessage;
            needToNavigate = navigateToReservation;
            var data = { reservation: classToPlain(reservation) };
            return _this.post(_this.reservationsRoot, { data: data });
        }, {
            retry: false,
            onSuccess: function () {
                queryClient.invalidateQueries(queries.reservations);
                queryClient.invalidateQueries([queries.patient]);
                _this.dipatachCreationSuccess({ successMessage: _this.successCreationMessage });
                needToNavigate && navigate('/reservations');
            }
        });
    };
    ReservationService.prototype.useGetReservationPatient = function (id) {
        var _this = this;
        return useQuery([queries.reservations, id], function () {
            return _this.get("".concat(_this.reservationsRoot, "/").concat(id));
        }, {
            retry: false,
            select: function (response) {
                var data = response.data;
                var reservation = plainToClass(Reservation, data.reservation);
                var patient = reservation.getPatient();
                return { patient: patient };
            }
        });
    };
    ReservationService.prototype.useGetReservation = function (id) {
        var _this = this;
        return useQuery([queries.reservations, id], function () {
            return _this.get("".concat(_this.reservationsRoot, "/").concat(id));
        }, {
            retry: false,
            select: function (response) {
                var data = response.data;
                var transactions = plainToClass(Transaction, data.meta.transactions);
                var reservation = plainToClass(Reservation, data.reservation);
                reservation.setTransactions(transactions);
                return { meta: data.meta, reservation: reservation };
            }
        });
    };
    ReservationService.prototype.useGetPackageTransaction = function (id) {
        var _this = this;
        return useQuery([queries.reservations, id], function () {
            return _this.get("".concat(_this.reservationsRoot, "/").concat(id));
        }, {
            retry: false,
            select: function (response) {
                var data = response.data;
                var transactions = plainToClass(Transaction, data.meta.transactions);
                var reservation = plainToClass(Reservation, data.reservation);
                var reservationPackages = reservation.getReservationPackages();
                var reservationTrancsactions = plainToClass(ReservationTransaction, reservation.getReservationTransactions());
                var issuer = reservation.getIssuer();
                return { reservationPackages: reservationPackages, transactions: transactions, reservationTrancsactions: reservationTrancsactions, issuer: issuer };
            }
        });
    };
    ReservationService.prototype.useGetReservationVisits = function (id) {
        var _this = this;
        var _a = this.doctorService.useDoctors(), doctors = _a.data, isDoctorsFetched = _a.isSuccess;
        return useQuery([queries.reservations, id, queries.visits], function () {
            return _this.get("".concat(_this.reservationsRoot, "/").concat(id, "/visits"));
        }, {
            retry: false,
            enabled: isDoctorsFetched,
            select: function (response) {
                var data = response.data;
                var doctorsById = doctors === null || doctors === void 0 ? void 0 : doctors.reduce(function (obj, doctor) {
                    var _a;
                    return (__assign(__assign({}, obj), (_a = {}, _a[doctor.getId()] = doctor, _a)));
                }, {});
                var reservationVisitsByVisitId = plainToClass(ReservationVisit, data.reservation_visits)
                    .reduce(function (obj, rv) {
                    var _a;
                    return (__assign(__assign({}, obj), (_a = {}, _a[rv.getVisitId()] = rv, _a)));
                }, {});
                var visits = plainToClass(Visit, data.meta.visits).map(function (visit) {
                    visit.setReservationVisits([reservationVisitsByVisitId[visit.getId()]]);
                    doctorsById && visit.setDoctor(doctorsById[visit.getDoctorId()]);
                    return visit;
                });
                return visits;
            }
        });
    };
    ReservationService.prototype.getReservationPackagesPrices = function (reservationPackages, reservationTransactions) {
        var packagesById = reservationPackages.reduce(function (obj, rp) {
            var _a;
            return (__assign(__assign({}, obj), (_a = {}, _a[rp.getPackage().getId()] = rp.getPackage(), _a)));
        }, {});
        var prices = reservationPackages.reduce(function (rpobj, rp) {
            var _a, _b;
            var packageRepeated = rp.getRepeated() || 1;
            // const packagePrice = rp.getPackage().getTotalPrice() * packageRepeated;
            var packagePrice = 0;
            var packageDebits = reservationTransactions.filter(function (rt) {
                return rt.getReservationId() === rp.getReservationId() && rt.getPackageId() === rp.getPackage().getId();
            }).map(function (rt) { return rt.getDebit(); });
            var currentPackageDebit = packageDebits.length ? Math.min.apply(Math, packageDebits) : packagePrice;
            return __assign(__assign({}, rpobj), (_a = {}, _a[rp.getReservationId()] = __assign(__assign({}, rpobj[rp.getReservationId()]), (_b = {}, _b[rp.getPackage().getId()] = currentPackageDebit, _b)), _a));
        }, {});
        var reservationIds = Object.keys(prices);
        var ReservationPackagePayments = [];
        reservationIds.forEach(function (reservationId) {
            var packages = prices[Number(reservationId)];
            Object.keys(packages).forEach(function (packageId) {
                var currentPackage = packagesById[Number(packageId)];
                ReservationPackagePayments.push(new ReservationPackagePayment(Number(reservationId), packages[Number(packageId)], 0, currentPackage));
            });
        });
        return ReservationPackagePayments;
    };
    ReservationService.getInstance = function () {
        if (!ReservationService.reservationInstance) {
            ReservationService.reservationInstance = new ReservationService();
        }
        return ReservationService.reservationInstance;
    };
    return ReservationService;
}(ApiService));
export default ReservationService;
