import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Button, Space, Form } from 'antd';
import { Employee, User } from 'models';
import SignUpForm from 'components/forms/users/SignUpForm';
import UserMainInfo from 'components/forms/users/UserMainInfo';
import JobInfoForm from 'components/forms/employees/JobInfoForm';
import services from 'services';
import localeTranslator from 'library/Hocs/LocalTranslator';
var NewEmployee = function (props) {
    var user = useState(new User())[0];
    var employee = useState(new Employee())[0];
    var _a = useState(false), disabled = _a[0], setDisabled = _a[1];
    var employeeService = services.employeeService, jobTitleService = services.jobTitleService;
    var createEmployee = employeeService.useCreateEmployee().mutate;
    var jobTitles = jobTitleService.useJobTitles().data;
    var form = Form.useForm()[0];
    var submit = function () {
        setDisabled(true);
        employee.setUser(user);
        createEmployee({ employee: employee, successMessage: props.translate('employeeCreated') });
    };
    var scrollToFirstElement = function () { return form.scrollToField('name', { behavior: 'smooth' }); };
    return (_jsxs("div", { style: { margin: 20, marginTop: 36 }, children: [_jsx("div", { className: "Header-24-bold title", children: props.translate('title') }), _jsx(Form, { layout: 'vertical', form: form, initialValues: { layout: 'vertical' }, size: 'large', onFinish: submit, onFinishFailed: scrollToFirstElement, children: _jsxs(Space, { direction: 'vertical', size: 60, style: { width: '100%' }, children: [_jsx(UserMainInfo, { entity: employee, user: user }), _jsx(SignUpForm, { user: user }), jobTitles && _jsx(JobInfoForm, { employee: employee, jobTitles: jobTitles }), _jsx(Space, { children: _jsx(Form.Item, { children: _jsx(Button, { type: "primary", htmlType: "submit", className: "normal-button Header-16-M", disabled: disabled, children: props.translate('common:buttons.submit') }) }) })] }) })] }));
};
export default localeTranslator(NewEmployee, 'employeeForm');
