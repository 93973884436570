import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Col, Form, Button } from 'antd';
import EntityHeader from 'components/PageHeader/EntityHeader';
import { Appointments, ReservationPackage } from 'constants/resources';
import localeTranslator from 'library/Hocs/LocalTranslator';
import { useParams } from 'react-router';
import services from 'services';
var Header = function (props) {
    var id = useParams().id;
    var onAddReservationPackage = function () { return props.setShowReservationPackagesDrawer(true); };
    var permissionService = services.permissionService;
    var buttons = function () {
        return (_jsx(_Fragment, { children: permissionService.hasPermissionToAdd(ReservationPackage) &&
                _jsx(Col, { xs: 24, sm: 24, lg: 24, xl: 5, children: _jsx(Form.Item, { children: _jsx(Button, { className: 'normal-button Header-16-M', onClick: onAddReservationPackage, children: props.translate('header.addPackage') }) }) }) }));
    };
    return (_jsx(EntityHeader, { children: buttons(), title: props.translate('header.title'), resource: Appointments }));
};
export default localeTranslator(Header, 'finishAppointment');
