import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import Icon from '@ant-design/icons';
var MoneyIcon = function (props) {
    var Image = function () {
        return _jsx("svg", { xmlns: "http://www.w3.org/2000/svg", width: "1em", height: "1em", fill: "currentColor", viewBox: "0 0 20 20", focusable: "false", "data-icon": "user", "aria-hidden": "true", className: props.className, children: _jsxs("g", { id: "dollar-circle", children: [_jsx("path", { id: "Vector", d: "M0 2.136h.487a.934.934 0 0 0 .892-.959C1.379.525 1.147.4.765.262L.007 0v2.136z", transform: "translate(9.557 9.797)" }), _jsx("path", { id: "Vector-2", "data-name": "Vector", d: "M7.473 0a7.5 7.5 0 1 0 7.518 7.473A7.5 7.5 0 0 0 7.473 0zM9.19 7.57a1.876 1.876 0 0 1 1.372 1.979 2.051 2.051 0 0 1-2.016 2.084h-.488v.435a.562.562 0 1 1-1.124 0v-.435h-.27a2.269 2.269 0 0 1-2.227-2.309.562.562 0 0 1 1.124 0 1.147 1.147 0 0 0 1.1 1.184h.27V7.975L5.8 7.57a1.876 1.876 0 0 1-1.37-1.978 2.051 2.051 0 0 1 2.016-2.084h.487v-.435a.562.562 0 1 1 1.124 0v.435h.27a2.269 2.269 0 0 1 2.226 2.309.562.562 0 1 1-1.124 0 1.147 1.147 0 0 0-1.1-1.184h-.27v2.533z", transform: "translate(1.499 1.424)" }), _jsx("path", { id: "Vector-3", "data-name": "Vector", d: "M0 .967c0 .652.232.78.615.914l.757.262V0H.884A.93.93 0 0 0 0 .967z", transform: "translate(7.061 6.056)" })] }) });
    };
    return (_jsx(Icon, { component: Image }));
};
export default memo(MoneyIcon);
