import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Space } from 'antd';
import localeTranslator from 'library/Hocs/LocalTranslator';
import '../../style.css';
var MainInfo = function (props) {
    var document = props.document;
    return (_jsxs("div", { id: "profile-content", className: "patient-info", children: [_jsx("div", { className: "Header-18-M form-title", children: props.translate('header.mainInfo') }), _jsxs(Space, { direction: "vertical", size: 40, children: [_jsx(Space, { size: 23, children: _jsxs(Space, { direction: "vertical", children: [_jsx("div", { className: 'Header-16-M description-text', children: props.translate('documentInfo.name') }), _jsx("div", { className: 'body---14pt-R', children: document.getName() })] }) }), _jsx(Space, { size: 23, children: _jsxs(Space, { direction: "vertical", children: [_jsx("div", { className: 'Header-16-M description-text', children: props.translate('documentInfo.documentLineGroupCount') }), _jsx("div", { className: 'body---14pt-R', children: document.getDocumentLineGroups().length })] }) })] })] }));
};
export default React.memo(localeTranslator(MainInfo, 'showDocument'));
