import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Col, Form, Button } from 'antd';
import EntityHeader from 'components/PageHeader/EntityHeader';
import { Appointments, Patient, ReservationPackage, Transaction } from 'constants/resources';
import localeTranslator from 'library/Hocs/LocalTranslator';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import services from 'services';
var Header = function (props) {
    var navigate = useNavigate();
    var id = useParams().id;
    var onAddReservationPackage = function () { return props.setShowReservationPackagesDrawer(true); };
    var onAddAppointment = function () { return props.setShowAppointmentDrawer(true); };
    var onAddTransaction = function () { return props.setShowTransactionDrawer(true); };
    var onEdit = function () { return navigate("/patients/".concat(id, "/update")); };
    var permissionService = services.permissionService;
    var buttons = function () {
        return (_jsxs(_Fragment, { children: [permissionService.hasPermissionToAdd(ReservationPackage) &&
                    _jsx(Col, { xs: 24, sm: 24, lg: 24, xl: 5, children: _jsx(Form.Item, { children: _jsx(Button, { className: 'normal-button Header-16-M', onClick: onAddReservationPackage, children: props.translate('header.addReservation') }) }) }), permissionService.hasPermissionToAdd(Appointments) &&
                    _jsx(Col, { xs: 24, sm: 24, lg: 24, xl: 5, children: _jsx(Form.Item, { children: _jsx(Button, { className: 'normal-button Header-16-M', onClick: onAddAppointment, children: props.translate('header.addAppointment') }) }) }), permissionService.hasPermissionToAdd(Transaction) &&
                    _jsx(Col, { xs: 24, sm: 24, lg: 24, xl: 5, children: _jsx(Form.Item, { children: _jsx(Button, { className: 'normal-button Header-16-M', onClick: onAddTransaction, children: props.translate('header.addTransaction') }) }) })] }));
    };
    return (_jsx(EntityHeader, { children: buttons(), title: props.translate('header.patientProfile'), editFn: onEdit, resource: Patient }));
};
export default localeTranslator(Header, 'showPatient');
