var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import Model from 'models/Model';
import { plainToClass } from 'class-transformer';
var Service = /** @class */ (function (_super) {
    __extends(Service, _super);
    function Service() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Service.prototype.getServiceById = function (id) {
        return Service.getServices().find(function (service) { return service.getId() === Number(id); });
    };
    Service.prototype.getId = function () {
        return Number(this.id);
    };
    Service.prototype.getName = function () {
        return this.name;
    };
    Service.getServices = function () {
        return plainToClass(Service, this.services);
    };
    Service.services = [
        { id: 1, name: 'Face' },
        { id: 2, name: 'Neck' },
        { id: 3, name: 'Bikkini' },
        { id: 4, name: 'Under arms' },
        { id: 5, name: 'Full arms' },
        { id: 6, name: 'Half arm lower' },
        { id: 7, name: 'Half arm upper' },
        { id: 8, name: 'Full leg' },
        { id: 9, name: 'Half leg lower' },
        { id: 10, name: 'Half leg upper' },
        { id: 11, name: 'Back' },
        { id: 12, name: 'Half back' },
        { id: 13, name: 'Stomach' }
    ];
    return Service;
}(Model));
export default Service;
