import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import Icon from '@ant-design/icons';
var GenderIcon = function () {
    var Image = function () {
        return _jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", id: "man", width: "24", height: "24", viewBox: "0 0 24 24", fill: "#647386", children: [_jsx("path", { id: "Vector", d: "M15.5 7.75A7.75 7.75 0 1 1 7.75 0a7.75 7.75 0 0 1 7.75 7.75z", transform: "translate(2.5 6)", style: { fill: 'none', stroke: '#647386', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '1.5px' } }), _jsx("path", { id: "Vector-2", "data-name": "Vector", d: "M5.5 0 0 5.5", transform: "translate(16 2.5)", style: { fill: 'none', stroke: '#647386', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '1.5px' } }), _jsx("path", { id: "Vector-3", "data-name": "Vector", d: "M0 0h6.5v6.5", transform: "translate(15 2.5)", style: { fill: 'none', stroke: '#647386', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '1.5px' } })] });
    };
    return (_jsx(Icon, { component: Image }));
};
export default memo(GenderIcon);
