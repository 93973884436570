var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import Model from 'models/Model';
var DocumentLineGroupFilters = /** @class */ (function (_super) {
    __extends(DocumentLineGroupFilters, _super);
    function DocumentLineGroupFilters() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DocumentLineGroupFilters.prototype.getPaidOn = function () {
        return this.paid_on;
    };
    DocumentLineGroupFilters.prototype.setPaidOn = function (paidOn) {
        this.paid_on = paidOn;
    };
    DocumentLineGroupFilters.prototype.getPaidFrom = function () {
        return this.paid_from;
    };
    DocumentLineGroupFilters.prototype.setPaidFrom = function (paidFrom) {
        this.paid_from = paidFrom;
    };
    return DocumentLineGroupFilters;
}(Model));
export default DocumentLineGroupFilters;
