import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Form, Row, Col } from 'antd';
import localeTranslator from 'library/Hocs/LocalTranslator';
import './style.css';
import { FormInputNumber } from 'components/AntEnhancement/FormControls';
var packageRow = function (props) {
    var translate = props.translate;
    var onChangeRepeated = function (repeated) {
        repeated && props.reservationPackage.setRepeated(repeated);
        props.onChangeRepeated(props.reservationPackage);
    };
    return (_jsxs(Row, { children: [_jsx(Col, { xs: 24, sm: 24, lg: 10, xl: 10, children: _jsx(Form.Item, { label: translate('reservationInfo.packageName'), name: "packageName", className: "form-item body---14pt-R", children: _jsx("div", { className: "package-name-rectangle body---14pt-R", children: props.packagePrice.getPackageName() }) }) }), _jsx(Col, { xs: 0, sm: 0, lg: 8, xl: 8 }), _jsx(Col, { xs: 24, sm: 24, lg: 4, xl: 4, children: _jsx(FormInputNumber, { formClasses: 'form-item body---14pt-R normal-input', inputClasses: "number-input", label: props.translate('reservationInfo.repeated'), name: "repeated-".concat(props.reservationPackage.getPackagePriceId()), rules: [
                        { required: true, message: translate('errors.repeated') },
                    ], placeHolder: props.translate('reservationInfo.repeated'), onChange: onChangeRepeated, min: 1 }) }), _jsx(Col, { xs: 0, sm: 0, lg: 2, xl: 2 })] }, props.reservationPackage.getPackagePriceId()));
};
export default React.memo(localeTranslator(packageRow, 'newReservationPackage'));
