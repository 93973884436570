import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import Icon from '@ant-design/icons';
var TelephonIcon = function () {
    var Image = function () {
        return _jsx("svg", { xmlns: "http://www.w3.org/2000/svg", width: "1em", height: "1em", fill: "currentColor", viewBox: "0 0 26 17", focusable: "false", "data-icon": "user", "aria-hidden": "true", children: _jsx("g", { id: "vuesax_bold_call", "data-name": "vuesax/bold/call", transform: "translate(-108 -188)", children: _jsxs("g", { id: "call", children: [_jsx("path", { id: "Vector", d: "M9.05 12.95 7.2 14.8a1 1 0 0 1-1.41.01c-.11-.11-.22-.21-.33-.32a28.414 28.414 0 0 1-2.79-3.27A17.828 17.828 0 0 1 .71 7.81 8.586 8.586 0 0 1 0 4.54a5.173 5.173 0 0 1 .36-1.93A4.6 4.6 0 0 1 1.51.94 2.93 2.93 0 0 1 3.59 0a1.879 1.879 0 0 1 .81.18 1.63 1.63 0 0 1 .67.56l2.32 3.27a3.422 3.422 0 0 1 .4.7 1.581 1.581 0 0 1 .14.61 1.357 1.357 0 0 1-.21.71 3.4 3.4 0 0 1-.56.71l-.76.79a.535.535 0 0 0-.16.4.908.908 0 0 0 .03.23c.03.08.06.14.08.2a8.3 8.3 0 0 0 .93 1.28c.45.52.93 1.05 1.45 1.58.1.1.21.2.31.3a1 1 0 0 1 .01 1.43z", transform: "translate(110 190)" }), _jsx("path", { id: "Vector-2", "data-name": "Vector", d: "M12.37 4.29a2.54 2.54 0 0 1-.15.85 2.442 2.442 0 0 1-.1.24 4.126 4.126 0 0 1-.68 1.02A4.508 4.508 0 0 1 9.8 7.58c-.01 0-.02.01-.03.01a5.052 5.052 0 0 1-1.92.37 8.334 8.334 0 0 1-3.26-.73 17.564 17.564 0 0 1-3.44-1.98C.76 4.96.37 4.67 0 4.36l3.27-3.27a5.618 5.618 0 0 0 .74.48c.05.02.11.05.18.08a.69.69 0 0 0 .25.04.55.55 0 0 0 .41-.17l.76-.75a3.068 3.068 0 0 1 .72-.56A1.332 1.332 0 0 1 7.04 0a1.6 1.6 0 0 1 .61.13 3.868 3.868 0 0 1 .7.39l3.31 2.35a1.517 1.517 0 0 1 .55.64 2.052 2.052 0 0 1 .16.78z", transform: "translate(117.6 202.04)" })] }) }) });
    };
    return (_jsx(Icon, { component: Image }));
};
export default memo(TelephonIcon);
