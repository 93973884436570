import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import Icon from '@ant-design/icons';
var WeightIcon = function () {
    var Image = function () {
        return _jsx("svg", { xmlns: "http://www.w3.org/2000/svg", width: "20", height: "20", viewBox: "0 0 20 20", fill: "#647386", children: _jsx("g", { id: "noun_Weight_3934961", transform: "translate(-9 -9)", children: _jsxs("g", { id: "Balance_measure_scale_weight", "data-name": "Balance, measure, scale, weight", transform: "translate(9 9)", children: [_jsx("path", { id: "Path_726", "data-name": "Path 726", d: "M34.24 25.589h3.195l-.773-1.574a.515.515 0 1 1 .922-.459l1.031 2.033h3.71a.049.049 0 0 0 .041-.021.039.039 0 0 0 0-.033 4.122 4.122 0 0 0-8.167 0 .039.039 0 0 0 .013.033.049.049 0 0 0 .028.021z", transform: "translate(-28.271 -18.848)" }), _jsx("path", { id: "Path_727", "data-name": "Path 727", d: "M25.756 9H12.244A3.256 3.256 0 0 0 9 12.244v13.512A3.256 3.256 0 0 0 12.244 29h13.512A3.256 3.256 0 0 0 29 25.756V12.244A3.256 3.256 0 0 0 25.756 9zm-11.588 6.4a4.878 4.878 0 0 1 9.663 0 1.017 1.017 0 0 1-.244.807 1.029 1.029 0 0 1-.773.354h-7.629a1.029 1.029 0 0 1-.776-.354 1.017 1.017 0 0 1-.241-.807zm10.685 9.941H13.146a.488.488 0 0 1 0-.976h11.708a.488.488 0 0 1 0 .976z", transform: "translate(-9 -9)" })] }) }) });
    };
    return (_jsx(Icon, { component: Image }));
};
export default memo(WeightIcon);
