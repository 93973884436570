var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import Model from 'models/Model';
import { plainToInstance } from 'class-transformer';
import DoctorClinicAppointmentsReport from './DoctorClinicAppointmentsReport';
var DoctorReportPerDoctor = /** @class */ (function (_super) {
    __extends(DoctorReportPerDoctor, _super);
    function DoctorReportPerDoctor() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DoctorReportPerDoctor.prototype.getTotalPatients = function () {
        return Number(this.total_patients);
    };
    DoctorReportPerDoctor.prototype.getDoctorName = function () {
        return this.doctor_name;
    };
    DoctorReportPerDoctor.prototype.getTotalAppointment = function () {
        return Number(this.appointments_count);
    };
    DoctorReportPerDoctor.prototype.getTotalTransactions = function () {
        return Number(this.total_transactions);
    };
    DoctorReportPerDoctor.prototype.getDoctorId = function () {
        return Number(this.doctor_id);
    };
    DoctorReportPerDoctor.prototype.getClinicAppointments = function () {
        return plainToInstance(DoctorClinicAppointmentsReport, this.clinic_appointments);
    };
    return DoctorReportPerDoctor;
}(Model));
export default DoctorReportPerDoctor;
