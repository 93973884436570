var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { plainToInstance } from 'class-transformer';
import queries from 'constants/queries';
import { Balance, PaginationMetaData } from 'models';
import { useQuery } from 'react-query';
import ApiService from '../ApiService';
var BalanceService = /** @class */ (function (_super) {
    __extends(BalanceService, _super);
    function BalanceService() {
        var _this = _super.call(this) || this;
        _this.balancesRoot = 'balances';
        return _this;
    }
    BalanceService.prototype.useBalances = function (pageNumber, filters) {
        var _this = this;
        if (pageNumber === void 0) { pageNumber = 1; }
        if (filters === void 0) { filters = {}; }
        return useQuery([queries.accounting.balances, pageNumber, filters], function () {
            return _this.get(_this.balancesRoot, __assign(__assign({}, filters), { page: pageNumber }));
        }, {
            keepPreviousData: true,
            select: function (_a) {
                var data = _a.data;
                var balances = plainToInstance(Balance, data === null || data === void 0 ? void 0 : data.balances);
                var meta = plainToInstance(PaginationMetaData, data === null || data === void 0 ? void 0 : data.meta);
                return { balances: balances, meta: meta };
            }
        });
    };
    BalanceService.getInstance = function () {
        if (!BalanceService.balanceService) {
            BalanceService.balanceService = new BalanceService();
        }
        return BalanceService.balanceService;
    };
    return BalanceService;
}(ApiService));
export default BalanceService;
