import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Table } from 'antd';
import localeTranslator from 'library/Hocs/LocalTranslator';
import EmptyComponent from 'components/EmptyComponent';
import '../../style.css';
import './style.css';
var TransactionEntries = function (props) {
    var columns = [
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('tables.transactionEntries.info.id') }),
            key: 'id',
            render: function (text, record) { return _jsx("div", { className: "body---14pt-R", children: record.getId() }); }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('tables.transactionEntries.info.type') }),
            key: 'type',
            render: function (text, record) { return _jsx("div", { className: "body---14pt-R", children: record.getDisplayedType() }); }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('tables.transactionEntries.info.amount') }),
            key: 'amount',
            render: function (text, record) {
                return _jsx("div", { className: "body---14pt-R", children: props.translate('common:price', { amount: record.getAmount() }) });
            }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('tables.transactionEntries.info.account') }),
            key: 'account',
            render: function (text, record) { return _jsx("div", { className: "body---14pt-R", children: record.getAccount().getName() }); }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('tables.transactionEntries.info.entityName') }),
            key: 'entityName',
            render: function (text, record) { return _jsx("div", { className: "body---14pt-R", children: record.getEntityName() }); }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('tables.transactionEntries.info.entityType') }),
            key: 'entityName',
            render: function (text, record) { return _jsx("div", { className: "body---14pt-R", children: record.getEntityType() }); }
        }
    ];
    return (_jsxs("div", { className: "page-subcontent", children: [_jsx("div", { className: "Header-18-M form-title", children: props.translate('tables.transactionEntries.header.transactionEntries') }), _jsx(Table, { columns: columns, pagination: false, dataSource: props.transaction_entries, locale: {
                    emptyText: _jsx(EmptyComponent, {})
                } })] }));
};
export default localeTranslator(TransactionEntries, 'showTransaction');
