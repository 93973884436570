var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { plainToClass } from 'class-transformer';
import Model from 'models/Model';
import { Patient, PackagePrice, Package, AppointmentDetail } from 'models';
var ReservationPackage = /** @class */ (function (_super) {
    __extends(ReservationPackage, _super);
    function ReservationPackage() {
        var _this = _super.call(this) || this;
        _this.repeated = 1;
        return _this;
    }
    ReservationPackage.prototype.getId = function () {
        return Number(this.id);
    };
    ReservationPackage.prototype.getPackageId = function () {
        return Number(this.package_id);
    };
    ReservationPackage.prototype.setPackageId = function (packageId) {
        this.package_id = Number(packageId);
    };
    ReservationPackage.prototype.getPackagePriceId = function () {
        return Number(this.package_price_id);
    };
    ReservationPackage.prototype.setPackagePriceId = function (packagePriceId) {
        this.package_price_id = Number(packagePriceId);
    };
    ReservationPackage.prototype.getRepeated = function () {
        return Number(this.repeated);
    };
    ReservationPackage.prototype.setRepeated = function (repeated) {
        this.repeated = Number(repeated);
    };
    ReservationPackage.prototype.getRemainder = function () {
        return Number(this.remainder);
    };
    ReservationPackage.prototype.getReservationId = function () {
        return Number(this.reservation_id);
    };
    ReservationPackage.prototype.getPatientId = function () {
        return Number(this.patient_id);
    };
    ReservationPackage.prototype.setPatientId = function (patientId) {
        this.patient_id = Number(patientId);
    };
    ReservationPackage.prototype.setPackage = function (currPackage) {
        this.package = plainToClass(Package, currPackage);
    };
    ReservationPackage.prototype.getPackage = function () {
        return plainToClass(Package, this.package);
    };
    ReservationPackage.prototype.getTotalReservationPackagePrice = function () {
        // return this.getRepeated() * this.getPackage().getTotalPrice();
        return 0;
    };
    ReservationPackage.prototype.getTotalUsage = function () {
        var p = this.getPackage();
        // return p.getQuantity() * this.getRepeated() - this.getRemainder();
        return 0;
    };
    ReservationPackage.prototype.isNeedToSettle = function () {
        return this.getRemainder() < 0;
    };
    ReservationPackage.prototype.setPackagePrice = function (packagePrice) {
        this.package_price = plainToClass(PackagePrice, packagePrice);
    };
    ReservationPackage.prototype.getPackagePrice = function () {
        return plainToClass(PackagePrice, this.package_price);
    };
    ReservationPackage.prototype.getStatus = function () {
        return this.status;
    };
    ReservationPackage.prototype.getDisplayedStatus = function () {
        return this.displayed_status;
    };
    ReservationPackage.prototype.setPatient = function (patient) {
        this.patient = plainToClass(Patient, patient);
    };
    ReservationPackage.prototype.getPatient = function () {
        return plainToClass(Patient, this.patient);
    };
    ReservationPackage.prototype.getCreatedAt = function () {
        return this.dateForamtter(new Date(this.created_at), 'DD/MM/YYYY');
    };
    ReservationPackage.prototype.getAppointmentDetails = function () {
        return plainToClass(AppointmentDetail, this.appointment_details);
    };
    ReservationPackage.prototype.setNotes = function (notes) {
        this.notes = notes;
    };
    ReservationPackage.prototype.getNotes = function () {
        return this.notes;
    };
    return ReservationPackage;
}(Model));
export default ReservationPackage;
