var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { plainToClass, plainToInstance } from 'class-transformer';
import Model from 'models/Model';
import { Appointment, ReservationPackage, Settlement } from 'models';
var AppointmentDetail = /** @class */ (function (_super) {
    __extends(AppointmentDetail, _super);
    function AppointmentDetail() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AppointmentDetail.prototype.getId = function () {
        return Number(this.id);
    };
    AppointmentDetail.prototype.getQuantity = function () {
        return Number(this.quantity);
    };
    AppointmentDetail.prototype.setQuantity = function (quantity) {
        this.quantity = Number(quantity);
    };
    AppointmentDetail.prototype.getAppointment = function () {
        return plainToClass(Appointment, this.appointment);
    };
    AppointmentDetail.prototype.getAppointmentId = function () {
        return Number(this.appointment_id);
    };
    AppointmentDetail.prototype.setAppointment = function (appointment) {
        this.appointment = appointment;
    };
    AppointmentDetail.prototype.setAppointmentId = function (appointmentId) {
        this.appointment_id = appointmentId;
    };
    AppointmentDetail.prototype.getReservationPackage = function () {
        return plainToClass(ReservationPackage, this.reservation_package);
    };
    AppointmentDetail.prototype.setReservationPackage = function (reservationPackage) {
        this.reservation_package = reservationPackage;
    };
    AppointmentDetail.prototype.getReservationPackageId = function () {
        return Number(this.reservation_package_id);
    };
    AppointmentDetail.prototype.setReservationPackageId = function (reservationPackageId) {
        this.reservation_package_id = reservationPackageId;
    };
    AppointmentDetail.prototype.getNote = function () {
        return this.note;
    };
    AppointmentDetail.prototype.setNote = function (note) {
        this.note = note;
    };
    AppointmentDetail.prototype.setSettlement = function (settlement) {
        this.settlement = plainToInstance(Settlement, settlement);
    };
    AppointmentDetail.prototype.getSettlement = function () {
        return this.settlement;
    };
    return AppointmentDetail;
}(Model));
export default AppointmentDetail;
