import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import Icon from '@ant-design/icons';
var WrongMarkIcon = function () {
    var Image = function () {
        return _jsx("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "-10 -11 40 40", fill: "#fff", children: _jsx("path", { d: "m13.272 9.339 4.6-4.6A2.781 2.781 0 1 0 13.939.807l-4.6 4.6-4.6-4.6A2.781 2.781 0 0 0 .807 4.739l4.6 4.6-4.6 4.6a2.8 2.8 0 0 0 0 3.932 2.776 2.776 0 0 0 3.932 0l4.6-4.6 4.6 4.6a2.776 2.776 0 0 0 3.932 0 2.8 2.8 0 0 0 0-3.932z" }) });
    };
    return (_jsx(Icon, { component: Image }));
};
export default memo(WrongMarkIcon);
