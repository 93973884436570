import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Table } from 'antd';
import localeTranslator from 'library/Hocs/LocalTranslator';
import EmptyComponent from 'components/EmptyComponent';
import './style.css';
import '../../style.css';
var Branches = function (props) {
    var columns = [
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('tables.branches.id') }),
            key: 'id',
            render: function (text, record, index) {
                return _jsx("div", { className: "body---14pt-R", children: record.getId() }, 'id-' + index);
            }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('tables.branches.address') }),
            key: 'address',
            render: function (text, record, index) {
                return _jsx("div", { className: "body---14pt-R table-body-content", children: record.getAddress() }, 'address-' + index);
            }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('tables.branches.telephone') }),
            key: 'telephone',
            render: function (text, record, index) {
                return _jsx("div", { className: "body---14pt-R table-body-content", children: record.getMainTelephone() }, 'telephone-' + index);
            }
        }
    ];
    return (_jsxs("div", { className: "page-subcontent", children: [_jsx("div", { className: "Header-18-M form-title", children: props.translate('tables.branches.header') }), _jsx(Table, { columns: columns, pagination: false, dataSource: props.supplier.getSupplierBranches(), locale: {
                    emptyText: _jsx(EmptyComponent, {})
                } })] }));
};
export default localeTranslator(Branches, 'showSupplier');
