import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Form, Row, Col, Input, Button } from 'antd';
import localeTranslator from 'library/Hocs/LocalTranslator';
import { Diagnose, DiagnoseDetail } from 'models';
import './style.css';
var TextArea = Input.TextArea;
var NewDiagnose = function (props) {
    var form = Form.useForm()[0];
    var diagnoseDetail = new DiagnoseDetail();
    var diagnose = new Diagnose();
    var onChangeDiagnose = function (event) {
        diagnoseDetail.setDiagnoseText(event.target.value);
        diagnose.setDetails(diagnoseDetail);
    };
    var submit = function () {
        props.createDiagnose(diagnose);
        props.setShowDiagnoseForm(false);
    };
    return (_jsx("div", { className: "diagnose-form-part", children: _jsx(Form, { layout: 'vertical', form: form, initialValues: { layout: 'vertical' }, size: 'large', onFinish: submit, children: _jsx(Row, { children: _jsxs(Col, { xs: 24, sm: 24, lg: 24, xl: 24, children: [_jsx(Form.Item, { name: "description", className: "form-item body---14pt-R", rules: [
                                { required: true, message: props.translate('diagnoses.form.errors.description') }
                            ], children: _jsx(TextArea, { className: "text-area-border space-item", placeholder: props.translate('diagnoses.form.description'), onChange: onChangeDiagnose, bordered: false, rows: 3 }) }), _jsx("div", { className: "new-diagnose-line" }), _jsx(Form.Item, { children: _jsx(Button, { type: "primary", htmlType: "submit", className: "add-diagnose-button Header-16-M", children: props.translate('common:buttons.submit') }) })] }) }) }) }));
};
export default localeTranslator(NewDiagnose, 'patientLog');
