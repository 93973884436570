import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Col, Row, Space, Menu, Dropdown } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import localeTranslator from 'library/Hocs/LocalTranslator';
import './style.css';
var VisitPackageCard = function (props) {
    var currentPackagePrice = props.reservationPackage.getPackagePrice();
    var onPackageClicked = function () {
        !props.isSelected ?
            props.onSelectPackage(props.reservationPackage.getId(), props.reservationPackage)
            :
                props.onDeselectPackage(props.reservationPackage.getId(), props.reservationPackage);
    };
    // const onSettlementButtonClick = () => props.onSettlementPress && props.onSettlementPress(props.reservationPackage);
    var onUsageButtonClick = function () { return props.onAddUsagePress && props.onAddUsagePress(props.reservationPackage); };
    var onUnassignButtonClick = function () { return props.onUnassignPress && props.onUnassignPress(props.reservationPackage); };
    var menu = function () {
        return (_jsxs(Menu, { children: [!!props.onUnassignPress &&
                    _jsx(Menu.Item, { onClick: onUnassignButtonClick, children: "Unassgin" }, "1"), !!props.onAddUsagePress &&
                    _jsx(Menu.Item, { onClick: onUsageButtonClick, children: "Add Usage" }, "2")] }));
    };
    return (_jsx("div", { className: "".concat(props.backgroundClass, " drawer-package-detail"), onClick: onPackageClicked, children: _jsxs(Space, { direction: "vertical", size: 10, children: [_jsxs(Row, { children: [_jsx(Col, { xs: 14, lg: 10, children: _jsx("div", { className: "Header-16-M", children: props.translate('packageName') }) }), _jsx(Col, { xs: 4, lg: 8 }), _jsx(Col, { xs: 6, lg: 6, children: _jsx(Dropdown.Button, { icon: _jsx(EllipsisOutlined, { className: 'action-button' }), overlay: menu(), className: "horizontal-three-dots three-dot" }) })] }), _jsx("div", { className: "body---14pt-R", children: currentPackagePrice.getPackageName() }), _jsxs(Row, { children: [_jsx(Col, { xl: 12, children: _jsxs(Space, { direction: "vertical", size: 15, children: [_jsx("div", { className: "body---14pt-R description-text", children: props.translate('remainderMoney') }), _jsx("div", { className: "body---14pt-R", children: props.moneyRemainder })] }) }), _jsx(Col, { xl: 12, children: _jsxs(Space, { direction: "vertical", size: 15, children: [_jsx("div", { className: "body---14pt-R description-text", children: props.translate('remainderQunatity') }), _jsx("div", { className: "body---14pt-R", children: props.reservationPackage.getRemainder() })] }) })] })] }) }));
};
export default localeTranslator(VisitPackageCard, 'visitPackageCard');
