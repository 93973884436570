import { jsx as _jsx } from "react/jsx-runtime";
import ProductCategories from 'pages/inventory/ProductCategories';
import NewProductCategory from 'pages/inventory/NewProductCategory';
import { Route } from 'react-router';
import AuthorizationService from '../AuthorizationService';
import { Create, Index } from 'constants/actions';
import { ProductCategory as ProductCategoryResource } from 'constants/resources';
var ProductCategoriesRoutes = function () {
    return ([
        _jsx(Route, { caseSensitive: true, path: '/product_categories', element: AuthorizationService.getAuthorizedElement(_jsx(ProductCategories, {}), Index, ProductCategoryResource) }, 'product-categories-route'),
        _jsx(Route, { caseSensitive: true, path: '/product_categories/new', element: AuthorizationService.getAuthorizedElement(_jsx(NewProductCategory, {}), Create, ProductCategoryResource) }, 'new-product-categories-route')
    ]);
};
export default ProductCategoriesRoutes;
