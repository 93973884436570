import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Row, Col } from 'antd';
import localeTranslator from 'library/Hocs/LocalTranslator';
import { FormInput, FormSelect } from 'components/AntEnhancement/FormControls';
import './style.css';
import DocumentLineForm from './DocumentLineForm';
var DocumentLineGroupForm = function (props) {
    var onChangeName = function (event) {
        props.documentLineGroup.setName(event.target.value);
    };
    var onChangePaidOnEntity = function (value) { return props.documentLineGroup.setPaidOn(String(value)); };
    var onChangePaidFromEntity = function (value) { return props.documentLineGroup.setPaidFrom(String(value)); };
    var getPaidFromEntities = function () {
        return props.paidFromEntities.map(function (paidFromEntity) { return ({
            value: paidFromEntity.getValue(),
            title: paidFromEntity.getName()
        }); });
    };
    var getPaidonEntities = function () {
        return props.paidOnEntities.map(function (paidOnEntity) { return ({
            value: paidOnEntity.getValue(),
            title: paidOnEntity.getName()
        }); });
    };
    return (_jsx(_Fragment, { children: _jsxs("div", { className: "form-part", children: [_jsxs(Row, { children: [_jsx(Col, { xs: 24, sm: 24, lg: 6, xl: 6, children: _jsx(FormInput, { label: props.translate('name'), name: "name", placeHolder: props.translate('name'), rules: [
                                    { required: true, message: props.translate('errors.name') }
                                ], onChange: onChangeName, initialValue: props.documentLineGroup.getName() }) }), _jsx(Col, { xs: 24, sm: 24, lg: 1, xl: 1 }), _jsx(Col, { xs: 24, sm: 24, lg: 7, xl: 7, children: _jsx(FormSelect, { label: props.translate('paidFrom'), name: "paidFrom", requiredMark: true, rules: [
                                    { required: true, message: props.translate('errors.paidFrom') }
                                ], placeHolder: props.translate('paidFrom'), onSelect: onChangePaidFromEntity, options: getPaidFromEntities(), initialValue: props.documentLineGroup.getPaidFrom() || null }) }), _jsx(Col, { xs: 24, sm: 24, lg: 1, xl: 1 }), _jsx(Col, { xs: 24, sm: 24, lg: 7, xl: 7, children: _jsx(FormSelect, { label: props.translate('paidOn'), name: "paidOn", requiredMark: true, rules: [
                                    { required: true, message: props.translate('errors.paidOn') }
                                ], placeHolder: props.translate('paidOn'), onSelect: onChangePaidOnEntity, options: getPaidonEntities(), initialValue: props.documentLineGroup.getPaidOn() || null }) })] }), props.documentLines.map(function (documentLine, index) {
                    documentLine.setDocumentId(props.document.getId());
                    return (_jsx(DocumentLineForm, { accounts: props.accounts, documentLine: documentLine, entityTypes: props.entityTypes, transactionEntryTypes: props.transactionEntryTypes, identifier: "documentLineRow-".concat(index) }, "documentLineRow-".concat(index)));
                })] }) }));
};
export default localeTranslator(DocumentLineGroupForm, 'documentLineGroupForm');
