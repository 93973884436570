import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import Icon from '@ant-design/icons';
var ReceiptMinusIcon = function () {
    var Image = function () {
        return _jsx("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 15 15", width: "24", height: "24", fill: "#fff", children: _jsxs("g", { id: "receipt-minus", transform: "translate(-556 -188)", children: [_jsx("path", { id: "Vector", style: { fill: 'none', stroke: '#8f9eb3', strokeLinecap: 'round', strokeLinejoin: 'round' }, d: "M3.988 2.659v1.608a1.6 1.6 0 0 1-1.715 1.715H0V1.336A1.342 1.342 0 0 1 1.343 0a2.655 2.655 0 0 1 2.645 2.659z", transform: "translate(566.634 189.329)" }), _jsx("path", { id: "Vector-2", "data-name": "Vector", style: { fill: 'none', stroke: '#8f9eb3', strokeLinecap: 'round', strokeLinejoin: 'round' }, d: "M0 3.323v9.3a.663.663 0 0 0 1.063.532l1.137-.846a.67.67 0 0 1 .877.066l1.1 1.11a.67.67 0 0 0 .944 0l1.117-1.117a.659.659 0 0 1 .864-.06l1.137.851a.666.666 0 0 0 1.061-.531V1.329A1.333 1.333 0 0 1 10.634 0H2.659A2.377 2.377 0 0 0 0 2.659z", transform: "translate(557.329 189.329)" }), _jsx("path", { id: "Vector-3", "data-name": "Vector", style: { fill: 'none', stroke: '#8f9eb3', strokeLinecap: 'round', strokeLinejoin: 'round' }, d: "M0 0h3.655", transform: "translate(560.154 194.646)" })] }) });
    };
    return (_jsx(Icon, { component: Image }));
};
export default memo(ReceiptMinusIcon);
