import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Divider } from 'antd';
import Article from 'library/components/Article';
import localeTranslator from 'library/Hocs/LocalTranslator';
import './style.css';
var PatientDetails = function (props) {
    var renderMedicalDetails = function () {
        var _a, _b;
        return (_jsxs(_Fragment, { children: [_jsx(Article, { title: props.translate('medicalConditions'), description: ((_a = props.patientDetails) === null || _a === void 0 ? void 0 : _a.getMedicalConditions()) || '' }), _jsx("div", { className: "medicine-divider" }), _jsx(Article, { title: props.translate('medications'), description: ((_b = props.patientDetails) === null || _b === void 0 ? void 0 : _b.getMedication()) || '' })] }));
    };
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "Header-16-M prescription-title", children: props.title }), _jsxs("div", { className: "prescription-items-container", children: [_jsx(Divider, { type: "vertical", className: "verticale-divider" }), _jsx("div", { className: "prescription-item", children: renderMedicalDetails() })] })] }));
};
export default localeTranslator(PatientDetails, 'patientDetail');
