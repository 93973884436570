import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import Icon from '@ant-design/icons';
var PatientIcon = function () {
    var Image = function () {
        return _jsx("svg", { xmlns: "http://www.w3.org/2000/svg", width: "1.5em", height: "1.5em", fill: "currentColor", viewBox: "0 0 20 20", focusable: "false", "data-icon": "user", "aria-hidden": "true", children: _jsxs("g", { id: "Group_303", "data-name": "Group 303", transform: "translate(7306.999 16421)", children: [_jsx("g", { id: "Group_300", "data-name": "Group 300", transform: "translate(-7344.999 -16654)", children: _jsxs("g", { id: "Group_42", "data-name": "Group 42", transform: "translate(-8211 -1814)", children: [_jsx("circle", { id: "Ellipse_13", "data-name": "Ellipse 13", cx: "4", cy: "4", r: "4", transform: "translate(8252 2047)" }), _jsx("path", { id: "Path_50", "data-name": "Path 50", d: "M8 21h8a3.009 3.009 0 0 0 3-3v-1a5 5 0 0 0-5-5h-4a5 5 0 0 0-5 5v1a3.009 3.009 0 0 0 3 3z", transform: "translate(8244 2044)" })] }) }), _jsx("circle", { id: "Ellipse_51", "data-name": "Ellipse 51", cx: "3", cy: "3", r: "3", transform: "translate(-7297 -16413)" }), _jsx("path", { id: "Path_506", "data-name": "Path 506", d: "M13.714 15.291h-.137a.48.48 0 0 1-.48-.48v-.617h-.617a.48.48 0 0 1-.48-.48v-.137a.48.48 0 0 1 .48-.48h.62v-.617a.48.48 0 0 1 .48-.48h.137a.48.48 0 0 1 .48.48v.62h.617a.48.48 0 0 1 .48.48v.137a.48.48 0 0 1-.48.48h-.617v.617a.48.48 0 0 1-.483.477z", transform: "translate(-7307.516 -16423.646)" })] }) });
    };
    return (_jsx(Icon, { component: Image }));
};
export default memo(PatientIcon);
