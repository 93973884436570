import { jsx as _jsx } from "react/jsx-runtime";
import Doctors from 'pages/Doctors';
import NewDoctor from 'pages/NewDoctor';
import ShowDoctor from 'pages/ShowDoctor';
import AuthorizationService from '../AuthorizationService';
import { Index, Create, Show } from 'constants/actions';
import { Doctor as DoctorsResource } from 'constants/resources';
import { Route } from 'react-router';
var DoctorRoutes = function () {
    return ([
        _jsx(Route, { caseSensitive: true, path: '/doctors/new', element: AuthorizationService.getAuthorizedElement(_jsx(NewDoctor, {}), Create, DoctorsResource) }, 'NewDoctor-route'),
        _jsx(Route, { caseSensitive: true, path: '/doctors', element: AuthorizationService.getAuthorizedElement(_jsx(Doctors, {}), Index, DoctorsResource) }, 'doctors-route'),
        _jsx(Route, { caseSensitive: true, path: '/doctors/:id', element: AuthorizationService.getAuthorizedElement(_jsx(ShowDoctor, {}), Show, DoctorsResource) }, 'showDoctor-route')
    ]);
};
export default DoctorRoutes;
