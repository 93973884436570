import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Tabs } from 'antd';
import PatientReservationPackages from './PatientReservationPackages';
import PatientAppointments from './PatientAppointments';
import '../../style.css';
import localeTranslator from 'library/Hocs/LocalTranslator';
import PatientTransactions from './PatientTransactions';
var TabPane = Tabs.TabPane;
var PatientTabs = function (props) {
    return (_jsx("div", { children: _jsxs(Tabs, { defaultActiveKey: '1', id: 'patient-tabs', children: [_jsx(TabPane, { className: 'Header-16-M', tab: props.translate('tab.patientPackages'), children: _jsx(PatientReservationPackages, { patient: props.patient }) }, 'tab-1'), _jsx(TabPane, { className: 'Header-16-M', tab: props.translate('tab.patientFinancials'), children: _jsx(PatientTransactions, { patient: props.patient }) }, 'tab-2'), _jsx(TabPane, { className: 'Header-16-M', tab: props.translate('tab.patientVisits'), children: _jsx(PatientAppointments, { patient: props.patient }) }, 'tab-3')] }) }));
};
export default localeTranslator(PatientTabs, 'showPatient');
