var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { plainToClass } from 'class-transformer';
import { UnitMeasurement } from 'models';
import Model from 'models/Model';
var Package = /** @class */ (function (_super) {
    __extends(Package, _super);
    function Package() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Package.prototype.getId = function () {
        return Number(this.id);
    };
    Package.prototype.getName = function () {
        return this.name;
    };
    Package.prototype.setName = function (name) {
        this.name = name;
    };
    Package.prototype.getUnitMeasurementId = function () {
        return Number(this.unit_measurement_id);
    };
    Package.prototype.setUnitMeasurementId = function (unitMeasurementId) {
        this.unit_measurement_id = Number(unitMeasurementId);
    };
    Package.prototype.getDescription = function () {
        return this.description;
    };
    Package.prototype.setDescription = function (description) {
        this.description = description;
    };
    Package.prototype.getEntityType = function () {
        return this.entity_type;
    };
    Package.prototype.setEntityType = function (entityType) {
        this.entity_type = entityType;
    };
    Package.prototype.setEntityId = function (entityId) {
        this.entity_id = entityId;
    };
    Package.prototype.getEntityId = function (entityId) {
        this.entity_id = entityId;
    };
    Package.prototype.getEntity = function () {
        return this.entity;
    };
    Package.prototype.setUnitMeasurement = function (unitMeasurement) {
        this.unitMeasurement = unitMeasurement;
    };
    Package.prototype.getUnitMeasurement = function () {
        return plainToClass(UnitMeasurement, this.unitMeasurement);
    };
    Package.prototype.getIsEnabled = function () {
        return this.is_enabled;
    };
    Package.prototype.setIsEnabled = function (isEnabled) {
        this.is_enabled = isEnabled;
    };
    Package.prototype.getEntityName = function () {
        return this.entity_name;
    };
    return Package;
}(Model));
export default Package;
