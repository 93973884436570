import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import ar from 'dayjs/locale/ar';
dayjs.locale(ar);
dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);
export var getDiffFromNow = function (date) {
    var formattedDate = dayjs(date.toString()).format('YYYY-MM-DD');
    return Number(dayjs(formattedDate).fromNow(true).split(' ')[0]);
};
export var formatDate = function (date, formatter) {
    return dayjs(date).locale(ar).format(formatter);
};
