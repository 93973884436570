import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Layout, Menu, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { UserOutlined, HomeFilled, ApartmentOutlined, LogoutOutlined, SettingFilled, ContainerFilled, CreditCardFilled, CalendarFilled, BookFilled, FolderOpenFilled, TagFilled, ProductFilled, ShopFilled, ShoppingFilled, TruckFilled, AuditOutlined } from '@ant-design/icons';
import { StethoscopeIcon, ReservationIcon, EmployeeIcon, TransactionsIcon, OtherIcon, MedicalMahcineIcon, PatientIcon, PackagesIcon, FinancialIcon, InventoryIcon, } from 'assets/icons';
import services from 'services';
import localeTranslator from 'library/Hocs/LocalTranslator';
import './style.css';
var Sider = Layout.Sider;
var SubMenu = Menu.SubMenu;
var Text = Typography.Text;
var SideBar = function (props) {
    var navigate = useNavigate();
    var userService = services.userService, clinicService = services.clinicService, permissionService = services.permissionService;
    var _a = userService.useCurrentUser(), currentUSer = _a.data, isUserLoading = _a.isLoading;
    // const { data: currentClinic, isLoading: isClinicLoading } = clinicService.useClinicInfo();
    var clinics = clinicService.useClinics().data;
    var changeLoggedInClinic = userService.useChangeLoggedInClinic().mutate;
    var logout = userService.useLogout().mutate;
    var onCollapse = function (collapsed) { return props.setCollapsed(collapsed); };
    var SubMenuTitleComponent = function () {
        return (_jsx("div", { className: "user-dropdown", children: !isUserLoading &&
                _jsx(Text, { className: "body---14pt-R name", ellipsis: { tooltip: currentUSer === null || currentUSer === void 0 ? void 0 : currentUSer.getName() }, children: currentUSer === null || currentUSer === void 0 ? void 0 : currentUSer.getName() }) }));
    };
    var goToHome = function () { return navigate('/'); };
    var goToPatients = function () { return navigate('/patients'); };
    var goToMedicalMachines = function () { return navigate('/medical-machines'); };
    var goToDoctors = function () { return navigate('/doctors'); };
    var goToEmployees = function () { return navigate('/employees'); };
    var goToAppointments = function () { return navigate('/appointments'); };
    var goToReservationPackages = function () { return navigate('/reservation-packages'); };
    var goToPackages = function () { return navigate('/packages'); };
    var goToSettings = function () { return navigate('/settings'); };
    var goToAccounts = function () { return navigate('/accounts'); };
    var goToAccountTypes = function () { return navigate('/account_types'); };
    var goToBalances = function () { return navigate('/balances'); };
    var goToDocuments = function () { return navigate('/documents'); };
    var goToFinancialPeriods = function () { return navigate('/financial_periods'); };
    var goToTransactions = function () { return navigate('/transactions'); };
    var goToProductCategories = function () { return navigate('/product_categories'); };
    var goToProducts = function () { return navigate('/products'); };
    var goToSuppliers = function () { return navigate('/suppliers'); };
    var goToSupplierBranches = function () { return navigate('/supplier_branches'); };
    var goToStocks = function () { return navigate('/stocks'); };
    var goToStockMovements = function () { return navigate('/stock_movements'); };
    var goToClinicReports = function () { return navigate('/reports/clinic'); };
    var goToPatientReports = function () { return navigate('/reports/patient'); };
    var goToDoctorReports = function () { return navigate('/reports/doctor'); };
    var goToPackageReports = function () { return navigate('/reports/package'); };
    var onClinicPress = function (clinic) {
        return function () { return changeLoggedInClinic({ successMessage: 'Clinic Changed Successfully', clinic: clinic }); };
    };
    return (_jsxs(Sider, { className: 'sider-color side-bar', collapsible: true, collapsed: props.collapsed, onCollapse: onCollapse, breakpoint: "md", reverseArrow: (currentUSer === null || currentUSer === void 0 ? void 0 : currentUSer.getLanguage()) === 'ar', width: 300, children: [_jsx("div", { className: "logo" }), _jsxs(Menu, { className: "sider-color sider-text-color menu-items", defaultSelectedKeys: ['1'], mode: "inline", children: [_jsxs(SubMenu, { icon: props.collapsed ? _jsx(UserOutlined, { width: "20", height: "20" }) : _jsx(_Fragment, {}), title: _jsx(SubMenuTitleComponent, {}), className: "user-menu-item menu-items", children: [_jsx(SubMenu, { icon: _jsx(ApartmentOutlined, { style: { margin: 0 } }), title: 'Branches', className: "user-menu-item menu-items", children: _jsx(Menu.ItemGroup, { children: clinics && clinics.map(function (clinic) {
                                        return _jsx(Menu.Item, { onClick: onClinicPress(clinic), children: clinic.getName() }, clinic.getId());
                                    }) }) }, "sub3"), _jsx(Menu.Item, { icon: _jsx(LogoutOutlined, {}), className: "body---14pt-R", onClick: logout, children: "Logout" }, "100")] }, "sub1"), _jsx(Menu.Item, { icon: _jsx(HomeFilled, { width: "20", height: "20" }), className: "body---14pt-R", onClick: goToHome, children: props.translate('home') }, "1"), _jsx(Menu.Item, { icon: _jsx(StethoscopeIcon, {}), className: "body---14pt-R", onClick: goToDoctors, children: props.translate('doctors') }, "2"), _jsx(Menu.Item, { icon: _jsx(PatientIcon, {}), onClick: goToPatients, className: "body---14pt-R", children: props.translate('patients') }, "4"), _jsx(Menu.Item, { icon: _jsx(ContainerFilled, {}), className: "body---14pt-R", onClick: goToReservationPackages, children: props.translate('reservationPackages') }, "5"), _jsx(Menu.Item, { icon: _jsx(ReservationIcon, {}), className: "body---14pt-R", onClick: goToAppointments, children: props.translate('appointments') }, "6"), _jsxs(SubMenu, { icon: _jsx(OtherIcon, {}), title: props.translate('other'), className: "body---14pt-R menu-items", children: [_jsx(Menu.Item, { icon: _jsx(EmployeeIcon, {}), className: "body---14pt-R menu-items", onClick: goToEmployees, children: props.translate('employees') }, "3"), _jsx(Menu.Item, { icon: _jsx(MedicalMahcineIcon, {}), onClick: goToMedicalMachines, className: "body---14pt-R menu-items", children: props.translate('medicalMachines') }, "7"), _jsx(Menu.Item, { icon: _jsx(PackagesIcon, {}), className: "body---14pt-R menu-items", onClick: goToPackages, children: props.translate('packages') }, "11"), _jsx(Menu.Item, { icon: _jsx(SettingFilled, {}), className: "body---14pt-R menu-items", onClick: goToSettings, children: props.translate('settings') }, "12")] }, "sub2"), permissionService.hasAnyFinanicePermission() &&
                        _jsxs(SubMenu, { icon: _jsx(FinancialIcon, {}), title: props.translate('accounting'), className: "body---14pt-R menu-items", children: [permissionService.hasAccountPermission() &&
                                    _jsx(Menu.Item, { icon: _jsx(CreditCardFilled, {}), className: "body---14pt-R menu-items", onClick: goToAccounts, children: props.translate('accounts') }, "accounting-sub-1"), permissionService.hasAccountTypePermission() &&
                                    _jsx(Menu.Item, { icon: _jsx(TagFilled, {}), onClick: goToAccountTypes, className: "body---14pt-R menu-items", children: props.translate('accountTypes') }, "accounting-sub-2"), permissionService.hasFinancialPeriodPermission() &&
                                    _jsx(Menu.Item, { icon: _jsx(CalendarFilled, {}), className: "body---14pt-R menu-items", onClick: goToFinancialPeriods, children: props.translate('financialPeriods') }, "accounting-sub-3"), permissionService.hasBalancePermission() &&
                                    _jsx(Menu.Item, { icon: _jsx(FolderOpenFilled, {}), className: "body---14pt-R menu-items", onClick: goToBalances, children: props.translate('balances') }, "accounting-sub-4"), permissionService.hasDocumentPermission() &&
                                    _jsx(Menu.Item, { icon: _jsx(BookFilled, {}), className: "body---14pt-R menu-items", onClick: goToDocuments, children: props.translate('documents') }, "accounting-sub-5"), permissionService.hasTransactionPermission() &&
                                    _jsx(Menu.Item, { icon: _jsx(TransactionsIcon, {}), className: "body---14pt-R menu-items", onClick: goToTransactions, children: props.translate('transactions') }, "accounting-sub-6")] }, "accounting-sub"), permissionService.hasAnyInventoryPermission() &&
                        _jsxs(SubMenu, { icon: _jsx(InventoryIcon, {}), title: props.translate('inventory'), className: "body---14pt-R menu-items", children: [permissionService.hasProductTypePermission() &&
                                    _jsx(Menu.Item, { icon: _jsx(CreditCardFilled, {}), className: "body---14pt-R menu-items", onClick: goToProductCategories, children: props.translate('productCategories') }, "inventory-sub-1"), permissionService.hasProductPermission() &&
                                    _jsx(Menu.Item, { icon: _jsx(ProductFilled, {}), onClick: goToProducts, className: "body---14pt-R menu-items", children: props.translate('products') }, "inventory-sub-2"), permissionService.hasSupplierPermission() &&
                                    _jsx(Menu.Item, { icon: _jsx(TruckFilled, {}), className: "body---14pt-R menu-items", onClick: goToSuppliers, children: props.translate('suppliers') }, "inventory-sub-3"), permissionService.hasStockPermission() &&
                                    _jsx(Menu.Item, { icon: _jsx(ShopFilled, {}), className: "body---14pt-R menu-items", onClick: goToStocks, children: props.translate('stocks') }, "inventory-sub-5"), permissionService.hasStockMovementPermission() &&
                                    _jsx(Menu.Item, { icon: _jsx(ShoppingFilled, {}), className: "body---14pt-R menu-items", onClick: goToStockMovements, children: props.translate('stockMovements') }, "inventory-sub-6")] }, "inventory-sub"), permissionService.hasAnyReportPermission() &&
                        _jsxs(SubMenu, { icon: _jsx(AuditOutlined, {}), title: props.translate('reports'), className: "body---14pt-R menu-items", children: [permissionService.hasClinicReportsPermission() &&
                                    _jsx(Menu.Item, { icon: _jsx(CreditCardFilled, {}), className: "body---14pt-R menu-items", onClick: goToClinicReports, children: props.translate('clinicReports') }, "reports-sub-1"), permissionService.hasDoctorReportsPermission() &&
                                    _jsx(Menu.Item, { icon: _jsx(ProductFilled, {}), onClick: goToDoctorReports, className: "body---14pt-R menu-items", children: props.translate('doctorReports') }, "reports-sub-2"), permissionService.hasPatientReportsPermission() &&
                                    _jsx(Menu.Item, { icon: _jsx(TruckFilled, {}), className: "body---14pt-R menu-items", onClick: goToPatientReports, children: props.translate('patientReports') }, "reports-sub-3"), permissionService.hasPackageReportsPermission() &&
                                    _jsx(Menu.Item, { icon: _jsx(ShopFilled, {}), className: "body---14pt-R menu-items", onClick: goToPackageReports, children: props.translate('packageReports') }, "reports-sub-5")] }, "reports-sub")] })] }));
};
export default localeTranslator(SideBar, 'sidebar');
