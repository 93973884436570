import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Card, Col, Row, Space, Table, Tag } from 'antd';
import StatisticCard from 'components/AntEnhancement/Statistics/StatisticCard';
import EmptyComponent from 'components/EmptyComponent';
import ReportHeader from 'components/PageHeader/ReportHeader';
import localeTranslator from 'library/Hocs/LocalTranslator';
import services from 'services';
var DoctorReports = function (props) {
    var _a;
    var reportService = services.reportService;
    var report = reportService.useDoctorReport().data;
    var columns = [
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('doctor') }),
            key: 'doctor',
            render: function (text, record, index) {
                return _jsx("div", { className: "body---14pt-R", children: record.getDoctorName() }, 'doctor-' + index);
            }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('clinicAppointments') }),
            key: 'patientName',
            render: function (text, record, index) {
                return _jsx("div", { className: "body---14pt-R table-body-content", children: record.getClinicAppointments().map(function (clinicAppointments) {
                        return (_jsxs(Tag, { bordered: false, color: 'blue', children: [" ", "".concat(clinicAppointments.getClinicName(), "(").concat(clinicAppointments.getTotalAppointment(), ")")] }));
                    }) }, 'patientName-' + index);
            }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('totalPatientsHandled') }),
            key: 'totalPatientsHandle',
            render: function (text, record, index) {
                return _jsx("div", { className: "body---14pt-R table-body-content", children: record.getTotalPatients() }, 'totalPatientsHandle-' + index);
            }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('totalAppointments') }),
            key: 'totalAppointments',
            render: function (text, record, index) {
                return _jsx("div", { className: "body---14pt-R table-body-content", children: record.getTotalAppointment() }, 'totalAppointments-' + index);
            }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('totalTransactions') }),
            key: 'totalTransactions',
            render: function (text, record, index) {
                return _jsx("div", { className: "body---14pt-R table-body-content", children: record.getTotalTransactions() }, 'totalTransactions-' + index);
            }
        }
    ];
    return (_jsxs(_Fragment, { children: [_jsx(ReportHeader, { title: props.translate('title') }), _jsx("div", { id: "profile-content", className: "page-content", style: { marginTop: '20px' }, children: report &&
                    _jsx(_Fragment, { children: _jsxs(Space, { direction: 'vertical', size: 25, children: [_jsxs(Row, { gutter: [25, 25], children: [_jsx(Col, { xs: 24, sm: 24, lg: 6, xl: 6, children: _jsx(StatisticCard, { title: props.translate('doctors'), value: report.getTotalDoctors() }) }), _jsx(Col, { xs: 24, sm: 24, lg: 6, xl: 6, children: _jsx(StatisticCard, { title: props.translate('totalPatientsHandled'), value: report.getTotalPatients() }) }), _jsx(Col, { xs: 24, sm: 24, lg: 6, xl: 6, children: _jsx(StatisticCard, { title: props.translate('appointments'), value: report.getTotalAppointment() }) }), _jsx(Col, { xs: 24, sm: 24, lg: 6, xl: 6, children: _jsx(StatisticCard, { title: props.translate('totalTransactions'), value: report.getTotalTransactions() }) })] }), _jsxs(Card, { children: [_jsxs("div", { className: "Header-18-M title", children: ["Doctors ", _jsx(Tag, { bordered: false, color: "purple", children: report.getTotalDoctors() })] }), _jsx(Table, { columns: columns, className: 'Header-R-14', pagination: {
                                                position: ['bottomRight'],
                                                pageSize: 25,
                                                total: (_a = report.getDoctorsReport()) === null || _a === void 0 ? void 0 : _a.length,
                                                showSizeChanger: false,
                                                responsive: true
                                            }, 
                                            // scroll={{ x: 1300 }}
                                            dataSource: report.getDoctorsReport(), locale: {
                                                emptyText: _jsx(EmptyComponent, {})
                                            } })] })] }) }) })] }));
};
export default localeTranslator(DoctorReports, 'doctorReports');
