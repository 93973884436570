import { jsx as _jsx } from "react/jsx-runtime";
import { Empty, Button } from 'antd';
import localeTranslator from 'library/Hocs/LocalTranslator';
var EmptyComponent = function (props) {
    var defaultImage = 'https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg';
    var defaultDescription = props.translate('empty.description');
    return (_jsx(Empty, { image: props.imagePath || defaultImage, imageStyle: {
            height: 200,
        }, description: _jsx("span", { className: "Header-R-16", children: props.description || defaultDescription }), children: props.buttonFunction &&
            _jsx(Button, { className: "normal-button", onClick: props.buttonFunction, children: props.buttonTitle }) }));
};
export default localeTranslator(EmptyComponent, 'common');
