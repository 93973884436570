var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import Model from 'models/Model';
import { Clinic, Document, FinancialPeriod, TransactionEntry, DocumentLineGroup } from 'models';
import { plainToInstance } from 'class-transformer';
var Transaction = /** @class */ (function (_super) {
    __extends(Transaction, _super);
    function Transaction() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Transaction.prototype.getId = function () {
        return Number(this.id);
    };
    Transaction.prototype.getAmount = function () {
        return Number(this.amount);
    };
    Transaction.prototype.setAmount = function (amount) {
        this.amount = amount;
    };
    Transaction.prototype.getEntity = function () {
        return this.entity;
    };
    Transaction.prototype.setEntity = function (entity) {
        this.entity = entity;
    };
    Transaction.prototype.getEntityType = function () {
        return this.entity_type;
    };
    Transaction.prototype.setEntityType = function (entityType) {
        this.entity_type = entityType;
    };
    Transaction.prototype.getEntityId = function () {
        return Number(this.entity_id);
    };
    Transaction.prototype.setEntityId = function (entityId) {
        this.entity_id = entityId;
    };
    Transaction.prototype.getIsFullyConsumed = function () {
        return Boolean(this.is_fully_consumed);
    };
    Transaction.prototype.getIsPurchase = function () {
        return Boolean(this.is_purchase);
    };
    Transaction.prototype.setIsPurchase = function (isPurchase) {
        this.is_purchase = isPurchase;
    };
    Transaction.prototype.getIsReverse = function () {
        return Boolean(this.is_reverse);
    };
    Transaction.prototype.getNote = function () {
        return this.note;
    };
    Transaction.prototype.setNote = function (note) {
        this.note = note;
    };
    Transaction.prototype.getReference = function () {
        return this.reference;
    };
    Transaction.prototype.setReference = function (reference) {
        this.reference = reference;
    };
    Transaction.prototype.getDisplayedStatus = function () {
        return this.displayed_status;
    };
    Transaction.prototype.getFormattedTransactionDate = function () {
        return this.dateForamtter(new Date(this.transaction_date), 'DD/MM/YYYY hh:mm A');
    };
    Transaction.prototype.getTransactionDate = function () {
        return new Date(this.transaction_date);
    };
    Transaction.prototype.getCode = function () {
        return this.code;
    };
    Transaction.prototype.getClinic = function () {
        return plainToInstance(Clinic, this.clinic);
    };
    Transaction.prototype.setClinic = function (clinic) {
        this.clinic = clinic;
    };
    Transaction.prototype.getClinicId = function () {
        return Number(this.clinic_id);
    };
    Transaction.prototype.getDocument = function () {
        return plainToInstance(Document, this.document);
    };
    Transaction.prototype.setDocument = function (document) {
        this.document = document;
    };
    Transaction.prototype.getDocumentId = function () {
        return Number(this.document_id);
    };
    Transaction.prototype.setDocumentId = function (documentId) {
        this.document_id = documentId;
    };
    Transaction.prototype.getDocumentLineGroup = function () {
        return plainToInstance(DocumentLineGroup, this.document_line_group);
    };
    Transaction.prototype.setDocumentLineGroup = function (documentLineGroup) {
        this.document_line_group = documentLineGroup;
    };
    Transaction.prototype.getDocumentLineGroupId = function () {
        return Number(this.document_line_group_id);
    };
    Transaction.prototype.setDocumentLineGroupId = function (documentLineGroupId) {
        this.document_line_group_id = documentLineGroupId;
    };
    Transaction.prototype.getFinancialPeriod = function () {
        return plainToInstance(FinancialPeriod, this.financial_period);
    };
    Transaction.prototype.getFinancialPeriodId = function () {
        return Number(this.financial_period_id);
    };
    Transaction.prototype.getParentTransaction = function () {
        return plainToInstance(Transaction, this.parent_transaction);
    };
    Transaction.prototype.getParentTransactionId = function () {
        return Number(this.parent_transaction_id);
    };
    Transaction.prototype.getTransactionEntries = function () {
        return plainToInstance(TransactionEntry, this.transaction_entries);
    };
    Transaction.prototype.getCanBeReverted = function () {
        return Boolean(this.can_be_reverted);
    };
    Transaction.prototype.getCanBeRefunded = function () {
        return Boolean(this.can_be_refunded);
    };
    return Transaction;
}(Model));
export default Transaction;
