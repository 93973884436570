import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import Icon from '@ant-design/icons';
var InventoryIcon = function () {
    var Image = function () {
        return _jsx("svg", { xmlns: "http://www.w3.org/2000/svg", width: "1em", height: "1em", fill: "currentColor", viewBox: "0 0 20 20", focusable: "false", "data-icon": "user", "aria-hidden": "true", children: _jsx("path", { "data-name": "Path 488", d: "m19.2 7.4-6-4.5a2 2 0 0 0-2.4 0l-6 4.5A2 2 0 0 0 4 9v9a2 2 0 0 0 2 2 1 1 0 0 0 1-1v-6.5A1.5 1.5 0 0 1 8.5 11h7a1.5 1.5 0 0 1 1.5 1.5V19a1 1 0 0 0 1 1 2 2 0 0 0 2-2V9a2 2 0 0 0-.8-1.6zM13.25 9h-2.5a.75.75 0 0 1 0-1.5h2.5a.75.75 0 1 1 0 1.5zM16 14.5a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1 0-1h7a.5.5 0 0 1 .5.5zm0-2a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1 0-1h7a.5.5 0 0 1 .5.5zm0 4a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1 0-1h7a.5.5 0 0 1 .5.5zm0 2a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1 0-1h7a.5.5 0 0 1 .5.5z", transform: "translate(-4 -2.5)" }) });
    };
    return (_jsx(Icon, { component: Image }));
};
export default memo(InventoryIcon);
