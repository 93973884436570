var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Steps } from 'antd';
import { FilledUserIcon, ReservationInfoIcon, RightMarkCircleIcon } from 'assets/icons';
import localeTranslator from 'library/Hocs/LocalTranslator';
import StepTitle from 'library/components/StepTitle';
import { useEffect, useState } from 'react';
var Step = Steps.Step;
var ReservationSteps = function (props) {
    var _a = useState(0), currentStep = _a[0], setCurrentStep = _a[1];
    var _b = useState(true), isUserScroll = _b[0], setIsUserScroll = _b[1];
    var prepareStepTitle = function (title, icon, isSelected) {
        var stepProps = { title: title, icon: icon, isSelected: isSelected };
        return _jsx(StepTitle, __assign({}, stepProps));
    };
    var scrollingTimeOut = setTimeout(function () {
        setIsUserScroll(true);
        clearTimeout(scrollingTimeOut);
    }, 1000);
    window.addEventListener('scroll', function () { return scrollingTimeOut; });
    useEffect(function () {
        isUserScroll && setCurrentStep(props.tabsVisibility.findIndex(Boolean));
    }, [props.tabsVisibility]);
    var onStepChanged = function (current) {
        setIsUserScroll(false);
        setCurrentStep(current);
        var currentRef = props.tabRefs[current];
        var offset = currentRef.current && currentRef.current.offsetTop - 140;
        window.scrollTo({ top: offset || undefined, behavior: 'smooth' });
    };
    var getIcon = function (tabIndex) { return props.tabsStatus[tabIndex] && _jsx(RightMarkCircleIcon, {}); };
    var isSelected = function (stepNumber) { return currentStep === stepNumber; };
    return (_jsxs(Steps, { type: "navigation", size: "small", current: currentStep, onChange: onStepChanged, className: "tabs", children: [_jsx(Step, { title: prepareStepTitle(props.translate('patientInfo.title'), _jsx(FilledUserIcon, {}), isSelected(0)), icon: getIcon(0), status: "wait" }), _jsx(Step, { title: prepareStepTitle(props.translate('reservationInfo.title'), _jsx(ReservationInfoIcon, {}), isSelected(1)), icon: getIcon(1), status: "wait" })] }));
};
export default localeTranslator(ReservationSteps, 'newReservation');
