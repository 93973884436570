import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Row, Col } from 'antd';
import localeTranslator from 'library/Hocs/LocalTranslator';
import { FormInput, FormSelect } from 'components/AntEnhancement/FormControls';
import './style.css';
var SupplierForm = function (props) {
    var onChangeName = function (event) {
        props.supplier.setName(event.target.value);
    };
    var onChangeEmail = function (event) {
        props.supplier.setEmail(event.target.value);
    };
    var onChangeSupplierType = function (value) { return props.supplier.setSupplierType(value.toString()); };
    var getSupplierTypes = function () {
        return props.supplierTypes.map(function (supplierType) { return ({
            value: supplierType.getValue(),
            title: supplierType.getName()
        }); });
    };
    return (_jsx(_Fragment, { children: _jsxs("div", { className: "form-part", children: [_jsx(Row, { children: _jsx(Col, { xs: 24, sm: 24, lg: 24, xl: 24, children: _jsx(FormInput, { label: props.translate('name'), name: "name", placeHolder: props.translate('name'), rules: [
                                { required: true, message: props.translate('errors.name') }
                            ], onChange: onChangeName, initialValue: props.supplier.getName() }) }) }), _jsx(Row, { children: _jsx(Col, { xs: 24, sm: 24, lg: 24, xl: 24, children: _jsx(FormInput, { label: props.translate('email'), name: "email", placeHolder: props.translate('email'), rules: [], onChange: onChangeEmail, initialValue: props.supplier.getEmail() }) }) }), _jsx(Row, { children: _jsx(Col, { xs: 24, sm: 24, lg: 24, xl: 24, children: _jsx(FormSelect, { label: props.translate('supplierType'), name: "supplierType", rules: [
                                { required: true, message: props.translate('errors.supplierType') }
                            ], placeHolder: props.translate('supplierType'), onSelect: onChangeSupplierType, options: getSupplierTypes(), initialValue: props.supplier.getSupplierType() }) }) })] }) }));
};
export default localeTranslator(SupplierForm, 'supplierForm');
