import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import PatientLogHeader from './PatientLogHeader';
import Article from 'library/components/Article';
import 'components/patients/style.css';
import { useState } from 'react';
import NewFollowUp from 'components/forms/FollowUp/NewFollowUp';
import localeTranslator from 'library/Hocs/LocalTranslator';
var FollowUps = function (props) {
    var _a = useState(false), showFollowUpForm = _a[0], setShowFollowUpForm = _a[1];
    var renderFollowUps = function () { return props.followUps.map(function (followUp, index) {
        return (_jsxs("div", { children: [_jsx(Article, { title: followUp.getName(), description: followUp.getResult() }), index + 1 < props.followUps.length && _jsx("div", { className: "prescription-line" })] }, followUp.getName() + index));
    }); };
    var goBack = function () { return props.setShowFollowUp(false); };
    var addFollowUp = function () { return setShowFollowUpForm(true); };
    return (_jsxs(_Fragment, { children: [_jsx(PatientLogHeader, { title: props.translate('followUps.title'), hasAddButton: true, hasBackButton: true, onGoBack: goBack, onAdd: addFollowUp, arrowIcon: props.arrowIcon }), showFollowUpForm &&
                _jsx(NewFollowUp, { createFollowUp: props.createFollowUp, setShowFollowUpForm: setShowFollowUpForm }), _jsx("div", { className: "log", children: renderFollowUps() })] }));
};
export default localeTranslator(FollowUps, 'patientLog');
