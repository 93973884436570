import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Card, Col, Row, Space } from 'antd';
import StatisticCard from 'components/AntEnhancement/Statistics/StatisticCard';
import ReportHeader from 'components/PageHeader/ReportHeader';
import localeTranslator from 'library/Hocs/LocalTranslator';
import services from 'services';
var PatientReports = function (props) {
    var _a;
    var reportService = services.reportService;
    var report = reportService.usePatientReport().data;
    var patientComponent = function (patientReport) {
        return (_jsxs("div", { children: [_jsx("div", { className: 'Header-R-14', children: patientReport.getPatientName() }), _jsxs("div", { className: 'body---14pt-R description-text', children: [_jsx("span", { className: 'report_number', children: patientReport.getTotalAppointment() }), " ", props.translate('appointments'), " /", _jsx("span", { className: 'report_number', children: patientReport.getTotalTransactions() }), " ", props.translate('transactions'), " /", _jsx("span", { className: 'report_number', children: patientReport.getTotalPackageReservations() }), " ", props.translate('reservedPackage')] })] }));
    };
    var patientBirthdayComponent = function (patientReport) {
        return (_jsxs("div", { children: [_jsx("div", { className: 'Header-R-14', children: patientReport.getPatientName() }), _jsx("div", { className: 'body---14pt-R description-text', style: { color: '#6941C6' }, children: "".concat(patientReport.getNextBirthday(), " ") })] }));
    };
    return (_jsxs(_Fragment, { children: [_jsx(ReportHeader, { title: props.translate('title') }), _jsx("div", { id: "profile-content", className: "page-content", style: { marginTop: '20px' }, children: report &&
                    _jsx(_Fragment, { children: _jsxs(Space, { direction: 'vertical', size: 25, children: [_jsxs(Row, { gutter: [25, 25], children: [_jsx(Col, { xs: 24, sm: 24, lg: 6, xl: 6, children: _jsx(StatisticCard, { title: props.translate('patients'), value: report.getTotalPatients() }) }), _jsx(Col, { xs: 24, sm: 24, lg: 6, xl: 6, children: _jsx(StatisticCard, { title: props.translate('packages'), value: report.getTotalPackageReservations() }) }), _jsx(Col, { xs: 24, sm: 24, lg: 6, xl: 6, children: _jsx(StatisticCard, { title: props.translate('appointments'), value: report.getTotalAppointment() }) }), _jsx(Col, { xs: 24, sm: 24, lg: 6, xl: 6, children: _jsx(StatisticCard, { title: props.translate('transactions'), value: report.getTotalTransactions() }) })] }), _jsxs(Row, { gutter: [25, 25], children: [_jsx(Col, { xs: 24, sm: 24, lg: 18, xl: 18, children: _jsxs(Card, { children: [_jsx("div", { className: "Header-18-M title", children: props.translate('topTen') }), _jsx(Row, { gutter: [25, 25], children: report &&
                                                            report.getTopTenPatientReport().map(function (patientReport) {
                                                                return (_jsx(Col, { xs: 24, sm: 24, lg: 12, xl: 12, children: patientComponent(patientReport) }));
                                                            }) })] }) }), _jsx(Col, { xs: 24, sm: 24, lg: 6, xl: 6, children: _jsxs(Card, { children: [_jsx("div", { className: "Header-18-M title", children: props.translate('birthday') }), _jsx(Row, { gutter: [15, 15], children: report &&
                                                            ((_a = report.getNearestTenBirthdays()) === null || _a === void 0 ? void 0 : _a.map(function (patientReport) {
                                                                return (_jsx(Col, { xl: 24, children: patientBirthdayComponent(patientReport) }));
                                                            })) })] }) })] })] }) }) })] }));
};
export default localeTranslator(PatientReports, 'patientReports');
