var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Row, Col, Form, Button } from 'antd';
import localeTranslator from 'library/Hocs/LocalTranslator';
import DateSelector from './DateSelector';
import { useNavigate } from 'react-router-dom';
import './style.css';
var DateHeader = function (props) {
    var navigate = useNavigate();
    var goToNewPatient = function () { return navigate('/patients/new'); };
    return (_jsxs(Row, { children: [_jsx(Col, { xs: 24, sm: 24, lg: 7, xl: 10, children: _jsx(Form.Item, { children: _jsx(Button, { className: "normal-secondary-button Header-16-M", onClick: goToNewPatient, children: props.translate('buttons.newPatient') }) }) }), _jsx(Col, { xs: 24, sm: 24, lg: 5, xl: 6 }), _jsx(Col, { xs: 24, sm: 24, lg: 12, xl: 8, children: _jsx(DateSelector, __assign({}, props)) })] }));
};
export default localeTranslator(DateHeader, 'common');
