import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Row, Col, Form, Input } from 'antd';
import { FormInputNumber, FormSelect } from 'components/AntEnhancement/FormControls';
import './style.css';
import localeTranslator from 'library/Hocs/LocalTranslator';
var NewSettlement = function (props) {
    var onAmountChange = function (price) { return price != null && props.settlement.setPrice(price); };
    var onQuantityChange = function (quantity) { return quantity != null && props.settlement.setQuantity(quantity); };
    var onChangePriceType = function (value) { return props.settlement.setPriceType(value.toString()); };
    var getPriceTypeOptions = function () {
        return [
            { value: 'unit_price', title: props.translate('common:priceType.unitPrice') },
            { value: 'total_price', title: props.translate('common:priceType.totalPrice') },
        ];
    };
    return (_jsx("div", { style: { width: '100%' }, children: _jsxs(Row, { children: [_jsx(Col, { xs: 24, sm: 24, lg: 11, xl: 11, children: _jsx(FormInputNumber, { name: "quantity", label: 'quantity', rules: [
                            { required: true, message: 'Please, Enter the settlement quantity.' }
                        ], placeHolder: "quantity", onChange: onQuantityChange, initialValue: props.settlement.getQuantity() }) }), _jsx(Col, { xs: 24, sm: 24, lg: 2, xl: 2 }), _jsx(Col, { xs: 24, sm: 24, lg: 11, xl: 11, children: _jsx(Form.Item, { label: 'Price', required: true, children: _jsxs(Input.Group, { compact: true, children: [_jsx(FormInputNumber, { formClasses: "form-item body---14pt-R input-group-size group-input", inputClasses: 'group-input-border number-input', name: "Price", rules: [
                                        { required: true, message: 'Please, Enter the settlement price.' }
                                    ], placeHolder: 'Price', onChange: onAmountChange, min: 1 }), _jsx(FormSelect, { formClasses: "form-item body---14pt-R select-group-size group-input", selectClasses: "form-item select-border", name: "priceType", rules: [
                                        { required: true, message: props.translate('errors.priceType') }
                                    ], placeHolder: props.translate('priceType'), onSelect: onChangePriceType, options: getPriceTypeOptions() })] }) }) })] }) }));
};
export default localeTranslator(NewSettlement, 'settlementForm');
;
