import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Row, Col, Divider, Space } from 'antd';
import Loader from 'components/AntEnhancement/Loader';
// import TransactionEntries from 'pages/accounting/ShowTransaction/components/Tables/TransactionEntries';
import MainInfo from 'pages/accounting/ShowDocument/components/Informations/MainInfo';
import DocumentLineGroups from 'pages/accounting/ShowDocument/components/DocumentLineGroups';
import { useParams } from 'react-router';
import services from 'services';
import './style.css';
import localeTranslator from 'library/Hocs/LocalTranslator';
import DocumentLineGroupDrawer from './components/drawers/DocumentLineGroupDrawer';
import { useState } from 'react';
import Header from './components/Header';
var ShowDocument = function (props) {
    var id = useParams().id;
    var documentService = services.documentService;
    var _a = documentService.useGetDocument(Number(id)), document = _a.data, documentLoaded = _a.isSuccess, isLoading = _a.isLoading;
    var _b = useState(0), documentLineGroupDrawerKey = _b[0], setDocumentLineGroupDrawerKey = _b[1];
    var _c = useState(false), showDocumentLineGroupDrawer = _c[0], setShowDocumentLineGroupDrawer = _c[1];
    var onCloseDocumentLineGroupDrawer = function () {
        setShowDocumentLineGroupDrawer(false);
        setDocumentLineGroupDrawerKey(documentLineGroupDrawerKey + 1);
    };
    return (_jsx("div", { className: "loader-container-height", children: _jsx(Loader, { renderSpinner: isLoading, children: _jsxs("div", { children: [_jsxs(Space, { direction: 'vertical', size: 50, children: [_jsx("div", { className: "page-header add-padding", children: _jsx(Header, { setShowDocumentLineGroupDrawer: setShowDocumentLineGroupDrawer }) }), _jsx("div", { className: "new-pages-content", children: _jsx(Space, { direction: "vertical", size: 24, children: _jsx(Row, { children: _jsx(Col, { xl: 24, children: document && _jsx(MainInfo, { document: document }) }) }) }) })] }), _jsx(Divider, {}), document &&
                        _jsx(DocumentLineGroups, { documentLineGroups: document.getDocumentLineGroups() }), document &&
                        _jsx(DocumentLineGroupDrawer, { visiable: showDocumentLineGroupDrawer, document: document, onCloseDrawer: onCloseDocumentLineGroupDrawer }, 'document-line-group-' + documentLineGroupDrawerKey)] }) }) }));
};
export default localeTranslator(ShowDocument, 'showDocument');
