import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Form, Row, Col, Input, Button } from 'antd';
import localeTranslator from 'library/Hocs/LocalTranslator';
import './style.css';
import { useState } from 'react';
var TextArea = Input.TextArea;
var NewMedicalPatientDetails = function (props) {
    var form = Form.useForm()[0];
    var patientDetails = useState(props.patient.getPatientDetails())[0];
    var onChangeMedication = function (event) {
        patientDetails.setMedication("".concat(patientDetails.getMedication(), "\n").concat(event.target.value));
    };
    var onChangeMedicalCondition = function (event) {
        patientDetails.setMedicalConditions("".concat(patientDetails.getMedicalConditions(), "\n").concat(event.target.value));
    };
    var submit = function () {
        props.patient.setPatientDetails(patientDetails);
        props.updatePatientDetails(props.patient);
        props.setShowPatientDetailsForm(false);
    };
    return (_jsx("div", { className: "follow-up-form-part", children: _jsx(Form, { layout: 'vertical', form: form, initialValues: { layout: 'vertical' }, size: 'large', onFinish: submit, children: _jsx(Row, { children: _jsxs(Col, { xs: 24, sm: 24, lg: 24, xl: 24, children: [_jsx(Form.Item, { name: "medicalConditions", className: "form-item body---14pt-R", children: _jsx(TextArea, { className: "text-area-border space-item", placeholder: props.translate('medicalConditions'), onChange: onChangeMedicalCondition, bordered: false, rows: 3 }) }), _jsx("div", { className: "new-follow-up-line" }), _jsx(Form.Item, { name: "medications", className: "form-item body---14pt-R", children: _jsx(TextArea, { className: "text-area-border space-item", placeholder: props.translate('medications'), onChange: onChangeMedication, bordered: false, rows: 3 }) }), _jsx("div", { className: "new-follow-up-line" }), _jsx(Form.Item, { children: _jsx(Button, { type: "primary", htmlType: "submit", className: "add-follow-up-button Header-16-M", children: props.translate('common:buttons.submit') }) })] }) }) }) }));
};
export default localeTranslator(NewMedicalPatientDetails, 'patientDetail');
