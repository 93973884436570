import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Row, Col } from 'antd';
import localeTranslator from 'library/Hocs/LocalTranslator';
import { FormInput, FormSelect } from 'components/AntEnhancement/FormControls';
import './style.css';
var AccountForm = function (props) {
    var onChangeName = function (event) {
        props.account.setName(event.target.value);
    };
    var onChangeAccountType = function (value) { return props.account.setAccountTypeId(Number(value)); };
    var getAccountTypes = function () {
        return props.accountTypes.map(function (accountType) { return ({
            value: accountType.getId(),
            title: accountType.getName()
        }); });
    };
    return (_jsx(_Fragment, { children: _jsxs("div", { className: "form-part", children: [_jsx(Row, { children: _jsx(Col, { xs: 24, sm: 24, lg: 24, xl: 24, children: _jsx(FormInput, { label: props.translate('name'), name: "name", placeHolder: props.translate('name'), rules: [
                                { required: true, message: props.translate('errors.name') }
                            ], onChange: onChangeName, initialValue: props.account.getName() }) }) }), _jsx(Row, { children: _jsx(Col, { xs: 24, sm: 24, lg: 24, xl: 24, children: _jsx(FormSelect, { label: props.translate('accountType'), name: "accountType", requiredMark: 'optional', rules: [], placeHolder: props.translate('accountType'), onSelect: onChangeAccountType, options: getAccountTypes(), initialValue: props.account.getAccountTypeId() || null }) }) })] }) }));
};
export default localeTranslator(AccountForm, 'accountForm');
