import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import Icon from '@ant-design/icons';
var StethoscopeIcon = function (props) {
    var Image = function () {
        return _jsx("svg", { xmlns: "http://www.w3.org/2000/svg", width: "1em", height: "1em", className: props.className, fill: "currentColor", viewBox: props.viewBox || "0 0 20 20", focusable: "false", "data-icon": "user", "aria-hidden": "true", children: _jsx("path", { d: "M19.939 1.833A2.577 2.577 0 0 0 17.865.8h-.348V.6a.6.6 0 1 0-1.2 0v1.6a.6.6 0 1 0 1.2 0V2h.348a1.4 1.4 0 0 1 1.345 1.782l-1.592 5.569a3.64 3.64 0 0 1-7 0l-1.59-5.569A1.4 1.4 0 0 1 10.373 2h.348v.2a.6.6 0 0 0 1.2 0V.6a.6.6 0 0 0-1.2 0v.2h-.348a2.6 2.6 0 0 0-2.5 3.312l1.593 5.569a4.8 4.8 0 0 0 1.734 2.533 4.91 4.91 0 0 0 .566.368l1.748 4.127v.433a2.123 2.123 0 1 1-4.247 0V13.03a3.437 3.437 0 0 0-6.875 0v1.419a3 3 0 1 0 1.2 0V13.03a2.238 2.238 0 0 1 4.477 0v4.112a3.323 3.323 0 1 0 6.645 0v-.433l1.749-4.128a4.91 4.91 0 0 0 .565-.367 4.8 4.8 0 0 0 1.739-2.534l1.591-5.569a2.577 2.577 0 0 0-.424-2.278zM3 17.986a.6.6 0 1 1 .6-.6.6.6 0 0 1-.6.6zm11.119-2.936-.819-1.929a4.883 4.883 0 0 0 1.635 0z" }) });
    };
    return (_jsx(Icon, { component: Image }));
};
export default memo(StethoscopeIcon);
