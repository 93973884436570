import ClinicService from './ClinicService';
import PatientService from './PatientService';
import UserService from './UserService';
import MedicalMachineService from './MedicalMachineService';
import DoctorService from './DoctorService';
import EmployeeService from './EmployeeService';
import SpecializationService from './SpecializationService';
import ToastrService from './ToastrService';
import UnitMeasurementService from './UnitMeasurementService';
import ReservationPackageService from './ReservationPackageService';
import ReservationService from './ReservationService';
import PackageService from './PackageService';
import ItemCategoryService from './ItemCategoryService';
import ReservationVisitService from './ReservationVisitService';
import HomeService from './HomeService';
import ClinicVisitService from './ClinicVisitService';
import JobTitleService from './JobTitleService';
import PatientAreaService from './PatientAreaService';
import PackagePriceService from './PackagePriceService';
import AppointmentService from './AppointmentService';
import AppointmentDetailService from './AppointmentDetailService';
import AppointmentTypeService from './AppointmentTypeService';
import AccountService from './accounting/AccountService';
import AccountTypeService from './accounting/AccountTypeService';
import BalanceService from './accounting/BalanceService';
import DocumentService from './accounting/DocumentService';
import FinancialPeriodService from './accounting/FinancialPeriodService';
import TransactionSevice from './accounting/TransactionSevice';
import DocumentLineGroupService from './accounting/DocumentLineGroupService';
import DocumentLineService from './accounting/DocumentLineService';
import ProductCategoryService from './inventory/ProductCategoryService';
import ProductService from './inventory/ProductService';
import SupplierService from './inventory/SupplierService';
import SupplierBranchService from './inventory/SupplierBranchService';
import StockService from './inventory/StockService';
import StockMovementService from './inventory/StockMovementService';
import PrescriptionService from './PrescriptionService';
import MedicalTestService from './MedicalTestService';
import FollowUpService from './FollowUpService';
import DiagnoseService from './DiagnoseService';
import SettlementService from './SettlementService';
import PermissionService from './PermissionService';
import RoleService from './RoleService';
import ReportService from './reporting/ReportService';
export default {
    userService: UserService.getInstance(),
    clinicService: ClinicService.getInstance(),
    patientService: PatientService.getInstance(),
    medicalMachineService: MedicalMachineService.getInstance(),
    doctorService: DoctorService.getInstance(),
    employeeService: EmployeeService.getInstance(),
    specializationService: SpecializationService.getInstance(),
    toastrService: ToastrService.getInstance(),
    unitMeasurementService: UnitMeasurementService.getInstance(),
    reservationService: ReservationService.getInstance(),
    reservationPackageService: ReservationPackageService.getInstance(),
    packageService: PackageService.getInstance(),
    itemCategoryService: ItemCategoryService.getInstance(),
    reservationVisitService: ReservationVisitService.getInstance(),
    homeService: HomeService.getInstance(),
    clinicVisitService: ClinicVisitService.getInstance(),
    jobTitleService: JobTitleService.getInstance(),
    patientAreaService: PatientAreaService.getInstance(),
    packagePriceService: PackagePriceService.getInstance(),
    appointmentService: AppointmentService.getInstance(),
    appointmentDetailService: AppointmentDetailService.getInstance(),
    accountService: AccountService.getInstance(),
    accountTypeService: AccountTypeService.getInstance(),
    balanceService: BalanceService.getInstance(),
    documentService: DocumentService.getInstance(),
    financialPeriodService: FinancialPeriodService.getInstance(),
    transactionService: TransactionSevice.getInstance(),
    documentLineGroupService: DocumentLineGroupService.getInstance(),
    documentLineService: DocumentLineService.getInstance(),
    productCategoryService: ProductCategoryService.getInstance(),
    productService: ProductService.getInstance(),
    supplierService: SupplierService.getInstance(),
    supplierBranchService: SupplierBranchService.getInstance(),
    stockService: StockService.getInstance(),
    stockMovementService: StockMovementService.getInstance(),
    appointmentTypeService: AppointmentTypeService.getInstance(),
    prescriptionService: PrescriptionService.getInstance(),
    medicalTestService: MedicalTestService.getInstance(),
    followUpService: FollowUpService.getInstance(),
    diagnoseService: DiagnoseService.getInstance(),
    settlementService: SettlementService.getInstance(),
    permissionService: PermissionService.getInstance(),
    roleService: RoleService.getInstance(),
    reportService: ReportService.getInstance()
};
