// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-part {
  background-color: white;
  padding: 24px 36px 25px 36px;
  border-radius: 5px;
}

.field-margin {
  margin: 0px 5px 0px 5px;
}

.form-title {
  margin-bottom: 24px;
}

.form-item {
  margin: 0px !important;
}

.ant-form-item-label {
  padding: 0px !important;
}

.caledar-size {
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/forms/users/style.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,4BAA4B;EAC5B,kBAAkB;AACpB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,WAAW;AACb","sourcesContent":[".form-part {\n  background-color: white;\n  padding: 24px 36px 25px 36px;\n  border-radius: 5px;\n}\n\n.field-margin {\n  margin: 0px 5px 0px 5px;\n}\n\n.form-title {\n  margin-bottom: 24px;\n}\n\n.form-item {\n  margin: 0px !important;\n}\n\n.ant-form-item-label {\n  padding: 0px !important;\n}\n\n.caledar-size {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
