import { jsx as _jsx } from "react/jsx-runtime";
import { Row, Col } from 'antd';
import localeTranslator from 'library/Hocs/LocalTranslator';
import { FormSelect } from 'components/AntEnhancement/FormControls';
import services from 'services';
var AppointmentTypes = function (props) {
    var appointmentTypeService = services.appointmentTypeService;
    var appointmentTypes = appointmentTypeService.useAppointmentTypes().data;
    var onChangeEntity = function (value) { return props.package.setEntityId(Number(value)); };
    var getAppointmentTypes = function () {
        return (appointmentTypes === null || appointmentTypes === void 0 ? void 0 : appointmentTypes.map(function (appointmentType) { return ({
            value: appointmentType.getId(),
            title: appointmentType.getName()
        }); })) || [];
    };
    return (_jsx(Row, { children: _jsx(Col, { xs: 24, sm: 24, lg: 11, xl: 11, children: _jsx(FormSelect, { label: props.packageElementTitle, name: "packageElementTitle", rules: [
                    { required: true, message: props.translate("errors.".concat(props.packageElementTitle)) }
                ], placeHolder: props.packageElementTitle, onSelect: onChangeEntity, options: getAppointmentTypes() }) }) }));
};
export default localeTranslator(AppointmentTypes, 'newPackage');
