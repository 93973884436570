import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Col, Form, Button } from 'antd';
import EntityHeader from 'components/PageHeader/EntityHeader';
import { Document } from 'constants/resources';
import localeTranslator from 'library/Hocs/LocalTranslator';
import services from 'services';
var Header = function (props) {
    var showDocumentLineGroupDrawer = function () { return props.setShowDocumentLineGroupDrawer(true); };
    var permissionService = services.permissionService;
    var buttons = function () {
        return (_jsx(_Fragment, { children: permissionService.hasPermissionToAdd(Document) &&
                _jsx(Col, { xs: 24, sm: 24, lg: 7, xl: 5, children: _jsx(Form.Item, { children: _jsx(Button, { className: "widder-normal-button Header-16-M", onClick: showDocumentLineGroupDrawer, children: props.translate('header.addDocumentLineGroup') }) }) }) }));
    };
    return (_jsx(EntityHeader, { title: props.translate('header.document'), children: buttons() }));
};
export default localeTranslator(Header, 'showDocument');
