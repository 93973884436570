var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import Model from 'models/Model';
import { SupplierBranch } from 'models';
import { plainToInstance } from 'class-transformer';
var Supplier = /** @class */ (function (_super) {
    __extends(Supplier, _super);
    function Supplier() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Supplier.prototype.getId = function () {
        return Number(this.id);
    };
    Supplier.prototype.getName = function () {
        return this.name;
    };
    Supplier.prototype.setName = function (name) {
        this.name = name;
    };
    Supplier.prototype.getSupplierBranches = function () {
        return plainToInstance(SupplierBranch, this.supplier_branches);
    };
    Supplier.prototype.getEmail = function () {
        return this.email;
    };
    Supplier.prototype.setEmail = function (email) {
        this.email = email;
    };
    Supplier.prototype.getSupplierType = function () {
        return this.supplier_type;
    };
    Supplier.prototype.setSupplierType = function (supplierType) {
        this.supplier_type = supplierType;
    };
    Supplier.prototype.getDisplayedSupplierType = function () {
        return this.displayed_supplier_type;
    };
    return Supplier;
}(Model));
export default Supplier;
