// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sider-color {
  background-color: #ffffff !important;
  border: 1px solid #ffffff !important;
}

.ant-layout-sider-trigger {
  background-color: hsl(227, 27%, 87%) !important;
  color: black;
}

.name {
  width: 120px;
}

.user-dropdown {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.user-name {
  width: 100%;
  height: 17px;
  margin: 0 91px 5px 1px;
}

.user-menu-item {
  margin-top: 10px;
  margin-bottom: 10px;
}
.user-menu-item .ant-menu-submenu-title {
  height: 35px !important;
}
.user-menu-item .ant-menu-submenu-title .anticon{
  margin-bottom: 15%;
}
.user-menu-item .ant-menu-submenu-title .ant-menu-submenu-arrow{
  top: 35%;
}

.side-bar {
  overflow: auto !important;
  min-height: 100vh !important;
  position: fixed !important;
  background-color: white !important;
}

.side-bar-ltr {
  left: 0 !important;
}

.side-bar-rtl {
  right: 0 !important;
}

.Header-R-16 {
  font-size: 18px !important;
}

.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 0px !important;
}

span > svg {
  width: 1.5em !important;
  height: 1.5em !important;
}

.menu-items {
  color: var(--menu-color) !important;
}`, "",{"version":3,"sources":["webpack://./src/components/Layout/SideBar/style.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,oCAAoC;AACtC;;AAEA;EACE,+CAA+C;EAC/C,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,uBAAuB;AACzB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,QAAQ;AACV;;AAEA;EACE,yBAAyB;EACzB,4BAA4B;EAC5B,0BAA0B;EAC1B,kCAAkC;AACpC;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,uBAAuB;EACvB,wBAAwB;AAC1B;;AAEA;EACE,mCAAmC;AACrC","sourcesContent":[".sider-color {\n  background-color: #ffffff !important;\n  border: 1px solid #ffffff !important;\n}\n\n.ant-layout-sider-trigger {\n  background-color: hsl(227, 27%, 87%) !important;\n  color: black;\n}\n\n.name {\n  width: 120px;\n}\n\n.user-dropdown {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n}\n\n.user-name {\n  width: 100%;\n  height: 17px;\n  margin: 0 91px 5px 1px;\n}\n\n.user-menu-item {\n  margin-top: 10px;\n  margin-bottom: 10px;\n}\n.user-menu-item .ant-menu-submenu-title {\n  height: 35px !important;\n}\n.user-menu-item .ant-menu-submenu-title .anticon{\n  margin-bottom: 15%;\n}\n.user-menu-item .ant-menu-submenu-title .ant-menu-submenu-arrow{\n  top: 35%;\n}\n\n.side-bar {\n  overflow: auto !important;\n  min-height: 100vh !important;\n  position: fixed !important;\n  background-color: white !important;\n}\n\n.side-bar-ltr {\n  left: 0 !important;\n}\n\n.side-bar-rtl {\n  right: 0 !important;\n}\n\n.Header-R-16 {\n  font-size: 18px !important;\n}\n\n.ant-menu-inline .ant-menu-item:not(:last-child) {\n  margin-bottom: 0px !important;\n}\n\nspan > svg {\n  width: 1.5em !important;\n  height: 1.5em !important;\n}\n\n.menu-items {\n  color: var(--menu-color) !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
