import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate } from 'react-router';
import { Col, Row, Tabs } from 'antd';
import services from 'services';
import SearchHeader from 'components/PageHeader/SearchHeader';
import UserCard from 'components/cards/UserCard';
import EmptyComponent from 'components/EmptyComponent';
import './style.css';
import localeTranslator from 'library/Hocs/LocalTranslator';
import { useState } from 'react';
import { Doctor } from 'constants/resources';
var Doctors = function (props) {
    var _a = useState(undefined), doctorName = _a[0], setDoctorName = _a[1];
    var doctorService = services.doctorService, specializationService = services.specializationService;
    var doctors = doctorService.useDoctors({ 'user_name': doctorName }).data;
    var specializations = specializationService.useSpecializations().data;
    var navigate = useNavigate();
    var onSearchValueChange = function (value) { return setDoctorName(value); };
    var goToNewDoctorForm = function () { return navigate('/doctors/new'); };
    var goToDoctorProfile = function (id) { return navigate("/doctors/".concat(id)); };
    var doctorsCards = function () {
        return doctors === null || doctors === void 0 ? void 0 : doctors.map(function (doctor) {
            var _a, _b, _c;
            return (_jsx(Col, { onClick: function () { return goToDoctorProfile(doctor.getId()); }, children: _jsx(UserCard, { name: props.translate('drPrefix') + doctor.getUser().getName(), subTitle: ((_a = specializationService.getSpecializationDataByID(specializations, doctor.getSpecializationId())) === null || _a === void 0 ? void 0 : _a.getName()) || '', phoneNumber: doctor.getUser().getMobile(), email: doctor.getUser().getEmail(), id: doctor.getId().toString(), imagePath: (_c = (_b = doctor.getAlbum()) === null || _b === void 0 ? void 0 : _b.getImage()) === null || _c === void 0 ? void 0 : _c.getImagePath() }, "doctor-".concat(doctor.getId())) }, "doctor-col-".concat(doctor.getId())));
        });
    };
    var items = [
        {
            key: '1',
            label: props.translate('current')
        },
        {
            key: '2',
            label: props.translate('archived')
        },
        {
            key: '3',
            label: props.translate('all')
        },
    ];
    return (_jsxs("div", { children: [_jsx(SearchHeader, { title: props.translate('header.doctorsList'), addButtonText: props.translate('common:buttons.newDoctor'), addButtonFunction: goToNewDoctorForm, onSearchValueChange: onSearchValueChange, resource: Doctor }), _jsxs("div", { className: "page-content", children: [_jsx(Tabs, { defaultActiveKey: "1", className: "Header-16-M tab-title-color", items: items }), _jsx(Row, { children: doctors ?
                            doctorsCards() :
                            _jsx("div", { className: "empty-component-container", children: _jsx(EmptyComponent, { description: props.translate('emptyText') }) }) })] })] }));
};
export default localeTranslator(Doctors, 'doctors');
