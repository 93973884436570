// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.medicine-divider {
  /* width: 393.1px; */
  height: 1px;
  margin: 16.5px 20.5px 12.5px 0px;
  background-color: var(--border-color);
}

.prescription-title {
  margin-bottom: 12.5px;
}

.prescription-items-container {
  display: flex;
  flex-direction: row;
}

.prescription-item {
  display: flex;
  flex-direction: column;
  width: 90%;
}

.verticale-divider {
  height: auto !important; 
  border-left: 2px solid #ccd7e4 !important;
}`, "",{"version":3,"sources":["webpack://./src/components/Prescription/style.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,WAAW;EACX,gCAAgC;EAChC,qCAAqC;AACvC;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,UAAU;AACZ;;AAEA;EACE,uBAAuB;EACvB,yCAAyC;AAC3C","sourcesContent":[".medicine-divider {\n  /* width: 393.1px; */\n  height: 1px;\n  margin: 16.5px 20.5px 12.5px 0px;\n  background-color: var(--border-color);\n}\n\n.prescription-title {\n  margin-bottom: 12.5px;\n}\n\n.prescription-items-container {\n  display: flex;\n  flex-direction: row;\n}\n\n.prescription-item {\n  display: flex;\n  flex-direction: column;\n  width: 90%;\n}\n\n.verticale-divider {\n  height: auto !important; \n  border-left: 2px solid #ccd7e4 !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
