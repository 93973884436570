import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import Icon from '@ant-design/icons';
var FinancialIcon = function () {
    var Image = function () {
        return _jsx("svg", { xmlns: "http://www.w3.org/2000/svg", width: "1em", height: "1em", fill: "currentColor", viewBox: "0 0 20 20", focusable: "false", "data-icon": "user", "aria-hidden": "true", children: _jsx("path", { "data-name": "Vector", d: "M16.5 1.67c0-.01 0-.02-.02-.03a1.052 1.052 0 0 0-.85-.43 2.8 2.8 0 0 0-1.86 1.09 1.768 1.768 0 0 1-2.8-.15L9.96.81A1.83 1.83 0 0 0 8.5 0a1.83 1.83 0 0 0-1.46.81L6.02 2.16a1.759 1.759 0 0 1-2.78.15l-.01-.01C2.1 1.09 1.09.91.52 1.64c-.02.01-.02.02-.02.03A8.2 8.2 0 0 0 0 5.04v9.92a8.2 8.2 0 0 0 .5 3.37.076.076 0 0 0 .02.04c.58.72 1.58.54 2.71-.67l.01-.01a1.759 1.759 0 0 1 2.78.15l1.02 1.35A1.83 1.83 0 0 0 8.5 20a1.83 1.83 0 0 0 1.46-.81l1.01-1.34a1.768 1.768 0 0 1 2.8-.15 2.8 2.8 0 0 0 1.86 1.09 1.042 1.042 0 0 0 .85-.42.076.076 0 0 0 .02-.04 8.2 8.2 0 0 0 .5-3.37V5.04a8.2 8.2 0 0 0-.5-3.37zM11 12.5H5A.75.75 0 0 1 5 11h6a.75.75 0 0 1 0 1.5zM13 9H5a.75.75 0 0 1 0-1.5h8A.75.75 0 0 1 13 9z", transform: "translate(3 2)" }) });
    };
    return (_jsx(Icon, { component: Image }));
};
export default memo(FinancialIcon);
