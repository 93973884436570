import { ERROR_API_EVENT, SUCCESS_API_EVENT } from 'constants/events';
var EventService = /** @class */ (function () {
    function EventService() {
    }
    EventService.prototype.dipatachError = function (data) {
        this.currentDispatacher(ERROR_API_EVENT, data);
    };
    EventService.prototype.dipatachCreationSuccess = function (data) {
        this.currentDispatacher(SUCCESS_API_EVENT, data);
    };
    EventService.prototype.currentDispatacher = function (eventName, data) {
        document.dispatchEvent(new CustomEvent(eventName, {
            detail: data
        }));
    };
    return EventService;
}());
export default EventService;
