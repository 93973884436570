var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import Model from 'models/Model';
import { ProductCategory, UnitMeasurement, Stock } from 'models';
import { plainToInstance } from 'class-transformer';
var Product = /** @class */ (function (_super) {
    __extends(Product, _super);
    function Product() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Product.prototype.getId = function () {
        return Number(this.id);
    };
    Product.prototype.getName = function () {
        return this.name;
    };
    Product.prototype.setName = function (name) {
        this.name = name;
    };
    Product.prototype.getBarcode = function () {
        return this.barcode;
    };
    Product.prototype.setBarcode = function (barcode) {
        this.barcode = barcode;
    };
    Product.prototype.getSystemCode = function () {
        return this.system_code;
    };
    Product.prototype.setQuantity = function (quantity) {
        this.quantity = Number(quantity);
    };
    Product.prototype.getQuantity = function () {
        return Number(this.quantity);
    };
    Product.prototype.setDescription = function (description) {
        this.description = description;
    };
    Product.prototype.getDescription = function () {
        return this.description;
    };
    Product.prototype.setZIP = function (zip) {
        this.zip = Number(zip);
    };
    Product.prototype.getZIP = function () {
        return Number(this.zip);
    };
    Product.prototype.setUnitMeasurementId = function (unitMeasurementId) {
        this.unit_measurement_id = Number(unitMeasurementId);
    };
    Product.prototype.getUnitMeasurementId = function () {
        return Number(this.unit_measurement_id);
    };
    Product.prototype.getUnitMeasurement = function () {
        return plainToInstance(UnitMeasurement, this.unit_measurement);
    };
    Product.prototype.setUnitMeasurement = function (unitMeasurement) {
        this.unit_measurement = unitMeasurement;
    };
    Product.prototype.setProductCategorytId = function (productCategoryId) {
        this.product_category_id = Number(productCategoryId);
    };
    Product.prototype.getProductCategoryId = function () {
        return Number(this.product_category_id);
    };
    Product.prototype.getProductCategory = function () {
        return plainToInstance(ProductCategory, this.product_category);
    };
    Product.prototype.setProductCategory = function (productCategory) {
        this.product_category = productCategory;
    };
    Product.prototype.getStocks = function () {
        return plainToInstance(Stock, this.stocks);
    };
    return Product;
}(Model));
export default Product;
