var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { plainToClass } from 'class-transformer';
import queries from 'constants/queries';
import { UnitMeasurement } from 'models';
import { useQuery } from 'react-query';
import ApiService from './ApiService';
var UnitMeasurementService = /** @class */ (function (_super) {
    __extends(UnitMeasurementService, _super);
    function UnitMeasurementService() {
        var _this = _super.call(this) || this;
        _this.unitMeasurementsRoot = 'unit_measurements';
        return _this;
    }
    UnitMeasurementService.prototype.useUnitMeasurements = function () {
        var _this = this;
        return useQuery(queries.unitMeasurement, function () {
            return _this.get(_this.unitMeasurementsRoot);
        }, {
            select: function (_a) {
                var data = _a.data;
                return plainToClass(UnitMeasurement, data);
            }
        });
    };
    UnitMeasurementService.prototype.getUnitMeasurementDataByID = function (unitMeasurements, id) {
        var unitMeasurement = unitMeasurements === null || unitMeasurements === void 0 ? void 0 : unitMeasurements.find(function (unitMeasurement) { return unitMeasurement.getId() === id; });
        return unitMeasurement || new UnitMeasurement();
    };
    UnitMeasurementService.getInstance = function () {
        if (!UnitMeasurementService.unitMeasurementInstance) {
            UnitMeasurementService.unitMeasurementInstance = new UnitMeasurementService();
        }
        return UnitMeasurementService.unitMeasurementInstance;
    };
    return UnitMeasurementService;
}(ApiService));
export default UnitMeasurementService;
