import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import Icon from '@ant-design/icons';
var MedicalMachineIcon = function () {
    var Image = function () {
        return _jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: "1em", height: "1em", fill: "currentColor", viewBox: "0 0 20 20", focusable: "false", "data-icon": "user", "aria-hidden": "true", children: [_jsx("path", { id: "Path_486", "data-name": "Path 486", d: "M-930.637 957.042h-3.614a3.732 3.732 0 0 0-3.559-2.642 3.732 3.732 0 0 0-3.559 2.642h-3.614a1.548 1.548 0 0 0-1.541 1.669l.954 11.374a1.54 1.54 0 0 0 1.541 1.413h12.419a1.54 1.54 0 0 0 1.541-1.413l.954-11.374a1.523 1.523 0 0 0-1.522-1.669zm-7.173-1.669a2.74 2.74 0 0 1 2.733 2.733 2.74 2.74 0 0 1-2.733 2.733 2.74 2.74 0 0 1-2.733-2.733 2.74 2.74 0 0 1 2.733-2.734z", transform: "translate(946.528 -954.4)" }), _jsx("path", { id: "Path_487", "data-name": "Path 487", d: "M-902.184 965.925a.716.716 0 0 0 .715-.715.713.713 0 0 0-.294-.587l-.312-1.431a.116.116 0 0 0-.11-.092.116.116 0 0 0-.11.092l-.312 1.431a.713.713 0 0 0-.294.587.716.716 0 0 0 .717.715z", transform: "translate(910.903 -961.504)" })] });
    };
    return (_jsx(Icon, { component: Image }));
};
export default memo(MedicalMachineIcon);
