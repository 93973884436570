var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { plainToClass } from 'class-transformer';
import { ClinicMedicalMachine, UnitMeasurement } from 'models';
import Model from 'models/Model';
var MedicalMachine = /** @class */ (function (_super) {
    __extends(MedicalMachine, _super);
    function MedicalMachine() {
        var _this = _super.call(this) || this;
        _this.clinic_medical_machines = [];
        return _this;
    }
    MedicalMachine.prototype.getId = function () {
        return Number(this.id);
    };
    MedicalMachine.prototype.getDefaultUnitPrice = function () {
        return Number(this.basic_unit_price);
    };
    MedicalMachine.prototype.setDefaultUnitPrice = function (unitPrice) {
        this.basic_unit_price = Number(unitPrice);
    };
    MedicalMachine.prototype.getName = function () {
        return this.name;
    };
    MedicalMachine.prototype.setName = function (name) {
        this.name = name;
    };
    MedicalMachine.prototype.getOriginCountry = function () {
        return this.origin_country;
    };
    MedicalMachine.prototype.setOriginCountry = function (originCountry) {
        this.origin_country = originCountry;
    };
    MedicalMachine.prototype.getUnitMeasurement = function () {
        return plainToClass(UnitMeasurement, this.unit_measurement);
    };
    MedicalMachine.prototype.setUnitMeasurementId = function (unitMeasurementId) {
        this.unit_measurement_id = unitMeasurementId;
    };
    MedicalMachine.prototype.getClinicMedicalMachines = function () {
        return plainToClass(ClinicMedicalMachine, this.clinic_medical_machines) || [];
    };
    MedicalMachine.prototype.addClinicMedicalMachine = function (clinicMedicalMachine) {
        this.clinic_medical_machines.push(clinicMedicalMachine);
    };
    MedicalMachine.prototype.setClinicMedicalMachine = function (clinicMedicalMachines) {
        this.clinic_medical_machines = clinicMedicalMachines;
    };
    MedicalMachine.prototype.removeClinicMedicalMachine = function (clinicId) {
        this.clinic_medical_machines = this.clinic_medical_machines.filter(function (cmm) {
            return cmm.getClinicId() !== Number(clinicId);
        });
    };
    return MedicalMachine;
}(Model));
export default MedicalMachine;
