import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import Icon from '@ant-design/icons';
var TransactionIcon = function () {
    var Image = function () {
        return _jsx("svg", { xmlns: "http://www.w3.org/2000/svg", width: "1em", height: "1em", fill: "currentColor", viewBox: "0 0 20 20", focusable: "false", "data-icon": "user", "aria-hidden": "true", children: _jsx("g", { "data-name": "Group 43", children: _jsx("path", { "data-name": "Path 54", d: "M11 10.5a.5.5 0 0 0 .5.5h1a2.512 2.512 0 0 1 .5 5 1 1 0 0 1-2 0h-1a1 1 0 0 1 0-2h2.5a.5.5 0 0 0 0-1h-1a2.5 2.5 0 0 1-.5-4.95V8a1 1 0 0 1 2 0h1a1 1 0 0 1 0 2h-2.5a.5.5 0 0 0-.5.5zm10.19-3.86a1 1 0 0 0 0-2h-2.83a.973.973 0 0 0-.92.61 1.09 1.09 0 0 0-.08.39v2.82a.992.992 0 0 0 1.94.3A8 8 0 0 1 8.56 19.22 1 1 0 1 0 7.69 21 10 10 0 0 0 20.43 6.64zm-15.55 7.9a1 1 0 0 0-.95.71A8 8 0 0 1 15.44 4.78 1 1 0 1 0 16.31 3 10 10 0 0 0 2 12a9.88 9.88 0 0 0 1.57 5.36h-.76a1 1 0 0 0 0 2h2.83a.972.972 0 0 0 .9-.61 1.23 1.23 0 0 0 .08-.38v-2.83a1 1 0 0 0-.98-1z", transform: "translate(-1.81 -2.023)" }) }) });
    };
    return (_jsx(Icon, { component: Image }));
};
export default memo(TransactionIcon);
