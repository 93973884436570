import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Menu, Dropdown, Table } from 'antd';
import { CheckOutlined, CloseOutlined, EllipsisOutlined } from '@ant-design/icons';
import localeTranslator from 'library/Hocs/LocalTranslator';
import EmptyComponent from 'components/EmptyComponent';
import services from 'services';
import '../../style.css';
import Loader from 'components/AntEnhancement/Loader';
import { useParams } from 'react-router';
import './style.css';
var PackagePrices = function (props) {
    var packagePriceService = services.packagePriceService, unitMeasurementService = services.unitMeasurementService;
    var id = useParams().id;
    var _a = useState(1), pageNumber = _a[0], setPageNumber = _a[1];
    var getNextPage = function (currentPageNumber) { return setPageNumber(currentPageNumber); };
    var onChangePriceAvailability = function (packagePrice) {
        props.setPackagePrice(packagePrice);
        props.setShowPackagePriceAvailabilityDrawer(true);
    };
    var _b = packagePriceService.usePackagePricesToPackage(Number(id), pageNumber), data = _b.data, isLoading = _b.isLoading;
    var _c = unitMeasurementService.useUnitMeasurements(), unitMeasurements = _c.data, isUnitMeasurementLoading = _c.isLoading;
    var packagePriceUnitMeasurement = function (packagePrice) {
        return unitMeasurementService.getUnitMeasurementDataByID(unitMeasurements, packagePrice.getUnitMeasurementId());
    };
    var columns = [
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('tables.packagePrices.info.id') }),
            key: 'package_price_id',
            render: function (text, record) { return _jsx("div", { className: "body---14pt-R", children: record.getId() }); }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('tables.packagePrices.info.quantity') }),
            key: 'package_price_quantity',
            render: function (text, record) { return _jsx("div", { className: "body---14pt-R", children: record.getQuantity() }); }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('tables.packagePrices.info.unitMeasurement') }),
            key: 'package_price_quantity',
            render: function (text, record) { return _jsx("div", { className: "body---14pt-R", children: packagePriceUnitMeasurement(record).getName() }); }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('tables.packagePrices.info.price') }),
            key: 'package_price_price',
            render: function (text, record) { return _jsx("div", { className: "body---14pt-R", children: record.getPrice() }); }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('tables.packagePrices.info.priceType') }),
            key: 'package_price_priceType',
            render: function (text, record) { return _jsx("div", { className: "body---14pt-R", children: record.getDisplayedPriceType() }); }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('tables.packagePrices.info.startedAt') }),
            key: 'package_price_startedAt',
            render: function (text, record) { return _jsx("div", { className: "body---14pt-R", children: record.getStartedAt() }); }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('tables.packagePrices.info.finishedAt') }),
            key: 'package_price_finishedAt',
            render: function (text, record) { return _jsx("div", { className: "body---14pt-R", children: record.getFinishedAt() }); }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('tables.packagePrices.info.available') }),
            key: 'package_price_finishedAt',
            render: function (text, record) { return _jsx("div", { className: "body---14pt-R", children: record.getIsEnabled() ? _jsx(CheckOutlined, {}) : _jsx(CloseOutlined, {}) }); }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('action') }),
            fixed: 'right',
            render: function (text, record) {
                return _jsx(Dropdown.Button, { icon: _jsx(EllipsisOutlined, { rotate: 90, className: 'action-button' }), overlay: menu(record), className: "three-dot" });
            }
        }
    ];
    var menu = function (packagePrice) {
        return (_jsx(Menu, { children: _jsx(Menu.Item, { onClick: function () { return onChangePriceAvailability(packagePrice); }, children: props.translate('drawers.changeAvailability') }, "1") }));
    };
    return (_jsx(Loader, { renderSpinner: isLoading && isUnitMeasurementLoading, className: 'loader-style', children: _jsxs("div", { className: "page-subcontent", children: [_jsx("div", { className: "Header-18-M form-title", children: props.translate('tables.packagePrices.header.packagePrice') }), data && data.packagePrices &&
                    _jsx(Table, { columns: columns, pagination: {
                            position: ['bottomRight'],
                            pageSize: 25,
                            total: (data === null || data === void 0 ? void 0 : data.meta) ? (data === null || data === void 0 ? void 0 : data.meta.getPagesCount()) * 25 : 0,
                            onChange: getNextPage,
                            showSizeChanger: false,
                            responsive: true
                        }, dataSource: data === null || data === void 0 ? void 0 : data.packagePrices, locale: {
                            emptyText: _jsx(EmptyComponent, {})
                        } })] }) }));
};
export default localeTranslator(PackagePrices, 'showPackage');
