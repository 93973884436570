// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dateContainer .ant-picker-input {
  border: 0px;
  font-size: 18px;
}

.dateContainer .ant-picker-input > input,
.ant-picker-clear {
  visibility: hidden;
  outline: none;
  display: none;
}

.dateContainer .ant-picker {
  border: 0px;
}

.dateContainer .ant-picker:hover,
.outlinedIcon:hover {
  cursor: pointer;
}

.dateContainer .ant-picker-suffix {
  margin: 0px;
}

.iconSize {
  font-size: small;
}

.Rectangle-154 {
  width: 40px;
  height: 40px;
  margin: 29px 27px 21px 8px;
  padding: 14.5px 16.7px 14.5px 17px;
  border-radius: 10px;
  background-color: #fcfcfc;
}

.rowAlign {
  align-items: center;
}

.outlinedIcon {
  font-size: small;
  color: rgb(200 197 197) !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/PageHeader/DateHeader/style.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,eAAe;AACjB;;AAEA;;EAEE,kBAAkB;EAClB,aAAa;EACb,aAAa;AACf;;AAEA;EACE,WAAW;AACb;;AAEA;;EAEE,eAAe;AACjB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,0BAA0B;EAC1B,kCAAkC;EAClC,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,kCAAkC;AACpC","sourcesContent":[".dateContainer .ant-picker-input {\n  border: 0px;\n  font-size: 18px;\n}\n\n.dateContainer .ant-picker-input > input,\n.ant-picker-clear {\n  visibility: hidden;\n  outline: none;\n  display: none;\n}\n\n.dateContainer .ant-picker {\n  border: 0px;\n}\n\n.dateContainer .ant-picker:hover,\n.outlinedIcon:hover {\n  cursor: pointer;\n}\n\n.dateContainer .ant-picker-suffix {\n  margin: 0px;\n}\n\n.iconSize {\n  font-size: small;\n}\n\n.Rectangle-154 {\n  width: 40px;\n  height: 40px;\n  margin: 29px 27px 21px 8px;\n  padding: 14.5px 16.7px 14.5px 17px;\n  border-radius: 10px;\n  background-color: #fcfcfc;\n}\n\n.rowAlign {\n  align-items: center;\n}\n\n.outlinedIcon {\n  font-size: small;\n  color: rgb(200 197 197) !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
