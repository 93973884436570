import { jsx as _jsx } from "react/jsx-runtime";
import ReservationPackages from 'pages/ReservationPackages';
import NewReservationPackage from 'pages/NewReservationPackage';
import ShowReservationPackage from 'pages/ShowReservationPackage';
import { Route } from 'react-router';
import AuthorizationService from '../AuthorizationService';
import { Index, Create, Show } from 'constants/actions';
import { ReservationPackage as ReservationPackageResource } from 'constants/resources';
var ReservationPackageRoutes = function () {
    return ([
        _jsx(Route, { caseSensitive: true, path: '/reservation-packages', element: AuthorizationService.getAuthorizedElement(_jsx(ReservationPackages, {}), Index, ReservationPackageResource) }, 'reservation-packages-route'),
        _jsx(Route, { caseSensitive: true, path: '/reservation-packages/new', element: AuthorizationService.getAuthorizedElement(_jsx(NewReservationPackage, {}), Create, ReservationPackageResource) }, 'new-reservation-packages-route'),
        // <Route
        //   caseSensitive
        //   path={'/patients/:id/update'}
        //   element={AuthorizationService.getAuthorizedElement(<UpdatePatient/>, Update, PatientsResource)}
        //   key='update-patient-route'
        // />,
        _jsx(Route, { caseSensitive: true, path: '/reservation-packages/:id', element: AuthorizationService.getAuthorizedElement(_jsx(ShowReservationPackage, {}), Show, ReservationPackageResource) }, 'patient-route'),
    ]);
};
export default ReservationPackageRoutes;
