import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import services from 'services';
import EmptyComponent from 'components/EmptyComponent';
import localeTranslator from 'library/Hocs/LocalTranslator';
import { Table, Button, Row, Col, Space } from 'antd';
import { useState } from 'react';
import AddJobTitleDrawer from '../drawers/AddJobTitleDrawer';
var JobTitles = function (props) {
    var jobTitleService = services.jobTitleService;
    var jobTitles = jobTitleService.useJobTitles().data;
    var _a = useState(0), newJobTitleDrawerKey = _a[0], setNewJobTitleDrawerKey = _a[1];
    var _b = useState(false), showNewJobTitleDrawer = _b[0], setShowNewJobTitleDrawer = _b[1];
    var onCloseJobTitleDrawer = function () {
        setShowNewJobTitleDrawer(false);
        setNewJobTitleDrawerKey(newJobTitleDrawerKey + 1);
    };
    var showJobTitleDrawer = function () { return setShowNewJobTitleDrawer(true); };
    var columns = [
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('jobTitles.id') }),
            key: 'id',
            render: function (text, record, index) {
                return _jsx("div", { className: "body---14pt-R", children: record.getId() }, 'id-' + index);
            }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('jobTitles.title') }),
            key: 'title',
            render: function (text, record, index) {
                return _jsx("div", { className: "body---14pt-R table-body-content", children: record.getTitle() }, 'title-' + index);
            }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('jobTitles.baseSalary') }),
            key: 'baseSalary',
            render: function (text, record, index) {
                return _jsx("div", { className: "body---14pt-R table-body-content", children: record.getBaseSalary() }, 'baseSalary-' + index);
            }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('jobTitles.role') }),
            key: 'role',
            render: function (text, record, index) {
                return _jsx("div", { className: "body---14pt-R table-body-content", children: record.getRole().getName() }, 'role-' + index);
            }
        }
    ];
    return (_jsxs("div", { className: "form-part", children: [_jsxs(Space, { direction: 'vertical', children: [_jsxs(Row, { children: [_jsx(Col, { xs: 24, sm: 24, lg: 20, xl: 20 }), _jsx(Col, { xs: 24, sm: 24, lg: 4, xl: 4, children: _jsx(Button, { className: "normal-button Header-16-M", onClick: showJobTitleDrawer, children: props.translate('jobTitles.addJobTitle') }) })] }), _jsx(Table, { columns: columns, className: 'Header-R-14', pagination: false, dataSource: jobTitles, locale: {
                            emptyText: _jsx(EmptyComponent, {})
                        } })] }), _jsx(AddJobTitleDrawer, { onCloseDrawer: onCloseJobTitleDrawer, visiable: showNewJobTitleDrawer }, 'jobTitle-' + newJobTitleDrawerKey)] }));
};
export default localeTranslator(JobTitles, 'settings');
