// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ellipse {
  margin-top: 4px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.28);
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-notification-notice-icon {
  top: 6px;
  left: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/library/components/ToasterMark/style.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,2CAA2C;EAC3C,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,QAAQ;EACR,UAAU;AACZ","sourcesContent":[".ellipse {\n  margin-top: 4px;\n  width: 40px;\n  height: 40px;\n  border-radius: 50%;\n  background-color: rgba(255, 255, 255, 0.28);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.ant-notification-notice-icon {\n  top: 6px;\n  left: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
