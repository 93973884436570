// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter-input {
  background-color: var(--backgorud-default-color) !important;
  height: 45px;
  border: 1px solid var(--border-color) !important;
  border-radius: 5px !important;
}

.filter-input input {
  background-color: var(--backgorud-default-color) !important;
}`, "",{"version":3,"sources":["webpack://./src/components/PageHeader/SearchHeader/style.css"],"names":[],"mappings":"AAAA;EACE,2DAA2D;EAC3D,YAAY;EACZ,gDAAgD;EAChD,6BAA6B;AAC/B;;AAEA;EACE,2DAA2D;AAC7D","sourcesContent":[".filter-input {\n  background-color: var(--backgorud-default-color) !important;\n  height: 45px;\n  border: 1px solid var(--border-color) !important;\n  border-radius: 5px !important;\n}\n\n.filter-input input {\n  background-color: var(--backgorud-default-color) !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
