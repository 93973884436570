var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { plainToClass } from 'class-transformer';
import queries from 'constants/queries';
import { Specialization } from 'models';
import { useQuery } from 'react-query';
import ApiService from './ApiService';
var SpecializationService = /** @class */ (function (_super) {
    __extends(SpecializationService, _super);
    function SpecializationService() {
        var _this = _super.call(this) || this;
        _this.specializationsRoot = 'specializations';
        return _this;
    }
    SpecializationService.prototype.useSpecializations = function () {
        var _this = this;
        return useQuery(queries.specializations, function () {
            return _this.get(_this.specializationsRoot);
        }, {
            select: function (response) {
                var data = response.data;
                return plainToClass(Specialization, data);
            }
        });
    };
    SpecializationService.prototype.getSpecializationDataByID = function (specializations, id) {
        return specializations === null || specializations === void 0 ? void 0 : specializations.find(function (specialization) { return specialization.getId() === id; });
    };
    SpecializationService.getInstance = function () {
        if (!SpecializationService.specializationInstance) {
            SpecializationService.specializationInstance = new SpecializationService();
        }
        return SpecializationService.specializationInstance;
    };
    return SpecializationService;
}(ApiService));
export default SpecializationService;
