import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Row, Space, Col } from 'antd';
import { HeightIcon, WeightIcon } from 'assets/icons';
import localeTranslator from 'library/Hocs/LocalTranslator';
import '../../style.css';
var PhysicalInformation = function (props) {
    var _a, _b;
    var patient = props.patient;
    return (_jsxs("div", { className: "Header-18-M form-title", children: [props.translate('patientInfo.patientPhysique'), _jsx(Space, { direction: 'vertical', size: 100, style: { marginTop: '45px' }, children: _jsxs(Row, { children: [_jsx(Col, { xs: 24, lg: 10, children: _jsxs(Space, { size: 27, children: [_jsx(WeightIcon, {}), _jsxs(Space, { direction: 'vertical', children: [_jsx("div", { className: 'body---14pt-R description-text', children: props.translate('patientInfo.weight') }), _jsx("div", { className: 'body---14pt-R', children: "".concat((_a = patient.getPatientDetails()) === null || _a === void 0 ? void 0 : _a.getWeight(), " Kg") })] })] }) }), _jsx(Col, { xs: 24, lg: 4 }), _jsx(Col, { xs: 24, lg: 10, children: _jsxs(Space, { size: 27, children: [_jsx(HeightIcon, {}), _jsxs(Space, { direction: 'vertical', children: [_jsx("div", { className: 'body---14pt-R description-text', children: props.translate('patientInfo.height') }), _jsx("div", { className: 'body---14pt-R', children: "".concat((_b = patient.getPatientDetails()) === null || _b === void 0 ? void 0 : _b.getHeight(), " Cm") })] })] }) })] }) })] }));
};
export default localeTranslator(PhysicalInformation, 'showPatient');
