var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Form, Select, Row, Col, Collapse } from 'antd';
import { ReservationPackage } from 'models';
import { useState } from 'react';
import PackageRow from 'components/forms/packages/PackageRow';
import PackageCardGroup from 'components/forms/packages/PackageCardGroup';
import localeTranslator from 'library/Hocs/LocalTranslator';
import './style.css';
import { RightMarkCircleIcon } from 'assets/icons';
var Option = Select.Option;
var Panel = Collapse.Panel;
var AddPackagesForm = function (props) {
    var panelHeader = function () {
        return _jsx("span", { className: "Header-18-M", children: props.translate('reservationInfo.choosePackage') });
    };
    var _a = useState(''), packageValidateStatus = _a[0], setPackageValidateStatus = _a[1];
    var _b = useState(''), searchString = _b[0], setSearchString = _b[1];
    var _c = useState([]), selectedValues = _c[0], setSelectedValues = _c[1];
    var packagesPricesById = props.packagePrices.reduce(function (obj, p) {
        var _a;
        return (__assign(__assign({}, obj), (_a = {}, _a[p.getId()] = p, _a)));
    }, {});
    var onSelectPackage = function (value) {
        var reservationPackage = new ReservationPackage();
        reservationPackage.setPackagePriceId(value);
        props.setReservationPackages(__spreadArray(__spreadArray([], props.reservationPackages, true), [reservationPackage], false));
        setSelectedValues(__spreadArray(__spreadArray([], selectedValues, true), [value], false));
        onChangeReservationPackages();
    };
    var onDeselectPackage = function (value) {
        props.setReservationPackages(props.reservationPackages.filter(function (rp) { return rp.getPackagePriceId() != value; }));
        setSelectedValues(selectedValues.filter(function (v) { return v != value; }));
        onChangeReservationPackages();
    };
    var onChangeReservationPackages = function () {
        setSearchString('');
    };
    var dropdownPackageCards = function () {
        return (_jsx("div", { className: "dropdown-packages", children: _jsx(PackageCardGroup, { unitMeasurements: props.unitMeasurements, selectedPackagePriceIds: selectedValues, packagePrices: props.packagePrices, onSelectPackage: onSelectPackage, onDeselectPackage: onDeselectPackage }) }));
    };
    var onSearchPackages = function (input) { return setSearchString(input === null || input === void 0 ? void 0 : input.toLowerCase()); };
    var getValues = function () { return props.reservationPackages.map(function (rp) { return rp.getPackagePriceId(); }); };
    var getOptions = function () { return props.packagePrices.map(function (p) { return ({ label: p.getPackageName(), value: p.getId() }); }); };
    var checkPackageValidation = function (_, value) {
        if (props.reservationPackages.length) {
            setPackageValidateStatus('success');
            return Promise.resolve();
        }
        setPackageValidateStatus('error');
        return Promise.reject(new Error(props.translate('errors.packages')));
    };
    var onChangeRepeated = function (nrp) {
        var reservationPackages = props.reservationPackages.map(function (rp) {
            if (rp.getPackagePriceId() === nrp.getPackagePriceId()) {
                rp.setRepeated(nrp.getRepeated());
            }
            return rp;
        });
        props.setReservationPackages(reservationPackages);
    };
    var getExtraIcon = function () {
        return !!props.reservationPackages.length && _jsx(RightMarkCircleIcon, { viewBox: "0 -3 20 20" });
    };
    return (_jsx(_Fragment, { children: _jsx("div", { className: "form-part", children: _jsx(Collapse, { defaultActiveKey: ['1'], ghost: true, expandIconPosition: "right", children: _jsxs(Panel, { header: panelHeader(), extra: getExtraIcon(), children: [_jsx(Row, { children: _jsx(Col, { xs: 24, children: _jsxs(Form.Item, { label: props.translate('reservationInfo.choosePackage'), name: "choosePackage", required: true, className: "form-item body---14pt-R", rules: [{ validator: checkPackageValidation }], validateStatus: packageValidateStatus, hasFeedback: true, children: [_jsx(Select, { mode: "multiple", showArrow: true, placeholder: props.translate('reservationInfo.choosePackage'), dropdownRender: dropdownPackageCards, maxTagCount: 'responsive', value: selectedValues, options: getOptions(), onSearch: onSearchPackages, filterOption: false, allowClear: false, onDeselect: onDeselectPackage }), props.packagePrices.map(function (p) {
                                            return _jsx(Option, { value: p.getId(), children: p.getPackageName() }, p.getId());
                                        })] }) }) }), _jsx("div", { className: "hidden-divider" }), !!props.reservationPackages.length &&
                            _jsx("div", { className: "Header-18-M", children: props.translate('reservationInfo.package') }), props.reservationPackages.map(function (rp) {
                            var packagePrice = packagesPricesById[rp.getPackagePriceId()];
                            return (_jsx(PackageRow, { packagePrice: packagePrice, reservationPackage: rp, onChangeRepeated: onChangeRepeated }, rp.getPackagePriceId()));
                        })] }, "1") }) }) }));
};
export default localeTranslator(AddPackagesForm, 'newReservationPackage');
