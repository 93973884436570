import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import Icon from '@ant-design/icons';
var PersonalIdIcon = function () {
    var Image = function () {
        return _jsx("svg", { xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", viewBox: "0 0 24 24", fill: "#647386", children: _jsx("path", { d: "M16 0H4a3.986 3.986 0 0 0-4 3.97v10.06A3.986 3.986 0 0 0 4 18h12a3.986 3.986 0 0 0 4-3.97V3.97A3.986 3.986 0 0 0 16 0zM6.5 4.17a2.31 2.31 0 1 1-2.31 2.31A2.317 2.317 0 0 1 6.5 4.17zm3.87 9.49a.506.506 0 0 1-.37.16H3a.506.506 0 0 1-.37-.16.52.52 0 0 1-.13-.38 3.548 3.548 0 0 1 3.19-3.17 8.659 8.659 0 0 1 1.61 0 3.539 3.539 0 0 1 3.19 3.17.475.475 0 0 1-.12.38zm6.63.09h-2a.75.75 0 0 1 0-1.5h2a.75.75 0 0 1 0 1.5zm0-4h-4a.75.75 0 0 1 0-1.5h4a.75.75 0 0 1 0 1.5zm0-4h-5a.75.75 0 0 1 0-1.5h5a.75.75 0 0 1 0 1.5z", transform: "translate(2 3)", "data-name": "vuesax/bold/personalcard" }) });
    };
    return (_jsx(Icon, { component: Image }));
};
export default memo(PersonalIdIcon);
