var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { useQuery } from 'react-query';
import queries from 'constants/queries';
import ApiService from './ApiService';
import { AppointmentType } from 'models';
import { plainToInstance } from 'class-transformer';
var AppointmentTypeService = /** @class */ (function (_super) {
    __extends(AppointmentTypeService, _super);
    function AppointmentTypeService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.rootPath = 'appointment_types';
        return _this;
    }
    AppointmentTypeService.prototype.useAppointmentTypes = function () {
        var _this = this;
        return useQuery(queries.appointmentTypes, function () { return _this.get(_this.rootPath); }, {
            select: function (_a) {
                var data = _a.data;
                return plainToInstance(AppointmentType, data);
            }
        });
    };
    AppointmentTypeService.getInstance = function () {
        if (!AppointmentTypeService.appointmentTypeInstance) {
            AppointmentTypeService.appointmentTypeInstance = new AppointmentTypeService();
        }
        return AppointmentTypeService.appointmentTypeInstance;
    };
    return AppointmentTypeService;
}(ApiService));
export default AppointmentTypeService;
