// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-position {
  min-height: 100vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
}

.error-404-text {
  font-size: 48px !important;
  /* padding-bottom: 20px; */
}

.page-not-found-text {
  font-size: 24px !important;
}

.error-padding {
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
}

.centered-self {
  align-self: center;
}

.go-to-home {
  display: flex;
}

.home-padding {
  padding-left: 5px;
}`, "",{"version":3,"sources":["webpack://./src/pages/PageNotFound/style.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,aAAa;EACb,yBAAyB;EACzB,mBAAmB;EACnB,sBAAsB;AACxB;;AAEA;EACE,0BAA0B;EAC1B,0BAA0B;AAC5B;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,oBAAoB;EACpB,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".image-position {\n  min-height: 100vh;\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n  flex-direction: column;\n}\n\n.error-404-text {\n  font-size: 48px !important;\n  /* padding-bottom: 20px; */\n}\n\n.page-not-found-text {\n  font-size: 24px !important;\n}\n\n.error-padding {\n  padding-bottom: 40px;\n  display: flex;\n  flex-direction: column;\n}\n\n.centered-self {\n  align-self: center;\n}\n\n.go-to-home {\n  display: flex;\n}\n\n.home-padding {\n  padding-left: 5px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
