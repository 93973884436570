import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Row, Col, Space, Form, Button } from 'antd';
import localeTranslator from 'library/Hocs/LocalTranslator';
import './style.css';
import services from 'services';
var EntityHeader = function (props) {
    var permissionService = services.permissionService;
    var onEditPress = function () { return props.editFn && props.editFn(); };
    return (_jsxs(Row, { className: "buttons-offset", children: [_jsx(Col, { xs: 24, lg: 12, xl: 12, children: _jsx("div", { className: "Header-24-bold", children: props.title }) }), _jsx(Col, { className: "arrange-buttons", xs: 24, lg: 12, xl: 12, children: (props.editFn || props.children) &&
                    _jsxs(Space, { direction: "horizontal", children: [props.editFn && (props.resource && permissionService.hasPermissionToEdit(props.resource)) &&
                                _jsx(Form.Item, { children: _jsx(Button, { className: "normal-secondary-button Header-16-M", onClick: onEditPress, children: props.translate('edit') }) }), props.children] }) })] }));
};
export default localeTranslator(EntityHeader, 'entityHeader');
