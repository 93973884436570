var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import Model from 'models/Model';
import { DocumentLine, Transaction, Account } from 'models';
import { plainToInstance } from 'class-transformer';
var TransactionEntry = /** @class */ (function (_super) {
    __extends(TransactionEntry, _super);
    function TransactionEntry() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    TransactionEntry.prototype.getId = function () {
        return Number(this.id);
    };
    TransactionEntry.prototype.getAmount = function () {
        return Number(this.amount);
    };
    TransactionEntry.prototype.getEntity = function () {
        return this.entity;
    };
    TransactionEntry.prototype.getEntityType = function () {
        return this.entity_type;
    };
    TransactionEntry.prototype.getEntityId = function () {
        return Number(this.entity_id);
    };
    TransactionEntry.prototype.getType = function () {
        return this.type;
    };
    TransactionEntry.prototype.getDisplayedType = function () {
        return this.displayed_type;
    };
    TransactionEntry.prototype.getDocumentLine = function () {
        return plainToInstance(DocumentLine, this.document_line);
    };
    TransactionEntry.prototype.getDocumentLineId = function () {
        return Number(this.document_line_id);
    };
    TransactionEntry.prototype.getAccount = function () {
        return plainToInstance(Account, this.account);
    };
    TransactionEntry.prototype.getAccountId = function () {
        return Number(this.account_id);
    };
    TransactionEntry.prototype.getTransaction = function () {
        return plainToInstance(Transaction, this.transaction);
    };
    TransactionEntry.prototype.getTransactionId = function () {
        return Number(this.transaction_id);
    };
    TransactionEntry.prototype.getEntityName = function () {
        return this.entity_name;
    };
    return TransactionEntry;
}(Model));
export default TransactionEntry;
