// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-layout {
  width: 350px;
  height: 150px;
  margin: 0 24px 24px 0;
  padding: 15px 16px 15px 13.7px;
  border-radius: 10px;
  background-color: #fff;
  display: grid;
  flex-direction: row;
  align-items: center;
}

.empty-user-image {
  background-color: var(--success-color-state) !important;
}

.email {
  width: 200px;
}

.ant-card-hoverable:hover {
  box-shadow: 10px 10px 5px rgb(24 96 108 / 12%) !important;
}

.description-div {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/cards/UserCard/style.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,qBAAqB;EACrB,8BAA8B;EAC9B,mBAAmB;EACnB,sBAAsB;EACtB,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,uDAAuD;AACzD;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,yDAAyD;AAC3D;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,kBAAkB;AACpB","sourcesContent":[".card-layout {\n  width: 350px;\n  height: 150px;\n  margin: 0 24px 24px 0;\n  padding: 15px 16px 15px 13.7px;\n  border-radius: 10px;\n  background-color: #fff;\n  display: grid;\n  flex-direction: row;\n  align-items: center;\n}\n\n.empty-user-image {\n  background-color: var(--success-color-state) !important;\n}\n\n.email {\n  width: 200px;\n}\n\n.ant-card-hoverable:hover {\n  box-shadow: 10px 10px 5px rgb(24 96 108 / 12%) !important;\n}\n\n.description-div {\n  display: flex;\n  align-items: center;\n  margin-bottom: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
