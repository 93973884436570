import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translations from 'locales';
var format = function (value, format, lng) {
    if (lng === void 0) { lng = 'ar'; }
    if (format === 'money') {
        return Number(value)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, '$&,') + ' E£';
    }
    return value.toString();
};
export var i18nSetup = function (lng) {
    if (lng === void 0) { lng = 'en'; }
    i18n.use(initReactI18next)
        .init({
        resources: translations,
        lng: lng,
        fallbackLng: 'en',
        interpolation: {
            format: format
        }
    });
};
