import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Form, Select, Spin, Empty, Collapse } from 'antd';
import { RightMarkCircleIcon } from 'assets/icons';
import localeTranslator from 'library/Hocs/LocalTranslator';
import './style.css';
var Option = Select.Option;
var Panel = Collapse.Panel;
var PatientSearch = function (props) {
    var onPatientSearch = function (value) {
        if (value != props.phoneNumber) {
            props.onSearch(value);
        }
    };
    var onPatientSelect = function (value) {
        props.onSearch(value);
    };
    var getExtraIcon = function () { return !!props.phoneNumber && _jsx(RightMarkCircleIcon, { viewBox: "0 -3 20 20" }); };
    var panelHeader = function () {
        return _jsxs("span", { className: "Header-18-M", children: [props.translate('patientInfo.searchForPatient'), " "] });
    };
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "Header-18-M form-title", id: "patient-search", children: props.translate('patientInfo.title') }), _jsx("div", { className: "form-part", children: _jsx(Collapse, { defaultActiveKey: ['1'], ghost: true, expandIconPosition: "right", children: _jsx(Panel, { header: panelHeader(), extra: getExtraIcon(), children: _jsx(Form.Item, { label: props.translate('patientInfo.phoneNumber'), name: "phoneNumber", required: true, className: "form-item body---14pt-R", rules: [
                                { required: true, message: props.translate('errors.patientPhoneNumber') }
                            ], hasFeedback: true, shouldUpdate: true, children: _jsx(Select, { showSearch: true, size: "large", placeholder: props.translate('patientInfo.phoneNumberSearch'), onSelect: onPatientSelect, onSearch: onPatientSearch, filterOption: false, notFoundContent: props.isLoading ? _jsx(Spin, { size: "small" }) : _jsx(Empty, { image: Empty.PRESENTED_IMAGE_SIMPLE }), children: (props.patients || []).map(function (patient) {
                                    return _jsx(Option, { className: 'body---14pt-R', value: patient.getPrimaryPhone(), children: patient.getName() }, "phone-".concat(patient.getId()));
                                }) }) }) }, "1") }) })] }));
};
export default localeTranslator(PatientSearch, 'newReservationPackage');
