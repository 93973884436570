var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react';
import { Row, Col, Space, Form, Select } from 'antd';
import ClinicMedicalMachineRow from '../ClinicMedicalMachine/ClinicMedicalMachineRow';
import localeTranslator from 'library/Hocs/LocalTranslator';
import { FormInput, FormInputNumber, FormSelect } from 'components/AntEnhancement/FormControls';
import './style.css';
import { ClinicMedicalMachine, Clinic } from 'models';
var Option = Select.Option;
var MedicalMachineForm = function (props) {
    var _a;
    var _b = useState(''), clinicMedicalMachineValidateStatus = _b[0], setClinicMedicalMachineValidateStatus = _b[1];
    var onChangeName = function (event) {
        props.medicalMachine.setName(event.target.value);
    };
    var _c = useState([]), setClinicMedicalMachine = _c[1];
    var onChangeOriginCountry = function (event) {
        props.medicalMachine.setOriginCountry(event.target.value);
    };
    var onChangeUnitMeasurement = function (value) { return props.medicalMachine.setUnitMeasurementId(Number(value)); };
    var onChangeUnitPrice = function (amount) { return amount && props.medicalMachine.setDefaultUnitPrice(amount); };
    // const onChangeBoughtAt = (boughtAt: Dayjs | null, dateString: string) => {
    //   boughtAt && props.medicalMachine.setBoughtAt(boughtAt.toDate());
    // };
    var checkClinicMedicalMachineValidation = function (_, value) {
        if (props.medicalMachine.getClinicMedicalMachines().length) {
            setClinicMedicalMachineValidateStatus('success');
            return Promise.resolve();
        }
        setClinicMedicalMachineValidateStatus('error');
        return Promise.reject(new Error(props.translate('errors.clinics')));
    };
    var getOptions = function () { return props.clinics.map(function (clinic) { return ({ label: clinic.getName(), value: clinic.getId() }); }); };
    var onSelectClinic = function (values) {
        var currentClinicIds = props.medicalMachine.getClinicMedicalMachines().map(function (cmm) { return cmm.getClinicId(); });
        var newClinicIds = values.filter(function (value) { return !currentClinicIds.includes(Number(value)); });
        newClinicIds.forEach(function (value) {
            var clinicMedicalMachine = new ClinicMedicalMachine();
            clinicMedicalMachine.setClinicId(Number(value));
            var clinic = props.clinics.find(function (clinic) { return clinic.getId() === Number(value); }) || new Clinic();
            clinicMedicalMachine.setClinic(clinic);
            props.medicalMachine.addClinicMedicalMachine(clinicMedicalMachine);
        });
        setClinicMedicalMachine(__spreadArray([], props.medicalMachine.getClinicMedicalMachines(), true));
    };
    var onDeselectClinics = function (value) {
        props.medicalMachine.removeClinicMedicalMachine(Number(value));
        setClinicMedicalMachine(__spreadArray([], props.medicalMachine.getClinicMedicalMachines(), true));
    };
    var onChangeBoughtAt = function (clinicMedicalMachine, boughtAt) {
        var clinicMedicalMachines = props.medicalMachine.getClinicMedicalMachines().map(function (cmm) {
            if (clinicMedicalMachine.getClinicId() === cmm.getClinicId()) {
                cmm.setBoughtAt(new Date(boughtAt));
            }
            return cmm;
        });
        props.medicalMachine.setClinicMedicalMachine(clinicMedicalMachines);
    };
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "Header-18-M form-title", children: props.translate('mainInfo') }), _jsx("div", { className: "form-part", children: _jsx(Space, { direction: 'vertical', className: 'space-item', size: 'large', children: _jsxs(Row, { children: [_jsx(Col, { xs: 24, sm: 24, lg: 12, xl: 12, children: _jsx(FormInput, { label: props.translate('name'), name: "name", placeHolder: props.translate('name'), rules: [
                                        { required: true, message: props.translate('errors.name') }
                                    ], onChange: onChangeName }) }), _jsx(Col, { xs: 0, sm: 0, lg: 1, xl: 1 }), _jsx(Col, { xs: 24, sm: 24, lg: 11, xl: 11, children: _jsx(FormInput, { label: props.translate('originCountry'), name: "originCountry", placeHolder: props.translate('originCountry'), rules: [], onChange: onChangeOriginCountry }) })] }) }) }), _jsx("div", { className: "hidden-divider" }), _jsx("div", { className: "hidden-divider" }), _jsx("div", { className: "Header-18-M form-title", children: props.translate('defaultPackageInfo') }), _jsx("div", { className: "form-part", children: _jsxs(Row, { children: [_jsx(Col, { xs: 24, sm: 24, lg: 11, xl: 11, children: _jsx(FormSelect, { label: props.translate('chooseUnitMeasurement'), name: "unitMeasurement", rules: [
                                    { required: true, message: props.translate('errors.unitMeasurement') }
                                ], placeHolder: props.translate('chooseUnitMeasurement'), onSelect: onChangeUnitMeasurement, options: props.unitMeasurements.map(function (um) { return ({ value: um.getId(), title: um.getName() }); }) }) }), _jsx(Col, { xs: 0, sm: 0, lg: 1, xl: 1 }), _jsx(Col, { xs: 24, sm: 24, lg: 12, xl: 12, children: _jsx(FormInputNumber, { label: props.translate('unitPrice'), name: "unitPrice", rules: [
                                    { required: true, message: props.translate('errors.unitPrice') }
                                ], placeHolder: props.translate('unitPrice'), onChange: onChangeUnitPrice, min: 0 }) })] }) }), _jsx("div", { className: "hidden-divider" }), _jsx("div", { className: "hidden-divider" }), _jsx("div", { className: "Header-18-M form-title", children: props.translate('clinics') }), _jsxs("div", { className: 'form-part', children: [_jsx(Row, { children: _jsx(Col, { xs: 24, children: _jsxs(Form.Item, { label: props.translate('chooseClinics'), name: "chooseClinics", required: true, className: "form-item body---14pt-R", rules: [{ validator: checkClinicMedicalMachineValidation }], validateStatus: clinicMedicalMachineValidateStatus, hasFeedback: true, children: [_jsx(Select, { mode: "multiple", showArrow: true, allowClear: true, placeholder: props.translate('chooseClinics'), maxTagCount: 'responsive', onChange: onSelectClinic, 
                                        // value={selectedValues}
                                        options: getOptions(), 
                                        // onSearch={onSearchPackages}
                                        filterOption: false, onDeselect: onDeselectClinics }), props.clinics.map(function (clinic) {
                                        return _jsx(Option, { value: clinic.getId(), children: clinic.getName() }, clinic.getId());
                                    })] }) }) }), (_a = props.medicalMachine) === null || _a === void 0 ? void 0 : _a.getClinicMedicalMachines().map(function (clinicMedicalMachine) {
                        return (_jsx(ClinicMedicalMachineRow, { clinicMedicalMachine: clinicMedicalMachine, onChangeBoughtAt: onChangeBoughtAt }, clinicMedicalMachine.getClinicId()));
                    })] })] }));
};
export default localeTranslator(MedicalMachineForm, 'medicalMachineForm');
