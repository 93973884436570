import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Row, Col, Divider, Space } from 'antd';
import Loader from 'components/AntEnhancement/Loader';
import TransactionEntries from 'pages/accounting/ShowTransaction/components/Tables/TransactionEntries';
import MainInfo from 'pages/accounting/ShowTransaction/components/Informations/MainInfo';
import OtherInfo from 'pages/accounting/ShowTransaction/components/Informations/OtherInfo';
import { useParams } from 'react-router';
import services from 'services';
import './style.css';
import Header from 'pages/accounting/ShowTransaction/components/Header';
import localeTranslator from 'library/Hocs/LocalTranslator';
var ShowTransaction = function (props) {
    var id = useParams().id;
    var transactionService = services.transactionService;
    var _a = transactionService.useGetTransaction(Number(id)), transaction = _a.data, isTransactionLoading = _a.isLoading;
    var revert = transactionService.useRevertTransaction().mutate;
    var refund = transactionService.useRefundTransaction().mutate;
    var revertTransaction = function (transaction) {
        revert({ transaction: transaction, successMessage: 'transactionReverted' });
    };
    var refundTransaction = function (transaction) {
        refund({ transaction: transaction, successMessage: 'transactionRefunded' });
    };
    return (_jsx("div", { className: "loader-container-height", children: _jsx(Loader, { renderSpinner: isTransactionLoading, children: _jsxs("div", { children: [_jsxs(Space, { direction: 'vertical', size: 50, children: [_jsx("div", { className: "page-header add-padding", children: transaction &&
                                    _jsx(Header, { transaction: transaction, revertTransaction: revertTransaction, refundTransaction: refundTransaction }) }), _jsx("div", { className: "new-pages-content", children: _jsx(Space, { direction: "vertical", size: 24, children: _jsxs(Row, { children: [_jsx(Col, { xl: 12, children: transaction && _jsx(MainInfo, { transaction: transaction }) }), _jsx(Col, { sm: 1 }), _jsx(Col, { xl: 11, children: transaction &&
                                                    _jsx(OtherInfo, { transaction: transaction }) })] }) }) })] }), _jsx(Divider, {}), transaction &&
                        _jsx(TransactionEntries, { transaction_entries: transaction.getTransactionEntries() })] }) }) }));
};
export default localeTranslator(ShowTransaction, 'showTransaction');
