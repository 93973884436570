import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import Icon from '@ant-design/icons';
var DocumentIcon = function () {
    var Image = function () {
        return _jsx("svg", { xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", viewBox: "0 0 24 24", children: _jsxs("g", { id: "document-normal-2", "data-name": "document-normal", transform: "translate(-748 -188)", children: [_jsx("path", { id: "Vector", style: { fill: 'none', stroke: '#292d32', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '1.5px' }, className: "cls-1", d: "M2 4h4c2 0 2-1 2-2 0-2-1-2-2-2H2C1 0 0 0 0 2s1 2 2 2z", transform: "translate(756 190)" }), _jsx("path", { id: "Vector-2", style: { fill: 'none', stroke: '#292d32', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '1.5px' }, "data-name": "Vector", className: "cls-1", d: "M13 0c3.33.18 5 1.41 5 5.98v6c0 4-1 6-6 6H6c-5 0-6-2-6-6v-6C0 1.42 1.67.18 5 0", transform: "translate(751 192.02)" }), _jsx("path", { id: "Path_736", "data-name": "Path 736", d: "M2.1-.264a.192.192 0 0 1-.14-.06.192.192 0 0 1-.06-.14v-1.464H.48a.209.209 0 0 1-.148-.056.188.188 0 0 1-.06-.144v-.584a.2.2 0 0 1 .06-.148.2.2 0 0 1 .148-.06H1.9v-1.416a.188.188 0 0 1 .06-.144.2.2 0 0 1 .14-.056h.66a.2.2 0 0 1 .14.056.2.2 0 0 1 .056.144v1.416h1.42a.188.188 0 0 1 .144.06.209.209 0 0 1 .056.148v.584a.2.2 0 0 1-.056.144.2.2 0 0 1-.144.056H2.96v1.464a.2.2 0 0 1-.056.14.188.188 0 0 1-.144.06z", transform: "translate(758 204)", style: { fill: "#292d32", stroke: '#292d32', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '1.5px' } })] }) });
    };
    return (_jsx(Icon, { component: Image }));
};
export default memo(DocumentIcon);
