var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { useMutation, useQueryClient } from 'react-query';
import ApiService from './ApiService';
import { instanceToPlain } from 'class-transformer';
var SettlementService = /** @class */ (function (_super) {
    __extends(SettlementService, _super);
    function SettlementService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.rootPath = 'appointment_settlements';
        _this.successCreationMessage = '';
        return _this;
    }
    SettlementService.prototype.useAppointmentSettlement = function () {
        var _this = this;
        var queryClient = useQueryClient();
        var id;
        return useMutation(function (_a) {
            var successMessage = _a.successMessage, settlement = _a.settlement;
            _this.successCreationMessage = successMessage;
            var data = { settlement: instanceToPlain(settlement) };
            return _this.post(_this.rootPath, { data: data });
        }, {
            onSuccess: function () {
                // queryClient.invalidateQueries([queries.reservationVisits, id]);
                _this.dipatachCreationSuccess({ successMessage: _this.successCreationMessage });
            }
        });
    };
    SettlementService.getInstance = function () {
        if (!SettlementService.settlementServiceInstance) {
            SettlementService.settlementServiceInstance = new SettlementService();
        }
        return SettlementService.settlementServiceInstance;
    };
    return SettlementService;
}(ApiService));
export default SettlementService;
