import { jsx as _jsx } from "react/jsx-runtime";
import Accounts from 'pages/accounting/Accounts';
import NewAccount from 'pages/accounting/NewAccount';
import { Route } from 'react-router';
import AuthorizationService from '../AuthorizationService';
import { Index, Create } from 'constants/actions';
import { Account as AccountResource } from 'constants/resources';
var AccountsRoutes = function () {
    return ([
        _jsx(Route, { caseSensitive: true, path: '/accounts', element: AuthorizationService.getAuthorizedElement(_jsx(Accounts, {}), Index, AccountResource) }, 'accounts-route'),
        _jsx(Route, { caseSensitive: true, path: '/accounts/new', element: AuthorizationService.getAuthorizedElement(_jsx(NewAccount, {}), Create, AccountResource) }, 'new-accounts-route')
    ]);
};
export default AccountsRoutes;
