var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { classToPlain, plainToClass } from 'class-transformer';
import { VISIT_STATUSES } from 'constants/VisitStatuses';
import { Clinic, Doctor, Patient, ReservationVisit, ReservationVisitDetails, VisitDetail, Settlement, ClinicVisit } from 'models';
import Model from 'models/Model';
var Visit = /** @class */ (function (_super) {
    __extends(Visit, _super);
    function Visit() {
        var _this = _super.call(this) || this;
        _this.reservation_visits = [];
        _this.setVisitTime(new Date());
        _this.setTimeSlots(1);
        _this.setDetails([]);
        return _this;
    }
    Visit.prototype.getId = function () {
        return Number(this.id);
    };
    Visit.prototype.getDoctor = function () {
        return plainToClass(Doctor, this.doctor);
    };
    Visit.prototype.getDoctorId = function () {
        return Number(this.doctor_id);
    };
    Visit.prototype.setDoctor = function (doctor) {
        this.doctor = classToPlain(doctor);
    };
    Visit.prototype.getClinic = function () {
        return plainToClass(Clinic, this.clinic);
    };
    Visit.prototype.setClinic = function (clinic) {
        this.clinic = clinic;
    };
    Visit.prototype.getVisitTime = function () {
        return this.dateForamtter(new Date(this.time), 'DD/MM/YYYY hh:mm A');
    };
    Visit.prototype.getVisitTimeObject = function () {
        return new Date(this.time);
    };
    Visit.prototype.setVisitTime = function (time) {
        this.time = time.toString();
    };
    Visit.prototype.setDate = function (date) {
        this.choosenDate = date;
        var visitDate = this.getVisitTimeObject();
        visitDate.setDate(date.getDate());
        visitDate.setMonth(date.getMonth());
        visitDate.setFullYear(date.getFullYear());
        this.setVisitTime(visitDate);
    };
    Visit.prototype.setTime = function (time) {
        this.choosenTime = time;
        var visitTime = this.getVisitTimeObject();
        visitTime.setHours(time.getHours(), time.getMinutes(), 0, 0);
        this.setVisitTime(visitTime);
    };
    Visit.prototype.getDate = function () {
        return this.choosenDate;
    };
    Visit.prototype.getTime = function () {
        return this.choosenTime;
    };
    Visit.prototype.getStartedAt = function () {
        return this.dateForamtter(new Date(this.started_at), 'DD/MM/YYYY hh:mm A');
    };
    Visit.prototype.setStartedAt = function () {
        this.started_at = new Date().toString();
    };
    Visit.prototype.getFinishedAt = function () {
        return this.dateForamtter(new Date(this.finished_at), 'DD/MM/YYYY hh:mm A');
    };
    Visit.prototype.setFinishedAt = function () {
        this.finished_at = new Date().toString();
    };
    Visit.prototype.getCreatorUserId = function () {
        return Number(this.created_by);
    };
    Visit.prototype.getUpdatedByUserId = function () {
        return Number(this.updated_by);
    };
    Visit.prototype.getNote = function () {
        return this.note;
    };
    Visit.prototype.setNote = function (note) {
        this.note = note;
    };
    Visit.prototype.getCancelledAt = function () {
        return this.dateForamtter(new Date(this.cancelled_at), 'DD/MM/YYYY hh:mm A');
    };
    Visit.prototype.setCancelledAt = function () {
        this.cancelled_at = new Date().toString();
    };
    Visit.prototype.getCancellationReason = function () {
        return this.cancellation_reason;
    };
    Visit.prototype.setCancellationReason = function (cancellation_reason) {
        this.cancellation_reason = cancellation_reason;
    };
    Visit.prototype.getCancellationfee = function () {
        return Number(this.cancellation_fee);
    };
    Visit.prototype.setCancellation = function (cancellationFee) {
        this.cancellation_fee = Number(cancellationFee);
    };
    Visit.prototype.getStatusId = function () {
        return Number(this.visit_status_id);
    };
    Visit.prototype.setStatusId = function (statusId) {
        this.visit_status_id = Number(statusId);
    };
    Visit.prototype.getNumber = function () {
        return Number(this.number || 0);
    };
    Visit.prototype.getPatientArrivedAt = function () {
        return this.dateForamtter(new Date(this.arrived_at), 'DD/MM/YYYY hh:mm A');
    };
    Visit.prototype.setPatientArrivedAt = function () {
        this.arrived_at = new Date().toString();
    };
    Visit.prototype.getTimeSlots = function () {
        return Number(this.time_slots);
    };
    Visit.prototype.setTimeSlots = function (timeSlots) {
        if (timeSlots === void 0) { timeSlots = 1; }
        this.time_slots = Number(timeSlots);
    };
    Visit.prototype.getDetails = function () {
        return plainToClass(VisitDetail, this.visit_details);
    };
    Visit.prototype.setDetails = function (details) {
        this.visit_details = details;
    };
    Visit.prototype.addDetail = function (selectedPackage, remainder) {
        var visitDetail = new VisitDetail(selectedPackage);
        remainder && visitDetail.setRemainder(remainder);
        this.visit_details.push(visitDetail);
    };
    Visit.prototype.removeDetail = function (packageId, detailId) {
        if (packageId) {
            this.visit_details = this.visit_details.filter(function (vd) {
                return vd.getPackage().getId() !== Number(packageId);
            });
        }
        if (detailId) {
            this.visit_details = this.visit_details.filter(function (vd) {
                return vd.getId() !== Number(detailId);
            });
        }
    };
    Visit.prototype.isTimeValid = function () {
        return !!this.getDate() && !!this.getTime() && !!this.getTimeSlots();
    };
    Visit.prototype.isHasDetails = function () {
        return !!this.getDetails().length;
    };
    Visit.prototype.isValidForCreation = function () {
        return this.isTimeValid() && !!this.getDoctor() && this.isHasDetails();
    };
    Visit.prototype.getReservationId = function () {
        return Number(this.reservation_id);
    };
    Visit.prototype.getClinicId = function () {
        return Number(this.clinic_id);
    };
    Visit.prototype.setReservationVisits = function (reservationVisits) {
        this.reservation_visits = plainToClass(ReservationVisit, reservationVisits);
    };
    Visit.prototype.addReservationVisit = function (reservationId, packageId) {
        var reservationVisit;
        reservationVisit = this.getReservationVisits().find(function (rv) { return rv.getReservationId() === reservationId; });
        var isNewReservationVisit = false;
        if (!reservationVisit) {
            reservationVisit = new ReservationVisit(reservationId);
            isNewReservationVisit = true;
        }
        var packageIds = reservationVisit.getDetails().getPackageIds();
        packageIds.push(packageId);
        var reservationVisitDetails = new ReservationVisitDetails();
        reservationVisitDetails.setPackageIds(packageIds);
        reservationVisit.setDetails(reservationVisitDetails);
        isNewReservationVisit && this.reservation_visits.push(reservationVisit);
    };
    Visit.prototype.removeReservationVisit = function (reservationId, packageId) {
        this.reservation_visits = this.reservation_visits.filter(function (rv) {
            var packageIds = rv.getDetails().getPackageIds();
            if (packageIds.length > 1 && rv.getReservationId() === reservationId) {
                var reservationVisitDetails = new ReservationVisitDetails();
                reservationVisitDetails.setPackageIds(packageIds.filter(function (currentPackageId) { return currentPackageId != packageId; }));
                rv.setDetails(reservationVisitDetails);
            }
            else if (rv.getReservationId() === reservationId) {
                return false;
            }
            return true;
        });
    };
    Visit.prototype.getReservationVisits = function () {
        return plainToClass(ReservationVisit, this.reservation_visits);
    };
    Visit.prototype.setPatient = function (patient) {
        this.patient = plainToClass(Patient, patient);
    };
    Visit.prototype.getPatient = function () {
        return plainToClass(Patient, this.patient);
    };
    Visit.prototype.getVisitStatus = function () {
        return VISIT_STATUSES[this.getStatusId()];
    };
    Visit.prototype.setPatientId = function (patientId) {
        this.patient_id = patientId;
    };
    Visit.prototype.getPatientId = function () {
        return Number(this.patient_id);
    };
    Visit.prototype.getSettlements = function () {
        return plainToClass(Settlement, this.settlements);
    };
    Visit.prototype.getClinicVisit = function () {
        return plainToClass(ClinicVisit, this.clinic_visit);
    };
    Visit.prototype.setClinicVisit = function (clinic_visit) {
        this.clinic_visit = clinic_visit;
    };
    return Visit;
}(Model));
export default Visit;
