import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import Icon from '@ant-design/icons';
var CalendarIcon = function () {
    var Image = function () {
        return _jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: "1.5em", height: "1.5em", fill: "currentColor", viewBox: "0 0 24 24", focusable: "false", "data-icon": "user", "aria-hidden": "true", children: [_jsx("path", { id: "Vector-2", "data-name": "Vector", style: { fill: '#8f9eb3' }, d: "M13.711 2.31V.75a.75.75 0 0 0-1.5 0v1.5h-6.5V.75a.75.75 0 0 0-1.5 0v1.56A4.248 4.248 0 0 0 0 6.56a.5.5 0 0 0 .5.53h16.92a.5.5 0 0 0 .5-.53 4.248 4.248 0 0 0-4.209-4.25z", transform: "translate(3.039 1.25)" }), _jsx("path", { id: "Vector-3", "data-name": "Vector", style: { fill: '#8f9eb3' }, d: "M17 0H1a1 1 0 0 0-1 1v6.16c0 3 1.5 5 5 5h8c3.5 0 5-2 5-5V1a1 1 0 0 0-1-1zM6.21 8.37a1.576 1.576 0 0 1-.15.12.757.757 0 0 1-.18.09.636.636 0 0 1-.18.06 1.355 1.355 0 0 1-.2.02 1 1 0 0 1-.38-.08 1.032 1.032 0 0 1-.33-.21 1.014 1.014 0 0 1 0-1.42 1.032 1.032 0 0 1 .33-.21.956.956 0 0 1 .58-.06.636.636 0 0 1 .18.06.757.757 0 0 1 .18.09l.15.12a1.014 1.014 0 0 1 0 1.42zm0-3.5a1.014 1.014 0 0 1-1.42 0 1.014 1.014 0 0 1 0-1.42 1 1 0 0 1 1.09-.21.933.933 0 0 1 .33.21 1.014 1.014 0 0 1 0 1.42zm3.5 3.5a1 1 0 0 1-1.42-1.42 1 1 0 0 1 1.42 1.42zm0-3.5-.15.12a.757.757 0 0 1-.18.09.636.636 0 0 1-.18.06 1.355 1.355 0 0 1-.2.02.99.99 0 0 1-1-1 1.052 1.052 0 0 1 .29-.71.933.933 0 0 1 .33-.21 1 1 0 0 1 1.09.21 1.014 1.014 0 0 1 0 1.42zm3.5 3.5a1 1 0 0 1-1.42-1.42 1 1 0 0 1 1.42 1.42zm0-3.5-.15.12a.757.757 0 0 1-.18.09.636.636 0 0 1-.18.06 1.5 1.5 0 0 1-.2.02.99.99 0 0 1-1-1 1.052 1.052 0 0 1 .29-.71 1.032 1.032 0 0 1 .33-.21.956.956 0 0 1 .58-.06.636.636 0 0 1 .18.06.757.757 0 0 1 .18.09l.15.12a1.014 1.014 0 0 1 0 1.42z", transform: "translate(3 9.84)" })] });
    };
    return (_jsx(Icon, { component: Image }));
};
export default memo(CalendarIcon);
