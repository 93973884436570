import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import Icon from '@ant-design/icons';
var RightMarkIcon = function () {
    var Image = function () {
        return _jsx("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "-10 -11 40 40", fill: "#fff", children: _jsx("path", { d: "M6.888 13.758a1.86 1.86 0 0 1-1.329-.552L.545 8.192A1.879 1.879 0 0 1 3.2 5.534L6.888 9.22l8.7-8.675A1.879 1.879 0 1 1 18.245 3.2L8.217 13.231a2.066 2.066 0 0 1-1.329.527z" }) });
    };
    return (_jsx(Icon, { component: Image }));
};
export default memo(RightMarkIcon);
