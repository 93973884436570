import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import Icon from '@ant-design/icons';
var VisitIcon = function () {
    var Image = function () {
        return _jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: "1.5em", height: "1.5em", fill: "currentColor", viewBox: "0 0 25 25", focusable: "false", "data-icon": "user", "aria-hidden": "true", children: [_jsx("path", { id: "Vector-2", "data-name": "Vector", d: "m8.52 3.85.59 9.89a.714.714 0 0 1-.18.53.693.693 0 0 1-.53.23H5.67L7.83 3.85zM9.62 0l-.01.02a2.342 2.342 0 0 1-.06.74L2.18 14.29A2.228 2.228 0 0 1 0 16h8.4a2.21 2.21 0 0 0 2.21-2.38z", transform: "translate(12.38 6)" }), _jsx("path", { id: "Vector-3", "data-name": "Vector", d: "M1.96.929a.756.756 0 0 0-.55-.91.764.764 0 0 0-.91.55L0 2.639h1.54z", transform: "translate(9.49 1.311)" }), _jsx("path", { id: "Vector-4", "data-name": "Vector", d: "M1.92.908A.751.751 0 1 0 .45.6L0 2.668h1.54z", transform: "translate(16.13 1.302)" }), _jsx("path", { id: "Vector-5", "data-name": "Vector", d: "M20.82 1.39A2.213 2.213 0 0 0 18.75.02h-2.08l-.56 2.59a.756.756 0 0 1-.73.59.44.44 0 0 1-.16-.02.765.765 0 0 1-.58-.89l.49-2.28h-5.1l-.63 2.6a.748.748 0 0 1-.73.57 1.1 1.1 0 0 1-.18-.02.751.751 0 0 1-.55-.91L8.48 0H6.45a2.241 2.241 0 0 0-2.14 1.58L.1 15.13a2.261 2.261 0 0 0 2.14 2.93h13.14a2.228 2.228 0 0 0 2.18-1.71l3.37-13.53a2.342 2.342 0 0 0 .06-.74 2 2 0 0 0-.17-.69zM13.7 12.81h-8a.75.75 0 0 1 0-1.5h8a.75.75 0 0 1 0 1.5zm1-4h-8a.75.75 0 1 1 0-1.5h8a.75.75 0 1 1 0 1.5z", transform: "translate(1 3.94)" })] });
    };
    return (_jsx(Icon, { component: Image }));
};
export default memo(VisitIcon);
