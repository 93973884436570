import { jsx as _jsx } from "react/jsx-runtime";
import { Drawer } from 'antd';
import localeTranslator from 'library/Hocs/LocalTranslator';
import services from 'services';
import 'pages/ShowAppointment/style.css';
import PatientLog from '../PatientLog';
import { useParams } from 'react-router';
var PatientLogDrawer = function (props) {
    var onClose = function () { return props.onCloseDrawer(); };
    var id = useParams().id;
    var currentUSer = services.userService.useCurrentUser().data;
    var placement = (currentUSer === null || currentUSer === void 0 ? void 0 : currentUSer.getLanguage()) != 'en' ? 'left' : 'right';
    return (_jsx(Drawer, { placement: placement, open: props.visiable, size: "default", onClose: onClose, className: "patient-log-drawer", children: props.appointment && _jsx(PatientLog, { patientId: props.appointment.getPatient().getId(), appointmentId: Number(id) }) }, placement));
};
export default localeTranslator(PatientLogDrawer, 'patientLog');
