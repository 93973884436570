var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import Model from 'models/Model';
import { DocumentLine } from 'models';
import { plainToInstance } from 'class-transformer';
var DocumentLineGroup = /** @class */ (function (_super) {
    __extends(DocumentLineGroup, _super);
    function DocumentLineGroup() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.document_lines = [];
        return _this;
    }
    DocumentLineGroup.prototype.getId = function () {
        return Number(this.id);
    };
    DocumentLineGroup.prototype.getName = function () {
        return this.name;
    };
    DocumentLineGroup.prototype.setName = function (name) {
        this.name = name;
    };
    DocumentLineGroup.prototype.getPaidOn = function () {
        return this.paid_on;
    };
    DocumentLineGroup.prototype.getDisplayedPaidOn = function () {
        return this.displayed_paid_on;
    };
    DocumentLineGroup.prototype.setPaidOn = function (paidOn) {
        this.paid_on = paidOn;
    };
    DocumentLineGroup.prototype.getPaidFrom = function () {
        return this.paid_from;
    };
    DocumentLineGroup.prototype.getDisplayedPaidFrom = function () {
        return this.displayed_paid_from;
    };
    DocumentLineGroup.prototype.setPaidFrom = function (paidFrom) {
        this.paid_from = paidFrom;
    };
    DocumentLineGroup.prototype.getDocumentLinesCount = function () {
        return Number(this.document_lines_count);
    };
    DocumentLineGroup.prototype.getDocumentLines = function () {
        return plainToInstance(DocumentLine, this.document_lines) || [];
    };
    DocumentLineGroup.prototype.setDocumentLines = function (documentLines) {
        this.document_lines = plainToInstance(DocumentLine, documentLines);
    };
    DocumentLineGroup.prototype.addDocumentLine = function () {
        this.document_lines.push(new DocumentLine());
    };
    DocumentLineGroup.prototype.setDocumentId = function (documentId) {
        this.document_id = documentId;
    };
    DocumentLineGroup.prototype.getDocumentId = function () {
        return Number(this.document_id);
    };
    return DocumentLineGroup;
}(Model));
export default DocumentLineGroup;
