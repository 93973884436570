var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { plainToInstance } from 'class-transformer';
import queries from 'constants/queries';
import { AccountType } from 'models';
import { useQuery } from 'react-query';
import ApiService from '../ApiService';
var AccountTypeService = /** @class */ (function (_super) {
    __extends(AccountTypeService, _super);
    function AccountTypeService() {
        var _this = _super.call(this) || this;
        _this.accountTypesRoot = 'account_types';
        return _this;
    }
    AccountTypeService.prototype.useAccountTypes = function () {
        var _this = this;
        return useQuery(queries.accounting.accountTypes, function () {
            return _this.get(_this.accountTypesRoot);
        }, {
            select: function (response) {
                var data = response.data;
                return plainToInstance(AccountType, data);
            }
        });
    };
    AccountTypeService.prototype.getAccountTypeDataByID = function (accountTypes, id) {
        return accountTypes === null || accountTypes === void 0 ? void 0 : accountTypes.find(function (accountType) { return accountType.getId() === id; });
    };
    AccountTypeService.getInstance = function () {
        if (!AccountTypeService.accountTypeService) {
            AccountTypeService.accountTypeService = new AccountTypeService();
        }
        return AccountTypeService.accountTypeService;
    };
    return AccountTypeService;
}(ApiService));
export default AccountTypeService;
