var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { plainToClass } from 'class-transformer';
import { Package, UnitMeasurement } from 'models';
import Model from 'models/Model';
import dayjs from 'dayjs';
var PackagePrice = /** @class */ (function (_super) {
    __extends(PackagePrice, _super);
    function PackagePrice() {
        var _this = _super.call(this) || this;
        _this.setIsEnabled(true);
        return _this;
    }
    PackagePrice.prototype.getId = function () {
        return Number(this.id);
    };
    PackagePrice.prototype.getFinishedAt = function () {
        return this.dateForamtter(new Date(this.finished_at), 'DD/MM/YYYY');
    };
    PackagePrice.prototype.setFinishedAt = function (finishedAt) {
        this.finished_at = finishedAt.toString();
    };
    PackagePrice.prototype.getFinishedAtDate = function () {
        return dayjs(this.finished_at);
    };
    PackagePrice.prototype.getStartedAt = function () {
        return this.dateForamtter(new Date(this.started_at), 'DD/MM/YYYY');
    };
    PackagePrice.prototype.setStartedAt = function (startedAt) {
        this.started_at = startedAt.toString();
    };
    PackagePrice.prototype.getPrice = function () {
        return Number(this.price);
    };
    PackagePrice.prototype.setPrice = function (price) {
        this.price = price;
    };
    PackagePrice.prototype.getPriceType = function () {
        return this.price_type;
    };
    PackagePrice.prototype.setPriceType = function (priceType) {
        this.price_type = priceType;
    };
    PackagePrice.prototype.getTotalPrice = function () {
        return this.getPriceType() === 'total_price' ?
            this.getPrice() : this.getPrice() * this.getQuantity();
    };
    PackagePrice.prototype.getQuantity = function () {
        return Number(this.quantity);
    };
    PackagePrice.prototype.setQuantity = function (quantity) {
        this.quantity = quantity;
    };
    PackagePrice.prototype.setUnitMeasurement = function (unitMeasurement) {
        this.unit_measurement = unitMeasurement;
    };
    PackagePrice.prototype.getUnitMeasurement = function () {
        return plainToClass(UnitMeasurement, this.unit_measurement);
    };
    PackagePrice.prototype.getIsEnabled = function () {
        return this.is_enabled;
    };
    PackagePrice.prototype.setIsEnabled = function (isEnabled) {
        this.is_enabled = isEnabled;
    };
    PackagePrice.prototype.setPackageId = function (packageId) {
        this.package_id = packageId;
    };
    PackagePrice.prototype.getPackageId = function () {
        return Number(this.package_id);
    };
    PackagePrice.prototype.getUnitMeasurementId = function () {
        return Number(this.unit_measurement_id);
    };
    PackagePrice.prototype.setUnitMeasurementId = function (value) {
        this.unit_measurement_id = value;
    };
    PackagePrice.prototype.getDisplayedPriceType = function () {
        return this.displayed_price_type;
    };
    PackagePrice.prototype.getPackageName = function () {
        return this.package_name;
    };
    PackagePrice.prototype.getEntityName = function () {
        return this.entity_name;
    };
    PackagePrice.prototype.getEntityType = function () {
        return this.entity_type;
    };
    PackagePrice.prototype.getPackage = function () {
        return plainToClass(Package, this.package);
    };
    return PackagePrice;
}(Model));
export default PackagePrice;
