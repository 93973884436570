var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { plainToClass } from 'class-transformer';
import Model from 'models/Model';
import { MedicalMachineUsage } from '.';
var MedicalMachineVisitDetail = /** @class */ (function (_super) {
    __extends(MedicalMachineVisitDetail, _super);
    function MedicalMachineVisitDetail() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    MedicalMachineVisitDetail.prototype.getId = function () {
        return this.id;
    };
    MedicalMachineVisitDetail.prototype.getVisitId = function () {
        return Number(this.visit_id);
    };
    MedicalMachineVisitDetail.prototype.setVisitId = function (visitId) {
        this.visit_id = visitId;
    };
    MedicalMachineVisitDetail.prototype.getReservationId = function () {
        return Number(this.reservation_id);
    };
    MedicalMachineVisitDetail.prototype.setReservationId = function (reservationId) {
        this.reservation_id = reservationId;
    };
    MedicalMachineVisitDetail.prototype.getPackageId = function () {
        return Number(this.package_id);
    };
    MedicalMachineVisitDetail.prototype.setPackageId = function (packageId) {
        this.package_id = packageId;
    };
    MedicalMachineVisitDetail.prototype.setDetails = function (details) {
        this.details = details;
    };
    MedicalMachineVisitDetail.prototype.getDetails = function () {
        return plainToClass(MedicalMachineUsage, this.details);
    };
    return MedicalMachineVisitDetail;
}(Model));
export default MedicalMachineVisitDetail;
