import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import './style.css';
var Article = function (props) {
    var StringToList = function () {
        var _a;
        var listItems = ((_a = props.description) === null || _a === void 0 ? void 0 : _a.split('\n')) || [''];
        return (_jsx("ul", { children: listItems.map(function (item, index) { return (_jsx("li", { className: "body---14pt-R description-text", children: item }, index)); }) }));
    };
    return (_jsxs("div", { className: "article", children: [_jsx("div", { className: "Header-R-14 title-margin", children: props.title }), _jsx(StringToList, {})] }));
};
export default Article;
