var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { plainToClass } from 'class-transformer';
import apiConfig from 'config/api';
import { get } from 'library/utilities/Storage';
import { Error } from 'models';
import EventService from './EventSerivce';
var ApiService = /** @class */ (function (_super) {
    __extends(ApiService, _super);
    function ApiService() {
        var _this = _super.call(this) || this;
        _this.baseURL = apiConfig.API_URL ? apiConfig.API_URL : '';
        return _this;
    }
    ApiService.prototype.post = function (url, body) {
        if (url === void 0) { url = ''; }
        return this.requestHandler(this.baseURL + url, 'POST', body);
    };
    ApiService.prototype.get = function (url, params) {
        if (url === void 0) { url = ''; }
        var paramsString = this.formatParams(params);
        var currentURL = this.baseURL + url + paramsString;
        return this.requestHandler(currentURL, 'GET', undefined);
    };
    ApiService.prototype.put = function (url, body) {
        if (url === void 0) { url = ''; }
        return this.requestHandler(this.baseURL + url, 'PUT', body);
    };
    ApiService.prototype.delete = function (url, body) {
        if (url === void 0) { url = ''; }
        return this.requestHandler(this.baseURL + url, 'DELETE', body);
    };
    ApiService.prototype.apiInformation = function (method, body) {
        if (method === void 0) { method = 'GET'; }
        var requestInfo = {
            method: method,
            headers: this.authHeader()
        };
        if (body) {
            requestInfo['body'] = JSON.stringify(body.data);
        }
        return requestInfo;
    };
    ApiService.prototype.authHeader = function () {
        var requestHeaders = new Headers();
        var token = get('token') || '';
        requestHeaders.set('Authorization', token);
        requestHeaders.set('Content-Type', 'application/json');
        return requestHeaders;
    };
    ApiService.prototype.requestHandler = function (url, method, body) {
        if (url === void 0) { url = ''; }
        if (method === void 0) { method = 'GET'; }
        return __awaiter(this, void 0, void 0, function () {
            var apiData;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, fetch(url, this.apiInformation(method, body))];
                    case 1:
                        apiData = _a.sent();
                        return [2 /*return*/, this.responseHandler(apiData)];
                }
            });
        });
    };
    ApiService.prototype.responseHandler = function (apiResponse) {
        return __awaiter(this, void 0, void 0, function () {
            var status, data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        status = apiResponse.status;
                        return [4 /*yield*/, apiResponse.json()];
                    case 1:
                        data = _a.sent();
                        if (status >= 400) {
                            this.dipatachError(plainToClass(Error, data));
                            throw data;
                        }
                        return [2 /*return*/, { status: status, data: data }];
                }
            });
        });
    };
    ApiService.prototype.formatParams = function (params) {
        if (!params) {
            return '';
        }
        var paramsString = '?';
        for (var _i = 0, _a = Object.entries(params); _i < _a.length; _i++) {
            var _b = _a[_i], key = _b[0], value = _b[1];
            if (value) {
                paramsString += "".concat(key, "=").concat(value, "&");
            }
        }
        return paramsString;
    };
    return ApiService;
}(EventService));
export default ApiService;
