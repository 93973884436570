// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#new-reservation {
  /* scroll-margin-top: 100;/ */
  margin-bottom: 50px;
}

#new-reservation .tabs {
  background-color: white;
  width: auto;
  position: fixed;
  top: 68px;
  right: 36px;
  left: 336px;
  z-index: 2;
  box-shadow: 2px 2px 2px rgb(24 96 108 / 12%);
}

.ant-layout-rtl #new-reservation .tabs {
  right: 336px;
  left: 36px;
}

#new-reservation .ant-tabs-nav {
  margin: 0 !important;
}

#new-reservation .ant-steps-navigation .ant-steps-item-container {
  /* padding-bottom: 0 !important; */
}
`, "",{"version":3,"sources":["webpack://./src/pages/NewReservationPackage/style.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,mBAAmB;AACrB;;AAEA;EACE,uBAAuB;EACvB,WAAW;EACX,eAAe;EACf,SAAS;EACT,WAAW;EACX,WAAW;EACX,UAAU;EACV,4CAA4C;AAC9C;;AAEA;EACE,YAAY;EACZ,UAAU;AACZ;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,kCAAkC;AACpC","sourcesContent":["#new-reservation {\n  /* scroll-margin-top: 100;/ */\n  margin-bottom: 50px;\n}\n\n#new-reservation .tabs {\n  background-color: white;\n  width: auto;\n  position: fixed;\n  top: 68px;\n  right: 36px;\n  left: 336px;\n  z-index: 2;\n  box-shadow: 2px 2px 2px rgb(24 96 108 / 12%);\n}\n\n.ant-layout-rtl #new-reservation .tabs {\n  right: 336px;\n  left: 36px;\n}\n\n#new-reservation .ant-tabs-nav {\n  margin: 0 !important;\n}\n\n#new-reservation .ant-steps-navigation .ant-steps-item-container {\n  /* padding-bottom: 0 !important; */\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
