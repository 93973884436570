var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { plainToInstance } from 'class-transformer';
import queries from 'constants/queries';
import { DocumentLineMetaInfo } from 'models';
import { useQuery } from 'react-query';
import ApiService from '../ApiService';
var DocumentLineService = /** @class */ (function (_super) {
    __extends(DocumentLineService, _super);
    function DocumentLineService() {
        var _this = _super.call(this) || this;
        _this.documentLinesRoot = 'document_lines';
        return _this;
    }
    DocumentLineService.prototype.useMetaInfo = function () {
        var _this = this;
        return useQuery(['meta-', queries.accounting.documentLines], function () {
            return _this.get("".concat(_this.documentLinesRoot, "/meta_info"));
        }, {
            select: function (_a) {
                var data = _a.data;
                return plainToInstance(DocumentLineMetaInfo, data);
            }
        });
    };
    DocumentLineService.getInstance = function () {
        if (!DocumentLineService.documentLineService) {
            DocumentLineService.documentLineService = new DocumentLineService();
        }
        return DocumentLineService.documentLineService;
    };
    return DocumentLineService;
}(ApiService));
export default DocumentLineService;
