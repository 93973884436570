import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Space } from 'antd';
import Loader from 'components/AntEnhancement/Loader';
import MainInfo from 'pages/inventory/ShowStockMovement/components/Informations/MainInfo';
import StockInfo from 'pages/inventory/ShowStockMovement/components/Informations/StockInfo';
import EntityInfo from 'pages/inventory/ShowStockMovement/components/Informations/EntityInfo';
import { useParams } from 'react-router';
import services from 'services';
import Header from 'pages/inventory/ShowStockMovement/components/Header';
import localeTranslator from 'library/Hocs/LocalTranslator';
import './style.css';
var ShowStockMovement = function () {
    var id = useParams().id;
    var stockMovementService = services.stockMovementService;
    var _a = stockMovementService.useStockMovement(Number(id)), stockMovement = _a.data, isStockMovementLoading = _a.isLoading;
    return (_jsx("div", { className: "loader-container-height", children: _jsx(Loader, { renderSpinner: isStockMovementLoading, children: _jsx("div", { children: _jsxs(Space, { direction: 'vertical', size: 50, children: [_jsx("div", { className: "page-header add-padding", children: _jsx(Header, {}) }), _jsx("div", { className: "new-pages-content", children: _jsxs(Space, { direction: "vertical", size: 24, children: [stockMovement && _jsx(MainInfo, { stockMovement: stockMovement }), stockMovement && _jsx(StockInfo, { stockMovement: stockMovement }), stockMovement && _jsx(EntityInfo, { stockMovement: stockMovement })] }) })] }) }) }) }));
};
export default localeTranslator(ShowStockMovement, 'showSockMovement');
