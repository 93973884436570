import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Form, Row, Col, Input, Button } from 'antd';
import { FormInput } from 'components/AntEnhancement/FormControls';
import localeTranslator from 'library/Hocs/LocalTranslator';
import { FollowUp } from 'models';
import './style.css';
var TextArea = Input.TextArea;
var NewFollowUp = function (props) {
    var form = Form.useForm()[0];
    var followUp = new FollowUp();
    var onChangeDescription = function (event) {
        followUp.setResult(event.target.value);
    };
    var onChangeFollowUpName = function (event) {
        followUp.setName(event.target.value);
    };
    var submit = function () {
        props.createFollowUp(followUp);
        props.setShowFollowUpForm(false);
    };
    return (_jsx("div", { className: "follow-up-form-part", children: _jsx(Form, { layout: 'vertical', form: form, initialValues: { layout: 'vertical' }, size: 'large', onFinish: submit, children: _jsx(Row, { children: _jsxs(Col, { xs: 24, sm: 24, lg: 24, xl: 24, children: [_jsx(FormInput, { name: "firstName", placeHolder: props.translate('followUps.form.name'), rules: [
                                { required: true, message: props.translate('followUps.form.errors.name') }
                            ], bordered: false, onChange: onChangeFollowUpName }), _jsx("div", { className: "new-follow-up-line" }), _jsx(Form.Item, { name: "description", className: "form-item body---14pt-R", rules: [
                                { required: true, message: props.translate('followUps.form.errors.description') }
                            ], children: _jsx(TextArea, { className: "text-area-border space-item", placeholder: props.translate('followUps.form.description'), onChange: onChangeDescription, bordered: false, rows: 3 }) }), _jsx("div", { className: "new-follow-up-line" }), _jsx(Form.Item, { children: _jsx(Button, { type: "primary", htmlType: "submit", className: "add-follow-up-button Header-16-M", children: props.translate('common:buttons.submit') }) })] }) }) }) }));
};
export default localeTranslator(NewFollowUp, 'patientLog');
