import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Table } from 'antd';
import localeTranslator from 'library/Hocs/LocalTranslator';
import EmptyComponent from 'components/EmptyComponent';
import './style.css';
import '../../style.css';
var Stocks = function (props) {
    var columns = [
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('tables.stocks.id') }),
            key: 'id',
            render: function (text, record, index) {
                return _jsx("div", { className: "body---14pt-R", children: record.getId() }, 'id-' + index);
            }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('tables.stocks.location') }),
            key: 'location',
            render: function (text, record, index) {
                return _jsx("div", { className: "body---14pt-R table-body-content", children: record.getLocation().getName() }, 'location-' + index);
            }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('tables.stocks.quantity') }),
            key: 'quantity',
            render: function (text, record, index) {
                return _jsx("div", { className: "body---14pt-R table-body-content", children: record.getQuantity() }, 'quantity-' + index);
            }
        }
    ];
    return (_jsxs("div", { className: "page-subcontent", children: [_jsx("div", { className: "Header-18-M form-title", children: props.translate('tables.stocks.header') }), _jsx(Table, { columns: columns, pagination: false, dataSource: props.product.getStocks(), locale: {
                    emptyText: _jsx(EmptyComponent, {})
                } })] }));
};
export default localeTranslator(Stocks, 'showProduct');
