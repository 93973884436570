// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.remove-padding {
  padding-bottom: 0px !important;
}

.patient-info {
  background-color: white;
  padding: 24px 24px;
  border-radius: 5px;
  height: 100%;
}

#patient-tabs .ant-tabs-nav-list{
  background-color: white;
  width: 100%;

  border-radius: 4px;
  border: solid 1px var(--border-color);
}

#patient-tabs .ant-tabs-tab {
  flex-grow: 0.34;
  height: 63px;
  display: flex;
  justify-content: center;
}

#patient-tabs .ant-tabs-tab-active {
  background-color: rgba(var(--primary-color-hex), 0.08);
}

.package-status-button {
  width: 152px;
  height: 35px;
  margin: 24px 0px 36px 0px;
  padding: 9px 40px 9px 41px;
  border-radius: 18px;
  border: solid 1px rgba(209, 209, 209, 0.71);
  background-color: #f7f9fb;
}

.package-status-active-button {
  color: var(--primary-color);
  border: solid 1px var(--primary-color);
  background-color: rgba(255, 255, 255, 0);
}

.reservation-drawer-line {
  width: 1000px;
  height: 1px;
  margin: 17px 52.5px 5px 25.5px;
  background-color: var(--border-color);
}

.normal-card-color {
  background-color: white;
}

.selected-card-color {
  background-color: rgba(var(--primary-color-hex), 0.08) !important;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ShowPatient/style.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;AAChC;;AAEA;EACE,uBAAuB;EACvB,kBAAkB;EAClB,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,uBAAuB;EACvB,WAAW;;EAEX,kBAAkB;EAClB,qCAAqC;AACvC;;AAEA;EACE,eAAe;EACf,YAAY;EACZ,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,sDAAsD;AACxD;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,yBAAyB;EACzB,0BAA0B;EAC1B,mBAAmB;EACnB,2CAA2C;EAC3C,yBAAyB;AAC3B;;AAEA;EACE,2BAA2B;EAC3B,sCAAsC;EACtC,wCAAwC;AAC1C;;AAEA;EACE,aAAa;EACb,WAAW;EACX,8BAA8B;EAC9B,qCAAqC;AACvC;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,iEAAiE;AACnE","sourcesContent":[".remove-padding {\n  padding-bottom: 0px !important;\n}\n\n.patient-info {\n  background-color: white;\n  padding: 24px 24px;\n  border-radius: 5px;\n  height: 100%;\n}\n\n#patient-tabs .ant-tabs-nav-list{\n  background-color: white;\n  width: 100%;\n\n  border-radius: 4px;\n  border: solid 1px var(--border-color);\n}\n\n#patient-tabs .ant-tabs-tab {\n  flex-grow: 0.34;\n  height: 63px;\n  display: flex;\n  justify-content: center;\n}\n\n#patient-tabs .ant-tabs-tab-active {\n  background-color: rgba(var(--primary-color-hex), 0.08);\n}\n\n.package-status-button {\n  width: 152px;\n  height: 35px;\n  margin: 24px 0px 36px 0px;\n  padding: 9px 40px 9px 41px;\n  border-radius: 18px;\n  border: solid 1px rgba(209, 209, 209, 0.71);\n  background-color: #f7f9fb;\n}\n\n.package-status-active-button {\n  color: var(--primary-color);\n  border: solid 1px var(--primary-color);\n  background-color: rgba(255, 255, 255, 0);\n}\n\n.reservation-drawer-line {\n  width: 1000px;\n  height: 1px;\n  margin: 17px 52.5px 5px 25.5px;\n  background-color: var(--border-color);\n}\n\n.normal-card-color {\n  background-color: white;\n}\n\n.selected-card-color {\n  background-color: rgba(var(--primary-color-hex), 0.08) !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
