import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Button, Drawer, Form } from 'antd';
import localeTranslator from 'library/Hocs/LocalTranslator';
import { Transaction } from 'models';
import services from 'services';
import '../../style.css';
import { useParams } from 'react-router';
import TransactionForm from 'components/forms/accounting/transactions/TransactionForm';
var AddTransactionDrawer = function (props) {
    var transaction = useState(new Transaction())[0];
    var id = useParams().id;
    var form = Form.useForm()[0];
    var onClose = function () { return props.onCloseDrawer(); };
    var transactionService = services.transactionService, documentLineGroupService = services.documentLineGroupService;
    var documentLineGroups = documentLineGroupService.usePatientGroups().data;
    var _a = transactionService.useCreatePatientTransaction(), createTransaction = _a.mutate, isTransactionCreated = _a.isSuccess;
    useEffect(function () {
        isTransactionCreated && onClose();
    }, [isTransactionCreated]);
    var onSubmit = function () {
        transaction.setEntityId(Number(id));
        createTransaction({ transaction: transaction, successMessage: 'transactionCreated' });
    };
    return (_jsx(Drawer, { title: props.translate('visitDrawer.title'), placement: "bottom", open: props.visiable, size: "default", onClose: onClose, height: 642, className: "transaction-drawer", children: _jsxs(Form, { layout: 'vertical', form: form, initialValues: { layout: 'vertical' }, size: 'large', onFinish: onSubmit, children: [documentLineGroups &&
                    _jsx(TransactionForm, { documentLineGroups: documentLineGroups, transaction: transaction }), _jsx(Form.Item, { children: _jsx(Button, { type: "primary", htmlType: "submit", className: "add-submit-button Header-16-M", children: props.translate('common:buttons.submit') }) })] }) }, "bottom"));
};
export default localeTranslator(AddTransactionDrawer, 'showPatient');
