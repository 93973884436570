var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Row, Col, Avatar, Space, Typography } from 'antd';
import { EmailIcon, MoneyIcon, TelephoneIcon } from 'assets/icons';
import { UserOutlined } from '@ant-design/icons';
import './style.css';
var Text = Typography.Text;
var UserCard = function (props) {
    var getAvaterSource = function () {
        if (props.imagePath) {
            return { src: props.imagePath };
        }
        return {
            icon: _jsx(UserOutlined, {}),
            className: 'empty-user-image'
        };
    };
    return (_jsx("div", { className: 'card-layout ant-card-hoverable', children: _jsxs(Row, { children: [_jsxs(Col, { span: '17', children: [_jsx("div", { className: 'Header-16-M', children: props.name }), _jsxs(Space, { direction: 'vertical', size: 'small', children: [_jsx(Text, { className: "body---14pt-R description-text", ellipsis: true, children: props.subTitle }), _jsxs(Space, { size: 0, direction: 'vertical', children: [_jsxs("div", { className: 'description-div description-text', children: [_jsx(TelephoneIcon, {}), _jsx(Text, { className: "body---14pt-R description-text", children: props.phoneNumber })] }), _jsxs("div", { className: 'description-text description-div', children: [_jsx(EmailIcon, {}), _jsx(Text, { className: "body---14pt-R email description-text", ellipsis: true, children: props.email })] }), props.salary &&
                                            _jsxs("div", { className: 'description-text description-div', children: [_jsx(MoneyIcon, {}), _jsx(Text, { className: "body---14pt-R description-text", children: props.salary })] })] })] })] }), _jsx(Col, { span: '7', children: _jsx(Avatar, __assign({}, getAvaterSource(), { size: { xs: 100, sm: 100, md: 100, lg: 100, xl: 100, xxl: 100 } })) })] }) }, props.id));
};
export default UserCard;
