export var PENDING = 1;
export var CONFIRMED = 2;
export var WAITING = 3;
export var IN_VISIT = 4;
export var AFTER_WORK = 5;
export var FINISHED = 6;
export var UN_REACHED = 7;
export var LATE = 8;
export var CANCELLED = 9;
export var POSTPONE = 10;
export var VISIT_STATUSES = Object.freeze({
    1: 'pending',
    2: 'confirmed',
    3: 'waiting',
    4: 'inVisit',
    5: 'afterWork',
    6: 'finished',
    7: 'unReached',
    8: 'late',
    9: 'cancelled',
    10: 'postpone'
});
