var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { instanceToPlain, plainToInstance } from 'class-transformer';
import queries from 'constants/queries';
import { Appointment, PaginationMetaData } from 'models';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import ApiService from './ApiService';
import { useNavigate } from 'react-router';
var AppointmentService = /** @class */ (function (_super) {
    __extends(AppointmentService, _super);
    function AppointmentService() {
        var _this = _super.call(this) || this;
        _this.appointmentsRoot = 'appointments';
        return _this;
    }
    AppointmentService.prototype.useAppointments = function (pageNumber, filters) {
        var _this = this;
        if (pageNumber === void 0) { pageNumber = 1; }
        if (filters === void 0) { filters = {}; }
        return useQuery([queries.appointments, pageNumber, filters], function () {
            return _this.get(_this.appointmentsRoot, __assign(__assign({}, filters), { page: pageNumber }));
        }, {
            keepPreviousData: true,
            select: function (_a) {
                var data = _a.data;
                var appointments = plainToInstance(Appointment, data === null || data === void 0 ? void 0 : data.appointments);
                var meta = plainToInstance(PaginationMetaData, data === null || data === void 0 ? void 0 : data.meta);
                return { appointments: appointments, meta: meta };
            }
        });
    };
    AppointmentService.prototype.useAllAppointments = function (filters) {
        var _this = this;
        return useQuery([queries.appointments, filters], function () {
            var plainedFilters = instanceToPlain(filters);
            return _this.get("".concat(_this.appointmentsRoot, "/list/all"), __assign({}, plainedFilters));
        }, {
            select: function (_a) {
                var data = _a.data;
                return plainToInstance(Appointment, data);
            }
        });
    };
    AppointmentService.prototype.useGetAppointment = function (id) {
        var _this = this;
        return useQuery([queries.appointments, id], function () {
            return _this.get("".concat(_this.appointmentsRoot, "/").concat(id));
        }, {
            retry: false,
            select: function (_a) {
                var data = _a.data;
                return plainToInstance(Appointment, data);
            }
        });
    };
    AppointmentService.prototype.useCreateAppointment = function () {
        var _this = this;
        var queryClient = useQueryClient();
        var navigate = useNavigate();
        return useMutation(function (_a) {
            var appointment = _a.appointment;
            var data = { appointment: instanceToPlain(appointment) };
            return _this.post(_this.appointmentsRoot, { data: data });
        }, {
            retry: false,
            onSuccess: function (response) {
                var data = response.data;
                queryClient.invalidateQueries(queries.appointments);
                navigate("/appointments/".concat(data.id));
            }
        });
    };
    AppointmentService.prototype.useUpdateAppointment = function () {
        var _this = this;
        var queryClient = useQueryClient();
        var navigate = useNavigate();
        return useMutation(function (_a) {
            var appointment = _a.appointment;
            var currentPlainAppointment = instanceToPlain(appointment);
            var data = { appointment: currentPlainAppointment };
            return _this.put("".concat(_this.appointmentsRoot, "/").concat(appointment.getId()), { data: data });
        }, {
            onSuccess: function (response) {
                var data = response.data;
                var appointment = plainToInstance(Appointment, data);
                queryClient.invalidateQueries([queries.appointments, appointment.getId()]);
                navigate('/appointments/' + appointment.getId());
            }
        });
    };
    AppointmentService.prototype.useChangeAppointmentStatus = function () {
        var _this = this;
        var queryClient = useQueryClient();
        var dupAppointment = null;
        return useMutation(function (_a) {
            var appointment = _a.appointment, newStatus = _a.newStatus;
            var url = _this.appointmentsRoot + '/' + appointment.getId() + '/mark_as_' + newStatus;
            dupAppointment = appointment;
            return _this.put(url);
        }, {
            onSuccess: function () { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, queryClient.refetchQueries({ queryKey: [queries.homeDoctorQueue, dupAppointment === null || dupAppointment === void 0 ? void 0 : dupAppointment.getDoctorId()] })];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            }); }
        });
    };
    AppointmentService.prototype.useFinishAppointment = function () {
        var _this = this;
        var queryClient = useQueryClient();
        var navigate = useNavigate();
        var dupAppointment = null;
        return useMutation(function (_a) {
            var appointment = _a.appointment;
            var data = { appointment: instanceToPlain(appointment) };
            dupAppointment = appointment;
            return _this.put(_this.appointmentsRoot + '/' + appointment.getId() + '/mark_as_finished', { data: data });
        }, {
            onSuccess: function () { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, queryClient.refetchQueries({ queryKey: [queries.homeDoctorQueue, dupAppointment === null || dupAppointment === void 0 ? void 0 : dupAppointment.getDoctorId()] })];
                        case 1:
                            _a.sent();
                            return [4 /*yield*/, queryClient.refetchQueries({ queryKey: [queries.appointments, dupAppointment === null || dupAppointment === void 0 ? void 0 : dupAppointment.getId()] })];
                        case 2:
                            _a.sent();
                            navigate('/appointments/' + (dupAppointment === null || dupAppointment === void 0 ? void 0 : dupAppointment.getId()));
                            return [2 /*return*/];
                    }
                });
            }); }
        });
    };
    AppointmentService.getInstance = function () {
        if (!AppointmentService.appointmentService) {
            AppointmentService.appointmentService = new AppointmentService();
        }
        return AppointmentService.appointmentService;
    };
    return AppointmentService;
}(ApiService));
export default AppointmentService;
