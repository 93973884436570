import { jsx as _jsx } from "react/jsx-runtime";
import './style.css';
var StatusModifier = function (props) {
    var onStatusChangePress = function () { return props.onPress(); };
    var statusClass = {
        'pending': 'pending-button-border',
        'un_reached': 'un-reached-button-border',
        'confirmed': 'confirmed-button-border',
        'waiting': 'waiting-button-border',
        'in_visit': 'in-visit-button-border',
        'after_work': 'after-work-button-border',
        'finished': 'finished-button-border',
        'cancelled': 'cancel-button-border',
        'postpone': 'postpone-button-border',
    };
    var getBorderClassName = function () {
        var borderClass = statusClass[props.status];
        return "".concat(borderClass).concat(props.rtlDirection ? '-rtl' : '');
    };
    return (_jsx("div", { className: "description-text cardStatus ".concat(getBorderClassName()), onClick: onStatusChangePress, children: props.title }));
};
export default StatusModifier;
