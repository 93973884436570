import { jsx as _jsx } from "react/jsx-runtime";
import Appointments from 'pages/Appointments';
import ShowAppointment from 'pages/ShowAppointment';
import UpdateAppointment from 'pages/UpdateAppointment';
import FinishAppointment from 'pages/FinishAppointment';
import { Route } from 'react-router';
import AuthorizationService from '../AuthorizationService';
import { Index, Show, Update } from 'constants/actions';
import { Appointments as AppointmentResource } from 'constants/resources';
import PageNotFound from 'pages/PageNotFound';
var AppointmentRoutes = function () {
    return ([
        _jsx(Route, { caseSensitive: true, path: '/appointments', element: AuthorizationService.getAuthorizedElement(_jsx(Appointments, {}), Index, AppointmentResource) }, 'appointments-route'),
        _jsx(Route, { caseSensitive: true, path: '/appointments/new', element: AuthorizationService.getAuthorizedElement(_jsx(PageNotFound, {}), Show, AppointmentResource) }, 'new-appointment-route'),
        _jsx(Route, { caseSensitive: true, path: '/appointments/:id/update', element: AuthorizationService.getAuthorizedElement(_jsx(UpdateAppointment, {}), Update, AppointmentResource) }, 'update-appointment-route'),
        _jsx(Route, { caseSensitive: true, path: '/appointments/:id', element: AuthorizationService.getAuthorizedElement(_jsx(ShowAppointment, {}), Show, AppointmentResource) }, 'appointment-route'),
        _jsx(Route, { caseSensitive: true, path: '/appointments/:id/finish', element: AuthorizationService.getAuthorizedElement(_jsx(FinishAppointment, {}), Show, AppointmentResource) }, 'appointment-route-finish')
    ]);
};
export default AppointmentRoutes;
