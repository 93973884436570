import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
import { memo } from 'react';
var FilterIcon = function () {
    var Image = function () {
        return _jsx("svg", { xmlns: "http://www.w3.org/2000/svg", width: "1em", height: "1em", fill: "#8f9eb3", viewBox: "0 -5 24 24", focusable: "false", "data-icon": "user", "aria-hidden": "true", children: _jsx("path", { d: "M10 18h4v-2h-4zM3 6v2h18V6zm3 7h12v-2H6z", transform: "translate(-3 -6)" }) });
    };
    return (_jsx(Icon, { component: Image }));
};
export default memo(FilterIcon);
