import { jsx as _jsx } from "react/jsx-runtime";
import { Form, Select } from 'antd';
var Option = Select.Option;
var FormSelect = function (props) {
    return (_jsx(Form.Item, { label: props.label, name: props.name, className: props.formClasses || 'form-item body---14pt-R', rules: props.rules, hasFeedback: true, initialValue: props.initialValue, children: _jsx(Select, { showSearch: true, size: "large", className: props.selectClasses, placeholder: props.placeHolder, bordered: props.bordered, onSelect: props.onSelect, filterOption: function (input, option) {
                return !(option === null || option === void 0 ? void 0 : option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()));
            }, children: props.options.map(function (option) {
                return _jsx(Option, { className: 'body---14pt-R', value: option.value, children: option.title }, "option-".concat(option.value));
            }) }) }));
};
export default FormSelect;
