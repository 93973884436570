var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { classToPlain, plainToClass } from 'class-transformer';
import queries from 'constants/queries';
import { MedicalMachine } from 'models';
import { useMutation } from 'react-query';
import { useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import ApiService from './ApiService';
var MedicalMachineService = /** @class */ (function (_super) {
    __extends(MedicalMachineService, _super);
    function MedicalMachineService() {
        var _this = _super.call(this) || this;
        _this.medicalMachinesRoot = 'medical_machines';
        _this.successCreationMessage = '';
        return _this;
    }
    MedicalMachineService.prototype.useMedicalMachines = function () {
        var _this = this;
        return useQuery(queries.medicalMachines, function () {
            return _this.get(_this.medicalMachinesRoot);
        }, {
            select: function (_a) {
                var data = _a.data;
                return plainToClass(MedicalMachine, data);
            }
        });
    };
    MedicalMachineService.prototype.useGetMedicalMachine = function (id) {
        var _this = this;
        return useQuery([queries.medicalMachines, id], function () {
            return _this.get("".concat(_this.medicalMachinesRoot, "/").concat(id));
        }, {
            retry: false,
            select: function (_a) {
                var data = _a.data;
                return plainToClass(MedicalMachine, data);
            }
        });
    };
    MedicalMachineService.prototype.useMedicalMachinesById = function () {
        var data = this.useMedicalMachines().data;
        return data === null || data === void 0 ? void 0 : data.reduce(function (obj, mm) {
            var _a;
            return (__assign(__assign({}, obj), (_a = {}, _a[mm.getId()] = mm, _a)));
        }, {});
    };
    MedicalMachineService.prototype.useCreateMedicalMachine = function () {
        var _this = this;
        var queryClient = useQueryClient();
        var navigate = useNavigate();
        return useMutation(function (_a) {
            var medicalMachine = _a.medicalMachine, successMessage = _a.successMessage;
            _this.successCreationMessage = successMessage;
            var data = { medical_machine: classToPlain(medicalMachine) };
            return _this.post(_this.medicalMachinesRoot, { data: data });
        }, {
            onSuccess: function () {
                queryClient.invalidateQueries(queries.medicalMachines);
                _this.dipatachCreationSuccess({ successMessage: _this.successCreationMessage });
                navigate('/medical-machines');
            }
        });
    };
    MedicalMachineService.getInstance = function () {
        if (!MedicalMachineService.medicalMachineServiceInstance) {
            MedicalMachineService.medicalMachineServiceInstance = new MedicalMachineService();
        }
        return MedicalMachineService.medicalMachineServiceInstance;
    };
    return MedicalMachineService;
}(ApiService));
export default MedicalMachineService;
