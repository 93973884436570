var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { plainToClass } from 'class-transformer';
import queries from 'constants/queries';
import { ItemCategory } from 'models';
import { useQuery } from 'react-query';
import ApiService from './ApiService';
var ItemCategoryService = /** @class */ (function (_super) {
    __extends(ItemCategoryService, _super);
    function ItemCategoryService() {
        var _this = _super.call(this) || this;
        _this.itemCategoriesRoot = 'items/categories';
        return _this;
    }
    ItemCategoryService.prototype.useItemCategories = function () {
        var _this = this;
        return useQuery(queries.itemCategories, function () {
            return _this.get(_this.itemCategoriesRoot);
        }, {
            select: function (_a) {
                var data = _a.data;
                return plainToClass(ItemCategory, data);
            }
        });
    };
    ItemCategoryService.prototype.useItemCategoriesById = function () {
        var data = this.useItemCategories().data;
        return data === null || data === void 0 ? void 0 : data.reduce(function (obj, ic) {
            var _a;
            return (__assign(__assign({}, obj), (_a = {}, _a[ic.getId()] = ic, _a)));
        }, {});
    };
    ItemCategoryService.getInstance = function () {
        if (!ItemCategoryService.itemCategoryInstance) {
            ItemCategoryService.itemCategoryInstance = new ItemCategoryService();
        }
        return ItemCategoryService.itemCategoryInstance;
    };
    return ItemCategoryService;
}(ApiService));
export default ItemCategoryService;
