import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import StatusModifier from 'components/cards/HomeCard/statusModifier';
import localeTranslator from 'library/Hocs/LocalTranslator';
import services from 'services';
import { useNavigate } from 'react-router';
var VisitStatusButton = function (props) {
    var navigate = useNavigate();
    var title = props.title;
    var updateAppointmentStatus = services.appointmentService.useChangeAppointmentStatus().mutate;
    var user = services.userService.useCurrentUser().data;
    var onChangeVisitStatus = function () {
        if (props.status === 'finished') {
            navigate("/appointments/".concat(props.appointment.getId(), "/finish"));
        }
        else {
            updateAppointmentStatus({ appointment: props.appointment, newStatus: props.status });
        }
    };
    return (_jsx(_Fragment, { children: user &&
            _jsx(StatusModifier, { title: title, onPress: onChangeVisitStatus, status: props.status, rtlDirection: user.getLanguage() === 'ar' }) }));
};
var VisitPaths = function (props) {
    var translate = props.translate;
    var pendingStatus = {
        status: 'pending',
        successMessage: translate('actions.pending.message'),
        title: translate('actions.pending.title')
    };
    var unReachedStatus = {
        status: 'un_reached',
        successMessage: translate('actions.unReached.message'),
        title: translate('actions.unReached.title')
    };
    var confirmedStatus = {
        status: 'confirmed',
        successMessage: translate('actions.confirmed.message'),
        title: translate('actions.confirmed.title')
    };
    var waitingStatus = {
        status: 'waiting',
        successMessage: translate('actions.waiting.message'),
        title: translate('actions.waiting.title')
    };
    var inVisitStatus = {
        status: 'in_visit',
        successMessage: translate('actions.inVisit.message'),
        title: translate('actions.inVisit.title')
    };
    var afterWorkStatus = {
        status: 'after_work',
        successMessage: translate('actions.afterWork.message'),
        title: translate('actions.afterWork.title')
    };
    var finishedStatus = {
        status: 'finished',
        successMessage: translate('actions.finished.message'),
        title: translate('actions.finished.title')
    };
    var cancelledStatus = {
        status: 'cancelled',
        successMessage: translate('actions.cancelled.message'),
        title: translate('actions.cancelled.title')
    };
    var visitStatusPaths = {
        'pending': [unReachedStatus, confirmedStatus, waitingStatus, cancelledStatus],
        'un_reached': [pendingStatus, confirmedStatus, waitingStatus, cancelledStatus],
        'confirmed': [pendingStatus, unReachedStatus, waitingStatus, cancelledStatus],
        'waiting': [inVisitStatus, cancelledStatus],
        'in_visit': [afterWorkStatus, finishedStatus],
        'after_work': [finishedStatus]
    };
    var paths = visitStatusPaths[props.appointment.getStatus()] || [];
    return (_jsx(_Fragment, { children: paths.map(function (path) {
            return _jsxs(_Fragment, { children: [_jsx("div", { className: "seperator" }), _jsx(VisitStatusButton, { appointment: props.appointment, status: path.status, title: path.title }, path.status), _jsx("div", { className: "seperator" })] });
        }) }));
};
export default localeTranslator(VisitPaths, 'visitStatuses');
