var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import Model from 'models/Model';
import { Clinic, Product } from 'models';
import { plainToInstance } from 'class-transformer';
var Stock = /** @class */ (function (_super) {
    __extends(Stock, _super);
    function Stock() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Stock.prototype.getId = function () {
        return Number(this.id);
    };
    Stock.prototype.getLocation = function () {
        return plainToInstance(Clinic, this.location);
    };
    Stock.prototype.setLocation = function (location) {
        this.location = location;
    };
    Stock.prototype.getLocationType = function () {
        return this.location_type;
    };
    Stock.prototype.setLocationType = function (locationType) {
        this.location_type = locationType;
    };
    Stock.prototype.getLocationId = function () {
        return Number(this.location_id);
    };
    Stock.prototype.setLocationId = function (locationId) {
        this.location_id = Number(locationId);
    };
    Stock.prototype.getProductId = function () {
        return Number(this.product_id);
    };
    Stock.prototype.setProductId = function (productId) {
        this.product_id = Number(productId);
    };
    Stock.prototype.getProduct = function () {
        return plainToInstance(Product, this.product);
    };
    Stock.prototype.setProduct = function (product) {
        this.product = product;
    };
    Stock.prototype.getPendingQuantity = function () {
        return Number(this.pending_quantity);
    };
    Stock.prototype.getQuantity = function () {
        return Number(this.quantity);
    };
    Stock.prototype.setQuantity = function (quantity) {
        this.quantity = Number(quantity);
    };
    return Stock;
}(Model));
export default Stock;
