var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { plainToInstance } from 'class-transformer';
import queries from 'constants/queries';
import { Role } from 'models';
import { useQuery } from 'react-query';
import ApiService from './ApiService';
var RoleService = /** @class */ (function (_super) {
    __extends(RoleService, _super);
    function RoleService() {
        var _this = _super.call(this) || this;
        _this.rolesRoot = 'roles';
        return _this;
    }
    RoleService.prototype.useRoles = function () {
        var _this = this;
        return useQuery(queries.roles, function () {
            return _this.get(_this.rolesRoot);
        }, {
            select: function (response) {
                var data = response.data;
                return plainToInstance(Role, data);
            }
        });
    };
    RoleService.getInstance = function () {
        if (!RoleService.roleService) {
            RoleService.roleService = new RoleService();
        }
        return RoleService.roleService;
    };
    return RoleService;
}(ApiService));
export default RoleService;
