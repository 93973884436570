import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import Icon from '@ant-design/icons';
var NoteIcon = function () {
    var Image = function () {
        return _jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", viewBox: "0 0 24 24", children: [_jsx("defs", { children: _jsx("style", { children: ".cls-1{fill:rgb(100, 115, 134)}" }) }), _jsxs("g", { id: "document", transform: "translate(-108 -188)", children: [_jsx("path", { id: "Vector", className: "cls-1", d: "M18 8.19h-2.89a4.306 4.306 0 0 1-4.3-4.3V1a1 1 0 0 0-1-1H5.57A5.277 5.277 0 0 0 0 5.57v8.86A5.277 5.277 0 0 0 5.57 20h7.86A5.277 5.277 0 0 0 19 14.43V9.19a1 1 0 0 0-1-1z", transform: "translate(110.5 190)" }), _jsx("path", { id: "Vector-2", "data-name": "Vector", className: "cls-1", d: "M1.12.195A.654.654 0 0 0 0 .635v3.49a2.726 2.726 0 0 0 2.75 2.67c.95.01 2.27.01 3.4.01a.631.631 0 0 0 .47-1.07c-1.44-1.45-4.02-4.06-5.5-5.54z", transform: "translate(122.68 190.015)" })] })] });
    };
    return (_jsx(Icon, { component: Image }));
};
export default memo(NoteIcon);
