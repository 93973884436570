var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { instanceToInstance, plainToInstance } from 'class-transformer';
import queries from 'constants/queries';
import { DocumentLineGroup, PaginationMetaData } from 'models';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import ApiService from '../ApiService';
import DocumentLineGroupMetaInfo from 'models/appEntities/DocumentLineGroupMetaInfo';
import { useNavigate } from 'react-router';
var DocumentLineGroupService = /** @class */ (function (_super) {
    __extends(DocumentLineGroupService, _super);
    function DocumentLineGroupService() {
        var _this = _super.call(this) || this;
        _this.documentLineGroupsRoot = 'document_line_groups';
        _this.successCreationMessage = '';
        return _this;
    }
    DocumentLineGroupService.prototype.useDocumentLineGroups = function (pageNumber, filters) {
        var _this = this;
        if (pageNumber === void 0) { pageNumber = 1; }
        if (filters === void 0) { filters = {}; }
        return useQuery([queries.accounting.documentLineGroups, pageNumber, filters], function () {
            return _this.get(_this.documentLineGroupsRoot, __assign(__assign({}, filters), { page: pageNumber }));
        }, {
            keepPreviousData: true,
            select: function (_a) {
                var data = _a.data;
                var documentLineGroups = plainToInstance(DocumentLineGroup, data === null || data === void 0 ? void 0 : data.document_line_groups);
                var meta = plainToInstance(PaginationMetaData, data === null || data === void 0 ? void 0 : data.meta);
                return { documentLineGroups: documentLineGroups, meta: meta };
            }
        });
    };
    DocumentLineGroupService.prototype.useExpenseGroups = function () {
        var _this = this;
        return useQuery(['expense-groups-', queries.accounting.documentLineGroups], function () {
            return _this.get("".concat(_this.documentLineGroupsRoot, "/expense_groups"));
        }, {
            keepPreviousData: true,
            select: function (_a) {
                var data = _a.data;
                return plainToInstance(DocumentLineGroup, data);
            }
        });
    };
    DocumentLineGroupService.prototype.usePatientGroups = function () {
        var _this = this;
        return useQuery(['patient-groups-', queries.accounting.documentLineGroups], function () {
            return _this.get("".concat(_this.documentLineGroupsRoot, "/patient_groups"));
        }, {
            keepPreviousData: true,
            select: function (_a) {
                var data = _a.data;
                return plainToInstance(DocumentLineGroup, data);
            }
        });
    };
    DocumentLineGroupService.prototype.useMetaInfo = function () {
        var _this = this;
        return useQuery(['meta-', queries.accounting.documentLineGroups], function () {
            return _this.get("".concat(_this.documentLineGroupsRoot, "/meta_info"));
        }, {
            select: function (_a) {
                var data = _a.data;
                return plainToInstance(DocumentLineGroupMetaInfo, data);
            }
        });
    };
    DocumentLineGroupService.prototype.useCreateDocumentLineGroup = function () {
        var _this = this;
        var queryClient = useQueryClient();
        var navigate = useNavigate();
        var document_id = 0;
        return useMutation(function (_a) {
            var documentLineGroup = _a.documentLineGroup, successMessage = _a.successMessage;
            _this.successCreationMessage = successMessage;
            document_id = documentLineGroup.getDocumentId();
            var data = { document_line_group: instanceToInstance(documentLineGroup) };
            return _this.post(_this.documentLineGroupsRoot, { data: data });
        }, {
            retry: false,
            onSuccess: function () {
                queryClient.invalidateQueries(queries.accounting.documents);
                queryClient.invalidateQueries(['show', queries.accounting.documents, document_id]);
                _this.dipatachCreationSuccess({ successMessage: _this.successCreationMessage });
                navigate("/documents");
            }
        });
    };
    DocumentLineGroupService.getInstance = function () {
        if (!DocumentLineGroupService.documentLineGroupService) {
            DocumentLineGroupService.documentLineGroupService = new DocumentLineGroupService();
        }
        return DocumentLineGroupService.documentLineGroupService;
    };
    return DocumentLineGroupService;
}(ApiService));
export default DocumentLineGroupService;
