import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Row, Col } from 'antd';
import localeTranslator from 'library/Hocs/LocalTranslator';
import { FormInput, FormInputNumber, FormSelect } from 'components/AntEnhancement/FormControls';
import './style.css';
var JobTitleForm = function (props) {
    var onChangeTitle = function (event) {
        props.jobTitle.setTitle(event.target.value);
    };
    var onChangeRole = function (value) { return props.jobTitle.setRoleId(Number(value)); };
    var onChangeBaseSalary = function (value) { return value && props.jobTitle.setBaseSalary(value); };
    var getRoleOptions = function () {
        return props.roles.map(function (role) { return ({
            value: role.getId(),
            title: role.getName()
        }); });
    };
    return (_jsxs("div", { className: "form-part", children: [_jsx(Row, { children: _jsx(Col, { xs: 24, sm: 24, lg: 24, xl: 24, children: _jsx(FormInput, { label: props.translate('jobTitles.title'), name: "title", placeHolder: props.translate('jobTitles.title'), rules: [
                            { required: true, message: props.translate('jobTitles.errors.title') }
                        ], onChange: onChangeTitle, initialValue: props.jobTitle.getTitle() }) }) }), _jsx(Row, { children: _jsx(Col, { xs: 24, sm: 24, lg: 24, xl: 24, children: _jsx(FormSelect, { label: props.translate('jobTitles.role'), name: "role", rules: [
                            { required: true, message: props.translate('jobTitles.errors.role') }
                        ], placeHolder: props.translate('jobTitles.role'), onSelect: onChangeRole, options: getRoleOptions(), initialValue: props.jobTitle.getRoleId() || null }) }) }), _jsx(Row, { children: _jsx(Col, { xs: 24, sm: 24, lg: 24, xl: 24, children: _jsx(FormInputNumber, { label: props.translate('jobTitles.baseSalary'), name: 'baseSalary', rules: [
                            { required: true, message: props.translate('jobTitles.errors.baseSalary') }
                        ], placeHolder: props.translate('jobTitles.baseSalary'), onChange: onChangeBaseSalary, min: 0 }) }) })] }));
};
export default localeTranslator(JobTitleForm, 'settings');
