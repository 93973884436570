import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import Icon from '@ant-design/icons';
var EmployeeIcon = function () {
    var Image = function () {
        return _jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: "1em", height: "1em", fill: "currentColor", viewBox: "0 0 20 20", focusable: "false", "data-icon": "user", "aria-hidden": "true", children: [_jsx("path", { id: "Path_482", "data-name": "Path 482", d: "m25.414 23.64-.1.05-.135.071a2.218 2.218 0 0 1-.223.113l-.063.025a4.091 4.091 0 0 1-3.253.013.588.588 0 0 1-.092-.034 2.153 2.153 0 0 1-.219-.113l-.139-.071-.1-.05c-2.715.361-4.052 1.538-4.182 3.695H29.6c-.134-2.161-1.471-3.339-4.186-3.699z", transform: "translate(-14.59 -16.536)" }), _jsx("path", { id: "Path_483", "data-name": "Path 483", d: "M31.687 43.734a.227.227 0 1 0-.227-.227.229.229 0 0 0 .227.227z", transform: "translate(-23.024 -27.921)" }), _jsx("path", { id: "Path_484", "data-name": "Path 484", d: "M25.306 13.15a3.525 3.525 0 0 0 3.762 0 3.487 3.487 0 1 0-3.762 0z", transform: "translate(-18.526 -6.74)" }), _jsx("path", { id: "Path_485", "data-name": "Path 485", d: "m28.229 42.28.639-6.671a1.321 1.321 0 0 0-.332-1 1.3 1.3 0 0 0-.963-.429H13.7a1.307 1.307 0 0 0-.975.429 1.289 1.289 0 0 0-.332 1l.639 6.671zm-7.6-5.01a.963.963 0 1 1-.963.963.965.965 0 0 1 .963-.963z", transform: "translate(-11.966 -22.646)" }), _jsx("path", { id: "Rectangle_330", "data-name": "Rectangle 330", transform: "translate(0 20.496)", d: "M0 0h17.331v.736H0z" })] });
    };
    return (_jsx(Icon, { component: Image }));
};
export default memo(EmployeeIcon);
