import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Row, Col } from 'antd';
import localeTranslator from 'library/Hocs/LocalTranslator';
import { FormInputNumber, FormDatePicker, FormSelect } from 'components/AntEnhancement/FormControls';
import './style.css';
var JobInfo = function (props) {
    var onChangeSalary = function (salary) { return salary && props.employee.setSalary(salary); };
    var onChangeHireDate = function (hireDate) {
        hireDate && props.employee.setHireDate(hireDate.toDate());
    };
    var onChangeJobTitle = function (value) {
        var _a;
        var jobTitle = (_a = props.jobTitles) === null || _a === void 0 ? void 0 : _a.find(function (jobTitle) { return jobTitle.id === Number(value); });
        jobTitle && props.employee.setJobTitle(jobTitle);
    };
    var getOptions = function () {
        var _a;
        return ((_a = props.jobTitles) === null || _a === void 0 ? void 0 : _a.map(function (jobTitle) {
            return ({
                value: jobTitle.getId(),
                title: jobTitle.getTitle()
            });
        })) || [];
    };
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "Header-18-M form-title", children: props.translate('jobInfo') }), _jsxs("div", { className: "form-part", children: [_jsx(Row, { children: _jsx(Col, { xs: 24, sm: 24, lg: 8, xl: 8, children: _jsx(FormSelect, { label: props.translate('chooseJobTitle'), name: "jobTitle", rules: [
                                    { required: true, message: props.translate('errors.jobTitle') }
                                ], placeHolder: props.translate('chooseJobTitle'), onSelect: onChangeJobTitle, options: getOptions() }) }) }), _jsxs(Row, { children: [_jsx(Col, { xs: 24, sm: 24, lg: 12, xl: 11, children: _jsx(FormDatePicker, { label: props.translate('hireDate'), name: "hireDate", rules: [
                                        { required: true, message: props.translate('errors.hireDate') }
                                    ], placeHolder: props.translate('selectDate'), onChange: onChangeHireDate }) }), _jsx(Col, { xs: 0, sm: 0, lg: 1, xl: 1 }), _jsx(Col, { xs: 24, sm: 24, lg: 12, xl: 12, children: _jsx(FormInputNumber, { label: props.translate('salary'), name: "salary", rules: [
                                        { required: true, message: props.translate('errors.salary') }
                                    ], placeHolder: props.translate('salary'), onChange: onChangeSalary, min: 0 }) })] })] })] }));
};
export default localeTranslator(JobInfo, 'employeeForm');
