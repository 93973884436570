var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import Model from 'models/Model';
var PatientDetails = /** @class */ (function (_super) {
    __extends(PatientDetails, _super);
    function PatientDetails() {
        var _this = _super.call(this) || this;
        _this.pregnant = false;
        return _this;
    }
    PatientDetails.prototype.setBreastFeeding = function (breastfeeding) {
        if (breastfeeding === void 0) { breastfeeding = false; }
        this.breastfeeding = breastfeeding;
    };
    PatientDetails.prototype.getBreastFeeding = function () {
        return this.breastfeeding;
    };
    PatientDetails.prototype.setPregnant = function (pregnant) {
        if (pregnant === void 0) { pregnant = false; }
        this.pregnant = pregnant;
    };
    PatientDetails.prototype.getPregnant = function () {
        return this.pregnant;
    };
    PatientDetails.prototype.setHeight = function (height) {
        this.height = height;
    };
    PatientDetails.prototype.getHeight = function () {
        return this.height || '0';
    };
    PatientDetails.prototype.setWeight = function (weight) {
        this.weight = weight;
    };
    PatientDetails.prototype.getWeight = function () {
        return this.weight || '0';
    };
    PatientDetails.prototype.setNote = function (note) {
        this.note = note;
    };
    PatientDetails.prototype.getNote = function () {
        return this.note || '';
    };
    PatientDetails.prototype.setMedicalConditions = function (medicalCondition) {
        this.medical_condition = medicalCondition;
    };
    PatientDetails.prototype.getMedicalConditions = function () {
        return this.medical_condition;
    };
    PatientDetails.prototype.setMedication = function (medication) {
        this.medication = medication;
    };
    PatientDetails.prototype.getMedication = function () {
        return this.medication;
    };
    PatientDetails.prototype.setReferrer = function (referrer) {
        this.referrer = referrer;
    };
    PatientDetails.prototype.getReferrer = function () {
        return this.referrer || '-';
    };
    PatientDetails.prototype.getTelephones = function () {
        return this.telephones || [];
    };
    PatientDetails.prototype.setTelephones = function (telephone) {
        this.telephones = [{ 'number': telephone }];
    };
    return PatientDetails;
}(Model));
export default PatientDetails;
