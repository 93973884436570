import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Menu, Dropdown, Table, Select, Space } from 'antd';
import { PackageFilter } from 'models';
import services from 'services';
import EmptyComponent from 'components/EmptyComponent';
import localeTranslator from 'library/Hocs/LocalTranslator';
import { CheckOutlined, CloseOutlined, EllipsisOutlined } from '@ant-design/icons';
import { Package as PackageResource } from 'constants/resources';
import './style.css';
import NormalHeader from 'components/PageHeader/NormalHeader';
var Option = Select.Option;
var Packages = function (props) {
    var _a = useState(1), pageNumber = _a[0], setPageNumber = _a[1];
    var _b = useState({}), filters = _b[0], setFilters = _b[1];
    var packageService = services.packageService, medicalMachineService = services.medicalMachineService;
    var data = packageService.usePackages(pageNumber, filters).data;
    var medicalMachines = medicalMachineService.useMedicalMachines().data;
    // const { data: itemCategories } = itemCategoryService.useItemCategories();
    var navigate = useNavigate();
    var columns = [
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('id') }),
            key: 'id',
            render: function (text, record) {
                return _jsx("div", { className: "body---14pt-R", children: record.getId() }, "packageId-".concat(record.getId()));
            }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('name') }),
            key: 'name',
            render: function (text, record) {
                return _jsx("div", { className: "body---14pt-R table-body-content", children: record.getName() }, "packageName-".concat(record.getId()));
            }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('packageElement') }),
            key: 'packageElement',
            render: function (text, record) {
                return _jsx("div", { className: "body---14pt-R table-body-content", children: record.getEntityName() }, "packageElement-".concat(record.getId()));
            }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('isEnabled') }),
            key: 'startDate',
            render: function (text, record) {
                return _jsx("div", { className: "body---14pt-R table-body-content", children: record.getIsEnabled() ? _jsx(CheckOutlined, {}) : _jsx(CloseOutlined, {}) }, "packageStartDate-".concat(record.getId()));
            }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('action') }),
            fixed: 'right',
            render: function (text, record) {
                return _jsx(Dropdown.Button, { icon: _jsx(EllipsisOutlined, { rotate: 90, className: 'action-button' }), overlay: menu(record.getId()), className: "three-dot" });
            }
        }
    ];
    var getNextPage = function (currentPageNumber) { return setPageNumber(currentPageNumber); };
    var goToPackageForm = function () { return navigate('/packages/new'); };
    var goToPackage = function (packageId) { return function () { return navigate("/packages/".concat(packageId)); }; };
    var menu = function (packageId) {
        return (_jsx(Menu, { children: _jsx(Menu.Item, { onClick: goToPackage(packageId), children: "Show" }, "1") }));
    };
    var onChangeElementType = function (value) {
        var packageFilters = new PackageFilter();
        packageFilters.setEntityType(Number(value.split('/')[0]));
        packageFilters.setEntityId(value.split('/')[1]);
        setPageNumber(1);
        setFilters(packageFilters);
    };
    var onClearFilters = function () {
        setPageNumber(1);
        setFilters({});
    };
    return (_jsxs("div", { children: [_jsx(NormalHeader, { title: props.translate('header.packagesList'), addButtonText: props.translate('common:buttons.newPackage'), addButtonFunction: goToPackageForm, resource: PackageResource }), _jsx("div", { className: "index-page-content", children: _jsxs(Space, { direction: 'vertical', size: 36, children: [_jsxs(Select, { showSearch: true, allowClear: true, onClear: onClearFilters, placeholder: props.translate('filterByPackageElement'), onSelect: onChangeElementType, optionFilterProp: "children", className: 'package-element-select search-text-color body---14pt-R', filterOption: function (input, option) { var _a; return ((_a = option === null || option === void 0 ? void 0 : option.children) !== null && _a !== void 0 ? _a : '').toString().toLowerCase().includes(input.toLowerCase()); }, children: [medicalMachines && medicalMachines.map(function (mm) {
                                    return _jsx(Option, { value: "1/".concat(mm.getId()), className: 'body---14pt-R search-text-color', children: props.translate('common:packageEntities.1') + ' - ' + mm.getName() });
                                }), _jsx(Option, { value: "4/", className: 'body---14pt-R search-text-color', children: props.translate('common:packageEntities.4') })] }), _jsx(Table, { columns: columns, pagination: {
                                position: ['bottomRight'],
                                pageSize: 25,
                                total: (data === null || data === void 0 ? void 0 : data.meta) ? (data === null || data === void 0 ? void 0 : data.meta.getPagesCount()) * 25 : 0,
                                onChange: getNextPage,
                                showSizeChanger: false,
                                responsive: true
                            }, dataSource: data === null || data === void 0 ? void 0 : data.packages, locale: {
                                emptyText: _jsx(EmptyComponent, {})
                            } })] }) })] }));
};
export default localeTranslator(Packages, 'packages');
