// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Transparent-Menu-Item > .ant-menu {
  background: transparent;
}

.Transparent-Menu-Item > .ant-menu-horizontal {
  border: 0px;
}

.Transparent-Menu > .ant-layout-header {
  background: transparent !important;
}

.Transparent-Menu > .ant-menu-title-content {
  color: black !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/PageHeader/LandingHeader/MenuDisplayer/style.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;AACzB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,kCAAkC;AACpC;;AAEA;EACE,uBAAuB;AACzB","sourcesContent":[".Transparent-Menu-Item > .ant-menu {\n  background: transparent;\n}\n\n.Transparent-Menu-Item > .ant-menu-horizontal {\n  border: 0px;\n}\n\n.Transparent-Menu > .ant-layout-header {\n  background: transparent !important;\n}\n\n.Transparent-Menu > .ant-menu-title-content {\n  color: black !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
