import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import Icon from '@ant-design/icons';
var EllipseIcon = function () {
    var Image = function () {
        return _jsx("svg", { xmlns: "http://www.w3.org/2000/svg", width: "1em", height: "1em", viewBox: "-7 0 25 10", focusable: "false", "data-icon": "user", "aria-hidden": "true", children: _jsx("circle", { "data-name": "Ellipse 11", cx: "5", cy: "5", r: "5" }) });
    };
    return (_jsx(Icon, { component: Image }));
};
export default memo(EllipseIcon);
