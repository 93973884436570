import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Col, Row, Tabs } from 'antd';
import { useNavigate } from 'react-router';
import NormalHeader from 'components/PageHeader/NormalHeader';
import UserCard from 'components/cards/UserCard';
import './style.css';
import services from 'services';
import EmptyComponent from 'components/EmptyComponent';
import localeTranslator from 'library/Hocs/LocalTranslator';
import { Employee } from 'constants/resources';
var TabPane = Tabs.TabPane;
var Employees = function (props) {
    var employeeService = services.employeeService, jobTitleService = services.jobTitleService;
    var employees = employeeService.useEmployees().data;
    var jobTitles = jobTitleService.useJobTitles().data;
    var navigate = useNavigate();
    var goToNewEmployeeForm = function () { return navigate('/employees/new'); };
    var goToEmployeeProfile = function (id) { return navigate("/employees/".concat(id)); };
    var employeesCards = function () {
        return employees === null || employees === void 0 ? void 0 : employees.map(function (employee) {
            var _a, _b, _c;
            return (_jsx(Col, { onClick: function () { return goToEmployeeProfile(employee.getId()); }, children: _jsx(UserCard, { name: employee.getUser().getName(), subTitle: ((_a = jobTitleService.getJobTitleDataByID(jobTitles, employee.getJobTitleId())) === null || _a === void 0 ? void 0 : _a.getTitle()) || '', phoneNumber: employee.getUser().getMobile(), email: employee.getUser().getEmail(), id: employee.getId().toString(), imagePath: (_c = (_b = employee.getAlbum()) === null || _b === void 0 ? void 0 : _b.getImage()) === null || _c === void 0 ? void 0 : _c.getImagePath(), salary: props.translate('common:price', { amount: employee.getSalary() }) }, "employee-".concat(employee.getId())) }));
        });
    };
    return (_jsxs("div", { children: [_jsx(NormalHeader, { title: props.translate('header.employeesList'), addButtonText: props.translate('common:buttons.newEmployee'), addButtonFunction: goToNewEmployeeForm, resource: Employee }), _jsxs("div", { className: "index-page-content", children: [_jsxs(Tabs, { defaultActiveKey: "1", className: "Header-16-M tab-title-color", children: [_jsx(TabPane, { tab: props.translate('current') }, "1"), _jsx(TabPane, { tab: props.translate('archived') }, "2"), _jsx(TabPane, { tab: props.translate('all') }, "3")] }), _jsx(Row, { children: employees ?
                            employeesCards() :
                            _jsx("div", { className: "empty-component-container", children: _jsx(EmptyComponent, { description: props.translate('emptyText') }) }) })] })] }));
};
export default localeTranslator(Employees, 'employees');
