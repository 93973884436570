import { notification } from 'antd';
export var showErrorToastr = function (message, description, icon) {
    notification.error({
        message: message,
        description: description,
        className: 'error-toastr',
        closeIcon: false,
        placement: 'bottomRight',
        style: {
            backgroundColor: '#e15b5b',
        },
        icon: icon
    });
};
export var showSuccessToastr = function (message, description, icon) {
    notification.success({
        message: message,
        description: description,
        className: 'success-toastr',
        closeIcon: false,
        placement: 'bottomRight',
        style: {
            backgroundColor: '#7fb55a',
        },
        icon: icon
    });
};
