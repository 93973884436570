import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Row, Col, Space } from 'antd';
import Header from 'pages/ShowPatient/components/Header';
import PatientTabs from 'pages/ShowPatient/components/Tabs';
import PersonalInformation from 'pages/ShowPatient/components/Informations/PersonalInformation';
import PhysicalInformation from 'pages/ShowPatient/components/Informations/PhysicalInformation';
import MedicalInformation from 'pages/ShowPatient/components/Informations/MedicalInformation';
import { useParams } from 'react-router';
import services from 'services';
import './style.css';
import { useState } from 'react';
import AddReservationPackageDrawer from './components/drawers/AddReservationPackageDrawer';
import AddAppointmentDrawer from './components/drawers/AddAppointmentDrawer';
import Loader from 'components/AntEnhancement/Loader';
import AddTransactionDrawer from './components/drawers/AddTransactionDrawer';
var PatientProfile = function () {
    var id = useParams().id;
    var patientService = services.patientService;
    var _a = patientService.useGetPatient(Number(id)), patient = _a.data, isPatientLoading = _a.isLoading;
    // reservation package drawer configuration
    var _b = useState(0), reservationPackagesDrawerKey = _b[0], setreservationPackagesDrawerKey = _b[1];
    var _c = useState(false), showReservationPackagesDrawer = _c[0], setShowReservationPackagesDrawer = _c[1];
    var onCloseReservationPackagesDrawer = function () {
        setShowReservationPackagesDrawer(false);
        setreservationPackagesDrawerKey(reservationPackagesDrawerKey + 1);
    };
    // appointment drawer configuration
    var _d = useState(0), appointmentDrawerKey = _d[0], setAppointmentDrawerKey = _d[1];
    var _e = useState(false), showAppointmentDrawer = _e[0], setShowAppointmentDrawer = _e[1];
    var onCloseAppointmentDrawer = function () {
        setShowAppointmentDrawer(false);
        setAppointmentDrawerKey(appointmentDrawerKey + 1);
    };
    // transaction drawer configuration
    var _f = useState(false), showTransactionDrawer = _f[0], setShowTransactionDrawer = _f[1];
    var _g = useState(0), transactionsDrawerKey = _g[0], setTransactionsDrawerKey = _g[1];
    var onCloseTransactionDrawer = function () {
        setShowTransactionDrawer(false);
        setTransactionsDrawerKey(transactionsDrawerKey + 1);
    };
    return (_jsx("div", { className: "loader-container-height", children: _jsx(Loader, { renderSpinner: isPatientLoading, children: _jsxs("div", { children: [_jsxs(Space, { direction: 'vertical', size: 50, children: [_jsx("div", { className: "page-header remove-padding", children: _jsx(Header, { setShowReservationPackagesDrawer: setShowReservationPackagesDrawer, setShowAppointmentDrawer: setShowAppointmentDrawer, setShowTransactionDrawer: setShowTransactionDrawer }) }), _jsx("div", { className: "new-pages-content", children: _jsxs(Space, { direction: "vertical", size: 24, children: [_jsxs(Row, { children: [_jsx(Col, { xl: 12, children: patient && _jsx(PersonalInformation, { patient: patient }) }), _jsx(Col, { sm: 1 }), _jsx(Col, { xl: 11, children: _jsxs(Space, { direction: "vertical", size: 20, children: [_jsx("div", { className: " patient-info", children: patient && _jsx(PhysicalInformation, { patient: patient }) }), _jsx("div", { className: " patient-info", children: patient && _jsx(MedicalInformation, { patient: patient }) })] }) })] }), patient &&
                                            _jsx(PatientTabs, { patient: patient })] }) })] }), _jsx(AddReservationPackageDrawer, { onCloseDrawer: onCloseReservationPackagesDrawer, visiable: showReservationPackagesDrawer }, 'reservation-' + reservationPackagesDrawerKey), _jsx(AddAppointmentDrawer, { onCloseDrawer: onCloseAppointmentDrawer, visiable: showAppointmentDrawer }, 'appointment-' + appointmentDrawerKey), _jsx(AddTransactionDrawer, { onCloseDrawer: onCloseTransactionDrawer, visiable: showTransactionDrawer }, 'transaction-' + transactionsDrawerKey)] }) }) }));
};
export default PatientProfile;
