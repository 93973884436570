var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { instanceToPlain, plainToInstance } from 'class-transformer';
import queries from 'constants/queries';
import { Diagnose } from 'models';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import ApiService from './ApiService';
var DiagnoseService = /** @class */ (function (_super) {
    __extends(DiagnoseService, _super);
    function DiagnoseService() {
        var _this = _super.call(this) || this;
        _this.diagnosesRoot = 'diagnoses';
        _this.successCreationMessage = '';
        return _this;
    }
    DiagnoseService.prototype.useCreateDiagnose = function () {
        var _this = this;
        var queryClient = useQueryClient();
        var patientId;
        return useMutation(function (_a) {
            var diagnose = _a.diagnose, successMessage = _a.successMessage;
            patientId = diagnose.getPatientId();
            _this.successCreationMessage = successMessage;
            var data = { diagnoses: instanceToPlain(diagnose) };
            return _this.post(_this.diagnosesRoot, { data: data });
        }, {
            onSuccess: function () {
                queryClient.invalidateQueries([queries.patients, patientId, queries.diagnoses]);
                _this.dipatachCreationSuccess({ successMessage: _this.successCreationMessage });
            }
        });
    };
    DiagnoseService.prototype.usePatientDiagnoses = function (patientId) {
        var _this = this;
        return useQuery([queries.patients, patientId, queries.diagnoses], function () {
            return _this.get(_this.diagnosesRoot, {
                patient_id: patientId
            });
        }, {
            keepPreviousData: true,
            select: function (_a) {
                var data = _a.data;
                return plainToInstance(Diagnose, data);
            }
        });
    };
    DiagnoseService.getInstance = function () {
        if (!DiagnoseService.diagnoseService) {
            DiagnoseService.diagnoseService = new DiagnoseService();
        }
        return DiagnoseService.diagnoseService;
    };
    return DiagnoseService;
}(ApiService));
export default DiagnoseService;
