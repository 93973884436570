var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Form, Row, Col, Button } from 'antd';
import localeTranslator from 'library/Hocs/LocalTranslator';
import { Prescription, PrescriptionItem } from 'models';
import { useState } from 'react';
import NewPrescriptionItem from './NewPrescriptionItem';
import './style.css';
var NewPrescription = function (props) {
    var form = Form.useForm()[0];
    var prescription = useState(new Prescription())[0];
    var _a = useState([new PrescriptionItem()]), prescriptionItems = _a[0], setPrescriptionItems = _a[1];
    var addPrescriptionItem = function () {
        var isEmptyPrescriptionItemExist = prescriptionItems.filter(function (prescriptionItem) {
            return !prescriptionItem.getMedicineName();
        });
        !isEmptyPrescriptionItemExist.length && setPrescriptionItems(__spreadArray(__spreadArray([], prescriptionItems, true), [new PrescriptionItem()], false));
    };
    var submit = function () {
        prescription.setPrescriptionItems(prescriptionItems);
        props.createPrescription(prescription);
        props.setShowPrescriptionForm(false);
    };
    return (_jsx("div", { className: "diagnose-form-part", children: _jsx(Form, { layout: 'vertical', form: form, initialValues: { layout: 'vertical' }, size: 'large', onFinish: submit, children: _jsx(Row, { children: _jsxs(Col, { xs: 24, sm: 24, lg: 24, xl: 24, children: [prescriptionItems.map(function (prescriptionItem, index) {
                            return (_jsx(NewPrescriptionItem, { prescriptionItem: prescriptionItem, addPrescriptionItem: addPrescriptionItem, elementIdentifier: index }));
                        }), _jsx(Form.Item, { children: _jsx(Button, { type: "primary", htmlType: "submit", className: "add-prescription-button Header-16-M", children: props.translate('common:buttons.submit') }) })] }) }) }) }));
};
export default localeTranslator(NewPrescription, 'patientLog');
