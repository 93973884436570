// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.patient-log {
  width: 50vw;
  margin-inline-start: 23px;
  padding: 0 0 501.5px;
  border-radius: 10px;
  background-color: #fff;
  position: fixed;
  top: 0;
  bottom: 0;
}
.ltr-log {
  right: 0;
}

.rtl-log {
  left: 0;
}

#header {
  width: 100%;
  height: 73px;
  margin: 0 0 27.8px;
  padding: 23px 29px 22px 36px;
  box-shadow: 0 1px 2px 0 rgba(203, 214, 226, 0.38);
  background-color: #fff;
}

.collapse-padding {
  padding-inline-start: 26px ;
}

#line {
  width: 85%;
  height: 1px;
  margin: 16.5px 22.5px 12.5px 26px;
  background-color: var(--border-color);
}

.add-button {
  width: 130.1px !important;
  height: 31px !important;
  padding: 12px 64.1px 12px 64px !important;
}

/* .prescription-line {
  width: 406.1px;
  height: 1px;
  margin: 15.7px 0 15.3px 0.5px;
  background-color: var(--border-color);
} */

.patient-log-entity-row {
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ShowAppointment/components/PatientLog/style.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,yBAAyB;EACzB,oBAAoB;EACpB,mBAAmB;EACnB,sBAAsB;EACtB,eAAe;EACf,MAAM;EACN,SAAS;AACX;AACA;EACE,QAAQ;AACV;;AAEA;EACE,OAAO;AACT;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,4BAA4B;EAC5B,iDAAiD;EACjD,sBAAsB;AACxB;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,UAAU;EACV,WAAW;EACX,iCAAiC;EACjC,qCAAqC;AACvC;;AAEA;EACE,yBAAyB;EACzB,uBAAuB;EACvB,yCAAyC;AAC3C;;AAEA;;;;;GAKG;;AAEH;EACE,eAAe;AACjB","sourcesContent":[".patient-log {\n  width: 50vw;\n  margin-inline-start: 23px;\n  padding: 0 0 501.5px;\n  border-radius: 10px;\n  background-color: #fff;\n  position: fixed;\n  top: 0;\n  bottom: 0;\n}\n.ltr-log {\n  right: 0;\n}\n\n.rtl-log {\n  left: 0;\n}\n\n#header {\n  width: 100%;\n  height: 73px;\n  margin: 0 0 27.8px;\n  padding: 23px 29px 22px 36px;\n  box-shadow: 0 1px 2px 0 rgba(203, 214, 226, 0.38);\n  background-color: #fff;\n}\n\n.collapse-padding {\n  padding-inline-start: 26px ;\n}\n\n#line {\n  width: 85%;\n  height: 1px;\n  margin: 16.5px 22.5px 12.5px 26px;\n  background-color: var(--border-color);\n}\n\n.add-button {\n  width: 130.1px !important;\n  height: 31px !important;\n  padding: 12px 64.1px 12px 64px !important;\n}\n\n/* .prescription-line {\n  width: 406.1px;\n  height: 1px;\n  margin: 15.7px 0 15.3px 0.5px;\n  background-color: var(--border-color);\n} */\n\n.patient-log-entity-row {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
