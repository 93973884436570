import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { Menu, Dropdown, Table } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import services from 'services';
import SearchHeader from 'components/PageHeader/SearchHeader';
import EmptyComponent from 'components/EmptyComponent';
import localeTranslator from 'library/Hocs/LocalTranslator';
import { Patient as PatientResource } from 'constants/resources';
var Patients = function (props) {
    var _a;
    var _b = useState(1), pageNumber = _b[0], setPageNumber = _b[1];
    var _c = useState('primary_phone'), searchKey = _c[0], setSearchKey = _c[1];
    var _d = useState(''), searchValue = _d[0], setSearchValue = _d[1];
    var navigate = useNavigate();
    var patientService = services.patientService;
    var data = patientService.usePatients(pageNumber, (_a = {}, _a[searchKey] = searchValue, _a)).data;
    var goToPatientLog = function (patientId) { return function () { return navigate("/patients/".concat(patientId)); }; };
    var menu = function (patientId) {
        return (_jsx(Menu, { children: _jsx(Menu.Item, { onClick: goToPatientLog(patientId), children: "Show" }, "1") }));
    };
    var onSearchKeyPress = function (key) { return function () { return setSearchKey(key); }; };
    var onSearchValueChange = function (value) {
        setPageNumber(1);
        setSearchValue(value);
    };
    var searchMenu = function () {
        return (_jsxs(Menu, { children: [_jsx(Menu.Item, { onClick: onSearchKeyPress('code'), children: "Code" }, "1"), _jsx(Menu.Item, { onClick: onSearchKeyPress('patient_name'), children: "Name" }, "2"), _jsx(Menu.Item, { onClick: onSearchKeyPress('primary_phone'), children: "Primary Phone" }, "3")] }));
    };
    var columns = [
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('id') }),
            key: 'id',
            // responsive: ['lg'],
            width: 15,
            render: function (text, record) { return _jsx("div", { className: "body---14pt-R", children: record.getId() }); }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('code') }),
            key: 'code',
            render: function (text, record) { return _jsx("div", { className: "body---14pt-R table-body-content", children: record.getCode() }); }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('name') }),
            key: 'name',
            render: function (text, record) { return _jsx("div", { className: "body---14pt-R table-body-content", children: record.getName() }); }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('age') }),
            key: 'age',
            // responsive: ['lg'],
            render: function (text, record) { return _jsx("div", { className: "body---14pt-R table-body-content", children: record.getAge() }); }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('gender') }),
            key: 'gender',
            // responsive: ['lg'],
            render: function (text, record) {
                return _jsx("div", { className: "body---14pt-R table-body-content", children: record.getGender() });
            }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('primaryPhone') }),
            key: 'primaryPhone',
            render: function (text, record) { return _jsx("div", { className: "body---14pt-R table-body-content", children: record.getPrimaryPhone() }); }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('action') }),
            fixed: 'right',
            render: function (text, record) {
                return _jsx(Dropdown.Button, { icon: _jsx(EllipsisOutlined, { rotate: 90, className: 'action-button' }), overlay: menu(record.getId()), className: "three-dot" });
            }
        }
    ];
    var getNextPage = function (currentPageNumber) { return setPageNumber(currentPageNumber); };
    var goToPatientForm = function () { return navigate('/patients/new'); };
    return (_jsxs("div", { children: [_jsx(SearchHeader, { title: props.translate('header.patientsList'), addButtonText: props.translate('common:buttons.newPatient'), addButtonFunction: goToPatientForm, menu: searchMenu(), onSearchValueChange: onSearchValueChange, resource: PatientResource }), _jsx("div", { className: "page-content", children: _jsx(Table, { columns: columns, pagination: {
                        position: ['bottomRight'],
                        pageSize: 25,
                        total: (data === null || data === void 0 ? void 0 : data.meta) ? (data === null || data === void 0 ? void 0 : data.meta.getPagesCount()) * 25 : 0,
                        onChange: getNextPage,
                        showSizeChanger: false,
                        responsive: true
                    }, dataSource: data === null || data === void 0 ? void 0 : data.patients, locale: {
                        emptyText: _jsx(EmptyComponent, {})
                    } }) })] }));
};
export default localeTranslator(Patients, 'patients');
