import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Row, Col, Card, Dropdown, Menu } from 'antd';
import PatientData from './CardInfo/PatientData';
import PatientStatus from './CardInfo/PatientStatus';
import PatientPackage from './CardInfo/PatientPackage';
import { EllipsisOutlined } from '@ant-design/icons';
import localeTranslator from 'library/Hocs/LocalTranslator';
import { useNavigate } from 'react-router-dom';
import './style.css';
var CardSkeleton = function (props) {
    var navigate = useNavigate();
    var statusClass = {
        'pending': 'pending-status',
        'un_reached': 'unreached-status',
        'confirmed': 'confirmed-status',
        'waiting': 'waiting-status',
        'in_visit': 'invisit-status',
        'after_work': 'afterwork-status',
        'finished': 'finished-status',
        'cancelled': 'cancel-status',
        'postponed': 'postpone-status',
    };
    var goToVisit = function () { return navigate("/appointments/".concat(props.appointment.getId())); };
    var goToPatient = function () { return navigate("/patients/".concat(props.appointment.getPatient().getId())); };
    var menu = function () {
        return (_jsxs(Menu, { children: [_jsx(Menu.Item, { onClick: goToPatient, children: props.translate('showPatient') }, "1"), _jsx(Menu.Item, { onClick: goToVisit, children: props.translate('showVisit') }, "2")] }));
    };
    var getAppointmentPackagePrices = function () {
        return props.appointment.getAppointmentDetails().map(function (appointmentDetail) { var _a; return (_a = appointmentDetail.getReservationPackage()) === null || _a === void 0 ? void 0 : _a.getPackagePrice(); });
    };
    return (_jsxs(Row, { children: [_jsx("div", { className: "card-identity ".concat(statusClass[props.appointment.getStatus()]) }), _jsxs(Card, { style: { borderRadius: '10px', borderBottomLeftRadius: '0', borderTopLeftRadius: '0' }, children: [_jsxs(Row, { children: [_jsx(Col, { xs: 24, lg: 12, children: _jsx(PatientData, { patient: props.appointment.getPatient() }) }), _jsx(Col, { xs: 24, lg: 10, children: _jsx(PatientStatus, { visitNumber: props.appointment.getNumber(), visitStatus: props.appointment.getDisplayedStatus() }) }), _jsx(Col, { xs: 24, lg: 2, children: _jsx(Dropdown.Button, { icon: _jsx(EllipsisOutlined, { rotate: 90, className: 'action-button' }), overlay: menu(), className: "three-dot", style: { padding: 0 } }) })] }), _jsx(PatientPackage, { packagePrices: getAppointmentPackagePrices() })] })] }));
};
export default localeTranslator(CardSkeleton, 'cardSkeleton');
