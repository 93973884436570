var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { useQuery } from 'react-query';
import queries from 'constants/queries';
import ApiService from '../ApiService';
import { ClinicReport, DoctorReport, PackageReport } from 'models';
import { plainToClass } from 'class-transformer';
import PatientReport from 'models/entities/reports/PatientReport';
var ReportService = /** @class */ (function (_super) {
    __extends(ReportService, _super);
    function ReportService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.rootPath = 'reports';
        return _this;
    }
    ReportService.prototype.useClinicReport = function () {
        var _this = this;
        return useQuery(queries.reports.clinicReports, function () {
            return _this.get("".concat(_this.rootPath, "/clinics"));
        }, {
            select: function (_a) {
                var data = _a.data;
                return plainToClass(ClinicReport, data);
            }
        });
    };
    ReportService.prototype.usePatientReport = function () {
        var _this = this;
        return useQuery(queries.reports.patientReports, function () {
            return _this.get("".concat(_this.rootPath, "/patients"));
        }, {
            select: function (_a) {
                var data = _a.data;
                return plainToClass(PatientReport, data);
            }
        });
    };
    ReportService.prototype.useDoctorReport = function () {
        var _this = this;
        return useQuery(queries.reports.doctorReports, function () {
            return _this.get("".concat(_this.rootPath, "/doctors"));
        }, {
            select: function (_a) {
                var data = _a.data;
                return plainToClass(DoctorReport, data);
            }
        });
    };
    ReportService.prototype.usePackageReport = function () {
        var _this = this;
        return useQuery(queries.reports.packageReports, function () {
            return _this.get("".concat(_this.rootPath, "/packages"));
        }, {
            select: function (_a) {
                var data = _a.data;
                return plainToClass(PackageReport, data);
            }
        });
    };
    ReportService.getInstance = function () {
        if (!ReportService.reportInstance) {
            ReportService.reportInstance = new ReportService();
        }
        return ReportService.reportInstance;
    };
    return ReportService;
}(ApiService));
export default ReportService;
