import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Col, Row, Space } from 'antd';
import ChartCard from 'components/AntEnhancement/Statistics/ChartCard';
import StatisticCard from 'components/AntEnhancement/Statistics/StatisticCard';
import ReportHeader from 'components/PageHeader/ReportHeader';
import { AREA, COLUMN } from 'constants/chartTypes';
import localeTranslator from 'library/Hocs/LocalTranslator';
import services from 'services';
var ClinicReports = function (props) {
    var reportService = services.reportService;
    var report = reportService.useClinicReport().data;
    return (_jsxs(_Fragment, { children: [_jsx(ReportHeader, { title: props.translate('title') }), _jsx("div", { id: "profile-content", className: "page-content", style: { marginTop: '20px' }, children: report &&
                    _jsx(_Fragment, { children: _jsxs(Space, { direction: 'vertical', size: 25, children: [_jsxs(Row, { gutter: [25, 25], children: [_jsx(Col, { xs: 24, sm: 24, lg: 5, xl: 5, children: _jsx(StatisticCard, { title: props.translate('patients'), value: report.getTotalPatients() }) }), _jsx(Col, { xs: 24, sm: 24, lg: 4, xl: 4, children: _jsx(StatisticCard, { title: props.translate('appointments'), value: report.getTotalAppointment() }) }), _jsx(Col, { xs: 24, sm: 24, lg: 5, xl: 5, children: _jsx(StatisticCard, { title: props.translate('transactions'), value: report.getTotalTransactions() }) }), _jsx(Col, { xs: 24, sm: 24, lg: 5, xl: 5, children: _jsx(StatisticCard, { title: props.translate('avgTransactions'), value: report.getAvgTransactions() }) }), _jsx(Col, { xs: 24, sm: 24, lg: 5, xl: 5, children: _jsx(StatisticCard, { title: props.translate('reservedPackages'), value: report.getTotalPackageReservations() }) })] }), _jsxs(Row, { gutter: [25, 25], children: [_jsx(Col, { xs: 24, sm: 24, lg: 12, xl: 12, children: _jsx(ChartCard, { data: report.getReportsPerMonth(), xAxis: 'report_date', yAxis: 'patients_count', chartType: AREA, title: props.translate('numberOfPatients') }) }), _jsx(Col, { xs: 24, sm: 24, lg: 12, xl: 12, children: _jsx(ChartCard, { data: report.getReportsPerMonth(), xAxis: 'report_date', yAxis: 'appointments_count', chartType: COLUMN, title: props.translate('numberOfAppointments') }) })] }), _jsxs(Row, { gutter: [25, 25], children: [_jsx(Col, { xs: 24, sm: 24, lg: 12, xl: 12, children: _jsx(ChartCard, { data: report.getTransactionsPerClinic(), xAxis: 'clinic_name', yAxis: 'total_transactions', chartType: COLUMN, title: props.translate('transactionPerClinic') }) }), _jsx(Col, { xs: 24, sm: 24, lg: 12, xl: 12, children: _jsx(ChartCard, { data: report.getReportsPerMonth(), xAxis: 'report_date', yAxis: 'total_transactions', chartType: AREA, title: props.translate('transactions') }) })] })] }) }) })] }));
};
export default localeTranslator(ClinicReports, 'clinicReports');
