import { jsx as _jsx } from "react/jsx-runtime";
import { Row, Col } from 'antd';
import localeTranslator from 'library/Hocs/LocalTranslator';
import { FormSelect } from 'components/AntEnhancement/FormControls';
import services from 'services';
var MedicalMachines = function (props) {
    var medicalMachineService = services.medicalMachineService;
    var medicalMachines = medicalMachineService.useMedicalMachines().data;
    var onChangeEntity = function (value) { return props.package.setEntityId(Number(value)); };
    var getMedicalMachines = function () {
        return (medicalMachines === null || medicalMachines === void 0 ? void 0 : medicalMachines.map(function (medicalMachine) { return ({
            value: medicalMachine.getId(),
            title: medicalMachine.getName()
        }); })) || [];
    };
    return (_jsx(Row, { children: _jsx(Col, { xs: 24, sm: 24, lg: 11, xl: 11, children: _jsx(FormSelect, { label: props.packageElementTitle, name: "packageElementTitle", rules: [
                    { required: true, message: props.translate("errors.".concat(props.packageElementTitle)) }
                ], placeHolder: props.packageElementTitle, onSelect: onChangeEntity, options: getMedicalMachines() }) }) }));
};
export default localeTranslator(MedicalMachines, 'newPackage');
