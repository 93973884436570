import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Row, Col, Divider, Space } from 'antd';
import DocumentLineGroupMainInfo from 'pages/accounting/ShowDocument/components/Informations/DocumentLineGroupMainInfo';
import DocumentLines from 'pages/accounting/ShowDocument/components/Tables/DocumentLines';
import localeTranslator from 'library/Hocs/LocalTranslator';
import './style.css';
var DocumentLineGroups = function (props) {
    var documentLineGroupComponent = function (documentLineGroup) {
        return (_jsxs(_Fragment, { children: [_jsxs(Space, { direction: 'vertical', size: 10, children: [_jsx("div", { className: "new-pages-sub-content", children: _jsx(Space, { direction: "vertical", size: 24, children: _jsx(Row, { children: _jsx(Col, { xl: 24, children: _jsx(DocumentLineGroupMainInfo, { documentLineGroup: documentLineGroup }) }) }) }) }), _jsx(DocumentLines, { documentLineGroup: documentLineGroup })] }), _jsx("div", { style: { display: "flex", justifyContent: "center" }, children: _jsx(Divider, { className: 'mini-divider' }) })] }));
    };
    return (_jsxs("div", { className: "loader-container-height", children: [_jsx("div", { className: "Header-20-bold form-title margin-title", children: props.translate('header.documentLineGroups') }), props.documentLineGroups.map(function (documentLineGroup) { return documentLineGroupComponent(documentLineGroup); })] }));
};
export default localeTranslator(DocumentLineGroups, 'showDocument');
