import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Row, Space, Col, Button } from 'antd';
import { StethoscopeIcon } from 'assets/icons';
import localeTranslator from 'library/Hocs/LocalTranslator';
import { useNavigate } from 'react-router';
import '../../style.css';
var VisitInfo = function (props) {
    var navigate = useNavigate();
    var goToPatient = function () { return navigate("/patients/".concat(props.appointment.getPatient().getId())); };
    return (_jsx("div", { id: "profile-content", className: "patient-info", children: _jsx(Space, { direction: 'vertical', size: 43, children: _jsxs(Row, { children: [_jsx(Col, { xs: 24, lg: 10, xl: 10, children: _jsxs(Space, { size: 27, children: [_jsx(StethoscopeIcon, { className: "icon-color" }), _jsxs(Space, { direction: 'vertical', children: [_jsx("div", { className: 'body---14pt-R description-text', children: props.translate('patientInfo.title') }), _jsx("div", { className: 'body---14pt-R', children: props.appointment.getPatient().getName() })] })] }) }), _jsx(Col, { xs: 15, lg: 9, xl: 9 }), _jsx(Col, { xs: 24, lg: 5, xl: 5, children: _jsx(Button, { type: "link", className: "Header-R-14", onClick: goToPatient, children: props.translate('patientInfo.viewPatient') }) })] }) }) }));
};
export default localeTranslator(VisitInfo, 'showVisit');
