var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import Model from 'models/Model';
var MedicalTest = /** @class */ (function (_super) {
    __extends(MedicalTest, _super);
    function MedicalTest() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    MedicalTest.prototype.getId = function () {
        return Number(this.id);
    };
    MedicalTest.prototype.getName = function () {
        return this.name;
    };
    MedicalTest.prototype.setName = function (name) {
        this.name = name;
    };
    MedicalTest.prototype.getResult = function () {
        return this.result;
    };
    MedicalTest.prototype.setResult = function (result) {
        this.result = result;
    };
    MedicalTest.prototype.getPatientId = function () {
        return Number(this.patient_id);
    };
    MedicalTest.prototype.setPatientId = function (patientId) {
        this.patient_id = patientId;
    };
    MedicalTest.prototype.getAppointmentId = function () {
        return Number(this.appointment_id);
    };
    MedicalTest.prototype.setAppointmentId = function (appointmentId) {
        this.appointment_id = appointmentId;
    };
    return MedicalTest;
}(Model));
export default MedicalTest;
