export var Album = 1;
export var Clinic = 2;
export var Patient = 3;
export var PatientArea = 4;
export var Permission = 5;
export var PermissionCategory = 6;
export var Specialization = 7;
export var SystemResource = 8;
export var SystemAction = 9;
export var Role = 10;
export var UnitMeasurement = 11;
export var TelephoneType = 12;
export var MedicalCenter = 13;
export var MedicalCenterPatient = 14;
export var Doctor = 15;
export var Employee = 16;
export var MedicalMachine = 17;
export var Package = 18;
export var JobTitle = 19;
export var ClinicMedicalMachine = 20;
export var ReservationPackage = 21;
export var Appointments = 22;
export var Account = 24;
export var AccountType = 25;
export var Balance = 26;
export var Document = 27;
export var Transaction = 28;
export var FinancialPeriod = 29;
export var ProductCategory = 30;
export var Product = 31;
export var Supplier = 32;
export var SupplierBranch = 33;
export var Stock = 34;
export var StockMovement = 35;
export var Reports = 36;
export var InventoryAccess = [30, 31, 32, 33, 34, 35];
export var FinaniceAccess = [24, 25, 26, 27, 28, 29];
