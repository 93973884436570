import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import Icon from '@ant-design/icons';
var ReservationIcon = function () {
    var Image = function () {
        return _jsx("svg", { xmlns: "http://www.w3.org/2000/svg", width: "1em", height: "1em", fill: "currentColor", viewBox: "0 0 20 20", focusable: "false", "data-icon": "user", "aria-hidden": "true", children: _jsx("path", { "data-name": "Path 52", d: "M17 22a3.009 3.009 0 0 0 3-3V7a3.009 3.009 0 0 0-3-3h-1a2.006 2.006 0 0 0-2-2h-4a2.006 2.006 0 0 0-2 2H7a3.009 3.009 0 0 0-3 3v12a3.009 3.009 0 0 0 3 3zm-2-5h-3a1 1 0 0 1 0-2h3a1 1 0 0 1 0 2zm0-4h-3a1 1 0 0 1 0-2h3a1 1 0 0 1 0 2zm-5-9h4v2h-4zm-1 7a1 1 0 1 1-1 1 1 1 0 0 1 1-1zm0 4a1 1 0 1 1-1 1 1 1 0 0 1 1-1z", transform: "translate(-4 -2)" }) });
    };
    return (_jsx(Icon, { component: Image }));
};
export default memo(ReservationIcon);
