import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Button, Space, Form } from 'antd';
import services from 'services';
import { Product } from 'models';
import ProductForm from 'components/forms/inventory/products/ProductForm';
import localeTranslator from 'library/Hocs/LocalTranslator';
var NewProduct = function (props) {
    var product = useState(new Product())[0];
    var productCategoryService = services.productCategoryService, productService = services.productService, unitMeasurementService = services.unitMeasurementService;
    var _a = useState(false), disabled = _a[0], setDisabled = _a[1];
    var _b = productService.useCreateProduct(), createProduct = _b.mutate, errorResponse = _b.error;
    var productCategories = productCategoryService.useProductCategories().data;
    var unitMeasurements = unitMeasurementService.useUnitMeasurements().data;
    var form = Form.useForm()[0];
    var submit = function () {
        setDisabled(true);
        createProduct({ product: product, successMessage: props.translate('productCreated') });
    };
    var scrollToFirstElement = function () { return form.scrollToField('name', { behavior: 'smooth' }); };
    return (_jsxs("div", { style: { margin: 20, marginTop: 36 }, children: [_jsx("div", { className: "Header-24-bold title", children: props.translate('title') }), _jsxs(Form, { layout: 'vertical', form: form, initialValues: { layout: 'vertical' }, size: 'large', onFinish: submit, onFinishFailed: scrollToFirstElement, children: [unitMeasurements && productCategories &&
                        _jsx(ProductForm, { product: product, unitMeaurements: unitMeasurements, productCategories: productCategories }), _jsx("div", { className: "hidden-divider" }), _jsx("div", { className: "hidden-divider" }), _jsx(Space, { children: _jsx(Form.Item, { children: _jsx(Button, { type: "primary", htmlType: "submit", className: "normal-button Header-16-M", disabled: disabled, children: props.translate('common:buttons.submit') }) }) })] })] }));
};
export default localeTranslator(NewProduct, 'productForm');
