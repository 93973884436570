import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import Icon from '@ant-design/icons';
var ReferrerIcon = function () {
    var Image = function () {
        return _jsx("svg", { id: "vuesax_bold_profile-2user", "data-name": "vuesax/bold/profile-2user", xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", viewBox: "0 0 24 24", fill: "#647386", children: _jsxs("g", { id: "profile-2user", children: [_jsx("path", { id: "Vector", d: "M4.75 0a4.746 4.746 0 0 0-.12 9.49.807.807 0 0 1 .22 0h.07A4.746 4.746 0 0 0 4.75 0z", transform: "translate(4.25 2)" }), _jsx("path", { id: "Vector-2", "data-name": "Vector", d: "M12.12 1.395a9.929 9.929 0 0 0-10.15 0A3.947 3.947 0 0 0 0 4.625a3.914 3.914 0 0 0 1.96 3.21 9.239 9.239 0 0 0 5.08 1.41 9.239 9.239 0 0 0 5.08-1.41 3.945 3.945 0 0 0 1.96-3.23 3.937 3.937 0 0 0-1.96-3.21z", transform: "translate(1.96 12.755)" }), _jsx("path", { id: "Vector-3", "data-name": "Vector", d: "M5.88 3.283a3.579 3.579 0 0 1-3.13 3.87H2.7a.468.468 0 0 0-.17.02A3.588 3.588 0 0 1 0 6.343a4.576 4.576 0 0 0 1.5-3.8A4.641 4.641 0 0 0 .73.363a3.59 3.59 0 0 1 5.15 2.92z", transform: "translate(14.11 4.057)" }), _jsx("path", { id: "Vector-4", "data-name": "Vector", d: "M8 4.09a3.046 3.046 0 0 1-1.74 2.38 7.058 7.058 0 0 1-3.51.78 3.569 3.569 0 0 0 1.22-2.32A3.94 3.94 0 0 0 2.3 1.55 8.255 8.255 0 0 0 0 .29a7.815 7.815 0 0 1 6.7 1.17A3.038 3.038 0 0 1 8 4.09z", transform: "translate(13.99 12.5)" })] }) });
    };
    return (_jsx(Icon, { component: Image }));
};
export default memo(ReferrerIcon);
