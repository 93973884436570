import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import { Typography } from 'antd';
import localeTranslator from 'library/Hocs/LocalTranslator';
import './style.css';
var Text = Typography.Text;
var PackageCard = function (props) {
    var translate = props.translate;
    return (_jsxs("div", { className: "card ".concat(props.backgroundClass), children: [_jsxs(Text, { className: 'body---14pt-R entity-title', ellipsis: true, children: [props.entityType, " ", props.entityName && "- ".concat(props.entityName)] }), _jsx("br", {}), _jsx(Text, { className: 'body---14pt-R card-title', ellipsis: true, children: props.packageName }), _jsx("br", {}), _jsxs("div", { className: "package-details", children: [_jsx(Text, { className: 'Header-R-14', children: translate('price', { amount: props.packageTotalPrice }) }), _jsx(Text, { className: 'Header-R-14', children: props.packageQuantity })] })] }));
};
export default memo(localeTranslator(PackageCard, 'common'));
