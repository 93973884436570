import { jsx as _jsx } from "react/jsx-runtime";
import Balances from 'pages/accounting/Balances';
import { Route } from 'react-router';
import AuthorizationService from '../AuthorizationService';
import { Index } from 'constants/actions';
import { Balance as BalanceResource } from 'constants/resources';
var BalancesRoutes = function () {
    return ([
        _jsx(Route, { caseSensitive: true, path: '/balances', element: AuthorizationService.getAuthorizedElement(_jsx(Balances, {}), Index, BalanceResource) }, 'balances-route')
    ]);
};
export default BalancesRoutes;
