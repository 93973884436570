import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Form, Input, Row, Col, Checkbox } from 'antd';
import { PatientDetails } from 'models';
import './style.css';
import localeTranslator from 'library/Hocs/LocalTranslator';
var TextArea = Input.TextArea;
var BasicForm = function (props) {
    var _a, _b, _c;
    var patientDetails = props.patient.getPatientDetails() || new PatientDetails();
    var setChangeInPatient = function () {
        props.patient.setPatientDetails(patientDetails);
    };
    var onChangeBreastFeeding = function (event) {
        patientDetails.setBreastFeeding(event.target.checked);
        setChangeInPatient();
    };
    var onChangePregnant = function (event) {
        patientDetails.setPregnant(event.target.checked);
        setChangeInPatient();
    };
    var onChangeNote = function (event) {
        patientDetails.setNote(event.target.value);
        setChangeInPatient();
    };
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "Header-18-M form-title", children: props.translate('forFemales') }), _jsx("div", { className: "form-part", children: _jsxs(Row, { children: [_jsx(Col, { xs: 24, sm: 24, lg: 11, xl: 11, children: _jsx(Form.Item, { className: "form-item body---14pt-R", children: _jsx(Checkbox, { onChange: onChangeBreastFeeding, defaultChecked: (_a = props.patient.getPatientDetails()) === null || _a === void 0 ? void 0 : _a.getBreastFeeding(), children: props.translate('breastFeeding') }) }) }), _jsx(Col, { xs: 0, sm: 0, lg: 1, xl: 1 }), _jsx(Col, { xs: 24, sm: 24, lg: 11, xl: 11, children: _jsx(Form.Item, { className: "form-item body---14pt-R", children: _jsx(Checkbox, { onChange: onChangePregnant, defaultChecked: (_b = props.patient.getPatientDetails()) === null || _b === void 0 ? void 0 : _b.getPregnant(), children: props.translate('pregnant') }) }) })] }) }), _jsx("div", { className: "hidden-divider" }), _jsx("div", { className: "Header-18-M form-title", children: props.translate('moreInformation') }), _jsx("div", { className: "form-part", children: _jsx(Form.Item, { label: props.translate('note'), name: "note", className: "form-item body---14pt-R", initialValue: (_c = props.patient.getPatientDetails()) === null || _c === void 0 ? void 0 : _c.getNote(), children: _jsx(TextArea, { className: "text-area-border space-item", rows: 3, placeholder: props.translate('note'), onChange: onChangeNote }) }) })] }));
};
export default localeTranslator(BasicForm, 'patientForm');
