import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { Row, Col } from 'antd';
import PackageDetails from './PackageDetails';
import localeTranslator from 'library/Hocs/LocalTranslator';
import './style.css';
import { FormInput, FormSelect } from 'components/AntEnhancement/FormControls';
var PackageForm = function (props) {
    var _a = useState(null), packageElementTitle = _a[0], setPackageElementTitle = _a[1];
    var onChangeName = function (event) { return props.package.setName(event.target.value); };
    var onChangeElementType = function (value) {
        props.package.setEntityType(value.toString());
        setPackageElementTitle(props.packageEntities.filter(function (pe) { return pe.getValue() === value.toString(); })[0].getName());
    };
    var getPackageEntities = function () {
        return props.packageEntities.map(function (packageEntity) { return ({
            value: packageEntity.getValue(),
            title: packageEntity.getName()
        }); });
    };
    return (_jsx(_Fragment, { children: _jsxs("div", { className: "form-part", children: [_jsxs(Row, { children: [_jsx(Col, { xs: 24, sm: 24, lg: 11, xl: 11, children: _jsx(FormInput, { label: props.translate('name'), name: "name", placeHolder: props.translate('name'), rules: [
                                    { required: true, message: props.translate('errors.name') }
                                ], onChange: onChangeName }) }), _jsx(Col, { xs: 0, sm: 0, lg: 1, xl: 1 }), _jsx(Col, { xs: 24, sm: 24, lg: 12, xl: 12, children: _jsx(FormSelect, { label: props.translate('packageElement'), name: "packageElement", rules: [
                                    { required: true, message: props.translate('errors.packageElement') }
                                ], placeHolder: props.translate('packageElement'), onSelect: onChangeElementType, options: getPackageEntities() }) })] }), packageElementTitle &&
                    _jsx(PackageDetails, { package: props.package, packageElementTitle: packageElementTitle, packagePrice: props.packagePrice })] }) }));
};
export default localeTranslator(PackageForm, 'newPackage');
