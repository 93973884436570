import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import Icon from '@ant-design/icons';
var RightMarkCircleIcon = function (props) {
    var Image = function () {
        return _jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: props.viewBox || "-10 -3 30 30", children: [_jsx("circle", { "data-name": "Ellipse 45", cx: "8", cy: "8", r: "8", style: { fill: '#4e971b' } }), _jsx("path", { d: "M3.014 6.02a.814.814 0 0 1-.581-.241L.239 3.585A.822.822 0 0 1 1.4 2.422l1.614 1.613L6.821.239A.822.822 0 1 1 7.984 1.4L3.6 5.79a.9.9 0 0 1-.586.23z", transform: "translate(3.889 4.99)", style: { fill: '#fff' } })] });
    };
    return (_jsx(Icon, { component: Image }));
};
export default memo(RightMarkCircleIcon);
