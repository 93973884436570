var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import Model from 'models/Model';
import { AccountType } from 'models';
import { plainToClass } from 'class-transformer';
var Account = /** @class */ (function (_super) {
    __extends(Account, _super);
    function Account() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Account.prototype.getId = function () {
        return Number(this.id);
    };
    Account.prototype.getName = function () {
        return this.name;
    };
    Account.prototype.setName = function (name) {
        this.name = name;
    };
    Account.prototype.getAccountType = function () {
        return plainToClass(AccountType, this.account_type);
    };
    Account.prototype.getAccountTypeId = function () {
        return Number(this.account_type_id);
    };
    Account.prototype.setAccountTypeId = function (accountTypeId) {
        this.account_type_id = accountTypeId;
    };
    return Account;
}(Model));
export default Account;
