var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import Model from 'models/Model';
import { Clinic } from 'models';
import { plainToClass } from 'class-transformer';
var ClinicMedicalMachine = /** @class */ (function (_super) {
    __extends(ClinicMedicalMachine, _super);
    function ClinicMedicalMachine() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ClinicMedicalMachine.prototype.getId = function () {
        return this.id;
    };
    ClinicMedicalMachine.prototype.getFormattedBoughtAt = function () {
        return this.dateForamtter(new Date(this.bought_at), 'DD/MM/YYYY');
    };
    ClinicMedicalMachine.prototype.getBoughtAt = function () {
        return this.bought_at;
    };
    ClinicMedicalMachine.prototype.setBoughtAt = function (boughtAt) {
        this.bought_at = boughtAt.toString();
    };
    ClinicMedicalMachine.prototype.getClinicId = function () {
        return Number(this.clinic_id);
    };
    ClinicMedicalMachine.prototype.setClinicId = function (clinicId) {
        this.clinic_id = clinicId;
    };
    ClinicMedicalMachine.prototype.getClinic = function () {
        return plainToClass(Clinic, this.clinic);
    };
    ClinicMedicalMachine.prototype.setClinic = function (clinic) {
        this.clinic = clinic;
    };
    return ClinicMedicalMachine;
}(Model));
export default ClinicMedicalMachine;
