// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.drawer-package-detail {
  margin-bottom: 25px;
  padding: 25px 19.5px 19.5px 24.1px;
  border-radius: 5px;
  background-color: #fff;
  border: solid 1px var(--border-color);
}

.tag-button {
  width: fit-content;
  margin: auto;
  padding: 4px 9px 4px 11px;
  border-radius: 12px;
  background-color: rgba(203, 214, 227, 0.2);  
  cursor: pointer;
  display: flex;
  flex-direction: row;
}

.tag-button-title {
  margin: 1.5px 3px;
}`, "",{"version":3,"sources":["webpack://./src/components/cards/VisitPackageCard/style.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,kCAAkC;EAClC,kBAAkB;EAClB,sBAAsB;EACtB,qCAAqC;AACvC;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,yBAAyB;EACzB,mBAAmB;EACnB,0CAA0C;EAC1C,eAAe;EACf,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".drawer-package-detail {\n  margin-bottom: 25px;\n  padding: 25px 19.5px 19.5px 24.1px;\n  border-radius: 5px;\n  background-color: #fff;\n  border: solid 1px var(--border-color);\n}\n\n.tag-button {\n  width: fit-content;\n  margin: auto;\n  padding: 4px 9px 4px 11px;\n  border-radius: 12px;\n  background-color: rgba(203, 214, 227, 0.2);  \n  cursor: pointer;\n  display: flex;\n  flex-direction: row;\n}\n\n.tag-button-title {\n  margin: 1.5px 3px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
