// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tab-title-color {
  color: var(--tab-title-color) !important;
}

.empty-component-container {
  display: flex;
  width: 100%;
  height: 50vh;
  justify-content: center;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Doctors/style.css"],"names":[],"mappings":"AAAA;EACE,wCAAwC;AAC1C;;AAEA;EACE,aAAa;EACb,WAAW;EACX,YAAY;EACZ,uBAAuB;EACvB,mBAAmB;AACrB","sourcesContent":[".tab-title-color {\n  color: var(--tab-title-color) !important;\n}\n\n.empty-component-container {\n  display: flex;\n  width: 100%;\n  height: 50vh;\n  justify-content: center;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
