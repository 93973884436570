var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { classToPlain, plainToClass } from 'class-transformer';
import queries from 'constants/queries';
import { PaginationMetaData, ReservationPackage } from 'models';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import ApiService from './ApiService';
import { useNavigate } from 'react-router';
var ReservationPackageService = /** @class */ (function (_super) {
    __extends(ReservationPackageService, _super);
    function ReservationPackageService() {
        var _this = _super.call(this) || this;
        _this.reservationPackagesRoot = 'reservation_packages';
        _this.successCreationMessage = '';
        return _this;
    }
    ReservationPackageService.prototype.useReservationPackages = function (pageNumber, filters) {
        var _this = this;
        if (pageNumber === void 0) { pageNumber = 1; }
        if (filters === void 0) { filters = {}; }
        return useQuery([queries.reservationPackages, pageNumber, filters], function () {
            return _this.get(_this.reservationPackagesRoot, __assign(__assign({}, filters), { page: pageNumber }));
        }, {
            keepPreviousData: true,
            select: function (_a) {
                var data = _a.data;
                var reservationPackages = plainToClass(ReservationPackage, data === null || data === void 0 ? void 0 : data.reservation_packages);
                var meta = plainToClass(PaginationMetaData, data === null || data === void 0 ? void 0 : data.meta);
                return { reservationPackages: reservationPackages, meta: meta };
            }
        });
    };
    ReservationPackageService.prototype.useAllReservationPackages = function (filters) {
        var _this = this;
        return useQuery([queries.reservationPackages, filters], function () {
            var plainedFilters = classToPlain(filters);
            !plainedFilters.available && delete plainedFilters.available;
            return _this.get("".concat(_this.reservationPackagesRoot, "/list/all"), __assign({}, plainedFilters));
        }, {
            select: function (_a) {
                var data = _a.data;
                return plainToClass(ReservationPackage, data);
            }
        });
    };
    ReservationPackageService.prototype.useCreateBlukReservationPackages = function () {
        var _this = this;
        var queryClient = useQueryClient();
        var navigate = useNavigate();
        var needToNavigate = true;
        return useMutation(function (_a) {
            var reservationPackages = _a.reservationPackages, navigateToReservationPackages = _a.navigateToReservationPackages;
            needToNavigate = navigateToReservationPackages;
            var data = { reservation_package: classToPlain(reservationPackages) };
            return _this.post("".concat(_this.reservationPackagesRoot, "/create_bulk"), { data: data });
        }, {
            retry: false,
            onSuccess: function () {
                queryClient.invalidateQueries(queries.reservationPackages);
                needToNavigate && navigate('/reservation-packages');
            }
        });
    };
    ReservationPackageService.prototype.useGetReservationPackage = function (id) {
        var _this = this;
        return useQuery([queries.reservations, id], function () {
            return _this.get("".concat(_this.reservationPackagesRoot, "/").concat(id));
        }, {
            retry: true,
            select: function (_a) {
                var data = _a.data;
                return plainToClass(ReservationPackage, data);
            }
        });
    };
    ReservationPackageService.getInstance = function () {
        if (!ReservationPackageService.reservationPackageInstance) {
            ReservationPackageService.reservationPackageInstance = new ReservationPackageService();
        }
        return ReservationPackageService.reservationPackageInstance;
    };
    return ReservationPackageService;
}(ApiService));
export default ReservationPackageService;
