import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EllipsisOutlined } from '@ant-design/icons';
import { Table, Menu, Dropdown } from 'antd';
import services from 'services';
import SearchHeader from 'components/PageHeader/SearchHeader';
import EmptyComponent from 'components/EmptyComponent';
import localeTranslator from 'library/Hocs/LocalTranslator';
import './style.css';
import { Document as DocumentResource } from 'constants/resources';
var Documents = function (props) {
    var navigate = useNavigate();
    var _a = useState(1), pageNumber = _a[0], setPageNumber = _a[1];
    var documentService = services.documentService;
    var data = documentService.useDocuments(pageNumber).data;
    var goToDocument = function (documentId) { return function () { return navigate("/documents/".concat(documentId)); }; };
    var menu = function (documentId) {
        return (_jsx(Menu, { children: _jsx(Menu.Item, { onClick: goToDocument(documentId), children: "Show" }, "1") }));
    };
    var columns = [
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('id') }),
            key: 'id',
            render: function (text, record, index) {
                return _jsx("div", { className: "body---14pt-R", children: record.getId() }, 'id-' + index);
            }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('name') }),
            key: 'name',
            render: function (text, record, index) {
                return _jsx("div", { className: "body---14pt-R table-body-content", children: record.getName() }, 'name-' + index);
            }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('documentLinesCount') }),
            key: 'documentLinesCount',
            render: function (text, record, index) {
                return _jsx("div", { className: "body---14pt-R table-body-content", children: record.getDocumentLinesCount() }, 'documentLinesCount-' + index);
            }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('action') }),
            fixed: 'right',
            render: function (text, record) {
                return _jsx(Dropdown.Button, { icon: _jsx(EllipsisOutlined, { rotate: 90, className: 'action-button' }), overlay: menu(record.getId()), className: "three-dot" });
            }
        }
    ];
    var getNextPage = function (currentPageNumber) { return setPageNumber(currentPageNumber); };
    var goToNewDocumentForm = function () { return navigate('/documents/new'); };
    return (_jsxs("div", { children: [_jsx(SearchHeader, { title: props.translate('header.documents'), addButtonText: props.translate('common:buttons.newDocument'), addButtonFunction: goToNewDocumentForm, resource: DocumentResource }), _jsx("div", { className: "page-content", children: _jsx(Table, { columns: columns, className: 'Header-R-14', pagination: {
                        position: ['bottomRight'],
                        pageSize: 25,
                        total: (data === null || data === void 0 ? void 0 : data.meta) ? (data === null || data === void 0 ? void 0 : data.meta.getPagesCount()) * 25 : 0,
                        onChange: getNextPage,
                        showSizeChanger: false,
                        responsive: true
                    }, 
                    // scroll={{ x: 1300 }}
                    dataSource: data === null || data === void 0 ? void 0 : data.documents, locale: {
                        emptyText: _jsx(EmptyComponent, {})
                    } }) })] }));
};
export default localeTranslator(Documents, 'documents');
