import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Row, Col, Form, Input, Divider } from 'antd';
import localeTranslator from 'library/Hocs/LocalTranslator';
import MedicalMachines from './entities/MedicalMachines';
import Products from './entities/Products';
import AppointmentTypes from './entities/AppointmentTypes';
import PackagePriceForm from '../packagePrices/PackagePriceForm';
import services from 'services';
var TextArea = Input.TextArea;
var PackageDetails = function (props) {
    var unitMeasurementService = services.unitMeasurementService;
    var unitMeasurements = unitMeasurementService.useUnitMeasurements().data;
    var onChangeDescription = function (event) {
        props.package.setDescription(event.target.value);
    };
    var entityComponent = function () {
        var Component = null;
        // case 'Product Category': {
        //   Component = <ProductCategories package={props.package} packageElementTitle={props.packageElementTitle}/>;
        //   break;
        // }
        switch (props.packageElementTitle) {
            case 'Medical Machine': {
                Component = _jsx(MedicalMachines, { package: props.package, packageElementTitle: props.packageElementTitle });
                break;
            }
            case 'Product': {
                Component = _jsx(Products, { package: props.package, packageElementTitle: props.packageElementTitle });
                break;
            }
            default: {
                Component = _jsx(AppointmentTypes, { package: props.package, packageElementTitle: props.packageElementTitle });
                break;
            }
        }
        return Component;
    };
    return (_jsxs(_Fragment, { children: [entityComponent(), _jsx(Row, { children: _jsx(Col, { xs: 24, sm: 24, lg: 24, xl: 24, children: _jsx(Form.Item, { label: props.translate('description'), name: "description", className: "form-item body---14pt-R", children: _jsx(TextArea, { className: "text-area-border space-item", placeholder: props.translate('description'), onChange: onChangeDescription, rows: 5 }) }) }) }), _jsx(Divider, {}), unitMeasurements &&
                _jsx(PackagePriceForm, { packagePrice: props.packagePrice, unitMeasurements: unitMeasurements })] }));
};
export default localeTranslator(PackageDetails, 'newPackage');
