import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Space } from 'antd';
import localeTranslator from 'library/Hocs/LocalTranslator';
import '../../style.css';
var MedicalInformation = function (props) {
    var _a, _b;
    var patient = props.patient;
    return (_jsx("div", { className: "patient-info", children: _jsxs(Space, { direction: "vertical", size: 40, children: [_jsx(Space, { size: 23, children: _jsxs(Space, { direction: "vertical", children: [_jsx("div", { className: 'Header-16-M', children: props.translate('patientInfo.medicalConditions') }), _jsx("div", { className: 'body---14pt-R', children: (_a = patient.getPatientDetails()) === null || _a === void 0 ? void 0 : _a.getMedicalConditions() })] }) }), _jsx(Space, { size: 23, children: _jsxs(Space, { direction: "vertical", children: [_jsx("div", { className: 'Header-16-M', children: props.translate('patientInfo.medication') }), _jsx("div", { className: 'body---14pt-R', children: (_b = patient.getPatientDetails()) === null || _b === void 0 ? void 0 : _b.getMedication() })] }) }), _jsx(Space, { size: 23, children: _jsxs(Space, { direction: "vertical", children: [_jsx("div", { className: 'Header-16-M', children: props.translate('patientInfo.medicalHistory') }), _jsx("div", { className: 'body---14pt-R', children: "Find comprehensive medical reference information including disease symptoms, diagnoses, treatments, and follow-up; plus drug and medication dosing, ..." })] }) })] }) }));
};
export default React.memo(localeTranslator(MedicalInformation, 'showPatient'));
