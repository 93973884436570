import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Col, Form, Button } from 'antd';
import EntityHeader from 'components/PageHeader/EntityHeader';
import { Transaction } from 'constants/resources';
import localeTranslator from 'library/Hocs/LocalTranslator';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import services from 'services';
var Header = function (props) {
    var navigate = useNavigate();
    var id = useParams().id;
    var onRevert = function () { return props.revertTransaction(props.transaction); };
    var permissionService = services.permissionService;
    var buttons = function () {
        return (_jsx(_Fragment, { children: props.transaction.getCanBeReverted() &&
                permissionService.hasPermissionToAdd(Transaction) &&
                _jsx(Col, { xs: 24, sm: 24, lg: 24, xl: 5, children: _jsx(Form.Item, { children: _jsx(Button, { className: 'normal-button Header-16-M', onClick: onRevert, children: props.translate('header.revert') }) }) }) }));
    };
    return (_jsx(EntityHeader, { children: buttons(), title: props.translate('header.transaction') }));
};
export default localeTranslator(Header, 'showTransaction');
