import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Button, Drawer, Form } from 'antd';
import localeTranslator from 'library/Hocs/LocalTranslator';
import { JobTitle } from 'models';
import services from 'services';
import JobTitleForm from 'components/forms/settings/JobTitleForm';
import '../style.css';
var AddJobTitleDrawer = function (props) {
    var jobTitle = useState(new JobTitle())[0];
    var form = Form.useForm()[0];
    var onClose = function () { return props.onCloseDrawer(); };
    var jobTitleService = services.jobTitleService, roleService = services.roleService;
    var roles = roleService.useRoles().data;
    var _a = jobTitleService.useCreateJobTitle(), createJobTitle = _a.mutate, isJobTitleCreated = _a.isSuccess;
    useEffect(function () {
        isJobTitleCreated && onClose();
    }, [isJobTitleCreated]);
    var onSubmit = function () { return createJobTitle({ jobTitle: jobTitle, successMessage: 'Job title created' }); };
    return (_jsx(Drawer, { title: props.translate('jobTitles.addJobTitle'), placement: "right", open: props.visiable, size: "default", onClose: onClose, width: 642, className: "transaction-drawer", children: _jsxs(Form, { layout: 'vertical', form: form, initialValues: { layout: 'vertical' }, size: 'large', onFinish: onSubmit, children: [roles &&
                    _jsx(JobTitleForm, { roles: roles, jobTitle: jobTitle }), _jsx(Form.Item, { children: _jsx(Button, { type: "primary", htmlType: "submit", className: "add-submit-button Header-16-M", children: props.translate('common:buttons.submit') }) })] }) }, "right"));
};
export default localeTranslator(AddJobTitleDrawer, 'settings');
