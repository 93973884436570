import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import Icon from '@ant-design/icons';
var TimeIcon = function () {
    var Image = function () {
        return _jsx("svg", { xmlns: "http://www.w3.org/2000/svg", width: "1.5em", height: "1.5em", fill: "currentColor", viewBox: "0 0 24 24", focusable: "false", "data-icon": "user", "aria-hidden": "true", children: _jsx("path", { d: "M10 0a10 10 0 1 0 10 10A10.016 10.016 0 0 0 10 0zm4.35 13.57a.746.746 0 0 1-.65.37.67.67 0 0 1-.38-.11l-3.1-1.85a2.949 2.949 0 0 1-1.34-2.36v-4.1a.75.75 0 0 1 1.5 0v4.1a1.478 1.478 0 0 0 .61 1.07l3.1 1.85a.74.74 0 0 1 .26 1.03z", transform: "translate(2 2)", style: { fill: '#8f9eb3' } }) });
    };
    return (_jsx(Icon, { component: Image }));
};
export default memo(TimeIcon);
