import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Row, Col, Divider, Space } from 'antd';
import Loader from 'components/AntEnhancement/Loader';
import Stocks from 'pages/inventory/ShowProduct/components/Tables/Stocks';
import MainInfo from 'pages/inventory/ShowProduct/components/Informations/MainInfo';
import OtherInfo from 'pages/inventory/ShowProduct/components/Informations/OtherInfo';
import { useParams } from 'react-router';
import services from 'services';
import Header from 'pages/inventory/ShowProduct/components/Header';
import localeTranslator from 'library/Hocs/LocalTranslator';
import './style.css';
var ShowProduct = function (props) {
    var id = useParams().id;
    var productService = services.productService;
    var _a = productService.useProduct(Number(id)), product = _a.data, isProduct = _a.isLoading;
    return (_jsx("div", { className: "loader-container-height", children: _jsx(Loader, { renderSpinner: isProduct, children: _jsxs("div", { children: [_jsxs(Space, { direction: 'vertical', size: 50, children: [_jsx("div", { className: "page-header add-padding", children: product && _jsx(Header, { product: product }) }), _jsx("div", { className: "new-pages-content", children: _jsx(Space, { direction: "vertical", size: 24, children: _jsxs(Row, { children: [_jsx(Col, { xl: 12, children: product && _jsx(MainInfo, { product: product }) }), _jsx(Col, { sm: 1 }), _jsx(Col, { xl: 11, children: product && _jsx(OtherInfo, { product: product }) })] }) }) })] }), _jsx(Divider, {}), product && _jsx(Stocks, { product: product })] }) }) }));
};
export default localeTranslator(ShowProduct, 'showProduct');
