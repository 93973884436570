import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Col, Row } from 'antd';
import PatientLogHeader from './PatientLogHeader';
import 'components/patients/style.css';
import localeTranslator from 'library/Hocs/LocalTranslator';
var PatientLogEntities = function (props) {
    var showMedicalTests = function () { return props.setShowMedicalTests(true); };
    var showFollowUps = function () { return props.setShowFollowUps(true); };
    var showPrescriptions = function () { return props.setShowPrescriptions(true); };
    var showMedicalHistory = function () { return props.setShowDiagnoses(true); };
    var showPatientMedicalDetails = function () { return props.setShowPatientMedicalDetails(true); };
    var showMedicalMachineHistory = function () { return props.setShowMedicalMachineUsages(true); };
    var showImages = function () { return props.setShowImages(true); };
    return (_jsxs(_Fragment, { children: [_jsx(PatientLogHeader, { title: props.translate('title'), hasAddButton: false, hasBackButton: false }), _jsxs(Row, { className: "patient-log-entity-row", onClick: showPrescriptions, children: [_jsx(Col, { xl: 21, children: _jsx("div", { className: "Header-16-M collapse-padding", children: props.translate('prescriptions.title') }) }), _jsx(Col, { xl: 3, children: props.arrowIcon })] }), _jsx("div", { id: "line" }), _jsxs(Row, { className: "patient-log-entity-row", onClick: showMedicalHistory, children: [_jsx(Col, { xl: 21, children: _jsx("div", { className: "Header-16-M collapse-padding", children: props.translate('diagnoses.title') }) }), _jsx(Col, { xl: 3, children: props.arrowIcon })] }), _jsx("div", { id: "line" }), _jsxs(Row, { onClick: showFollowUps, className: "patient-log-entity-row", children: [_jsx(Col, { xl: 21, children: _jsx("div", { className: "Header-16-M collapse-padding", children: props.translate('followUps.title') }) }), _jsx(Col, { xl: 3, children: props.arrowIcon })] }), _jsx("div", { id: "line" }), _jsxs(Row, { className: "patient-log-entity-row", onClick: showMedicalTests, children: [_jsx(Col, { xl: 21, children: _jsx("div", { className: "Header-16-M collapse-padding", children: props.translate('medicalTests.title') }) }), _jsx(Col, { xl: 3, children: props.arrowIcon })] }), _jsx("div", { id: "line" }), _jsxs(Row, { className: "patient-log-entity-row", onClick: showPatientMedicalDetails, children: [_jsx(Col, { xl: 21, children: _jsx("div", { className: "Header-16-M collapse-padding", children: props.translate('patientDetails.title') }) }), _jsx(Col, { xl: 3, children: props.arrowIcon })] }), _jsx("div", { id: "line" })] }));
};
export default localeTranslator(PatientLogEntities, 'patientLog');
