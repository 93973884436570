import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Space, Col, Row } from 'antd';
import localeTranslator from 'library/Hocs/LocalTranslator';
import '../../style.css';
var StockInfo = function (props) {
    var stockMovement = props.stockMovement;
    return (_jsxs("div", { id: "profile-content", className: "patient-info", children: [_jsx("div", { className: "Header-18-M form-title", children: props.translate('stockInfo.header') }), _jsx(Space, { direction: "vertical", size: 43, children: _jsxs(Row, { children: [_jsx(Col, { xs: 24, lg: 10, xl: 10, children: _jsxs(Space, { direction: "vertical", children: [_jsx("div", { className: 'Header-16-M description-text', children: props.translate('stockInfo.title') }), _jsx("div", { className: 'body---14pt-R', children: stockMovement.getStock().getLocation().getName() })] }) }), _jsx(Col, { xs: 15, lg: 4, xl: 4 }), _jsx(Col, { xs: 24, lg: 10, xl: 10, children: _jsxs(Space, { direction: "vertical", children: [_jsx("div", { className: 'Header-16-M description-text', children: props.translate('stockInfo.product') }), _jsx("div", { className: 'body---14pt-R', children: stockMovement.getStock().getProduct().getName() })] }) })] }) })] }));
};
export default React.memo(localeTranslator(StockInfo, 'showStockMovement'));
