import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from 'react';
import { Button, Space, Form } from 'antd';
import VisibilitySensor from 'react-visibility-sensor';
import services from 'services';
import PatientSearch from 'components/forms/patients/PatientSearch';
import ReservationForm from 'components/forms/reservations/ReservationInfoForm';
import NewEntityHeader from 'components/PageHeader/NewEntityHeader';
import ReservationSteps from './ReservationSteps';
import localeTranslator from 'library/Hocs/LocalTranslator';
import './style.css';
var NewReservationPackage = function (props) {
    var _a = useState(false), disabled = _a[0], setDisabled = _a[1];
    var _b = useState(''), phoneNumber = _b[0], setPhoneNumber = _b[1];
    var _c = useState([]), reservationPackages = _c[0], setReservationPackages = _c[1];
    var _d = useState(), patient = _d[0], setPatient = _d[1];
    var packagePriceService = services.packagePriceService, unitMeasurementService = services.unitMeasurementService, patientService = services.patientService, reservationPackageService = services.reservationPackageService;
    var packagePrices = packagePriceService.usePackagePrices().data;
    var unitMeasurements = unitMeasurementService.useUnitMeasurements().data;
    var _e = patientService.usePatientSearchByPrimaryPhone(phoneNumber), patients = _e.data, isLoading = _e.isLoading;
    var createBulkReservationPackages = reservationPackageService.useCreateBlukReservationPackages().mutate;
    // For tabs navigation Logic
    var patientRef = useRef(null);
    var reservationInfoRef = useRef(null);
    var _f = useState(false), isPatientVisible = _f[0], setIsPatientVisible = _f[1];
    var _g = useState(false), isReservationInfoVisible = _g[0], setIsReservationInfoVisible = _g[1];
    var onPatientVisible = function (isVisible) { return setIsPatientVisible(isVisible); };
    var onReservationInfoVisible = function (isVisible) { return setIsReservationInfoVisible(isVisible); };
    var isPatientTabFinished = function () { return !!patient; };
    var isReservationTabFinished = function () { return !!reservationPackages.length; };
    var form = Form.useForm()[0];
    useEffect(function () {
        var selectedPatient = patients === null || patients === void 0 ? void 0 : patients.find(function (p) { return p.getPrimaryPhone() === phoneNumber; });
        setPatient(selectedPatient);
    }, [JSON.stringify(patients)]);
    var submit = function () {
        setDisabled(true);
        patient && reservationPackages.forEach(function (rp) { return rp.setPatientId(patient.getId()); });
        createBulkReservationPackages({ reservationPackages: reservationPackages, navigateToReservationPackages: true });
    };
    return (_jsxs("div", { id: "new-reservation", children: [_jsx(NewEntityHeader, { title: props.translate('title') }), _jsx(ReservationSteps, { tabRefs: [patientRef, reservationInfoRef], tabsVisibility: [isPatientVisible, isReservationInfoVisible], tabsStatus: [isPatientTabFinished(), isReservationTabFinished()] }), _jsx(Form, { className: "new-pages-content", layout: 'vertical', form: form, initialValues: { layout: 'vertical' }, size: 'large', onFinish: submit, children: _jsxs(Space, { direction: 'vertical', size: 60, style: { width: '100%' }, children: [_jsx(VisibilitySensor, { onChange: onPatientVisible, partialVisibility: true, offset: { top: 140 }, children: _jsx("div", { ref: patientRef, children: _jsx(PatientSearch, { onSearch: setPhoneNumber, phoneNumber: phoneNumber, patients: patients, isLoading: isLoading }) }) }), _jsx(VisibilitySensor, { onChange: onReservationInfoVisible, partialVisibility: true, offset: { top: 140 }, children: _jsx("div", { ref: reservationInfoRef, children: packagePrices && unitMeasurements &&
                                    _jsx(ReservationForm, { packagePrices: packagePrices, unitMeasurements: unitMeasurements, reservationPackages: reservationPackages, setReservationPackages: setReservationPackages }) }) }), _jsx(Space, { children: _jsx(Form.Item, { children: _jsx(Button, { type: "primary", htmlType: "submit", className: "normal-button Header-16-M", disabled: disabled, children: props.translate('common:buttons.submit') }) }) })] }) })] }));
};
export default localeTranslator(NewReservationPackage, 'newReservation');
