import { jsx as _jsx } from "react/jsx-runtime";
import Packages from 'pages/Packages';
import NewPackage from 'pages/NewPackage';
import ShowPackage from 'pages/ShowPackage';
import { Route } from 'react-router';
import AuthorizationService from 'routes/AuthorizationService';
import { Index, Create, Show } from 'constants/actions';
import { Package as PackagesResource } from 'constants/resources';
var PackageRoutes = function () {
    return ([
        _jsx(Route, { caseSensitive: true, path: '/packages/new', element: AuthorizationService.getAuthorizedElement(_jsx(NewPackage, {}), Create, PackagesResource) }, 'package-new-route'),
        _jsx(Route, { caseSensitive: true, path: '/packages', element: AuthorizationService.getAuthorizedElement(_jsx(Packages, {}), Index, PackagesResource) }, 'packages-route'),
        _jsx(Route, { caseSensitive: true, path: '/packages/:id', element: AuthorizationService.getAuthorizedElement(_jsx(ShowPackage, {}), Show, PackagesResource) }, 'show-package-route')
    ]);
};
export default PackageRoutes;
