import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import Icon from '@ant-design/icons';
var PackagesIcon = function () {
    var Image = function () {
        return _jsx("svg", { xmlns: "http://www.w3.org/2000/svg", width: "1em", height: "1em", fill: "currentColor", viewBox: "0 0 22 22", focusable: "false", "data-icon": "user", "aria-hidden": "true", children: _jsx("g", { "data-name": "vuesax/bold/receipt-2", children: _jsx("g", { "data-name": "vuesax/bold/percentage-square", children: _jsx("path", { "data-name": "Vector", d: "M14.19 0H5.81C2.17 0 0 2.17 0 5.81v8.37C0 17.83 2.17 20 5.81 20h8.37c3.64 0 5.81-2.17 5.81-5.81V5.81C20 2.17 17.83 0 14.19 0zM5.75 7.14a1.48 1.48 0 1 1 1.48 1.48 1.483 1.483 0 0 1-1.48-1.48zm1.07 6.88a.742.742 0 0 1-.53-.22.754.754 0 0 1 0-1.06l6.55-6.55a.75.75 0 0 1 1.06 1.06L7.35 13.8a.742.742 0 0 1-.53.22zm6.95.32a1.48 1.48 0 1 1 1.48-1.48 1.483 1.483 0 0 1-1.48 1.48z", transform: "translate(2 2)" }) }) }) });
    };
    return (_jsx(Icon, { component: Image }));
};
export default memo(PackagesIcon);
