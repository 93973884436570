import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import PatientDetails from 'components/PatientDetails';
import PatientLogHeader from './PatientLogHeader';
import 'components/patients/style.css';
import { useState } from 'react';
import NewMedicalPatientDetails from 'components/forms/PatientMedicalDetails/NewMedicalPatientDetails';
import localeTranslator from 'library/Hocs/LocalTranslator';
var AllPatientDetails = function (props) {
    var _a = useState(false), showPatientDetialsForm = _a[0], setShowPatientDetialsForm = _a[1];
    var renderPatientDetails = function () {
        return _jsx("div", { children: _jsx(PatientDetails, { title: props.translate('patientDetails.title'), patientDetails: props.patient.getPatientDetails() }) }, 'patiendDetails');
    };
    var goBack = function () { return props.setShowPatientDetails(false); };
    var addPatientDetails = function () { return setShowPatientDetialsForm(true); };
    return (_jsxs(_Fragment, { children: [_jsx(PatientLogHeader, { title: props.translate('patientDetails.title'), hasAddButton: true, hasBackButton: true, onGoBack: goBack, onAdd: addPatientDetails, arrowIcon: props.arrowIcon }), showPatientDetialsForm &&
                _jsx(NewMedicalPatientDetails, { updatePatientDetails: props.updatePatientDetails, setShowPatientDetailsForm: setShowPatientDetialsForm, patient: props.patient }), _jsx("div", { className: "log", children: renderPatientDetails() })] }));
};
export default localeTranslator(AllPatientDetails, 'patientLog');
