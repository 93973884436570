import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Button, Space, Form } from 'antd';
import services from 'services';
import { Account } from 'models';
import localeTranslator from 'library/Hocs/LocalTranslator';
import AccountForm from 'components/forms/accounting/accounts/AccountForm';
var NewAccount = function (props) {
    var account = useState(new Account())[0];
    var accountTypeService = services.accountTypeService, accountService = services.accountService;
    var _a = useState(false), disabled = _a[0], setDisabled = _a[1];
    var _b = accountService.useCreateAccount(), createAccount = _b.mutate, errorResponse = _b.error;
    var accountTypes = accountTypeService.useAccountTypes().data;
    var form = Form.useForm()[0];
    var submit = function () {
        setDisabled(true);
        createAccount({ account: account, successMessage: props.translate('accountCreated') });
    };
    var scrollToFirstElement = function () { return form.scrollToField('name', { behavior: 'smooth' }); };
    return (_jsxs("div", { style: { margin: 20, marginTop: 36 }, children: [_jsx("div", { className: "Header-24-bold title", children: props.translate('title') }), _jsxs(Form, { layout: 'vertical', form: form, initialValues: { layout: 'vertical' }, size: 'large', onFinish: submit, onFinishFailed: scrollToFirstElement, children: [accountTypes && _jsx(AccountForm, { account: account, accountTypes: accountTypes }), _jsx("div", { className: "hidden-divider" }), _jsx("div", { className: "hidden-divider" }), _jsx(Space, { children: _jsx(Form.Item, { children: _jsx(Button, { type: "primary", htmlType: "submit", className: "normal-button Header-16-M", disabled: disabled, children: props.translate('common:buttons.submit') }) }) })] })] }));
};
export default localeTranslator(NewAccount, 'accountForm');
