import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Form, Row, Col } from 'antd';
import localeTranslator from 'library/Hocs/LocalTranslator';
import { FormDatePicker } from 'components/AntEnhancement/FormControls';
var clinicMedicalMachineRow = function (props) {
    var _a, _b;
    var translate = props.translate;
    var onChangeBoughtAt = function (boughtAt) {
        props.clinicMedicalMachine.setBoughtAt((boughtAt === null || boughtAt === void 0 ? void 0 : boughtAt.toDate()) || new Date());
        props.onChangeBoughtAt(props.clinicMedicalMachine, props.clinicMedicalMachine.getBoughtAt());
    };
    return (_jsxs(Row, { children: [_jsx(Col, { xs: 24, sm: 24, lg: 10, xl: 10, children: _jsx(Form.Item, { label: translate('clinic'), name: "clinic", className: "form-item body---14pt-R", children: _jsx("div", { className: "package-name-rectangle body---14pt-R", children: (_a = props.clinicMedicalMachine.getClinic()) === null || _a === void 0 ? void 0 : _a.getName() }) }) }), _jsx(Col, { xs: 0, sm: 0, lg: 8, xl: 8 }), _jsx(Col, { xs: 24, sm: 24, lg: 4, xl: 4, children: _jsx(FormDatePicker, { label: props.translate('boughtAt'), name: "boughtAt-".concat((_b = props.clinicMedicalMachine.getClinic()) === null || _b === void 0 ? void 0 : _b.getName()), rules: [
                        { required: true, message: props.translate('errors.boughtAt') }
                    ], placeHolder: props.translate('selectDate'), onChange: onChangeBoughtAt }, props.clinicMedicalMachine.getClinicId()) }), _jsx(Col, { xs: 0, sm: 0, lg: 2, xl: 2 })] }));
};
export default React.memo(localeTranslator(clinicMedicalMachineRow, 'clinicMedicalMachineRowForm'));
