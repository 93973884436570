import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Row, Col, Space, Input } from 'antd';
import Loader from 'components/AntEnhancement/Loader';
import HomeCard from 'components/cards/HomeCard';
import localeTranslator from 'library/Hocs/LocalTranslator';
import services from 'services';
import VisitPaths from './VisitPaths';
var Search = Input.Search;
var DoctorQueue = function (props) {
    var homeService = services.homeService;
    var _a = homeService.useGetDoctorHomeData(props.date, props.doctor_id), data = _a.data, isFetching = _a.isFetching, isSuccess = _a.isSuccess, isLoading = _a.isLoading;
    var _b = useState(data || []), appointments = _b[0], setAppointments = _b[1];
    useEffect(function () {
        isSuccess && setAppointments(data || []);
    }, [isSuccess, isFetching]);
    var onSearch = function (phone) {
        setAppointments(appointments.filter(function (appointment) { var _a; return (_a = appointment.getPatient()) === null || _a === void 0 ? void 0 : _a.getPrimaryPhone().includes(phone); }) || []);
    };
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: 'Header-R-16', style: { textAlign: 'center' }, children: props.doctor_name }), _jsx("div", { className: 'seperator', style: { marginTop: '20px' } }), _jsx(Row, { children: _jsxs(Space, { direction: 'vertical', size: 25, className: 'cards', children: [_jsx(Col, { xs: 24, children: _jsx(Search, { placeholder: props.translate('receptionist.phone'), size: 'large', style: { width: 280 }, onSearch: function (val) { return onSearch(val); } }) }), _jsx(Loader, { renderSpinner: isLoading, children: _jsx(_Fragment, { children: data && appointments &&
                                    appointments.map(function (appointment) {
                                        return (_jsx(Col, { style: { marginTop: '10px' }, xs: 24, children: _jsx(HomeCard, { appointment: appointment, children: _jsx(VisitPaths, { appointment: appointment }) }) }, "appointment-".concat(appointment.getId())));
                                    }) }) })] }) })] }));
};
export default localeTranslator(DoctorQueue, 'home');
