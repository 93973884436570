var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { memo, useState, useEffect, useCallback } from 'react';
import PackageCard from 'components/cards/PackageCard';
import localeTranslator from 'library/Hocs/LocalTranslator';
import { FixedSizeGrid as Grid, areEqual } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import './style.css';
var PackageCardGroup = function (props) {
    var _a = useState([]), selectedPackagePriceIds = _a[0], setSelectedPackagePriceIds = _a[1];
    var numberOfPackagePricesInRow = 4;
    var onPackagePriceClicked = useCallback(function (packagePriceId, isSelected) {
        if (!isSelected) {
            setSelectedPackagePriceIds(__spreadArray(__spreadArray([], selectedPackagePriceIds, true), [packagePriceId], false));
            props.onSelectPackage(packagePriceId);
        }
        else {
            setSelectedPackagePriceIds(selectedPackagePriceIds.filter(function (id) { return id != packagePriceId; }));
            props.onDeselectPackage(packagePriceId);
        }
    }, [props]);
    useEffect(function () {
        if (JSON.stringify(selectedPackagePriceIds) !== JSON.stringify(props.selectedPackagePriceIds)) {
            setSelectedPackagePriceIds(props.selectedPackagePriceIds);
        }
    }, [props.selectedPackagePriceIds, selectedPackagePriceIds]);
    var Cell = memo(function (_a) {
        var columnIndex = _a.columnIndex, rowIndex = _a.rowIndex, style = _a.style, isSelected = _a.isSelected;
        var packagePrice = props.packagePrices[rowIndex * numberOfPackagePricesInRow + columnIndex];
        var cardColor = isSelected ? 'selected-card-color' : 'normal-card-color';
        var unitMeasurement = props.unitMeasurements.find(function (um) { return um.getId() === (packagePrice === null || packagePrice === void 0 ? void 0 : packagePrice.getUnitMeasurementId()); });
        return (_jsx(_Fragment, { children: packagePrice &&
                _jsx("div", { onClick: function () { return onPackagePriceClicked(packagePrice === null || packagePrice === void 0 ? void 0 : packagePrice.getId(), isSelected); }, style: style, children: _jsx(PackageCard, { entityType: packagePrice === null || packagePrice === void 0 ? void 0 : packagePrice.getEntityType(), entityName: packagePrice === null || packagePrice === void 0 ? void 0 : packagePrice.getEntityName(), backgroundClass: cardColor, packageName: packagePrice === null || packagePrice === void 0 ? void 0 : packagePrice.getPackageName(), packageTotalPrice: packagePrice === null || packagePrice === void 0 ? void 0 : packagePrice.getTotalPrice(), packageQuantity: "".concat(packagePrice === null || packagePrice === void 0 ? void 0 : packagePrice.getQuantity(), " ").concat(unitMeasurement === null || unitMeasurement === void 0 ? void 0 : unitMeasurement.getName()) }) }, "package-price-selection-".concat(rowIndex * numberOfPackagePricesInRow + columnIndex)) }));
    }, areEqual);
    var Item = memo(function (_a) {
        var columnIndex = _a.columnIndex, rowIndex = _a.rowIndex, style = _a.style;
        var packagePrice = props.packagePrices[rowIndex * numberOfPackagePricesInRow + columnIndex];
        var isCardSelected = selectedPackagePriceIds.includes(packagePrice === null || packagePrice === void 0 ? void 0 : packagePrice.getId());
        return (_jsx(_Fragment, { children: packagePrice &&
                _jsx(Cell, { columnIndex: columnIndex, rowIndex: rowIndex, style: style, isSelected: isCardSelected }) }));
    }, areEqual);
    return (_jsx(AutoSizer, { children: function (_a) {
            var height = _a.height, width = _a.width;
            return (_jsx(_Fragment, { children: height && width &&
                    _jsx(Grid, { columnCount: 3, columnWidth: 300, height: height, rowCount: Math.ceil(props.packagePrices.length / 3), rowHeight: 150, width: width, children: Item }) }));
        } }));
};
export default memo(localeTranslator(PackageCardGroup, 'common'));
