var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { classToPlain } from 'class-transformer';
import queries from 'constants/queries';
import { useMutation, useQueryClient } from 'react-query';
import ApiService from './ApiService';
var AppointmentDetailService = /** @class */ (function (_super) {
    __extends(AppointmentDetailService, _super);
    function AppointmentDetailService() {
        var _this = _super.call(this) || this;
        _this.appointmentDetailsRoot = 'appointment_details';
        return _this;
    }
    AppointmentDetailService.prototype.useCreateAppointmentDetail = function () {
        var _this = this;
        var queryClient = useQueryClient();
        var appointmentId = null;
        return useMutation(function (_a) {
            var appointmentDetail = _a.appointmentDetail;
            var data = { appointment_detail: classToPlain(appointmentDetail) };
            appointmentId = appointmentDetail.getAppointmentId();
            return _this.post(_this.appointmentDetailsRoot, { data: data });
        }, {
            retry: false,
            onSuccess: function () {
                queryClient.invalidateQueries({ queryKey: [queries.appointments, appointmentId] });
            }
        });
    };
    AppointmentDetailService.prototype.useCreateBlukAppointmentDetails = function () {
        var _this = this;
        var queryClient = useQueryClient();
        var appointmentId = null;
        return useMutation(function (_a) {
            var appointmentDetails = _a.appointmentDetails;
            appointmentId = appointmentDetails[0].getAppointmentId();
            var data = { appointment_details: classToPlain(appointmentDetails) };
            return _this.post("".concat(_this.appointmentDetailsRoot, "/create_bulk"), { data: data });
        }, {
            retry: false,
            onSuccess: function () {
                queryClient.invalidateQueries({ queryKey: [queries.appointments, appointmentId] });
            }
        });
    };
    AppointmentDetailService.getInstance = function () {
        if (!AppointmentDetailService.appointmentDetailService) {
            AppointmentDetailService.appointmentDetailService = new AppointmentDetailService();
        }
        return AppointmentDetailService.appointmentDetailService;
    };
    return AppointmentDetailService;
}(ApiService));
export default AppointmentDetailService;
