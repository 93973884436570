var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { classToPlain, plainToClass } from 'class-transformer';
import { Specialization, User, Album } from 'models';
import Model from 'models/Model';
var Doctor = /** @class */ (function (_super) {
    __extends(Doctor, _super);
    function Doctor() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Doctor.prototype.getId = function () {
        return Number(this.id);
    };
    Doctor.prototype.getNationalId = function () {
        return this.national_id;
    };
    Doctor.prototype.setNationalId = function (nationalId) {
        this.national_id = nationalId;
    };
    Doctor.prototype.getMartialStatus = function () {
        return this.marital_status;
    };
    Doctor.prototype.setMartialStatusId = function (maritialStatus) {
        this.marital_status = maritialStatus.toString();
    };
    Doctor.prototype.getDeletedAt = function () {
        return this.dateForamtter(new Date(this.deleted_at), 'DD/MM/YYYY hh:mm A');
    };
    Doctor.prototype.getUser = function () {
        return plainToClass(User, this.user);
    };
    Doctor.prototype.setUser = function (user) {
        this.user = classToPlain(user);
    };
    Doctor.prototype.getSpecialization = function () {
        return plainToClass(Specialization, this.specialization);
    };
    Doctor.prototype.setSpecialization = function (specialization) {
        this.specialization = specialization;
    };
    Doctor.prototype.getSpecializationId = function () {
        return Number(this.specialization_id);
    };
    Doctor.prototype.getAlbum = function () {
        return plainToClass(Album, this.album);
    };
    return Doctor;
}(Model));
export default Doctor;
