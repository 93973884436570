import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Card, Col, Row, Space, Table, Tag } from 'antd';
import ChartCard from 'components/AntEnhancement/Statistics/ChartCard';
import StatisticCard from 'components/AntEnhancement/Statistics/StatisticCard';
import EmptyComponent from 'components/EmptyComponent';
import ReportHeader from 'components/PageHeader/ReportHeader';
import { AREA, COLUMN } from 'constants/chartTypes';
import localeTranslator from 'library/Hocs/LocalTranslator';
import services from 'services';
var PackageReports = function (props) {
    var _a;
    var reportService = services.reportService;
    var report = reportService.usePackageReport().data;
    var columns = [
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('doctor') }),
            key: 'doctor',
            render: function (text, record, index) {
                return _jsx("div", { className: "body---14pt-R", children: record.getPackageName() }, 'doctor-' + index);
            }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('totalTransactions') }),
            key: 'totalTransactions',
            render: function (text, record, index) {
                return _jsx("div", { className: "body---14pt-R table-body-content", children: record.getTotalTransactions() }, 'totalTransactions-' + index);
            }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('numberOfPatients') }),
            key: 'numberOfPatients',
            render: function (text, record, index) {
                return _jsx("div", { className: "body---14pt-R table-body-content", children: record.getTotalPatients() }, 'numberOfPatients-' + index);
            }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('totalReservations') }),
            key: 'totalReservations',
            render: function (text, record, index) {
                return _jsx("div", { className: "body---14pt-R table-body-content", children: record.getTotalPackageReservations() }, 'totalReservations-' + index);
            }
        },
    ];
    return (_jsxs(_Fragment, { children: [_jsx(ReportHeader, { title: props.translate('title') }), _jsx("div", { id: "profile-content", className: "page-content", style: { marginTop: '20px' }, children: report &&
                    _jsx(_Fragment, { children: _jsxs(Space, { direction: 'vertical', size: 25, children: [_jsxs(Row, { gutter: [25, 25], children: [_jsx(Col, { xs: 24, sm: 24, lg: 6, xl: 6, children: _jsx(StatisticCard, { title: props.translate('doctors'), value: report.getTotalPackages() }) }), _jsx(Col, { xs: 24, sm: 24, lg: 6, xl: 6, children: _jsx(StatisticCard, { title: props.translate('totalPackagePricesCount'), value: report.getTotalPackagePrices() }) }), _jsx(Col, { xs: 24, sm: 24, lg: 6, xl: 6, children: _jsx(StatisticCard, { title: props.translate('totalPackageReservations'), value: report.getTotalPackagreservations() }) }), _jsx(Col, { xs: 24, sm: 24, lg: 6, xl: 6, children: _jsx(StatisticCard, { title: props.translate('transactions'), value: report.getTotalTransactions() }) })] }), _jsxs(Row, { gutter: [25, 25], children: [_jsx(Col, { xs: 24, sm: 24, lg: 12, xl: 12, children: _jsx(ChartCard, { data: report.getReportsPerMonth(), xAxis: 'report_date', yAxis: 'packages_count', chartType: AREA, title: props.translate('numberOfPackages') }) }), _jsx(Col, { xs: 24, sm: 24, lg: 12, xl: 12, children: _jsx(ChartCard, { data: report.getReportsPerMonth(), xAxis: 'report_date', yAxis: 'appointments_count', chartType: COLUMN, title: props.translate('numberOfAppointments') }) })] }), _jsx(Row, { gutter: [25, 25], children: _jsx(Col, { xl: 24, children: _jsx(ChartCard, { data: report.getReportsPerMonth(), xAxis: 'report_date', yAxis: 'total_transactions', chartType: AREA, title: props.translate('transactions') }) }) }), _jsxs(Card, { children: [_jsxs("div", { className: "Header-18-M title", children: [props.translate('packages'), " ", _jsx(Tag, { bordered: false, color: "purple", children: report.getTotalPackages() })] }), _jsx(Table, { columns: columns, className: 'Header-R-14', pagination: {
                                                position: ['bottomRight'],
                                                pageSize: 25,
                                                total: (_a = report.getReportsPerPackage()) === null || _a === void 0 ? void 0 : _a.length,
                                                showSizeChanger: false,
                                                responsive: true
                                            }, 
                                            // scroll={{ x: 1300 }}
                                            dataSource: report.getReportsPerPackage(), locale: {
                                                emptyText: _jsx(EmptyComponent, {})
                                            } })] })] }) }) })] }));
};
export default localeTranslator(PackageReports, 'packageReports');
