import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Row, Col } from 'antd';
import localeTranslator from 'library/Hocs/LocalTranslator';
import { FormInput, FormDatePicker, FormSelect } from 'components/AntEnhancement/FormControls';
import './style.css';
import dayjs from 'dayjs';
var BasicForm = function (props) {
    var onChangeFirstName = function (event) {
        props.patient.setFirstName(event.target.value);
    };
    var onChangeMiddleName = function (event) {
        props.patient.setMiddleName(event.target.value);
    };
    var onChangeLastName = function (event) {
        props.patient.setLastName(event.target.value);
    };
    var onChangeCode = function (event) { return props.patient.setCode(event.target.value); };
    var onChangePrimaryPhone = function (event) {
        props.patient.setPrimaryPhone(event.target.value);
    };
    var onChangeSecondaryPhone = function (event) {
        props.patient.setSecondaryPhone(event.target.value);
    };
    var onChangeBirthDate = function (birthdate, dateString) {
        birthdate && props.patient.setBirthdate(birthdate.toDate());
    };
    var onChangeGender = function (value) { return props.patient.setGender(value.toString()); };
    var onChangeArea = function (value) { return props.patient.setPatientAreaId(Number(value)); };
    var getAreaOptions = function () {
        return props.patientAreas.map(function (patientArea) { return ({
            value: patientArea.getId(),
            title: patientArea.getName()
        }); });
    };
    var getGenderOptions = function () {
        return [
            { value: 'male', title: props.translate('common:gender.male') },
            { value: 'female', title: props.translate('common:gender.female') }
        ];
    };
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "Header-18-M form-title", children: props.translate('personalInfomation') }), _jsxs("div", { className: "form-part", children: [_jsxs(Row, { children: [_jsx(Col, { xs: 24, sm: 24, lg: 7, xl: 7, children: _jsx(FormInput, { label: props.translate('firstName'), name: "firstName", placeHolder: props.translate('firstName'), rules: [
                                        { required: true, message: props.translate('errors.firstName') }
                                    ], onChange: onChangeFirstName, initialValue: props.patient.getFirstName() }) }), _jsx(Col, { xs: 0, sm: 0, lg: 1, xl: 1 }), _jsx(Col, { xs: 24, sm: 24, lg: 7, xl: 7, children: _jsx(FormInput, { label: props.translate('middleName'), name: "middleName", placeHolder: props.translate('middleName'), rules: [
                                        { required: true, message: props.translate('errors.middleName') }
                                    ], onChange: onChangeMiddleName, initialValue: props.patient.getSecondName() }) }), _jsx(Col, { xs: 0, sm: 0, lg: 1, xl: 1 }), _jsx(Col, { xs: 24, sm: 24, lg: 8, xl: 8, children: _jsx(FormInput, { label: props.translate('lastName'), name: "lastName", placeHolder: props.translate('lastName'), rules: [
                                        { required: true, message: props.translate('errors.lastName') }
                                    ], onChange: onChangeLastName, initialValue: props.patient.getThirdName() }) })] }), _jsxs(Row, { children: [_jsx(Col, { xs: 24, sm: 24, lg: 7, xl: 7, children: _jsx(FormInput, { label: props.translate('primaryPhone'), name: "primaryPhone", placeHolder: props.translate('primaryPhone'), rules: [
                                        { required: true, message: props.translate('errors.primaryPhone') }
                                    ], onChange: onChangePrimaryPhone, initialValue: props.patient.getPrimaryPhone() }) }), _jsx(Col, { xs: 0, sm: 0, lg: 1, xl: 1 }), _jsx(Col, { xs: 24, sm: 24, lg: 7, xl: 7, children: _jsx(FormDatePicker, { label: props.translate('birthdate'), name: "birthdate", rules: [
                                        { required: true, message: props.translate('errors.birthDate') }
                                    ], placeHolder: props.translate('selectDate'), onChange: onChangeBirthDate, initialValue: dayjs(props.patient.getBirthdate()) }) }), _jsx(Col, { xs: 0, sm: 0, lg: 1, xl: 1 }), _jsx(Col, { xs: 24, sm: 24, lg: 8, xl: 8, children: _jsx(FormSelect, { label: props.translate('chooseGender'), name: "gender", rules: [
                                        { required: true, message: props.translate('errors.gender') }
                                    ], placeHolder: props.translate('chooseGender'), onSelect: onChangeGender, options: getGenderOptions(), initialValue: props.patient.getGender() || null }) })] }), _jsxs(Row, { children: [_jsx(Col, { xs: 24, sm: 24, lg: 7, xl: 7, children: _jsx(FormInput, { label: props.translate('secondayPhone'), name: "secondayPhone", placeHolder: props.translate('secondayPhone'), rules: [], onChange: onChangeSecondaryPhone, requiredMark: 'optional', initialValue: props.patient.getSecondaryPhone() }) }), _jsx(Col, { xs: 0, sm: 0, lg: 1, xl: 1 }), _jsx(Col, { xs: 24, sm: 24, lg: 7, xl: 7, children: _jsx(FormInput, { label: props.translate('code'), name: "code", placeHolder: props.translate('code'), rules: [], onChange: onChangeCode, requiredMark: 'optional', initialValue: props.patient.getCode() }) }), _jsx(Col, { xs: 0, sm: 0, lg: 1, xl: 1 }), _jsx(Col, { xs: 24, sm: 24, lg: 8, xl: 8, children: _jsx(FormSelect, { label: props.translate('chooseArea'), name: "area", requiredMark: 'optional', rules: [], placeHolder: props.translate('chooseArea'), onSelect: onChangeArea, options: getAreaOptions(), initialValue: props.patient.getPatientAreaId() || null }) })] })] })] }));
};
export default localeTranslator(BasicForm, 'patientForm');
