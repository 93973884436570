import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import Icon from '@ant-design/icons';
var FilledUserIcon = function () {
    var Image = function () {
        return _jsx("svg", { xmlns: "http://www.w3.org/2000/svg", width: "1.5em", height: "1.5em", fill: "currentColor", viewBox: "0 0 25 25", focusable: "false", "data-icon": "user", "aria-hidden": "true", children: _jsxs("g", { id: "frame", children: [_jsx("path", { id: "Vector", d: "M4.75 0a4.746 4.746 0 0 0-.12 9.49.807.807 0 0 1 .22 0h.07A4.746 4.746 0 0 0 4.75 0z", transform: "translate(7.25 2)" }), _jsx("path", { id: "Vector-2", "data-name": "Vector", d: "M12.12 1.395a9.929 9.929 0 0 0-10.15 0A3.947 3.947 0 0 0 0 4.625a3.914 3.914 0 0 0 1.96 3.21 9.239 9.239 0 0 0 5.08 1.41 9.239 9.239 0 0 0 5.08-1.41 3.945 3.945 0 0 0 1.96-3.23 3.937 3.937 0 0 0-1.96-3.21z", transform: "translate(4.96 12.755)" })] }) });
    };
    return (_jsx(Icon, { component: Image }));
};
export default memo(FilledUserIcon);
