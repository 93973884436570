var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import Model from 'models/Model';
import { Account, Document, DocumentLineGroup } from 'models';
import { plainToInstance } from 'class-transformer';
var DocumentLine = /** @class */ (function (_super) {
    __extends(DocumentLine, _super);
    function DocumentLine() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DocumentLine.prototype.getId = function () {
        return Number(this.id);
    };
    DocumentLine.prototype.getEntityType = function () {
        return this.entity_type;
    };
    DocumentLine.prototype.getDisplayedEntityType = function () {
        return this.displayed_entity_type;
    };
    DocumentLine.prototype.setEntityType = function (entityType) {
        this.entity_type = entityType;
    };
    DocumentLine.prototype.getTransactionEntryType = function () {
        return this.transaction_entry_type;
    };
    DocumentLine.prototype.getDisplayedTransactionEntryType = function () {
        return this.displayed_transaction_entry_type;
    };
    DocumentLine.prototype.setTransactionEntryType = function (transactionEntryType) {
        this.transaction_entry_type = transactionEntryType;
    };
    DocumentLine.prototype.getProportion = function () {
        return Number(this.proportion);
    };
    DocumentLine.prototype.setProportion = function (proportion) {
        this.proportion = proportion;
    };
    DocumentLine.prototype.getAccount = function () {
        return plainToInstance(Account, this.account);
    };
    DocumentLine.prototype.getAccountId = function () {
        return Number(this.account_id);
    };
    DocumentLine.prototype.setAccountId = function (accountId) {
        this.account_id = accountId;
    };
    DocumentLine.prototype.getDocument = function () {
        return plainToInstance(Document, this.document);
    };
    DocumentLine.prototype.getDocumentId = function () {
        return Number(this.document_id);
    };
    DocumentLine.prototype.setDocumentId = function (documentId) {
        this.document_id = documentId;
    };
    DocumentLine.prototype.getDocumentLineGroup = function () {
        return plainToInstance(DocumentLineGroup, this.document_line_group);
    };
    DocumentLine.prototype.getDocumentLineGroupId = function () {
        return Number(this.document_line_group_id);
    };
    DocumentLine.prototype.setDocumentLineGroupId = function (documentLineGroupId) {
        this.document_line_group_id = Number(documentLineGroupId);
    };
    return DocumentLine;
}(Model));
export default DocumentLine;
