import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Row, Col, Form, Input, Checkbox, Divider } from 'antd';
import localeTranslator from 'library/Hocs/LocalTranslator';
import { FormInputNumber, FormDatePicker, FormSelect } from 'components/AntEnhancement/FormControls';
var PackagePriceForm = function (props) {
    var onChangeQuantity = function (quantity) { return quantity && props.packagePrice.setQuantity(quantity); };
    var onChangePrice = function (price) { return price && props.packagePrice.setPrice(price); };
    var onChangePriceType = function (value) { return props.packagePrice.setPriceType(value.toString()); };
    var onChangeStartedAt = function (startedAt) {
        startedAt && props.packagePrice.setStartedAt(startedAt.toDate());
    };
    var onChangeFinishedAt = function (finishedAt) {
        finishedAt && props.packagePrice.setFinishedAt(finishedAt.toDate());
    };
    var onChangeIsEnable = function (event) {
        props.packagePrice.setIsEnabled(event.target.checked);
    };
    var onChangeUnitMeasurement = function (value) { return props.packagePrice.setUnitMeasurementId(Number(value)); };
    var getUnitMeasurementOptions = function () { var _a; return ((_a = props.unitMeasurements) === null || _a === void 0 ? void 0 : _a.map(function (um) { return ({ value: um.getId(), title: um.getName() }); })) || []; };
    var getPriceTypeOptions = function () {
        return [
            { value: 'unit_price', title: props.translate('common:priceType.unitPrice') },
            { value: 'total_price', title: props.translate('common:priceType.totalPrice') },
        ];
    };
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "Header-18-M form-title", children: props.translate('quantityPrice') }), _jsxs(Row, { children: [_jsx(Col, { xs: 24, sm: 24, lg: 11, xl: 11, children: _jsx(Form.Item, { label: props.translate('quantity'), required: true, children: _jsxs(Input.Group, { compact: true, children: [_jsx(FormInputNumber, { formClasses: "form-item body---14pt-R input-group-size group-input", inputClasses: 'group-input-border number-input', name: "quantity", rules: [
                                            { required: true, message: props.translate('errors.quantity') }
                                        ], placeHolder: props.translate('quantity'), onChange: onChangeQuantity, min: 1 }), _jsx(FormSelect, { formClasses: "form-item body---14pt-R select-group-size group-input", selectClasses: "form-item select-border", name: "unitMeasurement", rules: [
                                            { required: true, message: props.translate('errors.unitMeasurement') }
                                        ], placeHolder: props.translate('unitMeasurement'), onSelect: onChangeUnitMeasurement, options: getUnitMeasurementOptions() })] }) }) }), _jsx(Col, { xs: 0, sm: 0, lg: 1, xl: 1 }), _jsx(Col, { xs: 24, sm: 24, lg: 12, xl: 12, children: _jsx(Form.Item, { label: props.translate('price'), required: true, children: _jsxs(Input.Group, { compact: true, children: [_jsx(FormInputNumber, { formClasses: "form-item body---14pt-R input-group-size group-input", inputClasses: 'group-input-border number-input', name: "price", rules: [
                                            { required: true, message: props.translate('errors.price') }
                                        ], placeHolder: props.translate('price'), onChange: onChangePrice, min: 0 }), _jsx(FormSelect, { formClasses: "form-item body---14pt-R select-group-size group-input", selectClasses: "form-item select-border", name: "priceType", rules: [
                                            { required: true, message: props.translate('errors.priceType') }
                                        ], placeHolder: props.translate('priceType'), onSelect: onChangePriceType, options: getPriceTypeOptions() })] }) }) })] }), _jsx(Divider, {}), _jsx("div", { className: "Header-18-M form-title", children: props.translate('availabilityInfo') }), _jsxs(Row, { children: [_jsx(Col, { xs: 24, sm: 24, lg: 11, xl: 11, children: _jsx(FormDatePicker, { label: props.translate('startDate'), name: "startDate", rules: [
                                { required: true, message: props.translate('errors.startDate') }
                            ], placeHolder: props.translate('selectDate'), onChange: onChangeStartedAt }) }), _jsx(Col, { xs: 0, sm: 0, lg: 1, xl: 1 }), _jsx(Col, { xs: 24, sm: 24, lg: 12, xl: 12, children: _jsx(FormDatePicker, { label: props.translate('endDate'), name: "endDate", rules: [], placeHolder: props.translate('selectDate'), onChange: onChangeFinishedAt }) })] }), _jsx(Row, { children: _jsx(Form.Item, { className: "form-item body---14pt-R", children: _jsx(Checkbox, { onChange: onChangeIsEnable, defaultChecked: props.packagePrice.getIsEnabled(), children: props.translate('isEnabled') }) }) })] }));
};
export default localeTranslator(PackagePriceForm, 'packagePriceForm');
