import { jsx as _jsx } from "react/jsx-runtime";
import Suppliers from 'pages/inventory/Suppliers';
import ShowSupplier from 'pages/inventory/ShowSupplier';
import NewSupplier from 'pages/inventory/NewSupplier';
import { Route } from 'react-router';
import AuthorizationService from '../AuthorizationService';
import { Create, Index, Show } from 'constants/actions';
import { Supplier as SupplierResource } from 'constants/resources';
var SuppliersRoutes = function () {
    return ([
        _jsx(Route, { caseSensitive: true, path: '/suppliers', element: AuthorizationService.getAuthorizedElement(_jsx(Suppliers, {}), Index, SupplierResource) }, 'suppliers-route'),
        _jsx(Route, { caseSensitive: true, path: '/suppliers/new', element: AuthorizationService.getAuthorizedElement(_jsx(NewSupplier, {}), Create, SupplierResource) }, 'new-suppliers-route'),
        _jsx(Route, { caseSensitive: true, path: '/suppliers/:id', element: AuthorizationService.getAuthorizedElement(_jsx(ShowSupplier, {}), Show, SupplierResource) }, 'show-suppliers-route')
    ]);
};
export default SuppliersRoutes;
