import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-empty-function */
import { Row, Col, Button } from 'antd';
import VisitPackageCard from 'components/cards/VisitPackageCard';
import localeTranslator from 'library/Hocs/LocalTranslator';
import { useState } from 'react';
import { useParams } from 'react-router';
import '../../style.css';
var PackagesInfo = function (props) {
    var id = useParams().id;
    var _a = useState(), reservationPackage = _a[0], setReservationPackage = _a[1];
    var _b = useState(0), settlementDrawerKey = _b[0], setSettlementDrawerKey = _b[1];
    var _c = useState(false), showSettlementDrawer = _c[0], setShowSettlementDrawer = _c[1];
    var onCloseSettlementDrawer = function () {
        setShowSettlementDrawer(false);
        setSettlementDrawerKey(settlementDrawerKey + 1);
    };
    var _d = useState(0), packageUsageDrawerKey = _d[0], setPackageUsageDrawerKey = _d[1];
    var _e = useState(false), showPackageUsageDrawer = _e[0], setShowPackageUsageDrawer = _e[1];
    var onClosePackageUsageDrawer = function () {
        setShowPackageUsageDrawer(false);
        setPackageUsageDrawerKey(packageUsageDrawerKey + 1);
    };
    // const {reservationVisitService} = services;
    // const {mutate: unassignPackage} = reservationVisitService.useUnassignPackageToVisit();
    // const reservationPackagesById = props.appointment.getAppointmentDetails()
    //   .map(detail => detail.getReservationPackage())
    //   .reduce<Record<number, ReservationPackage>>((obj, p) => ({...obj, [p.getId()]: p}), {});
    // const reservationPackagesPrices = props.visit.getReservationVisits()
    //   .reduce<Record<number, Record<number, number>>>((rvobj, rv) => {
    //     const packageTransactions = rv.getDetails().getPackageIds().reduce<Record<number, number>>((obj, packageId) => {
    //       const reservationPackage = props.reservationPackages.find(rp =>
    //         rp.getPackageId() === packageId && rp.getReservationId() === rv.getReservationId());
    //       const packageRepeated = reservationPackage?.getRepeated() || 1;
    //       // const packgePrice = packagesById[packageId].getTotalPrice() * packageRepeated;
    //       const packgePrice = 0;
    //       return{
    //         ...obj,
    //         [packageId]: packgePrice
    //       };
    //     }, {});
    //     return {
    //       ...rvobj,
    //       [rv.getReservationId()]: packageTransactions
    //     };
    //   }, {});
    var getPackageRemainderMoney = function (packageId, reservationId) {
        // const reservationVisit = props.visit.getReservationVisits()
        //   .find(rv => rv.getDetails().getPackageIds().includes(packageId) && rv.getReservationId() === reservationId);
        // const debits = props.reservationsTransactions
        //   .filter(rt => reservationVisit?.getReservationId() === rt.getReservationId() && packageId === rt.getPackageId())
        //   .map(rt => rt.getDebit());
        // return debits.length > 0 ? Math.min(...debits) : reservationPackagesPrices[reservationId][packageId];
    };
    var openPackageDrawer = function () { return props.setShowPackagesDrawer(true); };
    // const onSettlementPress = (reservationPackage: ReservationPackage) => {
    //   setShowSettlementDrawer(true);
    //   setReservationPackage(reservationPackage);
    // };
    // const getSettlements = (): Settlement[] => {
    //   return props.visit.getSettlements().filter(settlement => {
    //     return settlement.getReservationId() === reservationPackage?.getReservationId() &&
    //       settlement.getPackageId() === reservationPackage?.getPackageId();
    //   });
    // };
    // const onAddPackageUsage = (reservationPackage: ReservationPackage) => {
    //   setShowPackageUsageDrawer(true);
    //   setReservationPackage(reservationPackage);
    // };
    // const onUnassign = (reservationPackage: ReservationPackage) => {
    //   const visitDetail = getAppointmentDetail(reservationPackage.getId());
    //   !!visitDetail?.getId() && unassignPackage({
    //     reservationVisitId: Number(id),
    //     successMessage: props.translate('packagesInfo.packageUnAssginedSuccess'),
    //     visitDetailId: visitDetail.getId()
    //   });
    // };
    // const getAppointmentDetail = (reservationPackageId: number): AppointmentDetail | undefined =>
    //   props.appointment.getAppointmentDetails().find(detail =>
    //     detail.getReservationPackageId() === reservationPackageId
    //   );
    return (_jsxs(_Fragment, { children: [_jsxs(Row, { id: "packages-header", children: [_jsx(Col, { xs: 24, lg: 10, xl: 10, children: _jsx("div", { className: "Header-20-bold", children: props.translate('packagesInfo.title') }) }), _jsx(Col, { xs: 24, lg: 6, xl: 10 }), _jsx(Col, { xs: 24, lg: 8, xl: 4, children: _jsxs(Button, { type: "link", className: "Header-R-14", onClick: openPackageDrawer, children: [props.translate('packagesInfo.addPackages'), " +"] }) })] }), _jsx("div", { id: "profile-content", children: _jsx(Row, { children: props.appointment.getAppointmentDetails().map(function (detail) {
                        // const p = packagesById[reservationPackage.getPackageId()];
                        // reservationPackage.setPackage(p);
                        // const visitDetail = getVisitDetail(reservationPackage.getReservationId(), p.getId());
                        // visitDetail?.setPackage(p);
                        return (_jsxs(_Fragment, { children: [_jsx(Col, { xs: 24, lg: 7, children: _jsx(VisitPackageCard, { reservationPackage: detail.getReservationPackage(), 
                                        // moneyRemainder={getPackageRemainderMoney(p.getId(), reservationPackage.getReservationId())}
                                        moneyRemainder: 500, onDeselectPackage: function () { }, onSelectPackage: function () { }, backgroundClass: "normal-card-color", isSelected: false, onSettlementPress: undefined, 
                                        // onAddUsagePress={p.getIsQuantitive() ? onAddPackageUsage : undefined }
                                        onAddUsagePress: undefined, 
                                        // onUnassignPress={visitDetail?.canBeUnAssigned() ? onUnassign : undefined}
                                        onUnassignPress: undefined }) }), _jsx(Col, { xs: 24, lg: 1 })] }));
                    }) }) })] }));
};
export default localeTranslator(PackagesInfo, 'showVisit');
