var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { instanceToPlain, plainToInstance } from 'class-transformer';
import queries from 'constants/queries';
import { MedicalTest } from 'models';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import ApiService from './ApiService';
var MedicalTestService = /** @class */ (function (_super) {
    __extends(MedicalTestService, _super);
    function MedicalTestService() {
        var _this = _super.call(this) || this;
        _this.medicalTestsRoot = 'medical_analyses';
        _this.successCreationMessage = '';
        return _this;
    }
    MedicalTestService.prototype.useCreateMedicalTests = function () {
        var _this = this;
        var queryClient = useQueryClient();
        var patientId;
        return useMutation(function (_a) {
            var medicalTest = _a.medicalTest, successMessage = _a.successMessage;
            patientId = medicalTest.getPatientId();
            _this.successCreationMessage = successMessage;
            var data = { medical_analyses: instanceToPlain(medicalTest) };
            return _this.post(_this.medicalTestsRoot, { data: data });
        }, {
            onSuccess: function () {
                queryClient.invalidateQueries([queries.patients, patientId, queries.medicalTests]);
                _this.dipatachCreationSuccess({ successMessage: _this.successCreationMessage });
            }
        });
    };
    MedicalTestService.prototype.usePatientMedicalTests = function (patientId) {
        var _this = this;
        return useQuery([queries.patients, patientId, queries.medicalTests], function () {
            return _this.get(_this.medicalTestsRoot, {
                patient_id: patientId
            });
        }, {
            keepPreviousData: true,
            select: function (_a) {
                var data = _a.data;
                return plainToInstance(MedicalTest, data);
            }
        });
    };
    MedicalTestService.getInstance = function () {
        if (!MedicalTestService.medicalTestService) {
            MedicalTestService.medicalTestService = new MedicalTestService();
        }
        return MedicalTestService.medicalTestService;
    };
    return MedicalTestService;
}(ApiService));
export default MedicalTestService;
