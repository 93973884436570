// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.body-layout {
  min-height: '100vh';
}

.site-content-layout {
  margin-inline-start: 300px;
}
.site-content-collapsed-layout {
  margin-inline-start: 80px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Layout/style.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,0BAA0B;AAC5B;AACA;EACE,yBAAyB;AAC3B","sourcesContent":[".body-layout {\n  min-height: '100vh';\n}\n\n.site-content-layout {\n  margin-inline-start: 300px;\n}\n.site-content-collapsed-layout {\n  margin-inline-start: 80px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
