import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Divider, Space } from 'antd';
import Loader from 'components/AntEnhancement/Loader';
import Branches from 'pages/inventory/ShowSupplier/components/Tables/Branches';
import MainInfo from 'pages/inventory/ShowSupplier/components/Informations/MainInfo';
import { useParams } from 'react-router';
import services from 'services';
import Header from 'pages/inventory/ShowSupplier/components/Header';
import localeTranslator from 'library/Hocs/LocalTranslator';
import './style.css';
var ShowSupplier = function () {
    var id = useParams().id;
    var supplierService = services.supplierService;
    var _a = supplierService.useSupplier(Number(id)), supplier = _a.data, isSupplierLoading = _a.isLoading;
    return (_jsx("div", { className: "loader-container-height", children: _jsx(Loader, { renderSpinner: isSupplierLoading, children: _jsxs("div", { children: [_jsxs(Space, { direction: 'vertical', size: 50, children: [_jsx("div", { className: "page-header add-padding", children: supplier && _jsx(Header, { supplier: supplier }) }), _jsx("div", { className: "new-pages-content", children: _jsx(Space, { direction: "vertical", size: 24, children: supplier && _jsx(MainInfo, { supplier: supplier }) }) })] }), _jsx(Divider, {}), supplier && _jsx(Branches, { supplier: supplier })] }) }) }));
};
export default localeTranslator(ShowSupplier, 'showSupplier');
