import { jsx as _jsx } from "react/jsx-runtime";
import { Row, Col } from 'antd';
import localeTranslator from 'library/Hocs/LocalTranslator';
import { FormSelect } from 'components/AntEnhancement/FormControls';
import services from 'services';
var Products = function (props) {
    var productService = services.productService;
    var products = productService.useMiniProducts().data;
    var onChangeEntity = function (value) { return props.package.setEntityId(Number(value)); };
    var getProducts = function () {
        return (products === null || products === void 0 ? void 0 : products.map(function (product) { return ({
            value: product.getId(),
            title: product.getName()
        }); })) || [];
    };
    return (_jsx(Row, { children: _jsx(Col, { xs: 24, sm: 24, lg: 11, xl: 11, children: _jsx(FormSelect, { label: props.packageElementTitle, name: "packageElementTitle", rules: [
                    { required: true, message: props.translate("errors.".concat(props.packageElementTitle)) }
                ], placeHolder: props.packageElementTitle, onSelect: onChangeEntity, options: getProducts() }) }) }));
};
export default localeTranslator(Products, 'newPackage');
