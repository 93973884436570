import { jsx as _jsx } from "react/jsx-runtime";
import Products from 'pages/inventory/Products';
import ShowProduct from 'pages/inventory/ShowProduct';
import NewProduct from 'pages/inventory/NewProduct';
import { Route } from 'react-router';
import AuthorizationService from '../AuthorizationService';
import { Create, Index, Show } from 'constants/actions';
import { Product as ProductResource } from 'constants/resources';
var ProductsRoutes = function () {
    return ([
        _jsx(Route, { caseSensitive: true, path: '/products', element: AuthorizationService.getAuthorizedElement(_jsx(Products, {}), Index, ProductResource) }, 'products-route'),
        _jsx(Route, { caseSensitive: true, path: '/products/new', element: AuthorizationService.getAuthorizedElement(_jsx(NewProduct, {}), Create, ProductResource) }, 'new-products-route'),
        _jsx(Route, { caseSensitive: true, path: '/products/:id', element: AuthorizationService.getAuthorizedElement(_jsx(ShowProduct, {}), Show, ProductResource) }, 'show-product-route')
    ]);
};
export default ProductsRoutes;
