import { jsx as _jsx } from "react/jsx-runtime";
import Transactions from 'pages/accounting/Transactions';
import ShowTransaction from 'pages/accounting/ShowTransaction';
import NewTransaction from 'pages/accounting/NewTransaction';
import { Route } from 'react-router';
import AuthorizationService from '../AuthorizationService';
import { Create, Index, Show } from 'constants/actions';
import { Transaction as TransacitonResource } from 'constants/resources';
var TransactionRoutes = function () {
    return ([
        _jsx(Route, { caseSensitive: true, path: '/transactions', element: AuthorizationService.getAuthorizedElement(_jsx(Transactions, {}), Index, TransacitonResource) }, 'transactions-route'),
        _jsx(Route, { caseSensitive: true, path: '/transactions/expenses/new', element: AuthorizationService.getAuthorizedElement(_jsx(NewTransaction, {}), Create, TransacitonResource) }, 'new-accounts-route'),
        _jsx(Route, { caseSensitive: true, path: '/transactions/:id', element: AuthorizationService.getAuthorizedElement(_jsx(ShowTransaction, {}), Show, TransacitonResource) }, 'show-transactions-route')
    ]);
};
export default TransactionRoutes;
