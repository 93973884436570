import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Row, Col } from 'antd';
import localeTranslator from 'library/Hocs/LocalTranslator';
import { FormInput } from 'components/AntEnhancement/FormControls';
import './style.css';
var DocumentForm = function (props) {
    var onChangeName = function (event) {
        props.document.setName(event.target.value);
    };
    return (_jsx(_Fragment, { children: _jsx("div", { className: "form-part", children: _jsx(Row, { children: _jsx(Col, { xs: 24, sm: 24, lg: 24, xl: 24, children: _jsx(FormInput, { label: props.translate('name'), name: "name", placeHolder: props.translate('name'), rules: [
                            { required: true, message: props.translate('errors.name') }
                        ], onChange: onChangeName, initialValue: props.document.getName() }) }) }) }) }));
};
export default localeTranslator(DocumentForm, 'documentForm');
