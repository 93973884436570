import { jsx as _jsx } from "react/jsx-runtime";
import StockMovements from 'pages/inventory/StockMovements';
import ShowStockMovement from 'pages/inventory/ShowStockMovement';
import { Route } from 'react-router';
import AuthorizationService from '../AuthorizationService';
import { Index, Show } from 'constants/actions';
import { StockMovement as StockMovementResource } from 'constants/resources';
var StockMovementsRoutes = function () {
    return ([
        _jsx(Route, { caseSensitive: true, path: '/stock_movements', element: AuthorizationService.getAuthorizedElement(_jsx(StockMovements, {}), Index, StockMovementResource) }, 'stock-movements-route'),
        _jsx(Route, { caseSensitive: true, path: '/stock_movements/:id', element: AuthorizationService.getAuthorizedElement(_jsx(ShowStockMovement, {}), Show, StockMovementResource) }, 'show-stock-movements-route'),
    ]);
};
export default StockMovementsRoutes;
