var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Row, Collapse } from 'antd';
import CardSkeleton from './card';
import './style.css';
var Panel = Collapse.Panel;
var HomeCard = function (props) {
    return (_jsx(_Fragment, { children: _jsx(Row, { className: "container", children: _jsx(Collapse, { ghost: true, children: _jsx(Panel, { header: _jsx(CardSkeleton, __assign({}, props)), showArrow: false, children: props.children }, new Date().getTime()) }) }) }));
};
export default HomeCard;
