// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.info-part {
  background-color: white;
  padding: 24px 36px 42px 36px;
  border-radius: 5px;
}

#profile-content {
  margin-top: 0px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ShowEmployee/style.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,4BAA4B;EAC5B,kBAAkB;AACpB;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".info-part {\n  background-color: white;\n  padding: 24px 36px 42px 36px;\n  border-radius: 5px;\n}\n\n#profile-content {\n  margin-top: 0px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
