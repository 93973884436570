var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { plainToClass } from 'class-transformer';
import { ReservationVisitDetails } from 'models';
import Model from 'models/Model';
var ReservationVisit = /** @class */ (function (_super) {
    __extends(ReservationVisit, _super);
    function ReservationVisit(reservationId) {
        var _this = _super.call(this) || this;
        _this.details = plainToClass(ReservationVisitDetails, {});
        reservationId && _this.setReservationId(reservationId);
        return _this;
    }
    ReservationVisit.prototype.getId = function () {
        return Number(this.id);
    };
    ReservationVisit.prototype.getReservationId = function () {
        return Number(this.reservation_id);
    };
    ReservationVisit.prototype.setReservationId = function (reservationId) {
        this.reservation_id = Number(reservationId);
    };
    ReservationVisit.prototype.getVisitId = function () {
        return this.visit_id;
    };
    ReservationVisit.prototype.setVisitId = function (visitId) {
        this.visit_id = visitId;
    };
    ReservationVisit.prototype.getClinicId = function () {
        return this.clinic_id;
    };
    ReservationVisit.prototype.setClinicId = function (clinicId) {
        this.clinic_id = clinicId;
    };
    ReservationVisit.prototype.getDetails = function () {
        return plainToClass(ReservationVisitDetails, this.details);
    };
    ReservationVisit.prototype.setDetails = function (details) {
        this.details = details;
    };
    return ReservationVisit;
}(Model));
export default ReservationVisit;
