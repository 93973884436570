var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { plainToClass } from 'class-transformer';
import { add as addToStorage, get as getFormStorage } from 'library/utilities/Storage';
import { Appointment } from 'models';
import moment from 'moment';
import { useQuery } from 'react-query';
import ApiService from './ApiService';
import queries from 'constants/queries';
var HomeService = /** @class */ (function (_super) {
    __extends(HomeService, _super);
    function HomeService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    HomeService.prototype.useGetDoctorHomeData = function (date, doctorId) {
        var _this = this;
        this.setHomeCurrentDate(date);
        return useQuery([queries.homeDoctorQueue, doctorId, date.startOf('day').toString(), date.endOf('day').toString()], function () {
            return _this.get("doctors/".concat(doctorId, "/appointments"), {
                from_date: date.startOf('day').toString(),
                to_date: date.endOf('day').toString()
            });
        }, {
            retry: false,
            select: function (_a) {
                var data = _a.data;
                return plainToClass(Appointment, data);
            }
        });
    };
    HomeService.prototype.setHomeCurrentDate = function (date) {
        addToStorage('homeDate', date.toString());
    };
    HomeService.prototype.getHomeCurrentDate = function () {
        return getFormStorage('homeDate') ? moment(getFormStorage('homeDate')) : moment();
    };
    HomeService.getInstance = function () {
        if (!HomeService.homeService) {
            HomeService.homeService = new HomeService();
        }
        return HomeService.homeService;
    };
    return HomeService;
}(ApiService));
export default HomeService;
