var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import queries from 'constants/queries';
import ApiService from './ApiService';
import { instanceToPlain, plainToInstance } from 'class-transformer';
import { Permission, User } from 'models';
import { add, get, clear as clearStorage } from 'library/utilities/Storage';
import { useTranslation } from 'react-i18next';
var UserService = /** @class */ (function (_super) {
    __extends(UserService, _super);
    function UserService() {
        var _this = _super.call(this) || this;
        _this.loginPath = 'users/login';
        _this.changeClinicPath = 'users/change_clinic';
        _this.successCreationMessage = '';
        _this.currentUserPath = 'users/me';
        _this.rootPath = 'users';
        return _this;
    }
    UserService.prototype.useLogin = function () {
        var _this = this;
        var queryClient = useQueryClient();
        return useMutation(function (_a) {
            var email = _a.email, password = _a.password, clinic_code = _a.clinic_code;
            var data = { email: email, password: password, clinic_code: clinic_code };
            return _this.post(_this.loginPath, { data: data });
        }, {
            onSuccess: function (response) {
                var data = response.data;
                var user = plainToInstance(User, data);
                add('token', user.getToken());
                add('userType', user === null || user === void 0 ? void 0 : user.getProfileType().toString());
                var lang = user.getLanguage() ? user.getLanguage() : 'en';
                add('lang', lang);
                add('user', JSON.stringify(user));
                add('permissions', JSON.stringify(user.getPermissions()));
                queryClient.setQueryData(queries.user, function () { return user; });
                queryClient.setQueryData(['Current-User-', queries.permissions], function () { return user.getPermissions(); });
                queryClient.setQueryData(queries.loginData, function () { return ({
                    token: user.getToken(),
                    userType: user === null || user === void 0 ? void 0 : user.getProfileType(),
                    lang: lang,
                    user: user
                }); });
                // window.location.reload();
            }
        });
    };
    UserService.prototype.useCurrentUser = function () {
        var queryClient = useQueryClient();
        return useQuery(queries.user, function () {
            var user_data = get('user');
            if (user_data) {
                user_data = JSON.parse(user_data);
                var user = plainToInstance(User, user_data);
                if (user) {
                    queryClient.prefetchQuery(['permissions', user.getId()]);
                }
                return user;
            }
        });
    };
    UserService.prototype.useCurrentUserPermissions = function () {
        return useQuery(['Current-User-', queries.permissions], function () {
            var permissions_data = get('permissions');
            if (permissions_data) {
                permissions_data = JSON.parse(permissions_data);
                var permissions = plainToInstance(Permission, permissions_data);
                return permissions;
            }
        });
    };
    UserService.prototype.useLoginData = function () {
        return useQuery(queries.loginData, function () {
            var token = get('token');
            var userType = Number(get('userType'));
            var lang = get('lang');
            return { token: token, userType: userType, lang: lang };
        });
    };
    UserService.prototype.useChangeLoggedInClinic = function () {
        var _this = this;
        var queryClient = useQueryClient();
        var navigate = useNavigate();
        return useMutation(function (_a) {
            var successMessage = _a.successMessage, clinic = _a.clinic;
            _this.successCreationMessage = successMessage;
            var data = { clinic: instanceToPlain(clinic) };
            return _this.post(_this.changeClinicPath, { data: data });
        }, {
            onSuccess: function (_a) {
                var data = _a.data;
                _this.dipatachCreationSuccess({ successMessage: _this.successCreationMessage });
                navigate('/');
                add('token', data.token);
                queryClient.invalidateQueries();
            }
        });
    };
    UserService.prototype.useLogout = function () {
        var queryClient = useQueryClient();
        var navigate = useNavigate();
        return useMutation(function () {
            clearStorage();
            queryClient.invalidateQueries();
            navigate('/');
        });
    };
    UserService.prototype.useChangeEmail = function () {
        var _this = this;
        var queryClient = useQueryClient();
        var t = useTranslation(['settings']).t;
        return useMutation(function (_a) {
            var user = _a.user;
            var currentPlainPatient = instanceToPlain(user);
            var data = { user: currentPlainPatient };
            return _this.put("".concat(_this.rootPath, "/").concat(user.getId(), "/change-email"), { data: data });
        }, {
            onSuccess: function (response) {
                var data = response.data;
                var user = plainToInstance(User, data);
                add('userType', user === null || user === void 0 ? void 0 : user.getProfileType().toString());
                var lang = user.getLanguage() ? user.getLanguage() : 'en';
                add('lang', lang);
                add('user', JSON.stringify(user));
                queryClient.setQueryData(queries.user, function () { return user; });
                _this.dipatachCreationSuccess({ successMessage: t('emailAndPassword.emailSuccess') });
            }
        });
    };
    UserService.prototype.useChangePassword = function () {
        var _this = this;
        var t = useTranslation(['settings']).t;
        return useMutation(function (_a) {
            var user = _a.user;
            var currentPlainPatient = instanceToPlain(user);
            var data = { user: currentPlainPatient };
            return _this.put("".concat(_this.rootPath, "/").concat(user.getId(), "/change-password"), { data: data });
        }, {
            onSuccess: function () {
                _this.dipatachCreationSuccess({ successMessage: t('emailAndPassword.passwordSuccess') });
            }
        });
    };
    UserService.prototype.hasPermission = function (action, resource) {
        var permissions = get('permissions');
        if (!permissions) {
            return false;
        }
        var formattedPermissions = plainToInstance(Permission, JSON.parse(permissions));
        var requiredPermission = formattedPermissions.filter(function (permission) {
            return permission.getResourceName() === resource && permission.getActionName() === action;
        });
        return requiredPermission && (requiredPermission === null || requiredPermission === void 0 ? void 0 : requiredPermission.length) > 0 ? true : false;
    };
    UserService.prototype.useUserPermissions = function (user) {
        var _this = this;
        var queryClient = useQueryClient();
        return useQuery(['userPermissions-', queries.permissions], function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!user) {
                            return [2 /*return*/, { status: 400, data: [new Permission()] }];
                        }
                        ;
                        return [4 /*yield*/, this.get("".concat(this.rootPath, "/").concat(user.getId(), "/permissions"))];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        }); }, {
            enabled: !!user,
            select: function (_a) {
                var data = _a.data;
                var permissions = plainToInstance(Permission, data);
                add('permissions', JSON.stringify(permissions));
                if (user) {
                    queryClient.setQueryData(['Current-User-', queries.permissions], function () { return user.getPermissions(); });
                }
                return permissions;
            },
            refetchInterval: 60000,
        });
    };
    UserService.prototype.useUsers = function () {
        var _this = this;
        return useQuery(queries.employees, function () {
            return _this.get(_this.rootPath);
        }, {
            select: function (response) {
                var data = response.data;
                return plainToInstance(User, data);
            }
        });
    };
    UserService.getInstance = function () {
        if (!UserService.userInstance) {
            UserService.userInstance = new UserService();
        }
        return UserService.userInstance;
    };
    return UserService;
}(ApiService));
export default UserService;
