import { jsx as _jsx } from "react/jsx-runtime";
import Documents from 'pages/accounting/Documents';
import ShowDocument from 'pages/accounting/ShowDocument';
import NewDocument from 'pages/accounting/NewDocument';
import { Route } from 'react-router';
import AuthorizationService from '../AuthorizationService';
import { Create, Index, Show } from 'constants/actions';
import { Document as DocumentResource } from 'constants/resources';
var DocumentsRoutes = function () {
    return ([
        _jsx(Route, { caseSensitive: true, path: '/documents', element: AuthorizationService.getAuthorizedElement(_jsx(Documents, {}), Index, DocumentResource) }, 'documents-route'),
        _jsx(Route, { caseSensitive: true, path: '/documents/new', element: AuthorizationService.getAuthorizedElement(_jsx(NewDocument, {}), Create, DocumentResource) }, 'new-accounts-route'),
        _jsx(Route, { caseSensitive: true, path: '/documents/:id', element: AuthorizationService.getAuthorizedElement(_jsx(ShowDocument, {}), Show, DocumentResource) }, 'show-documents-route')
    ]);
};
export default DocumentsRoutes;
