import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Row, Col } from 'antd';
import localeTranslator from 'library/Hocs/LocalTranslator';
import { FormInputNumber, FormSelect } from 'components/AntEnhancement/FormControls';
import './style.css';
var DocumentLineForm = function (props) {
    var onChangeProportion = function (proportion) { return proportion && props.documentLine.setProportion(Number(proportion)); };
    var onChangeEntityType = function (value) { return props.documentLine.setEntityType(String(value)); };
    var onChangeTransactionEntryType = function (value) { return props.documentLine.setTransactionEntryType(String(value)); };
    var onChangeAccounId = function (value) { return props.documentLine.setAccountId(Number(value)); };
    var getEntityTypes = function () {
        return props.entityTypes.map(function (entityType) { return ({
            value: entityType.getValue(),
            title: entityType.getName()
        }); });
    };
    var getTransactionTypes = function () {
        return props.transactionEntryTypes.map(function (transactionEntryType) { return ({
            value: transactionEntryType.getValue(),
            title: transactionEntryType.getName()
        }); });
    };
    var getAccounts = function () {
        return props.accounts.map(function (account) { return ({
            value: account.getId(),
            title: account.getName()
        }); });
    };
    return (_jsx(_Fragment, { children: _jsx("div", { className: "form-part", children: _jsxs(Row, { children: [_jsx(Col, { xs: 24, sm: 24, lg: 6, xl: 6, children: _jsx(FormSelect, { label: props.translate('account'), name: "account-".concat(props.identifier), requiredMark: true, rules: [
                                { required: true, message: props.translate('errors.account') }
                            ], placeHolder: props.translate('account'), onSelect: onChangeAccounId, options: getAccounts(), initialValue: props.documentLine.getAccountId() || null }, "account-".concat(props.identifier)) }, "account-".concat(props.identifier)), _jsx(Col, { xs: 24, sm: 24, lg: 1, xl: 1 }), _jsx(Col, { xs: 24, sm: 24, lg: 5, xl: 5, children: _jsx(FormSelect, { label: props.translate('transactionEntryType'), name: "transactionEntryType-".concat(props.identifier), requiredMark: true, rules: [
                                { required: true, message: props.translate('errors.transactionEntryType') }
                            ], placeHolder: props.translate('transactionEntryType'), onSelect: onChangeTransactionEntryType, options: getTransactionTypes(), initialValue: props.documentLine.getTransactionEntryType() || null }) }), _jsx(Col, { xs: 24, sm: 24, lg: 1, xl: 1 }), _jsx(Col, { xs: 24, sm: 24, lg: 5, xl: 5, children: _jsx(FormSelect, { label: props.translate('entityType'), name: "entityType-".concat(props.identifier), requiredMark: true, rules: [
                                { required: true, message: props.translate('errors.entityType') }
                            ], placeHolder: props.translate('entityType'), onSelect: onChangeEntityType, options: getEntityTypes(), initialValue: props.documentLine.getEntityType() || null }) }), _jsx(Col, { xs: 24, sm: 24, lg: 1, xl: 1 }), _jsx(Col, { xs: 24, sm: 24, lg: 5, xl: 5, children: _jsx(FormInputNumber, { label: props.translate('proportion'), name: "proportion-".concat(props.identifier), rules: [
                                { required: true, message: props.translate('errors.proportion') }
                            ], placeHolder: props.translate('proportion'), onChange: onChangeProportion, min: 0 }) })] }) }) }));
};
export default localeTranslator(DocumentLineForm, 'documentLineForm');
