// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader-container {
  display: flex;
  justify-content: center; 
  align-items: center; 
  width: 100%; 
  height: 100%;
}

.loader-container-height {
  height: 100vh;
}
`, "",{"version":3,"sources":["webpack://./src/components/AntEnhancement/Loader/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,aAAa;AACf","sourcesContent":[".loader-container {\n  display: flex;\n  justify-content: center; \n  align-items: center; \n  width: 100%; \n  height: 100%;\n}\n\n.loader-container-height {\n  height: 100vh;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
