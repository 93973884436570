// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.new-entity-page-header {
  background-color: white;
  height: 70px;
  padding: 28px 755px 5px 25px;
  width: auto;
  position: fixed;
  top: 0;
  right: 0;
  left: 300px;
  z-index: 2;
}

.ant-layout-rtl .new-entity-page-header {
  right: 300px;
  left: 0;
  padding: 28px 25px 5px 755px;
}
`, "",{"version":3,"sources":["webpack://./src/components/PageHeader/NewEntityHeader/style.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,YAAY;EACZ,4BAA4B;EAC5B,WAAW;EACX,eAAe;EACf,MAAM;EACN,QAAQ;EACR,WAAW;EACX,UAAU;AACZ;;AAEA;EACE,YAAY;EACZ,OAAO;EACP,4BAA4B;AAC9B","sourcesContent":[".new-entity-page-header {\n  background-color: white;\n  height: 70px;\n  padding: 28px 755px 5px 25px;\n  width: auto;\n  position: fixed;\n  top: 0;\n  right: 0;\n  left: 300px;\n  z-index: 2;\n}\n\n.ant-layout-rtl .new-entity-page-header {\n  right: 300px;\n  left: 0;\n  padding: 28px 25px 5px 755px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
