var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { classToPlain, plainToClass } from 'class-transformer';
import { Doctor, Package, Patient, ReservationPackage, ReservationTransaction, ReservationVisit, Transaction, User, Visit } from 'models';
import Model from 'models/Model';
var Reservation = /** @class */ (function (_super) {
    __extends(Reservation, _super);
    function Reservation() {
        var _this = _super.call(this) || this;
        _this.setPackages([]);
        _this.setReservationPackages([]);
        _this.setVisits([]);
        _this.setReservationTransactions([]);
        return _this;
    }
    Reservation.prototype.getId = function () {
        return Number(this.id);
    };
    Reservation.prototype.getCancellationReason = function () {
        return this.cancellation_reason;
    };
    Reservation.prototype.setCancellationReason = function (cancellationReason) {
        this.cancellation_reason = cancellationReason;
    };
    Reservation.prototype.getCancelledAt = function () {
        return new Date(this.cancelled_at);
    };
    Reservation.prototype.setCancelledAt = function (cancelledAt) {
        this.cancelled_at = cancelledAt.toString();
    };
    Reservation.prototype.getNote = function () {
        return this.note;
    };
    Reservation.prototype.setNote = function (note) {
        this.note = note;
    };
    Reservation.prototype.getIssuer = function () {
        return plainToClass(User, this.user);
    };
    Reservation.prototype.setIssuer = function (user) {
        this.user = classToPlain(user);
    };
    Reservation.prototype.getPatient = function () {
        return plainToClass(Patient, this.patient);
    };
    Reservation.prototype.setPatient = function (patient) {
        this.patient = classToPlain(patient);
    };
    Reservation.prototype.getDoctor = function () {
        return plainToClass(Doctor, this.doctor);
    };
    Reservation.prototype.setDoctor = function (doctor) {
        this.doctor = classToPlain(doctor);
    };
    Reservation.prototype.getPackages = function () {
        return plainToClass(Package, this.packages);
    };
    Reservation.prototype.setPackages = function (packages) {
        this.packages = packages;
    };
    Reservation.prototype.getReservationPackages = function () {
        return plainToClass(ReservationPackage, this.reservations_packages);
    };
    Reservation.prototype.setReservationPackages = function (reservationPackages) {
        this.reservations_packages = reservationPackages;
    };
    Reservation.prototype.addReservationPackage = function (reservationPackage) {
        this.reservations_packages.push(reservationPackage);
    };
    Reservation.prototype.removeReservationPackage = function (reservationPackageId, packageId) {
        if (reservationPackageId) {
            this.reservations_packages = this.reservations_packages.filter(function (rp) {
                return rp.getId() !== Number(reservationPackageId);
            });
        }
        if (packageId) {
            this.reservations_packages = this.reservations_packages.filter(function (rp) {
                return rp.getPackageId() !== Number(packageId);
            });
        }
    };
    Reservation.prototype.getVisits = function () {
        return plainToClass(Visit, this.visits);
    };
    Reservation.prototype.setVisits = function (visits) {
        this.visits = visits;
    };
    Reservation.prototype.getTransactions = function () {
        return plainToClass(Transaction, this.transactions);
    };
    Reservation.prototype.setTransactions = function (transactions) {
        this.transactions = transactions;
    };
    Reservation.prototype.addReservationTransaction = function (reservationTransaction) {
        this.reservation_transactions.push(reservationTransaction);
    };
    Reservation.prototype.getReservationTransactions = function () {
        return plainToClass(ReservationTransaction, this.reservation_transactions);
    };
    Reservation.prototype.setReservationTransactions = function (reservationTransaction) {
        this.reservation_transactions = reservationTransaction;
    };
    Reservation.prototype.getValidReservationTransactions = function () {
        return this.getReservationTransactions().filter(function (rt) { return !!rt.getTransaction().getAmount(); });
    };
    Reservation.prototype.getReservationVisits = function () {
        return plainToClass(ReservationVisit, this.reservation_visits);
    };
    Reservation.prototype.getReservationPrice = function () {
        return this.getReservationPackages().reduce(function (totalPrice, rp) {
            return totalPrice + rp.getTotalReservationPackagePrice();
        }, 0);
    };
    Reservation.prototype.getReservationTotalPaidAmount = function () {
        return this.getTransactions().reduce(function (totalPaid, t) { return totalPaid + t.getAmount(); }, 0);
    };
    return Reservation;
}(Model));
export default Reservation;
