import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Table } from 'antd';
import localeTranslator from 'library/Hocs/LocalTranslator';
import EmptyComponent from 'components/EmptyComponent';
import '../../style.css';
import './style.css';
var DocumentLines = function (props) {
    var columns = [
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('tables.documentLines.info.id') }),
            key: 'id',
            render: function (text, record) { return _jsx("div", { className: "body---14pt-R", children: record.getId() }); }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('tables.documentLines.info.account') }),
            key: 'account',
            render: function (text, record) { return _jsx("div", { className: "body---14pt-R", children: record.getAccount().getName() }); }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('tables.documentLines.info.entityType') }),
            key: 'entityType',
            render: function (text, record) { return _jsx("div", { className: "body---14pt-R", children: record.getDisplayedEntityType() }); }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('tables.documentLines.info.transactionEntryType') }),
            key: 'transactionEntryType',
            render: function (text, record) {
                return _jsx("div", { className: "body---14pt-R", children: record.getDisplayedTransactionEntryType() });
            }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('tables.documentLines.info.proportion') }),
            key: 'proportion',
            render: function (text, record) { return _jsx("div", { className: "body---14pt-R", children: record.getProportion() }); }
        }
    ];
    return (_jsxs("div", { className: "new-pages-sub-content", children: [_jsx("div", { className: "Header-18-M form-title", children: props.translate('tables.documentLines.header.documentLines') }), _jsx(Table, { columns: columns, pagination: false, dataSource: props.documentLineGroup.getDocumentLines(), locale: {
                    emptyText: _jsx(EmptyComponent, {})
                } })] }));
};
export default localeTranslator(DocumentLines, 'showDocument');
