import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { PageNotFoundImageSVG } from 'assets/icons';
import { useNavigate } from 'react-router';
import './style.css';
var PageNotFound = function () {
    var navigate = useNavigate();
    var goToHome = function () { return navigate('/home'); };
    return (_jsxs("div", { className: 'image-position', children: [_jsxs("div", { className: 'error-padding', children: [_jsx("div", { className: "Header-24-bold error-404-text", children: "Error 404" }), _jsx("div", { className: "Header-R-16 page-not-found-text centered-self", children: "Page Not Found" })] }), _jsxs("div", { className: 'go-to-home', children: [_jsx("div", { className: "Header-R-14", children: "Go Back For" }), _jsx("a", { className: "Header-R-14 home-padding", onClick: goToHome, children: "Home Page" })] }), _jsx(PageNotFoundImageSVG, {})] }));
};
export default PageNotFound;
