import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Button, Space, Form } from 'antd';
import services from 'services';
import localeTranslator from 'library/Hocs/LocalTranslator';
import { useParams } from 'react-router';
import Loader from 'components/AntEnhancement/Loader';
import AppointmentMetaInfoForm from 'components/forms/visits/AppointmentMetaInfoForm';
import AppointmentForm from 'components/forms/visits/AppointmentForm';
var UpdateAppointment = function (props) {
    var id = useParams().id;
    var clinicService = services.clinicService, doctorService = services.doctorService, appointmentService = services.appointmentService;
    var _a = useState(false), disabled = _a[0], setDisabled = _a[1];
    var updateAppointment = appointmentService.useUpdateAppointment().mutate;
    var appointment = appointmentService.useGetAppointment(Number(id)).data;
    var clinics = clinicService.useClinics().data;
    var doctors = doctorService.useDoctors().data;
    var form = Form.useForm()[0];
    var submit = function () {
        setDisabled(true);
        appointment && updateAppointment({ appointment: appointment });
    };
    var scrollToFirstElement = function () { return form.scrollToField('firstName', { behavior: 'smooth' }); };
    return (_jsx(Loader, { renderSpinner: !appointment, children: _jsxs("div", { style: { margin: 20, marginTop: 36 }, children: [_jsx("div", { className: "Header-24-bold title", children: props.translate('title') }), _jsxs(Form, { layout: 'vertical', form: form, initialValues: { layout: 'vertical' }, size: 'large', onFinish: submit, onFinishFailed: scrollToFirstElement, children: [appointment && doctors && clinics &&
                            _jsx(AppointmentMetaInfoForm, { appointment: appointment, doctors: doctors, clinics: clinics }), appointment &&
                            _jsx(AppointmentForm, { appointment: appointment, isRequireCollapse: false }), _jsx("div", { className: "hidden-divider" }), _jsx("div", { className: "hidden-divider" }), _jsx(Space, { children: _jsx(Form.Item, { children: _jsx(Button, { type: "primary", htmlType: "submit", className: "normal-button Header-16-M", disabled: disabled, children: props.translate('common:buttons.submit') }) }) })] })] }) }));
};
export default localeTranslator(UpdateAppointment, 'patientUpdate');
