import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Row, Col } from 'antd';
import { FormInput } from 'components/AntEnhancement/FormControls';
import localeTranslator from 'library/Hocs/LocalTranslator';
import './style.css';
var NewPrescriptionItem = function (props) {
    var onChangeMedicineName = function (event) {
        props.prescriptionItem.setMedicineName(event.target.value);
        props.addPrescriptionItem();
    };
    var onChangeDescription = function (event) {
        props.prescriptionItem.setDescription(event.target.value);
    };
    return (_jsxs(Row, { children: [_jsxs(Col, { xs: 8, children: [_jsx(FormInput, { name: "medicine" + props.elementIdentifier, placeHolder: props.translate('prescriptions.form.medicine'), rules: [], bordered: false, onChange: onChangeMedicineName }), _jsx("div", { className: "new-prescription-item-line" })] }), _jsxs(Col, { xs: 16, children: [_jsx(FormInput, { name: "description" + props.elementIdentifier, placeHolder: props.translate('prescriptions.form.description'), rules: [], bordered: false, onChange: onChangeDescription }), _jsx("div", { className: "new-prescription-item-line" })] })] }));
};
export default localeTranslator(NewPrescriptionItem, 'patientLog');
