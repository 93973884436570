var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { plainToInstance } from 'class-transformer';
import Model from 'models/Model';
import { AppointmentDetail, PackagePrice, ReservationPackage, UnitMeasurement } from 'models';
var Settlement = /** @class */ (function (_super) {
    __extends(Settlement, _super);
    function Settlement() {
        return _super.call(this) || this;
    }
    Settlement.prototype.getId = function () {
        return Number(this.id);
    };
    Settlement.prototype.setPrice = function (price) {
        this.price = price;
    };
    Settlement.prototype.getPrice = function () {
        return Number(this.price);
    };
    Settlement.prototype.setQuantity = function (quantity) {
        this.quantity = quantity;
    };
    Settlement.prototype.getQuantity = function () {
        return Number(this.quantity);
    };
    Settlement.prototype.setPriceType = function (priceType) {
        this.price_type = priceType;
    };
    Settlement.prototype.getPriceType = function () {
        return this.price_type;
    };
    Settlement.prototype.setAppointmentDetailId = function (appointmentDetailId) {
        this.appointment_detail_id = appointmentDetailId;
    };
    Settlement.prototype.getAppointmentDetailId = function () {
        return Number(this.appointment_detail_id);
    };
    Settlement.prototype.getReservationPackage = function () {
        return plainToInstance(ReservationPackage, this.reservation_package);
    };
    Settlement.prototype.getPackagePrice = function () {
        return plainToInstance(PackagePrice, this.package_price);
    };
    Settlement.prototype.getUnitMeasurement = function () {
        return plainToInstance(UnitMeasurement, this.unit_measurement);
    };
    Settlement.prototype.getAppointmentDetail = function () {
        return plainToInstance(AppointmentDetail, this.appointment_detail);
    };
    return Settlement;
}(Model));
export default Settlement;
