import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import Icon from '@ant-design/icons';
var IssuedByIcon = function () {
    var Image = function () {
        return _jsx("svg", { xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", viewBox: "0 0 24 24", children: _jsx("g", { "data-name": "vuesax/bold/tag-user", children: _jsx("path", { "data-name": "Vector", d: "M15 0H3a2.983 2.983 0 0 0-3 2.97v10.91a2.992 2.992 0 0 0 3 2.98h.76a3 3 0 0 1 2.12.87l1.71 1.69a2.01 2.01 0 0 0 2.82 0l1.71-1.69a3 3 0 0 1 2.12-.87H15a2.992 2.992 0 0 0 3-2.98V2.97A2.983 2.983 0 0 0 15 0zM9 3.55a1.951 1.951 0 0 1 .07 3.9h-.15A1.945 1.945 0 0 1 7.04 5.5 1.968 1.968 0 0 1 9 3.55zm2.75 9.14a5.37 5.37 0 0 1-5.5 0 1.806 1.806 0 0 1 0-3.23 5.37 5.37 0 0 1 5.5 0 1.806 1.806 0 0 1 0 3.23z", transform: "translate(3 2)", style: { fill: "rgb(100, 115, 134)" } }) }) });
    };
    return (_jsx(Icon, { component: Image }));
};
export default memo(IssuedByIcon);
