var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import Model from 'models/Model';
var ReservationTransaction = /** @class */ (function (_super) {
    __extends(ReservationTransaction, _super);
    function ReservationTransaction() {
        return _super.call(this) || this;
    }
    ReservationTransaction.prototype.getId = function () {
        return Number(this.id);
    };
    ReservationTransaction.prototype.getPackageId = function () {
        return Number(this.package_id);
    };
    ReservationTransaction.prototype.setPackageId = function (packageId) {
        this.package_id = Number(packageId);
    };
    ReservationTransaction.prototype.getReservationId = function () {
        return Number(this.reservation_id);
    };
    ReservationTransaction.prototype.setReservationId = function (reservationId) {
        this.reservation_id = Number(reservationId);
    };
    ReservationTransaction.prototype.getTransactionId = function () {
        return this.transaction_id;
    };
    ReservationTransaction.prototype.setTransactionId = function (transactionId) {
        this.transaction_id = Number(transactionId);
    };
    ReservationTransaction.prototype.getTransaction = function () {
        return this.transaction;
    };
    ReservationTransaction.prototype.setTransaction = function (transaction) {
        this.transaction = transaction;
    };
    ReservationTransaction.prototype.getDebit = function () {
        return Number(this.debit);
    };
    ReservationTransaction.prototype.getCredit = function () {
        return Number(this.credit);
    };
    return ReservationTransaction;
}(Model));
export default ReservationTransaction;
