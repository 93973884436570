var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { plainToClass } from 'class-transformer';
import { VisitDetail } from 'models';
import Model from 'models/Model';
var ClosedPackageDetails = /** @class */ (function (_super) {
    __extends(ClosedPackageDetails, _super);
    function ClosedPackageDetails(visitDetail, usage, doctorPercentage, sellingPrice, discount) {
        var _this = _super.call(this) || this;
        visitDetail && _this.setVisitDetail(visitDetail);
        usage && _this.setUsage(usage);
        doctorPercentage && _this.setDoctorPercentage(doctorPercentage);
        sellingPrice && _this.setSellingPrice(sellingPrice);
        discount && _this.setDiscount(discount);
        visitDetail && _this.setId();
        return _this;
    }
    ClosedPackageDetails.prototype.setId = function () {
        this.id = this.getVisitDetail().getId();
    };
    ClosedPackageDetails.prototype.setVisitDetail = function (visitDetail) {
        this.visit_detail = visitDetail;
    };
    ClosedPackageDetails.prototype.getVisitDetail = function () {
        return plainToClass(VisitDetail, this.visit_detail);
    };
    ClosedPackageDetails.prototype.setUsage = function (usage) {
        this.usage = usage;
    };
    ClosedPackageDetails.prototype.setDoctorPercentage = function (doctorPercentage) {
        this.doctor_percentage = doctorPercentage;
    };
    ClosedPackageDetails.prototype.setSellingPrice = function (sellingPrice) {
        this.selling_price = sellingPrice;
    };
    ClosedPackageDetails.prototype.setDiscount = function (discount) {
        this.discount = discount;
    };
    return ClosedPackageDetails;
}(Model));
export default ClosedPackageDetails;
