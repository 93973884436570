// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.new-prescription-item-line {
  width: 100%;
  height: 1px;
  /* margin: 0 0 10.3px 0px; */
  background-color: var(--border-color);
}

.add-prescription-button {
  height: 41px !important;
  padding: 12px 180px 12px 180px !important;
  border-radius: 5px !important;
  background-color: var(--success-color-state-for-inputs) !important;
  border-color: white !important;
  margin-top: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/components/forms/Prescription/style.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,WAAW;EACX,4BAA4B;EAC5B,qCAAqC;AACvC;;AAEA;EACE,uBAAuB;EACvB,yCAAyC;EACzC,6BAA6B;EAC7B,kEAAkE;EAClE,8BAA8B;EAC9B,gBAAgB;AAClB","sourcesContent":[".new-prescription-item-line {\n  width: 100%;\n  height: 1px;\n  /* margin: 0 0 10.3px 0px; */\n  background-color: var(--border-color);\n}\n\n.add-prescription-button {\n  height: 41px !important;\n  padding: 12px 180px 12px 180px !important;\n  border-radius: 5px !important;\n  background-color: var(--success-color-state-for-inputs) !important;\n  border-color: white !important;\n  margin-top: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
