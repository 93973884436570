import { jsx as _jsx } from "react/jsx-runtime";
import Patients from 'pages/Patients';
import NewPatient from 'pages/NewPatient';
import PatientProfile from 'pages/ShowPatient';
import UpdatePatient from 'pages/UpdatePatient';
import { Route } from 'react-router';
import AuthorizationService from '../AuthorizationService';
import { Index, Create, Update, Show } from '../../constants/actions';
import { Patient as PatientsResource } from '../../constants/resources';
var PatientRoutes = function () {
    return ([
        _jsx(Route, { caseSensitive: true, path: '/patients/new', element: AuthorizationService.getAuthorizedElement(_jsx(NewPatient, {}), Index, PatientsResource) }, 'new-patient-route'),
        _jsx(Route, { caseSensitive: true, path: '/patients', element: AuthorizationService.getAuthorizedElement(_jsx(Patients, {}), Create, PatientsResource) }, 'patients-route'),
        _jsx(Route, { caseSensitive: true, path: '/patients/:id/update', element: AuthorizationService.getAuthorizedElement(_jsx(UpdatePatient, {}), Update, PatientsResource) }, 'update-patient-route'),
        _jsx(Route, { caseSensitive: true, path: '/patients/:id', element: AuthorizationService.getAuthorizedElement(_jsx(PatientProfile, {}), Show, PatientsResource) }, 'patient-route'),
    ]);
};
export default PatientRoutes;
