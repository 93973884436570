import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useRef, useState } from 'react';
import Company from './Company';
import Features from './Features';
import Pricing from './Pricing';
import Intro from './Intro';
import ContactUs from './Contactus';
import './style.css';
import Login from './Login';
var Landing = function () {
    var _a = useState(false), isModalVisible = _a[0], setIsModalVisible = _a[1];
    var sectionMapper = {
        'company': 0,
        'features': 1,
        'pricing': 2,
        'start': 3
    };
    var _b = useState(''), pageSection = _b[0], setPageSection = _b[1];
    var refs = useRef([]);
    var executeScroll = function (index) { var _a; return refs.current && ((_a = refs.current[index]) === null || _a === void 0 ? void 0 : _a.scrollIntoView({ behavior: 'smooth', block: 'start' })); };
    useEffect(function () {
        executeScroll(sectionMapper[pageSection]);
    }, [pageSection]);
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: 'layout Body-Background', children: [_jsx(Intro, { setPageSection: setPageSection, showLoginModal: setIsModalVisible }), _jsx(Login, { isModalVisible: isModalVisible, showLoginModal: setIsModalVisible })] }), _jsx("div", { className: 'layout', ref: function (element) { refs.current[sectionMapper['company']] = element; }, children: _jsx(Company, {}) }), _jsx("div", { className: 'layout', style: { backgroundColor: '#e4fefe' }, ref: function (element) { refs.current[sectionMapper['features']] = element; }, children: _jsx(Features, {}) }), _jsx("div", { className: 'layout Pricing-Background', ref: function (element) { refs.current[sectionMapper['pricing']] = element; }, children: _jsx(Pricing, {}) }), _jsx("div", { className: 'layout Body-Background', ref: function (element) { refs.current[sectionMapper['start']] = element; }, children: _jsx(ContactUs, {}) }), _jsx("div", { className: 'layout Remove-Padding body---14pt description-text', children: "Copyright \u00A9 2021, Clinicmnager. All rights reserved." })] }));
};
export default Landing;
