import { jsx as _jsx } from "react/jsx-runtime";
import services from 'services';
import PageNotFound from 'pages/PageNotFound';
var AuthorizationService = /** @class */ (function () {
    function AuthorizationService() {
    }
    AuthorizationService.getAuthorizedElement = function (element, action, resource) {
        var currentUser = services.userService.useCurrentUser().data;
        var auth = this.userHasPermission(currentUser, action, resource);
        return auth ? element : _jsx(PageNotFound, {});
    };
    AuthorizationService.userHasPermission = function (user, action, resource) {
        if (!user)
            return false;
        var userService = services.userService;
        return userService.hasPermission(action, resource);
    };
    return AuthorizationService;
}());
export default AuthorizationService;
