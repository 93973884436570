import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Button, Drawer, Form } from 'antd';
import AddPackagesForm from 'components/forms/packages/AddPackagesForm';
import localeTranslator from 'library/Hocs/LocalTranslator';
import services from 'services';
import '../../style.css';
import { useParams } from 'react-router';
var AddReservationPackageDrawer = function (props) {
    var id = useParams().id;
    var _a = useState(false), disabled = _a[0], setDisabled = _a[1];
    var _b = useState([]), reservationPackages = _b[0], setReservationPackages = _b[1];
    var form = Form.useForm()[0];
    var onClose = function () { return props.onCloseDrawer(); };
    var packagePriceService = services.packagePriceService, unitMeasurementService = services.unitMeasurementService, reservationPackageService = services.reservationPackageService;
    var unitMeasurements = unitMeasurementService.useUnitMeasurements().data;
    var packagePrices = packagePriceService.usePackagePrices().data;
    var _c = reservationPackageService.useCreateBlukReservationPackages(), createBulkReservationPackages = _c.mutate, isReservationPackagesCreated = _c.isSuccess;
    useEffect(function () {
        isReservationPackagesCreated && onClose();
    }, [isReservationPackagesCreated]);
    var onSubmit = function () {
        setDisabled(true);
        reservationPackages.forEach(function (rp) { return rp.setPatientId(Number(id)); });
        createBulkReservationPackages({ reservationPackages: reservationPackages, navigateToReservationPackages: false });
    };
    return (_jsx(Drawer, { title: props.translate('reservationDrawer.newReservation'), placement: "bottom", open: props.visiable, size: "default", onClose: onClose, height: 642, className: "transaction-drawer", children: _jsxs(Form, { layout: 'vertical', form: form, initialValues: { layout: 'vertical' }, size: 'large', onFinish: onSubmit, children: [packagePrices && unitMeasurements &&
                    _jsx(_Fragment, { children: _jsx(AddPackagesForm, { packagePrices: packagePrices, unitMeasurements: unitMeasurements, reservationPackages: reservationPackages, setReservationPackages: setReservationPackages }) }), _jsx(Form.Item, { children: _jsx(Button, { type: "primary", htmlType: "submit", className: "add-submit-button Header-16-M", disabled: disabled, children: props.translate('common:buttons.submit') }) })] }) }, "bottom"));
};
export default localeTranslator(AddReservationPackageDrawer, 'showPatient');
