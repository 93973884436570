import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Col, Row, Space } from 'antd';
import { FormInputNumber } from 'components/AntEnhancement/FormControls';
import localeTranslator from 'library/Hocs/LocalTranslator';
import { Settlement } from 'models';
// import SettlementDrawer from 'pages/FinishAppointment/drawers/SettlementDrawer';
import { useState } from 'react';
import NewSettlement from '../Settlements/NewSettlement';
var FinishPackagePrices = function (props) {
    var reservationPackage = useState(props.appointmentDetail.getReservationPackage())[0];
    var _a = useState(false), showSettlementForm = _a[0], setShowSettlementForm = _a[1];
    var onSettlementPress = function () { return setShowSettlementForm(!showSettlementForm); };
    var onChangeQuantity = function (quantity) { return quantity && props.appointmentDetail.setQuantity(quantity); };
    var getUsedComponent = function () {
        var maxValue = function () { return reservationPackage.getRemainder() + props.appointmentDetail.getQuantity(); };
        return (_jsx(FormInputNumber, { name: "used-".concat(props.appointmentDetail.getId()), rules: [
                { required: true, message: 'Please, Enter the package usage' }
            ], max: maxValue() > 0 ? maxValue() : 0, onChange: onChangeQuantity, formClasses: 'form-item body---14pt-R normal-input', inputClasses: "number-input", initialValue: props.appointmentDetail.getQuantity() }, "appointment-detaile-quantity-".concat(props.appointmentDetail.getId())));
    };
    var settlementComponent = function () {
        props.appointmentDetail.setSettlement(new Settlement());
        return showSettlementForm && (_jsxs(Space, { direction: "vertical", size: 'small', children: [_jsx("div", { className: "body---14pt-R", style: { color: 'orange' }, children: "Settlement" }), _jsx(NewSettlement, { settlement: props.appointmentDetail.getSettlement(), reservationPackage: props.appointmentDetail.getReservationPackage() })] }));
    };
    return (_jsx("div", { className: "form-part", children: _jsxs(Space, { direction: "vertical", size: 15, children: [_jsxs(Row, { children: [_jsx(Col, { lg: 20 }), _jsx(Col, { children: _jsx(Button, { type: "link", className: "Header-R-14 invoice-button", onClick: onSettlementPress, children: showSettlementForm ? 'Remove settlement' : 'Add settlement' }) })] }), _jsxs(Space, { direction: "vertical", size: 7.5, children: [_jsxs(Row, { children: [_jsx(Col, { lg: 10, children: _jsx("div", { className: "body---14pt-R", children: "Package Name" }) }), _jsx(Col, { lg: 8, children: _jsx("div", { className: "body---14pt-R", children: "Remainder Quantity" }) }), _jsx(Col, { lg: 6, children: _jsx("div", { className: "body---14pt-R", children: "Used" }) })] }), _jsxs(Row, { children: [_jsx(Col, { lg: 10, children: _jsx("div", { className: "body---14pt-R description-text", children: reservationPackage.getPackagePrice().getPackageName() }) }), _jsx(Col, { lg: 8, children: _jsx(Space, { direction: "horizontal", size: 10, children: _jsx("div", { className: "body---14pt-R description-text", children: reservationPackage.getRemainder() - props.appointmentDetail.getQuantity() }) }) }), _jsx(Col, { lg: 6, children: getUsedComponent() })] })] }), settlementComponent()] }) }));
};
export default localeTranslator(FinishPackagePrices, '');
