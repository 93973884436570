var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Row, DatePicker, Space } from 'antd';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import moment from 'moment';
import DocumentIcon from 'assets/icons/document';
import './style.css';
import services from 'services';
var DateSelector = function (props) {
    var today = moment();
    var currentUSer = services.userService.useCurrentUser().data;
    var getNextDay = function () {
        var targerDate = moment(props.date).add(1, 'days');
        props.setDate(targerDate);
    };
    var getPreviousDay = function () {
        var targerDate = moment(props.date).subtract(1, 'days');
        props.setDate(targerDate);
    };
    var previousDayArrow = function () {
        var props = {
            className: 'outlinedIcon',
            onClick: getPreviousDay
        };
        return (currentUSer === null || currentUSer === void 0 ? void 0 : currentUSer.getLanguage()) === 'ar' ?
            _jsx(RightOutlined, __assign({}, props, { style: { marginInlineStart: '30px' } }))
            :
                _jsx(LeftOutlined, __assign({}, props, { style: { marginInlineStart: '30px' } }));
    };
    var nextDayArrow = function () {
        var props = {
            className: 'outlinedIcon',
            onClick: getNextDay
        };
        return (currentUSer === null || currentUSer === void 0 ? void 0 : currentUSer.getLanguage()) === 'ar' ? _jsx(LeftOutlined, __assign({}, props)) : _jsx(RightOutlined, __assign({}, props));
    };
    return (_jsxs(Row, { className: 'Header-20-bold dateContainer rowAlign', children: [_jsxs(Space, { size: 15, children: [_jsx("div", { className: 'iconSize', children: _jsx(DocumentIcon, {}) }), props.date.toDate().toDateString() === today.toDate().toDateString() ?
                        _jsxs("div", { children: [props.date.toDate().getDate() + ' ' + props.date.toDate().toLocaleString('default', { month: 'short' }), _jsx("span", { className: 'description-text', children: " TODAY " })] })
                        : props.date.toDate().getDate() + ' ' + props.date.toDate().toLocaleString('default', { month: 'short' })] }), previousDayArrow(), _jsx(DatePicker, { onChange: function (date) { return props.setDate(date); }, format: 'DD MMM' }), nextDayArrow()] }));
};
export default DateSelector;
