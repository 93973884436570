import { jsx as _jsx } from "react/jsx-runtime";
import Employees from 'pages/Employees';
import NewEmployee from 'pages/NewEmployee';
import ShowEmployee from 'pages/ShowEmployee';
import AuthorizationService from '../AuthorizationService';
import { Index, Create, Show } from 'constants/actions';
import { Employee as EmployeeResource } from 'constants/resources';
import { Route } from 'react-router';
var EmployeeRoutes = function () {
    return ([
        _jsx(Route, { caseSensitive: true, path: '/employees/new', element: AuthorizationService.getAuthorizedElement(_jsx(NewEmployee, {}), Create, EmployeeResource) }, 'newEmployee-route'),
        _jsx(Route, { caseSensitive: true, path: '/employees', element: AuthorizationService.getAuthorizedElement(_jsx(Employees, {}), Index, EmployeeResource) }, 'employees-route'),
        _jsx(Route, { caseSensitive: true, path: '/employees/:id', element: AuthorizationService.getAuthorizedElement(_jsx(ShowEmployee, {}), Show, EmployeeResource) }, 'showEmployee-route'),
    ]);
};
export default EmployeeRoutes;
