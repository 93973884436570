import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Form, Input, Row, Space, Button, Col } from 'antd';
import localeTranslator from 'library/Hocs/LocalTranslator';
import './style.css';
var useState = React.useState, useEffect = React.useEffect;
var PasswordForm = function (props) {
    var form = Form.useForm()[0];
    var _a = useState(false), passwordDisabled = _a[0], setPasswordDisabled = _a[1];
    var onChangeOldPassword = function (event) {
        props.user.setOldPassword(event.target.value);
    };
    var onChangeNewPassword = function (event) {
        props.user.setPassword(event.target.value);
    };
    var onSubmit = function () {
        setPasswordDisabled(true);
        props.updatePassword({ user: props.user });
    };
    useEffect(function () {
        if (props.disableReset) {
            setPasswordDisabled(false);
        }
    }, [props.disableReset]);
    return (_jsx(_Fragment, { children: _jsxs("div", { className: "form-part", children: [_jsx("div", { className: "Header-18-M form-title", children: props.translate('emailAndPassword.changePassword') }), _jsx(Form, { layout: 'vertical', form: form, initialValues: { layout: 'vertical' }, size: 'large', onFinish: onSubmit, children: _jsxs(Space, { direction: 'vertical', size: 40, style: { width: '100%' }, children: [_jsx(Row, { children: _jsx(Col, { xs: 24, sm: 24, lg: 24, xl: 24, children: _jsx(Form.Item, { label: props.translate('emailAndPassword.oldPassword'), name: "oldPassword", required: true, className: "form-item body---14pt-R", rules: [
                                            {
                                                required: true,
                                                message: props.translate('emailAndPassword.errors.oldPassword'),
                                            }
                                        ], hasFeedback: true, children: _jsx(Input.Password, { className: "input-border", placeholder: props.translate('emailAndPassword.oldPassword'), onChange: onChangeOldPassword }) }) }) }), _jsx(Row, { children: _jsx(Col, { xs: 24, sm: 24, lg: 24, xl: 24, children: _jsx(Form.Item, { label: props.translate('emailAndPassword.newPassword'), name: "newPassword", required: true, className: "form-item body---14pt-R", rules: [
                                            {
                                                required: true,
                                                message: props.translate('emailAndPassword.errors.newPassword'),
                                            }
                                        ], hasFeedback: true, children: _jsx(Input.Password, { className: "input-border", placeholder: props.translate('emailAndPassword.newPassword'), onChange: onChangeNewPassword }) }) }) }), _jsx(Space, { children: _jsx(Form.Item, { children: _jsx(Button, { type: "primary", htmlType: "submit", className: "normal-button Header-16-M", disabled: passwordDisabled, children: props.translate('common:buttons.submit') }) }) })] }) })] }) }));
};
export default localeTranslator(PasswordForm, 'settings');
