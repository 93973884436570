import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import localeTranslator from 'library/Hocs/LocalTranslator';
import './style.css';
import TabHeader from 'components/PageHeader/TabHeader';
import EditMainInfo from './tabs/EditMainInfo';
import Users from './tabs/Users';
import JobTitles from './tabs/JobTitles';
var Settings = function (props) {
    var _a, _b, _c;
    var _d = useState('emailAndPassword'), currentTab = _d[0], setCurrentTab = _d[1];
    var Tabs = ['emailAndPassword', 'users', 'jobTitles'];
    var items = [
        {
            label: props.translate('header.menu.emailAndPassword'),
            key: 'emailAndPassword'
        },
        {
            label: props.translate('header.menu.users'),
            key: 'users'
        },
        {
            label: props.translate('header.menu.jobTitles'),
            key: 'jobTitles'
        }
    ];
    return (_jsxs(_Fragment, { children: [_jsx(TabHeader, { title: props.translate('header.title'), currentTab: currentTab, items: items, setCurrentTab: setCurrentTab }), currentTab === Tabs[0] &&
                _jsx("div", { className: 'setting-page-content-with-header', children: _jsx(EditMainInfo, {}) }, (_a = items[0]) === null || _a === void 0 ? void 0 : _a.key), currentTab === Tabs[1] &&
                _jsx("div", { className: 'setting-page-content-with-header', children: _jsx(Users, {}) }, (_b = items[1]) === null || _b === void 0 ? void 0 : _b.key), currentTab === Tabs[2] &&
                _jsx("div", { className: 'setting-page-content-with-header', children: _jsx(JobTitles, {}) }, (_c = items[2]) === null || _c === void 0 ? void 0 : _c.key)] }));
};
export default localeTranslator(Settings, 'settings');
