import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Col, Row } from 'antd';
import { FormSelect } from 'components/AntEnhancement/FormControls';
import DoctorSearch from 'components/forms/doctors/DoctorSearch';
import localeTranslator from 'library/Hocs/LocalTranslator';
var AppointmentMetaInfoForm = function (props) {
    var _a;
    var getClinicOptions = function () {
        return props.clinics.map(function (clinic) {
            return ({
                value: clinic.getId(),
                title: clinic.getName()
            });
        }) || [];
    };
    var onChangeClinic = function (clinicId) {
        props.appointment.setClinicId(Number(clinicId));
    };
    return (_jsxs(Row, { children: [_jsx(Col, { lg: 12, children: _jsx(FormSelect, { label: props.translate('clinic'), name: "chooseClinic", rules: [], placeHolder: props.translate('chooseClinic'), onSelect: onChangeClinic, options: getClinicOptions(), initialValue: (_a = props.appointment.getClinic()) === null || _a === void 0 ? void 0 : _a.getId() }) }), _jsx(Col, { xs: 1 }), _jsx(Col, { xs: 11, children: _jsx(DoctorSearch, { doctors: props.doctors, entity: props.appointment, isCollapseRequired: false }) })] }));
};
export default localeTranslator(AppointmentMetaInfoForm, 'visitMetaInfoForm');
