import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Layout } from 'antd';
import SideBar from './SideBar';
import './style.css';
var AppLayout = function (_a) {
    var appContent = _a.appContent;
    var _b = useState(false), collapsed = _b[0], setCollapsed = _b[1];
    var siteContentMargin = function () { return collapsed ? 'site-content-collapsed-layout' : 'site-content-layout'; };
    return (_jsxs(Layout, { className: "body-layout", children: [_jsx(SideBar, { collapsed: collapsed, setCollapsed: setCollapsed }), _jsx(Layout, { className: "site-layout ".concat(siteContentMargin(), " site-content-layout-transition"), children: appContent })] }));
};
export default AppLayout;
