// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cls-3 {
  fill:#dfebfa
}
.cls-4 {
  fill:#eef6ff
}
.cls-5 {
  fill:#cbdcf7
}
.cls-7 {
  fill:#7ab1f7
} 
.cls-8 {
  fill:#769fd3
} 
.cls-9 {
  fill:#aec3ff
}
.cls-12 {
  fill:#b7d3f3
}
.cls-14 {
  fill:#3e8ef4
} 
.cls-15 {
  fill:#3670b9
}
.cls-17 {
  fill:#c8dbf4
}
.cls-18 {
  fill:#fff
}
.cls-19 {
  fill:#d4e4f8
}`, "",{"version":3,"sources":["webpack://./src/assets/icons/pageNotFoundImage/style.css"],"names":[],"mappings":"AAAA;EACE;AACF;AACA;EACE;AACF;AACA;EACE;AACF;AACA;EACE;AACF;AACA;EACE;AACF;AACA;EACE;AACF;AACA;EACE;AACF;AACA;EACE;AACF;AACA;EACE;AACF;AACA;EACE;AACF;AACA;EACE;AACF;AACA;EACE;AACF","sourcesContent":[".cls-3 {\n  fill:#dfebfa\n}\n.cls-4 {\n  fill:#eef6ff\n}\n.cls-5 {\n  fill:#cbdcf7\n}\n.cls-7 {\n  fill:#7ab1f7\n} \n.cls-8 {\n  fill:#769fd3\n} \n.cls-9 {\n  fill:#aec3ff\n}\n.cls-12 {\n  fill:#b7d3f3\n}\n.cls-14 {\n  fill:#3e8ef4\n} \n.cls-15 {\n  fill:#3670b9\n}\n.cls-17 {\n  fill:#c8dbf4\n}\n.cls-18 {\n  fill:#fff\n}\n.cls-19 {\n  fill:#d4e4f8\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
