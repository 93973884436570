var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import Model from 'models/Model';
var AppointmentConsumption = /** @class */ (function (_super) {
    __extends(AppointmentConsumption, _super);
    function AppointmentConsumption() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AppointmentConsumption.prototype.getId = function () {
        return Number(this.id);
    };
    AppointmentConsumption.prototype.getQuantity = function () {
        return Number(this.quantity);
    };
    AppointmentConsumption.prototype.setQuantity = function (quantity) {
        this.quantity = Number(quantity);
    };
    AppointmentConsumption.prototype.getAppointmentDetailId = function () {
        return Number(this.appointment_detail_id);
    };
    AppointmentConsumption.prototype.setAppointmentDetailId = function (appointmentDetailId) {
        this.appointment_detail_id = Number(appointmentDetailId);
    };
    AppointmentConsumption.prototype.setAppointmentId = function (appointmentId) {
        this.appointment_id = Number(appointmentId);
    };
    AppointmentConsumption.prototype.getAppointmentId = function () {
        return Number(this.appointment_id);
    };
    AppointmentConsumption.prototype.getUnitMeasurementId = function () {
        return Number(this.unit_measurement_id);
    };
    AppointmentConsumption.prototype.setUnitMeasurementId = function (unitMeasurementId) {
        this.unit_measurement_id = Number(unitMeasurementId);
    };
    AppointmentConsumption.prototype.getProductId = function () {
        return Number(this.product_id);
    };
    AppointmentConsumption.prototype.setProductId = function (productId) {
        this.product_id = productId;
    };
    return AppointmentConsumption;
}(Model));
export default AppointmentConsumption;
