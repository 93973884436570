// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tab-title {
  display: flex;
  align-items: center;
  margin-left: 5px;
  margin-top: 3px;
}

.tab-title-text {
  margin-left: 5px;
}

.unselected-color {
  color: var(--menu-color) !important;
}

.selected-color {
  color: var(--primary-color) !important;
}`, "",{"version":3,"sources":["webpack://./src/library/components/StepTitle/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,mCAAmC;AACrC;;AAEA;EACE,sCAAsC;AACxC","sourcesContent":[".tab-title {\n  display: flex;\n  align-items: center;\n  margin-left: 5px;\n  margin-top: 3px;\n}\n\n.tab-title-text {\n  margin-left: 5px;\n}\n\n.unselected-color {\n  color: var(--menu-color) !important;\n}\n\n.selected-color {\n  color: var(--primary-color) !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
