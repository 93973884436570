// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card {
  /* width: 190px;
  height: 70px; */
  margin: 21px 0 0 21px;
  padding: 15px 15px 15px 12px;
  border-radius: 10px;
  border: solid 1px var(--border-color);
  cursor: pointer;
}

.card:hover {
  box-shadow: 10px 10px 5px rgb(0 0 0 / 12%) !important;
}

.card-title {
  color: var(--input-text-color);
  margin: 0 0 8px;
}

.package-details {
  margin: 8px 0 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.entity-title {
  font-size: 12px;
  color: var(--border-color)
}
`, "",{"version":3,"sources":["webpack://./src/components/cards/PackageCard/style.css"],"names":[],"mappings":"AAAA;EACE;iBACe;EACf,qBAAqB;EACrB,4BAA4B;EAC5B,mBAAmB;EACnB,qCAAqC;EACrC,eAAe;AACjB;;AAEA;EACE,qDAAqD;AACvD;;AAEA;EACE,8BAA8B;EAC9B,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,eAAe;EACf;AACF","sourcesContent":[".card {\n  /* width: 190px;\n  height: 70px; */\n  margin: 21px 0 0 21px;\n  padding: 15px 15px 15px 12px;\n  border-radius: 10px;\n  border: solid 1px var(--border-color);\n  cursor: pointer;\n}\n\n.card:hover {\n  box-shadow: 10px 10px 5px rgb(0 0 0 / 12%) !important;\n}\n\n.card-title {\n  color: var(--input-text-color);\n  margin: 0 0 8px;\n}\n\n.package-details {\n  margin: 8px 0 0;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n}\n\n.entity-title {\n  font-size: 12px;\n  color: var(--border-color)\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
