var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Area, Column, Line } from '@ant-design/plots';
import { Card, Divider } from 'antd';
import { AREA, LINE } from 'constants/chartTypes';
import './style.css';
var ChartCard = function (props) {
    var config = {
        data: props.data,
        xField: props.xAxis,
        yField: props.yAxis,
    };
    var chartComponent = function () {
        if (props.chartType == AREA) {
            return _jsx(Area, __assign({}, config));
        }
        else if (props.chartType == LINE) {
            return _jsx(Line, __assign({}, config));
        }
        else {
            return _jsx(Column, __assign({}, config));
        }
    };
    return (_jsxs(Card, { bordered: false, style: { width: '100%' }, children: [_jsx("div", { className: "Header-18-M title", children: props.title }), _jsx(Divider, {}), chartComponent()] }));
};
export default ChartCard;
