var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import Model from 'models/Model';
var MedicalMachineUsage = /** @class */ (function (_super) {
    __extends(MedicalMachineUsage, _super);
    function MedicalMachineUsage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    MedicalMachineUsage.prototype.setServiceId = function (serviceId) {
        this.service_id = serviceId;
    };
    MedicalMachineUsage.prototype.getServiceId = function () {
        return Number(this.service_id);
    };
    MedicalMachineUsage.prototype.setQuantity = function (quantity) {
        this.quantity = quantity;
    };
    MedicalMachineUsage.prototype.getQuantity = function () {
        return Number(this.quantity);
    };
    MedicalMachineUsage.prototype.setPower1 = function (power1) {
        this.power1 = power1;
    };
    MedicalMachineUsage.prototype.getPower1 = function () {
        return Number(this.power1);
    };
    MedicalMachineUsage.prototype.setPower2 = function (power2) {
        this.power2 = power2;
    };
    MedicalMachineUsage.prototype.getPower2 = function () {
        return Number(this.power2);
    };
    MedicalMachineUsage.prototype.setSkinType = function (skinType) {
        this.skinType = skinType;
    };
    MedicalMachineUsage.prototype.getSkinType = function () {
        return Number(this.skinType);
    };
    MedicalMachineUsage.prototype.setCooling = function (cooling) {
        this.cooling = cooling;
    };
    MedicalMachineUsage.prototype.getCooling = function () {
        return Number(this.cooling);
    };
    MedicalMachineUsage.prototype.setNote = function (note) {
        this.note = note;
    };
    MedicalMachineUsage.prototype.getNote = function () {
        return this.note;
    };
    return MedicalMachineUsage;
}(Model));
export default MedicalMachineUsage;
