import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Row, Col } from 'antd';
import { FormSelect } from 'components/AntEnhancement/FormControls';
import localeTranslator from 'library/Hocs/LocalTranslator';
import './style.css';
var JobInfo = function (props) {
    var onChangeSpecialization = function (value) {
        var _a;
        var specialization = (_a = props.specializations) === null || _a === void 0 ? void 0 : _a.find(function (specialization) { return specialization.id === value; });
        specialization && props.doctor.setSpecialization(specialization);
    };
    var getOptions = function () {
        var _a;
        return ((_a = props.specializations) === null || _a === void 0 ? void 0 : _a.map(function (specialization) {
            return ({
                value: specialization.getId(),
                title: specialization.getName()
            });
        })) || [];
    };
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "Header-18-M form-title", children: props.translate('jobInfo') }), _jsx("div", { className: "form-part", children: _jsx(Row, { children: _jsx(Col, { xs: 24, sm: 24, lg: 8, xl: 8, children: _jsx(FormSelect, { label: props.translate('chooseSpecialization'), name: "specialization", rules: [
                                { required: true, message: props.translate('errors.specialization') }
                            ], placeHolder: props.translate('chooseSpecialization'), onSelect: onChangeSpecialization, options: getOptions() }) }) }) })] }));
};
export default localeTranslator(JobInfo, 'doctorForm');
