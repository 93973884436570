import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Form, Row, Col, Select } from 'antd';
import './style.css';
import localeTranslator from 'library/Hocs/LocalTranslator';
import { FormDatePicker, FormInput } from 'components/AntEnhancement/FormControls';
var Option = Select.Option;
var BasicForm = function (props) {
    var onChangeName = function (event) {
        props.user.setName(event.target.value);
    };
    var onChangeNationalId = function (event) {
        var _a;
        (_a = props.entity) === null || _a === void 0 ? void 0 : _a.setNationalId(event.target.value);
    };
    var onChangeAddress = function (event) {
        props.user.setAddress(event.target.value);
    };
    var onChangePrimaryPhone = function (event) {
        props.user.setMobile(event.target.value);
    };
    var onChangeSecondaryPhone = function (event) {
        props.user.setHomePhone(event.target.value);
    };
    // const onChangeBirthDate = (birthdate: Moment | null): void => {
    //   birthdate && props.user.setBirthDay(birthdate.toDate());
    // };
    var onChangeBirthDate = function (birthdate) {
        birthdate && props.user.setBirthDay(birthdate.toDate());
    };
    var onChangeGender = function (value) { return props.user.setGender(value); };
    var onChangeMaritialStatus = function (value) { var _a; return (_a = props.entity) === null || _a === void 0 ? void 0 : _a.setMartialStatusId(value); };
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "Header-18-M form-title", children: props.translate('personalInfomation') }), _jsxs("div", { className: "form-part", children: [_jsxs(Row, { children: [_jsx(Col, { xs: 24, sm: 24, lg: 7, xl: 7, children: _jsx(FormInput, { label: props.translate('name'), name: "name", placeHolder: props.translate('name'), rules: [
                                        { required: true, message: props.translate('errors.name') }
                                    ], onChange: onChangeName }) }), _jsx(Col, { xs: 0, sm: 0, lg: 1, xl: 1 }), _jsx(Col, { xs: 24, sm: 24, lg: 7, xl: 7, children: _jsx(FormDatePicker, { label: props.translate('birthdate'), name: "birthdate", rules: [
                                        { required: true, message: props.translate('errors.birthDate') }
                                    ], placeHolder: props.translate('selectDate'), onChange: onChangeBirthDate }) }), _jsx(Col, { xs: 0, sm: 0, lg: 1, xl: 1 }), _jsx(Col, { xs: 24, sm: 24, lg: 8, xl: 8, children: _jsx(Form.Item, { label: props.translate('chooseGender'), name: "gender", required: true, className: "form-item body---14pt-R", rules: [
                                        { required: true, message: props.translate('errors.gender') }
                                    ], hasFeedback: true, children: _jsxs(Select, { showSearch: true, placeholder: props.translate('chooseGender'), onSelect: onChangeGender, optionFilterProp: "children", filterOption: function (input, option) { var _a; return ((_a = option === null || option === void 0 ? void 0 : option.children) !== null && _a !== void 0 ? _a : '').toString().toLowerCase().includes(input.toLowerCase()); }, children: [_jsx(Option, { value: "male", className: 'body---14pt-R', children: props.translate('common:gender.male') }), _jsx(Option, { value: "female", className: 'body---14pt-R', children: props.translate('common:gender.female') })] }) }) })] }), _jsxs(Row, { children: [_jsx(Col, { xs: 24, sm: 24, lg: 7, xl: 7, children: _jsx(FormInput, { label: props.translate('nationalId'), name: "nationalId", placeHolder: props.translate('nationalId'), rules: [
                                        { required: true, message: props.translate('errors.nationalId.required') },
                                        { min: 13, message: props.translate('errors.nationalId.minLength') }
                                    ], onChange: onChangeNationalId }) }), _jsx(Col, { xs: 0, sm: 0, lg: 1, xl: 1 }), _jsx(Col, { xs: 24, sm: 24, lg: 7, xl: 7, children: _jsx(FormInput, { label: props.translate('mobile'), name: "mobile", placeHolder: props.translate('mobile'), rules: [
                                        { required: true, message: props.translate('errors.mobile') }
                                    ], onChange: onChangePrimaryPhone }) }), _jsx(Col, { xs: 0, sm: 0, lg: 1, xl: 1 }), _jsx(Col, { xs: 24, sm: 24, lg: 8, xl: 8, children: _jsx(Form.Item, { label: props.translate('chooseMaritialStatus'), name: "maritialStatus", required: true, className: "form-item body---14pt-R", rules: [
                                        { required: true, message: props.translate('errors.maritialStatus') }
                                    ], hasFeedback: true, children: _jsxs(Select, { showSearch: true, placeholder: props.translate('chooseMaritialStatus'), onSelect: onChangeMaritialStatus, optionFilterProp: "children", 
                                        // filterOption={(input, option) =>
                                        //   option && option.children && option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        // }
                                        filterOption: function (input, option) { var _a; return ((_a = option === null || option === void 0 ? void 0 : option.children) !== null && _a !== void 0 ? _a : '').toString().toLowerCase().includes(input.toLowerCase()); }, children: [_jsx(Option, { value: "single", className: 'body---14pt-R', children: props.translate('common:maritialStatus.single') }), _jsx(Option, { value: "married", className: 'body---14pt-R', children: props.translate('common:maritialStatus.married') })] }) }) })] }), _jsxs(Row, { children: [_jsx(Col, { xs: 24, sm: 24, lg: 11, xl: 11, children: _jsx(FormInput, { label: props.translate('secondayPhone'), name: "secondayPhone", placeHolder: props.translate('secondayPhone'), rules: [], requiredMark: 'optional', onChange: onChangeSecondaryPhone }) }), _jsx(Col, { xs: 0, sm: 0, lg: 1, xl: 1 }), _jsx(Col, { xs: 24, sm: 24, lg: 12, xl: 12, children: _jsx(FormInput, { label: props.translate('address'), name: "address", placeHolder: props.translate('address'), rules: [], requiredMark: 'optional', onChange: onChangeAddress }) })] })] })] }));
};
export default localeTranslator(BasicForm, 'userForm');
