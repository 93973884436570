var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { classToPlain, plainToClass } from 'class-transformer';
import { Package } from 'models';
import Model from 'models/Model';
var VisitDetail = /** @class */ (function (_super) {
    __extends(VisitDetail, _super);
    function VisitDetail(selectedPackage) {
        var _this = _super.call(this) || this;
        _this.setPackage(selectedPackage);
        return _this;
    }
    VisitDetail.prototype.getId = function () {
        return Number(this.id);
    };
    VisitDetail.prototype.getQuantity = function () {
        return Number(this.quantity);
    };
    VisitDetail.prototype.setQuantity = function (quantity) {
        this.quantity = quantity;
    };
    VisitDetail.prototype.getDiscount = function () {
        return Number(this.discount_amount);
    };
    VisitDetail.prototype.setDiscount = function (discount) {
        this.discount_amount = discount;
    };
    VisitDetail.prototype.getNote = function () {
        return this.note;
    };
    VisitDetail.prototype.setNote = function (note) {
        this.note = note;
    };
    VisitDetail.prototype.getPackage = function () {
        return plainToClass(Package, this.package);
    };
    VisitDetail.prototype.getPackageId = function () {
        return Number(this.package_id);
    };
    VisitDetail.prototype.setPackage = function (currentPackage) {
        this.package = classToPlain(currentPackage);
    };
    VisitDetail.prototype.getActualQuantity = function () {
        return Number(this.actual_quantity);
    };
    VisitDetail.prototype.setActualQuantity = function (actualQuantity) {
        this.actual_quantity = actualQuantity;
    };
    VisitDetail.prototype.getRemainder = function () {
        return Number(this.remainder);
    };
    VisitDetail.prototype.setRemainder = function (remainder) {
        this.remainder = remainder;
    };
    VisitDetail.prototype.getReservationId = function () {
        return Number(this.reservation_id);
    };
    VisitDetail.prototype.setReservationId = function (reservationId) {
        this.reservation_id = reservationId;
    };
    VisitDetail.prototype.canBeUnAssigned = function () {
        return this.getQuantity() === 0;
    };
    return VisitDetail;
}(Model));
export default VisitDetail;
