// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#header {
  width: 459px;
  height: 73px;
  margin: 0 0 27.8px;
  padding: 23px 29px 22px 36px;
  box-shadow: 0 1px 2px 0 rgba(203, 214, 226, 0.38);
  background-color: #fff;
}

.collapse-padding {
  padding-left: 26px ;
}

#line {
  width: 85%;
  height: 1px;
  margin: 16.5px 22.5px 12.5px 26px;
  background-color: var(--border-color);
}

.add-button {
  width: 130.1px !important;
  height: 31px !important;
  padding: 12px 64.1px 12px 64px !important;
}

.prescription-line {
  height: 1px;
  margin: 16.5px 20.5px 12.5px 0px;
  background-color: var(--border-color);
}

.patient-log-entity-row {
  cursor: pointer;
}

.patient-log-title-column {
  display: flex !important;
}

.patient-log-title {
  display: flex;
  align-items: center;
  justify-content: center;
}

.patient-log-back-button {
  font-size: 12px; 
  color: #8f9eb3 !important; 
  margin-right: 15px;
}

.log {
  margin-inline-start: 36px; 
  overflow-y: scroll; 
  height: 85vh;
}`, "",{"version":3,"sources":["webpack://./src/components/patients/style.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,4BAA4B;EAC5B,iDAAiD;EACjD,sBAAsB;AACxB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,UAAU;EACV,WAAW;EACX,iCAAiC;EACjC,qCAAqC;AACvC;;AAEA;EACE,yBAAyB;EACzB,uBAAuB;EACvB,yCAAyC;AAC3C;;AAEA;EACE,WAAW;EACX,gCAAgC;EAChC,qCAAqC;AACvC;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,eAAe;EACf,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,YAAY;AACd","sourcesContent":["#header {\n  width: 459px;\n  height: 73px;\n  margin: 0 0 27.8px;\n  padding: 23px 29px 22px 36px;\n  box-shadow: 0 1px 2px 0 rgba(203, 214, 226, 0.38);\n  background-color: #fff;\n}\n\n.collapse-padding {\n  padding-left: 26px ;\n}\n\n#line {\n  width: 85%;\n  height: 1px;\n  margin: 16.5px 22.5px 12.5px 26px;\n  background-color: var(--border-color);\n}\n\n.add-button {\n  width: 130.1px !important;\n  height: 31px !important;\n  padding: 12px 64.1px 12px 64px !important;\n}\n\n.prescription-line {\n  height: 1px;\n  margin: 16.5px 20.5px 12.5px 0px;\n  background-color: var(--border-color);\n}\n\n.patient-log-entity-row {\n  cursor: pointer;\n}\n\n.patient-log-title-column {\n  display: flex !important;\n}\n\n.patient-log-title {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.patient-log-back-button {\n  font-size: 12px; \n  color: #8f9eb3 !important; \n  margin-right: 15px;\n}\n\n.log {\n  margin-inline-start: 36px; \n  overflow-y: scroll; \n  height: 85vh;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
