import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Prescription from 'components/Prescription';
import PatientLogHeader from './PatientLogHeader';
import 'components/patients/style.css';
import { useState } from 'react';
import NewPrescription from 'components/forms/Prescription/NewPrescription';
import localeTranslator from 'library/Hocs/LocalTranslator';
var Prescriptions = function (props) {
    var _a = useState(false), showPrescriptionForm = _a[0], setShowPrescriptionForm = _a[1];
    var renderPrescriptions = function () { return props.prescriptions.map(function (p, index) {
        return (_jsxs("div", { children: [_jsx(Prescription, { title: props.translate('prescriptions.title') + p.id, prescriptionItems: p.getPrescriptionItems() }), index + 1 < props.prescriptions.length && _jsx("div", { className: "prescription-line" })] }, p.id));
    }); };
    var goBack = function () { return props.setShowPrescriptions(false); };
    var addPrescription = function () { return setShowPrescriptionForm(true); };
    return (_jsxs(_Fragment, { children: [_jsx(PatientLogHeader, { title: props.translate('prescriptions.title'), hasAddButton: true, hasBackButton: true, onGoBack: goBack, onAdd: addPrescription, arrowIcon: props.arrowIcon }), showPrescriptionForm &&
                _jsx(NewPrescription, { createPrescription: props.createPrescription, setShowPrescriptionForm: setShowPrescriptionForm }), _jsx("div", { className: "log", children: renderPrescriptions() })] }));
};
export default localeTranslator(Prescriptions, 'patientLog');
