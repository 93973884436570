var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { classToPlain, plainToClass } from 'class-transformer';
import { User, Album, JobTitle } from 'models';
import Model from 'models/Model';
var Employee = /** @class */ (function (_super) {
    __extends(Employee, _super);
    function Employee() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Employee.prototype.getId = function () {
        return Number(this.id);
    };
    Employee.prototype.getSalary = function () {
        return Number(this.salary_amount);
    };
    Employee.prototype.setSalary = function (salary) {
        this.salary_amount = salary;
    };
    Employee.prototype.getNationalId = function () {
        return this.national_id;
    };
    Employee.prototype.getMartialStatus = function () {
        return this.marital_status;
    };
    Employee.prototype.setNationalId = function (nationalId) {
        this.national_id = nationalId;
    };
    Employee.prototype.getJobTitle = function () {
        return plainToClass(JobTitle, this.job_title);
    };
    Employee.prototype.setJobTitle = function (jobTitle) {
        this.job_title = jobTitle;
    };
    Employee.prototype.getJobTitleId = function () {
        return Number(this.job_title_id);
    };
    Employee.prototype.getMartialStatusId = function () {
        return Number(this.marital_status);
    };
    Employee.prototype.setMartialStatusId = function (maritialStatusId) {
        this.marital_status = maritialStatusId.toString();
    };
    Employee.prototype.getDeletedAt = function () {
        return this.dateForamtter(new Date(this.deleted_at), 'DD/MM/YYYY hh:mm A');
    };
    Employee.prototype.getHireDate = function () {
        return this.dateForamtter(new Date(this.started_at), 'DD/MM/YYYY');
    };
    Employee.prototype.setHireDate = function (hireDate) {
        this.started_at = hireDate.toString();
    };
    Employee.prototype.getUser = function () {
        return plainToClass(User, this.user);
    };
    Employee.prototype.setUser = function (user) {
        this.user = classToPlain(user);
    };
    Employee.prototype.getAlbum = function () {
        return plainToClass(Album, this.album);
    };
    return Employee;
}(Model));
export default Employee;
