export var add = function (key, value) {
    window.localStorage.setItem(key, value); //eslint-ignore-line
};
export var get = function (key) {
    return window.localStorage.getItem(key);
};
export var removeKey = function (key) {
    window.localStorage.removeItem(key);
};
export var clear = function () {
    window.localStorage.clear();
};
export default {
    add: add,
    get: get,
    removeKey: removeKey,
    clear: clear
};
