var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { plainToClass } from 'class-transformer';
import { Package } from 'models';
import Model from 'models/Model';
var ReservationPackagePayment = /** @class */ (function (_super) {
    __extends(ReservationPackagePayment, _super);
    function ReservationPackagePayment(reservationId, debit, credit, currentPackage) {
        var _this = _super.call(this) || this;
        reservationId && _this.setReservationId(reservationId);
        debit && _this.setDebit(debit);
        credit && _this.setCredit(credit);
        currentPackage && _this.setPackage(currentPackage);
        reservationId && currentPackage && _this.setId();
        return _this;
    }
    ReservationPackagePayment.prototype.setId = function () {
        this.id = "".concat(this.getReservationId(), "-").concat(this.getPackage().getId());
    };
    ReservationPackagePayment.prototype.getId = function () {
        return this.id;
    };
    ReservationPackagePayment.prototype.setReservationId = function (reservationId) {
        this.reservationId = reservationId;
    };
    ReservationPackagePayment.prototype.getReservationId = function () {
        return this.reservationId;
    };
    ReservationPackagePayment.prototype.setDebit = function (debit) {
        this.debit = debit;
    };
    ReservationPackagePayment.prototype.getDebit = function () {
        return this.debit;
    };
    ReservationPackagePayment.prototype.setCredit = function (credit) {
        this.credit = credit;
    };
    ReservationPackagePayment.prototype.getCredit = function () {
        return this.credit;
    };
    ReservationPackagePayment.prototype.setPackage = function (p) {
        this.package = p;
    };
    ReservationPackagePayment.prototype.getPackage = function () {
        return plainToClass(Package, this.package);
    };
    ReservationPackagePayment.computeIdFormate = function (reservationId, packageId) {
        return "".concat(reservationId, "-").concat(packageId);
    };
    return ReservationPackagePayment;
}(Model));
export default ReservationPackagePayment;
