import { jsx as _jsx } from "react/jsx-runtime";
import AccountTypes from 'pages/accounting/AccountTypes';
import { Route } from 'react-router';
import AuthorizationService from '../AuthorizationService';
import { Index } from 'constants/actions';
import { AccountType as AccounTypeResource } from 'constants/resources';
var AccountTypesRoutes = function () {
    return ([
        _jsx(Route, { caseSensitive: true, path: '/account_types', element: AuthorizationService.getAuthorizedElement(_jsx(AccountTypes, {}), Index, AccounTypeResource) }, 'account-types-route')
    ]);
};
export default AccountTypesRoutes;
