import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import Icon from '@ant-design/icons';
var HeightIcon = function () {
    var Image = function () {
        return _jsx("svg", { xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", viewBox: "0 0 24 24", fill: "#647386", children: _jsx("path", { d: "M17 0a.248.248 0 0 0-.25.25V5a.75.75 0 0 1-1.5 0V.25A.248.248 0 0 0 15 0h-2a.248.248 0 0 0-.25.25V2.6a.75.75 0 0 1-1.5 0V.25A.248.248 0 0 0 11 0H9.04a.248.248 0 0 0-.25.25l-.04 4.76a.747.747 0 0 1-.75.74h-.01a.749.749 0 0 1-.74-.76L7.29.25A.248.248 0 0 0 7.04 0H5a.248.248 0 0 0-.25.25V3.8a.75.75 0 1 1-1.5 0V.25A.248.248 0 0 0 3 0a3 3 0 0 0-3 3v4a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3V3a3 3 0 0 0-3-3z", transform: "rotate(90 7.5 9.5)", "data-name": "vuesax/bold/ruler" }) });
    };
    return (_jsx(Icon, { component: Image }));
};
export default memo(HeightIcon);
