import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Col, Row, Space, Form, Button, Input } from 'antd';
import { FormInput } from 'components/AntEnhancement/FormControls';
var TextArea = Input.TextArea;
var ContactUsForm = function () {
    var onChangeName = function () { };
    var onChangeEmail = function () { };
    var onChangePhoneNumber = function () { };
    return (_jsx("div", { className: "form-part", style: { padding: '30px 100px' }, children: _jsx(Form, { layout: 'vertical', initialValues: { layout: 'vertical' }, size: 'large', children: _jsxs(Space, { direction: 'vertical', className: 'space-item', size: 'large', children: [_jsxs(Row, { children: [_jsx(Col, { xs: 24, children: _jsx(FormInput, { label: 'Name', name: "name", placeHolder: 'your name', rules: [
                                        { required: true }
                                    ], onChange: onChangeName }) }), _jsx(Col, { xs: 0, sm: 0, lg: 1, xl: 1 }), _jsx(Col, { xs: 24, children: _jsx(FormInput, { label: 'Email', name: "email", placeHolder: 'You@clinic.com', rules: [{ required: true }], onChange: onChangeEmail }) })] }), _jsxs(Row, { children: [_jsx(Col, { xs: 24, children: _jsx(FormInput, { label: 'Phone number', name: "phone_number", placeHolder: '0100 000 0000 00', rules: [{ required: true }], onChange: onChangePhoneNumber }) }), _jsx(Col, { xs: 0, sm: 0, lg: 1, xl: 1 }), _jsx(Col, { xs: 24, children: _jsx(Form.Item, { label: 'How can we help?', name: 'problem_statement', className: "form-item body---14pt-R", rules: [{ required: true }], hasFeedback: true, children: _jsx(TextArea, { rows: 4, placeholder: "tell us little about your questions", maxLength: 6 }) }) })] }), _jsxs(Row, { children: [_jsx(Col, { xs: 0, sm: 0, lg: 1, xl: 15 }), _jsx(Col, { xs: 24, children: _jsx(Form.Item, { children: _jsx(Button, { type: "primary", htmlType: "submit", className: "login-form-button", style: { width: '100%' }, children: "Log in" }) }) })] }), _jsx(Row, { children: _jsx(Col, { xs: 24, children: _jsxs("div", { className: 'Header-R-16 description-text ', children: ["you can reach us any time at ", _jsx("span", { className: 'link', children: "HI@clnicmngr.com" })] }) }) })] }) }) }));
};
export default ContactUsForm;
