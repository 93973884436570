import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Row, Col, Form, Checkbox } from 'antd';
import localeTranslator from 'library/Hocs/LocalTranslator';
import { FormDatePicker } from 'components/AntEnhancement/FormControls';
var ChangeAvailability = function (props) {
    var onChangeFinishedAt = function (finishedAt) {
        finishedAt && props.packagePrice.setFinishedAt(finishedAt.toDate());
    };
    var onChangeIsEnable = function (event) {
        props.packagePrice.setIsEnabled(event.target.checked);
    };
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "Header-18-M form-title", children: props.translate('availabilityInfo') }), _jsxs(Row, { children: [_jsx(Col, { xs: 24, sm: 24, lg: 12, xl: 12, children: _jsx(FormDatePicker, { label: props.translate('endDate'), name: "endDate", rules: [], placeHolder: props.translate('selectDate'), initialValue: props.packagePrice.getFinishedAtDate(), onChange: onChangeFinishedAt }) }), _jsx(Col, { xs: 2, sm: 2, lg: 1, xl: 1 })] }), _jsx(Row, { children: _jsx(Col, { children: _jsx(Form.Item, { className: "form-item body---14pt-R", children: _jsx(Checkbox, { onChange: onChangeIsEnable, defaultChecked: props.packagePrice.getIsEnabled(), children: props.translate('isEnabled') }) }) }) })] }));
};
export default localeTranslator(ChangeAvailability, 'packagePriceForm');
