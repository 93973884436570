var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import Model from 'models/Model';
var ReservationPackagePayment = /** @class */ (function (_super) {
    __extends(ReservationPackagePayment, _super);
    function ReservationPackagePayment() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ReservationPackagePayment.prototype.setPatientId = function (patientId) {
        this.patient_id = patientId;
    };
    ReservationPackagePayment.prototype.getPatientId = function () { return this.patient_id; };
    ReservationPackagePayment.prototype.setAvailable = function (available) {
        this.available = available;
    };
    ReservationPackagePayment.prototype.getAvailable = function () { return this.available; };
    return ReservationPackagePayment;
}(Model));
export default ReservationPackagePayment;
