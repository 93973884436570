import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Row, Col, Form } from 'antd';
import localeTranslator from 'library/Hocs/LocalTranslator';
import { FormInput, FormInputNumber, FormSelect } from 'components/AntEnhancement/FormControls';
import './style.css';
import TextArea from 'antd/es/input/TextArea';
var ProductForm = function (props) {
    var onChangeName = function (event) {
        props.product.setName(event.target.value);
    };
    var onChangeBarcode = function (event) {
        props.product.setBarcode(event.target.value);
    };
    var onChangeDescription = function (event) {
        var _a;
        (_a = props.product) === null || _a === void 0 ? void 0 : _a.setDescription(event.target.value);
    };
    var onChangeProductCategory = function (value) { return props.product.setProductCategorytId(Number(value)); };
    var onChangeUnitMesurement = function (value) { return props.product.setUnitMeasurementId(Number(value)); };
    var onChangeZip = function (zip) { return zip && props.product.setZIP(Number(zip)); };
    var getUnitMeasurements = function () {
        return props.unitMeaurements.map(function (unitMeasurement) { return ({
            value: unitMeasurement.getId(),
            title: unitMeasurement.getName()
        }); });
    };
    var getProductCategories = function () {
        return props.productCategories.map(function (productCategory) { return ({
            value: productCategory.getId(),
            title: productCategory.getName()
        }); });
    };
    return (_jsx(_Fragment, { children: _jsxs("div", { className: "form-part", children: [_jsx(Row, { children: _jsx(Col, { xs: 24, sm: 24, lg: 24, xl: 24, children: _jsx(FormInput, { label: props.translate('name'), name: "name", placeHolder: props.translate('name'), rules: [
                                { required: true, message: props.translate('errors.name') }
                            ], onChange: onChangeName, initialValue: props.product.getName() }) }) }), _jsxs(Row, { children: [_jsx(Col, { xs: 24, sm: 24, lg: 11, xl: 11, children: _jsx(FormSelect, { label: props.translate('productCategory'), name: "productCategory", rules: [
                                    { required: true, message: props.translate('errors.productCategory') }
                                ], placeHolder: props.translate('productCategory'), onSelect: onChangeProductCategory, options: getProductCategories(), initialValue: props.product.getProductCategoryId() || null }) }), _jsx(Col, { xs: 24, sm: 24, lg: 2, xl: 2 }), _jsx(Col, { xs: 24, sm: 24, lg: 11, xl: 11, children: _jsx(FormSelect, { label: props.translate('unitMeasurement'), name: "unitMeasurement", rules: [
                                    { required: true, message: props.translate('errors.unitMeasurement') }
                                ], placeHolder: props.translate('unitMeasurement'), onSelect: onChangeUnitMesurement, options: getUnitMeasurements(), initialValue: props.product.getUnitMeasurementId() || null }) })] }), _jsxs(Row, { children: [_jsx(Col, { xs: 24, sm: 24, lg: 11, xl: 11, children: _jsx(FormInputNumber, { label: props.translate('zip'), name: 'zip', min: 0, rules: [], placeHolder: props.translate('zip'), onChange: onChangeZip }) }), _jsx(Col, { xs: 24, sm: 24, lg: 2, xl: 2 }), _jsx(Col, { xs: 24, sm: 24, lg: 11, xl: 11, children: _jsx(FormInput, { label: props.translate('barcode'), name: "barcode", placeHolder: props.translate('barcode'), rules: [], onChange: onChangeBarcode, initialValue: props.product.getBarcode() }) })] }), _jsx(Row, { children: _jsx(Col, { xs: 24, sm: 24, lg: 24, xl: 24, children: _jsx(Form.Item, { label: props.translate('description'), name: "description", className: "form-item body---14pt-R", children: _jsx(TextArea, { className: "text-area-border space-item", placeholder: props.translate('description'), onChange: onChangeDescription, rows: 9 }) }) }) })] }) }));
};
export default localeTranslator(ProductForm, 'productForm');
