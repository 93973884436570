export var Create = 1;
export var Index = 2;
export var Show = 3;
export var Update = 4;
export var SupplierTypes = 5;
export var MiniIndex = 6;
export var BulkInsert = 7;
export var ClosePeriod = 8;
export var CreatePatientTransaction = 9;
export var Revert = 10;
export var MetaInfo = 11;
export var Refund = 12;
export var ListAllData = 13;
export var MarkAsPending = 14;
export var MarkAsUnReached = 15;
export var MarkAsConfirmed = 16;
export var MarkAsLate = 17;
export var MarkAsWaiting = 18;
export var MarkAsInVisit = 19;
export var MarkAsAfterWork = 20;
export var MarkAsFinished = 21;
export var MarkAsCancelled = 22;
export var BulkCreate = 23;
export var Void = 24;
export var UpdateAvailability = 25;
export var Prices = 26;
export var Entities = 27;
export var Appointments = 28;
export var CurrentInVisitAppointment = 29;
export var SearchByPhone = 30;
export var PackageReports = 31;
export var DoctorReports = 32;
export var PatientReports = 33;
export var ClinicReports = 34;
