import { jsx as _jsx } from "react/jsx-runtime";
import FinancialPeriods from 'pages/accounting/FinancialPeriods';
import { Route } from 'react-router';
import AuthorizationService from '../AuthorizationService';
import { Index } from 'constants/actions';
import { FinancialPeriod as FinancialPeriodResource } from 'constants/resources';
var FinancialPeriodsRoutes = function () {
    return ([
        _jsx(Route, { caseSensitive: true, path: '/financial_periods', element: AuthorizationService.getAuthorizedElement(_jsx(FinancialPeriods, {}), Index, FinancialPeriodResource) }, 'financial-periods-route')
    ]);
};
export default FinancialPeriodsRoutes;
