import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Space, Col, Row } from 'antd';
import localeTranslator from 'library/Hocs/LocalTranslator';
import '../../style.css';
var MainInfo = function (props) {
    var supplier = props.supplier;
    return (_jsxs("div", { id: "profile-content", className: "patient-info", children: [_jsx("div", { className: "Header-18-M form-title", children: props.translate('header.mainInfo') }), _jsxs(Space, { direction: "vertical", size: 40, children: [_jsx(Space, { direction: "vertical", size: 43, children: _jsx(Row, { children: _jsx(Col, { xs: 24, lg: 10, xl: 10, children: _jsxs(Space, { direction: "vertical", children: [_jsx("div", { className: 'Header-16-M description-text', children: props.translate('supplierInfo.name') }), _jsx("div", { className: 'body---14pt-R', children: supplier.getName() })] }) }) }) }), _jsx(Space, { direction: "vertical", size: 43, children: _jsx(Row, { children: _jsx(Col, { xs: 24, lg: 10, xl: 10, children: _jsxs(Space, { direction: "vertical", children: [_jsx("div", { className: 'Header-16-M description-text', children: props.translate('supplierInfo.email') }), _jsx("div", { className: 'body---14pt-R', children: supplier.getEmail() })] }) }) }) }), _jsx(Space, { direction: "vertical", size: 43, children: _jsx(Row, { children: _jsx(Col, { xs: 24, lg: 10, xl: 10, children: _jsxs(Space, { direction: "vertical", children: [_jsx("div", { className: 'Header-16-M description-text', children: props.translate('supplierInfo.type') }), _jsx("div", { className: 'body---14pt-R', children: supplier.getDisplayedSupplierType() })] }) }) }) })] })] }));
};
export default React.memo(localeTranslator(MainInfo, 'showSupplier'));
