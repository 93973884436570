import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Row, Col, Form, Space, Button } from 'antd';
import localeTranslator from 'library/Hocs/LocalTranslator';
import { FormInput } from 'components/AntEnhancement/FormControls';
import './style.css';
var useState = React.useState, useEffect = React.useEffect;
var EmailForm = function (props) {
    var form = Form.useForm()[0];
    var _a = useState(false), emailDisabled = _a[0], setEmailDisabled = _a[1];
    var onChangeEmail = function (event) {
        props.user.setEmail(event.target.value);
    };
    var onSubmit = function () {
        setEmailDisabled(true);
        props.updateEmail({ user: props.user });
    };
    useEffect(function () {
        if (props.disableReset) {
            setEmailDisabled(false);
        }
    }, [props.disableReset]);
    return (_jsxs("div", { className: "form-part", children: [_jsx("div", { className: "Header-18-M form-title", children: props.translate('emailAndPassword.changeEmail') }), _jsx(Form, { layout: 'vertical', form: form, initialValues: { layout: 'vertical' }, size: 'large', onFinish: onSubmit, children: _jsxs(Space, { direction: 'vertical', size: 60, style: { width: '100%' }, children: [_jsx(Row, { children: _jsx(Col, { xs: 24, sm: 24, lg: 24, xl: 24, children: _jsx(FormInput, { label: props.translate('emailAndPassword.email'), name: "firstName", placeHolder: props.translate('emailAndPassword.email'), rules: [
                                        { required: true, message: props.translate('emailAndPassword.errors.email') }
                                    ], onChange: onChangeEmail, initialValue: props.user.getEmail() }) }) }), _jsx(Space, { children: _jsx(Form.Item, { children: _jsx(Button, { type: "primary", htmlType: "submit", className: "normal-button Header-16-M", disabled: emailDisabled, children: props.translate('common:buttons.submit') }) }) })] }) })] }));
};
export default localeTranslator(EmailForm, 'settings');
