import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Space } from 'antd';
import services from 'services';
import EmailForm from 'components/forms/settings/EmailForm';
import PasswordForm from 'components/forms/settings/PasswordForm';
var EditMainInfo = function () {
    var userService = services.userService;
    var currentUser = userService.useCurrentUser().data;
    var _a = userService.useChangeEmail(), updateEmail = _a.mutate, emailError = _a.isError, emailSuccess = _a.isSuccess;
    var _b = userService.useChangePassword(), updatePassword = _b.mutate, passwordError = _b.isError, passwordSuccess = _b.isSuccess;
    return (_jsx(_Fragment, { children: _jsxs(Space, { direction: 'vertical', size: 0, style: { width: '100%' }, children: [currentUser &&
                    _jsx(EmailForm, { user: currentUser, updateEmail: updateEmail, disableReset: emailError || emailSuccess }), currentUser &&
                    _jsx(PasswordForm, { user: currentUser, updatePassword: updatePassword, disableReset: passwordError || passwordSuccess })] }) }));
};
export default EditMainInfo;
