import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, Menu, Dropdown } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import services from 'services';
import SearchHeader from 'components/PageHeader/SearchHeader';
import EmptyComponent from 'components/EmptyComponent';
import localeTranslator from 'library/Hocs/LocalTranslator';
import { Supplier as SupplierResource } from 'constants/resources';
import './style.css';
var Suppliers = function (props) {
    var navigate = useNavigate();
    var _a = useState(1), pageNumber = _a[0], setPageNumber = _a[1];
    var supplierService = services.supplierService;
    var data = supplierService.useSuppliers(pageNumber).data;
    var goToSupplier = function (supplierId) { return function () { return navigate("/suppliers/".concat(supplierId)); }; };
    var menu = function (supplierId) {
        return (_jsx(Menu, { children: _jsx(Menu.Item, { onClick: goToSupplier(supplierId), children: "Show" }, "1") }));
    };
    var columns = [
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('id') }),
            key: 'id',
            render: function (text, record, index) {
                return _jsx("div", { className: "body---14pt-R", children: record.getId() }, 'id-' + index);
            }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('name') }),
            key: 'productName',
            render: function (text, record, index) {
                return _jsx("div", { className: "body---14pt-R table-body-content", children: record.getName() }, 'productName-' + index);
            }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('email') }),
            key: 'email',
            render: function (text, record, index) {
                return _jsx("div", { className: "body---14pt-R table-body-content", children: record.getEmail() }, 'email-' + index);
            }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('supplierType') }),
            key: 'supplierType',
            render: function (text, record, index) {
                return _jsx("div", { className: "body---14pt-R table-body-content", children: record.getDisplayedSupplierType() }, 'supplierType-' + index);
            }
        },
        {
            title: _jsx("div", { className: "body---14pt-R", children: props.translate('action') }),
            fixed: 'right',
            render: function (text, record) {
                return _jsx(Dropdown.Button, { icon: _jsx(EllipsisOutlined, { rotate: 90, className: 'action-button' }), overlay: menu(record.getId()), className: "three-dot" });
            }
        }
    ];
    var getNextPage = function (currentPageNumber) { return setPageNumber(currentPageNumber); };
    var goToNewSupplierForm = function () { return navigate('/suppliers/new'); };
    return (_jsxs("div", { children: [_jsx(SearchHeader, { title: props.translate('header'), addButtonText: props.translate('common:buttons.newSupplier'), addButtonFunction: goToNewSupplierForm, resource: SupplierResource }), _jsx("div", { className: "page-content", children: _jsx(Table, { columns: columns, className: 'Header-R-14', pagination: {
                        position: ['bottomRight'],
                        pageSize: 25,
                        total: (data === null || data === void 0 ? void 0 : data.meta) ? (data === null || data === void 0 ? void 0 : data.meta.getPagesCount()) * 25 : 0,
                        onChange: getNextPage,
                        showSizeChanger: false,
                        responsive: true
                    }, 
                    // scroll={{ x: 1300 }}
                    dataSource: data === null || data === void 0 ? void 0 : data.suppliers, locale: {
                        emptyText: _jsx(EmptyComponent, {})
                    } }) })] }));
};
export default localeTranslator(Suppliers, 'suppliers');
