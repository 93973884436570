import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import Icon from '@ant-design/icons';
var ReservationInfoIcon = function () {
    var Image = function () {
        return _jsx("svg", { xmlns: "http://www.w3.org/2000/svg", width: "1.5em", height: "1.5em", fill: "currentColor", viewBox: "0 0 25 25", focusable: "false", "data-icon": "user", "aria-hidden": "true", children: _jsxs("g", { id: "clipboard-tick", children: [_jsx("path", { id: "Vector", d: "M6.59 0h-4.7A1.886 1.886 0 0 0 0 1.88v.94A1.878 1.878 0 0 0 1.88 4.7h4.71a1.878 1.878 0 0 0 1.88-1.88v-.94A1.871 1.871 0 0 0 6.59 0z", transform: "translate(7.76 2)" }), _jsx("path", { id: "Vector-2", "data-name": "Vector", d: "M13.95.737a2.9 2.9 0 0 1-2.89 2.89h-4.7A2.9 2.9 0 0 1 3.47.737a.747.747 0 0 0-1.1-.65A4.472 4.472 0 0 0 0 4.037v9.41a4.481 4.481 0 0 0 4.47 4.47h8.48a4.481 4.481 0 0 0 4.47-4.47v-9.41a4.472 4.472 0 0 0-2.37-3.95.747.747 0 0 0-1.1.65zm-1.9 7.91-4 4a.748.748 0 0 1-1.06 0l-1.5-1.5a.75.75 0 0 1 1.06-1.06l.97.97 3.47-3.47a.75.75 0 1 1 1.06 1.06z", transform: "translate(3.29 4.083)" })] }) });
    };
    return (_jsx(Icon, { component: Image }));
};
export default memo(ReservationInfoIcon);
