import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Space, Col, Row } from 'antd';
import localeTranslator from 'library/Hocs/LocalTranslator';
import '../../style.css';
var DocumentLineGroupMainInfo = function (props) {
    var documentLineGroup = props.documentLineGroup;
    return (_jsxs("div", { id: "profile-content", className: "patient-info", children: [_jsx("div", { className: "Header-18-M form-title", children: props.translate('header.documentLineGroupMainInfo') + ' # ' + documentLineGroup.getId() }), _jsx(Space, { direction: "vertical", size: 40, children: _jsxs(Row, { children: [_jsx(Col, { xs: 24, lg: 6, xl: 6, children: _jsxs(Space, { direction: "vertical", children: [_jsx("div", { className: 'Header-16-M description-text', children: props.translate('documentLineGroupInfo.name') }), _jsx("div", { className: 'body---14pt-R', children: documentLineGroup.getName() })] }) }), _jsx(Col, { xs: 15, lg: 2, xl: 2 }), _jsx(Col, { xs: 24, lg: 6, xl: 6, children: _jsxs(Space, { direction: "vertical", children: [_jsx("div", { className: 'Header-16-M description-text', children: props.translate('documentLineGroupInfo.paidOn') }), _jsx("div", { className: 'body---14pt-R', children: documentLineGroup.getDisplayedPaidOn() })] }) }), _jsx(Col, { xs: 15, lg: 2, xl: 2 }), _jsx(Col, { xs: 24, lg: 6, xl: 6, children: _jsxs(Space, { direction: "vertical", children: [_jsx("div", { className: 'Header-16-M description-text', children: props.translate('documentLineGroupInfo.paidFrom') }), _jsx("div", { className: 'body---14pt-R', children: documentLineGroup.getDisplayedPaidFrom() })] }) })] }) })] }));
};
export default React.memo(localeTranslator(DocumentLineGroupMainInfo, 'showDocument'));
