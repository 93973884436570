import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Input, Row, Col, Form, Button, Dropdown } from 'antd';
import { FilterIcon } from 'assets/icons';
import localeTranslator from 'library/Hocs/LocalTranslator';
import services from 'services';
import './style.css';
var SearchHeader = function (props) {
    var debounceTimeOut;
    var onChangeSearchValue = function (event) {
        clearTimeout(debounceTimeOut);
        debounceTimeOut = setTimeout(function () {
            props.onSearchValueChange && props.onSearchValueChange(event.target.value);
        }, 500);
    };
    var permissionService = services.permissionService;
    return (_jsxs("div", { className: "page-header", children: [_jsx("div", { className: "Header-24-bold title", children: props.title }), _jsxs(Row, { gutter: { xs: 24, sm: 16, md: 24, lg: 32 }, children: [_jsx(Col, { xs: 24, sm: 24, lg: 12, xl: 10, children: _jsx(Input, { size: 'large', placeholder: props.translate('search'), className: "filter-input body---14pt-R", onChange: onChangeSearchValue, suffix: props.menu && _jsx(Dropdown.Button, { icon: _jsx(FilterIcon, {}), overlay: props.menu, className: "three-dot" }) }) }), _jsx(Col, { xs: 24, sm: 24, lg: 5, xl: 9 }), _jsx(Col, { xs: 24, sm: 24, lg: 7, xl: 5, children: props.addButtonText && (props.resource && permissionService.hasPermissionToAdd(props.resource)) &&
                            _jsx(Form.Item, { children: _jsx(Button, { className: props.addButtonClasses || "normal-button Header-16-M", onClick: props.addButtonFunction, children: props.addButtonText }) }) })] })] }));
};
export default localeTranslator(SearchHeader, 'common');
