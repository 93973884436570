import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import Icon from '@ant-design/icons';
var OtherIcon = function () {
    var Image = function () {
        return _jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: "1em", height: "1em", fill: "currentColor", viewBox: "0 0 20 20", focusable: "false", "data-icon": "user", "aria-hidden": "true", children: [_jsx("path", { id: "Path_55", "data-name": "Path 55", d: "M5.779 0H1.651A1.656 1.656 0 0 0 0 1.651v4.128A1.656 1.656 0 0 0 1.651 7.43h4.128A1.656 1.656 0 0 0 7.43 5.779V1.651A1.656 1.656 0 0 0 5.779 0zm0 9.081H1.651A1.656 1.656 0 0 0 0 10.732v4.128a1.656 1.656 0 0 0 1.651 1.651h4.128A1.656 1.656 0 0 0 7.43 14.86v-4.128a1.656 1.656 0 0 0-1.651-1.651zm9.081 0h-4.128a1.656 1.656 0 0 0-1.651 1.651v4.128a1.656 1.656 0 0 0 1.651 1.651h4.128a1.656 1.656 0 0 0 1.651-1.651v-4.128a1.656 1.656 0 0 0-1.651-1.651z" }), _jsx("circle", { id: "Ellipse_14", "data-name": "Ellipse 14", cx: "3.5", cy: "3.5", r: "3.5", transform: "translate(9.511)" })] });
    };
    return (_jsx(Icon, { component: Image }));
};
export default memo(OtherIcon);
