import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import Icon from '@ant-design/icons';
var AgeIcon = function () {
    var Image = function () {
        return _jsx("svg", { xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", viewBox: "0 0 24 24", fill: "#647386", children: _jsx("g", { id: "vuesax_bold_cake", "data-name": "vuesax/bold/cake", transform: "translate(-684 -316)", children: _jsxs("g", { id: "cake", children: [_jsx("path", { id: "Vector", d: "M17.78 3v1h-.87a1.342 1.342 0 0 0-1.34 1.35v.3a1.345 1.345 0 1 1-2.69 0v-.3a1.345 1.345 0 1 0-2.69 0v.3a1.35 1.35 0 1 1-2.7 0v-.3a1.345 1.345 0 1 0-2.69 0v.3a1.345 1.345 0 1 1-2.69 0v-.32A1.345 1.345 0 0 0 .79 3.98H0V3A3.064 3.064 0 0 1 2.45.11 3.6 3.6 0 0 1 3.33 0h11.12a3.6 3.6 0 0 1 .88.11A3.064 3.064 0 0 1 17.78 3z", transform: "translate(687.11 326)" }), _jsx("path", { id: "Vector-2", "data-name": "Vector", d: "M12.88 2.17v1.41A4.3 4.3 0 0 0 12 3.5H.88a4.308 4.308 0 0 0-.88.09V2.17A2.3 2.3 0 0 1 2.42 0h8.04a2.3 2.3 0 0 1 2.42 2.17z", transform: "translate(689.56 321)" }), _jsx("path", { id: "Vector-3", "data-name": "Vector", d: "M1.5.65v1.46H.73A2.863 2.863 0 0 0 0 2.2V.65A.714.714 0 0 1 .75 0a.714.714 0 0 1 .75.65z", transform: "translate(691.25 318.9)" }), _jsx("path", { id: "Vector-4", "data-name": "Vector", d: "M1.5.75v1.77a2.477 2.477 0 0 0-.73-.1H0V.75A.755.755 0 0 1 .75 0a.755.755 0 0 1 .75.75z", transform: "translate(699.25 318.58)" }), _jsx("path", { id: "Vector-5", "data-name": "Vector", d: "M1.5.82V3H0V.82A.792.792 0 0 1 .75 0a.792.792 0 0 1 .75.82z", transform: "translate(695.25 318)" }), _jsx("path", { id: "Vector-6", "data-name": "Vector", d: "M20 5.77a.755.755 0 0 1-.75.75H.75a.75.75 0 0 1 0-1.5h.36V0h.61v.07a2.9 2.9 0 0 0 2.19 2.87 2.822 2.822 0 0 0 2.65-.74.978.978 0 0 1 1.38-.01 2.869 2.869 0 0 0 2.01.83 2.827 2.827 0 0 0 2.01-.83.987.987 0 0 1 1.38.01 2.807 2.807 0 0 0 2.65.74A2.9 2.9 0 0 0 18.18.07V.02h.71v5h.36a.755.755 0 0 1 .75.75z", transform: "translate(686 331.48)" })] }) }) });
    };
    return (_jsx(Icon, { component: Image }));
};
export default memo(AgeIcon);
