// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-follow-up-button {
  height: 41px !important;
  padding: 12px 180px 12px 180px !important;
  border-radius: 5px !important;
  background-color: var(--success-color-state-for-inputs) !important;
  border-color: white !important;
}

.new-follow-up-line {
  width: 406.1px;
  height: 1px;
  margin: 0 0 10.3px 0px;
  background-color: var(--border-color);
}

.follow-up-form-part {
  margin: 0 20px;
}
`, "",{"version":3,"sources":["webpack://./src/components/forms/MedicalTest/style.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,yCAAyC;EACzC,6BAA6B;EAC7B,kEAAkE;EAClE,8BAA8B;AAChC;;AAEA;EACE,cAAc;EACd,WAAW;EACX,sBAAsB;EACtB,qCAAqC;AACvC;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".add-follow-up-button {\n  height: 41px !important;\n  padding: 12px 180px 12px 180px !important;\n  border-radius: 5px !important;\n  background-color: var(--success-color-state-for-inputs) !important;\n  border-color: white !important;\n}\n\n.new-follow-up-line {\n  width: 406.1px;\n  height: 1px;\n  margin: 0 0 10.3px 0px;\n  background-color: var(--border-color);\n}\n\n.follow-up-form-part {\n  margin: 0 20px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
