import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Button, Space, Form } from 'antd';
import services from 'services';
import BasicForm from 'components/forms/patients/BasicForm';
import PatientDetailsForm from 'components/forms/patients/PatientDetailsForm';
import localeTranslator from 'library/Hocs/LocalTranslator';
import { useParams } from 'react-router';
import Loader from 'components/AntEnhancement/Loader';
var UpdatePatient = function (props) {
    var id = useParams().id;
    var patientService = services.patientService, patientAreaService = services.patientAreaService;
    var _a = useState(false), disabled = _a[0], setDisabled = _a[1];
    var _b = patientService.useUpdatePatient(), updatePatient = _b.mutate, errorResponse = _b.error;
    var patient = patientService.useGetPatient(Number(id)).data;
    var patientAreas = patientAreaService.usePatientAreas().data;
    var form = Form.useForm()[0];
    var submit = function () {
        setDisabled(true);
        patient && updatePatient({ patient: patient, successMessage: props.translate('patientCreated') });
    };
    var scrollToFirstElement = function () { return form.scrollToField('firstName', { behavior: 'smooth' }); };
    return (_jsx(Loader, { renderSpinner: !patient, children: _jsxs("div", { style: { margin: 20, marginTop: 36 }, children: [_jsx("div", { className: "Header-24-bold title", children: props.translate('title') }), _jsxs(Form, { layout: 'vertical', form: form, initialValues: { layout: 'vertical' }, size: 'large', onFinish: submit, onFinishFailed: scrollToFirstElement, children: [patient && patientAreas && _jsx(BasicForm, { patient: patient, patientAreas: patientAreas }), _jsx("div", { className: "hidden-divider" }), patient && _jsx(PatientDetailsForm, { patient: patient }), _jsx("div", { className: "hidden-divider" }), _jsx("div", { className: "hidden-divider" }), _jsx(Space, { children: _jsx(Form.Item, { children: _jsx(Button, { type: "primary", htmlType: "submit", className: "normal-button Header-16-M", disabled: disabled, children: props.translate('common:buttons.submit') }) }) })] })] }) }));
};
export default localeTranslator(UpdatePatient, 'patientUpdate');
