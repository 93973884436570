var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { instanceToPlain, plainToInstance } from 'class-transformer';
import queries from 'constants/queries';
import { FollowUp } from 'models';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import ApiService from './ApiService';
var FollowUpService = /** @class */ (function (_super) {
    __extends(FollowUpService, _super);
    function FollowUpService() {
        var _this = _super.call(this) || this;
        _this.medicalTestsRoot = 'follow_ups';
        _this.successCreationMessage = '';
        return _this;
    }
    FollowUpService.prototype.useCreateFollowUp = function () {
        var _this = this;
        var queryClient = useQueryClient();
        var patientId;
        return useMutation(function (_a) {
            var followUp = _a.followUp, successMessage = _a.successMessage;
            patientId = followUp.getPatientId();
            _this.successCreationMessage = successMessage;
            var data = { follow_ups: instanceToPlain(followUp) };
            return _this.post(_this.medicalTestsRoot, { data: data });
        }, {
            onSuccess: function () {
                queryClient.invalidateQueries([queries.patients, patientId, queries.followUps]);
                _this.dipatachCreationSuccess({ successMessage: _this.successCreationMessage });
            }
        });
    };
    FollowUpService.prototype.usePatientFollowUps = function (patientId) {
        var _this = this;
        return useQuery([queries.patients, patientId, queries.followUps], function () {
            return _this.get(_this.medicalTestsRoot, {
                patient_id: patientId
            });
        }, {
            keepPreviousData: true,
            select: function (_a) {
                var data = _a.data;
                return plainToInstance(FollowUp, data);
            }
        });
    };
    FollowUpService.getInstance = function () {
        if (!FollowUpService.followUpService) {
            FollowUpService.followUpService = new FollowUpService();
        }
        return FollowUpService.followUpService;
    };
    return FollowUpService;
}(ApiService));
export default FollowUpService;
