import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Divider } from 'antd';
import Article from 'library/components/Article';
import './style.css';
var Prescription = function (props) {
    var renderPrescriptionItems = function () { return props.prescriptionItems.map(function (pi, index) {
        return (_jsxs(_Fragment, { children: [_jsx(Article, { title: pi.getMedicineName(), description: pi.getDescription() }), index + 1 < props.prescriptionItems.length && _jsx("div", { className: "medicine-divider" })] }));
    }); };
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "Header-16-M prescription-title", children: props.title }), _jsxs("div", { className: "prescription-items-container", children: [_jsx(Divider, { type: "vertical", className: "verticale-divider" }), _jsx("div", { className: "prescription-item", children: renderPrescriptionItems() })] })] }));
};
export default Prescription;
