import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Form, Input, Row, Col } from 'antd';
import { FormInput } from 'components/AntEnhancement/FormControls';
import localeTranslator from 'library/Hocs/LocalTranslator';
import './style.css';
var SignUpForm = function (props) {
    var onChangeEmail = function (event) {
        var _a;
        (_a = props.user) === null || _a === void 0 ? void 0 : _a.setEmail(event.target.value);
    };
    var onChangePassword = function (event) {
        var _a;
        (_a = props.user) === null || _a === void 0 ? void 0 : _a.setPassword(event.target.value);
    };
    var onChangePasswordConfirmation = function (event) {
        var _a;
        (_a = props.user) === null || _a === void 0 ? void 0 : _a.setPasswordConfirmation(event.target.value);
    };
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "Header-18-M form-title", children: props.translate('signUpInfo') }), _jsxs("div", { className: "form-part", children: [_jsx(Row, { children: _jsx(Col, { xs: 24, sm: 24, lg: 24, xl: 24, children: _jsx(FormInput, { label: props.translate('email'), name: "email", placeHolder: props.translate('email'), rules: [
                                    { required: true, message: props.translate('errors.email.required') },
                                    { type: 'email', message: props.translate('errors.email.type') }
                                ], onChange: onChangeEmail }) }) }), _jsxs(Row, { children: [_jsx(Col, { xs: 24, sm: 24, lg: 11, xl: 11, children: _jsx(Form.Item, { label: props.translate('password'), name: "password", required: true, className: "form-item body---14pt-R", rules: [
                                        { required: true, message: props.translate('errors.password.required') },
                                        { min: 8, message: props.translate('errors.password.minLength') }
                                    ], hasFeedback: true, children: _jsx(Input.Password, { className: "input-border", placeholder: props.translate('password'), onChange: onChangePassword }) }) }), _jsx(Col, { xs: 0, sm: 0, lg: 1, xl: 1 }), _jsx(Col, { xs: 24, sm: 24, lg: 12, xl: 12, children: _jsx(Form.Item, { label: props.translate('passwordConfirmation'), name: "passwordConfirmation", required: true, className: "form-item body---14pt-R", rules: [
                                        {
                                            required: true,
                                            message: props.translate('errors.passwordConfirmation.required'),
                                        },
                                        function (_a) {
                                            var getFieldValue = _a.getFieldValue;
                                            return ({
                                                validator: function (_, value) {
                                                    if (!value || getFieldValue('password') === value) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(new Error(props.translate('errors.passwordConfirmation.match')));
                                                },
                                            });
                                        },
                                    ], hasFeedback: true, children: _jsx(Input.Password, { className: "input-border", placeholder: props.translate('passwordConfirmation'), onChange: onChangePasswordConfirmation }) }) })] })] })] }));
};
export default localeTranslator(SignUpForm, 'userForm');
