import { jsx as _jsx } from "react/jsx-runtime";
import Stocks from 'pages/inventory/Stocks';
import { Route } from 'react-router';
import AuthorizationService from '../AuthorizationService';
import { Index } from 'constants/actions';
import { Stock as StockResource } from 'constants/resources';
var SuppliersRoutes = function () {
    return ([
        _jsx(Route, { caseSensitive: true, path: '/stocks', element: AuthorizationService.getAuthorizedElement(_jsx(Stocks, {}), Index, StockResource) }, 'stocks-route')
    ]);
};
export default SuppliersRoutes;
