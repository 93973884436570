import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Modal, Button, Form, Input } from 'antd';
import services from 'services';
var Login = function (props) {
    var userService = services.userService;
    var mutate = userService.useLogin().mutate;
    var onFinish = function (values) {
        mutate(values);
    };
    var onCancel = function () {
        props.showLoginModal(!props.isModalVisible);
    };
    return (_jsx(Modal, { title: "Basic Modal", open: props.isModalVisible, onCancel: onCancel, footer: null, children: _jsxs(Form, { name: "basic", labelCol: { span: 8 }, wrapperCol: { span: 16 }, initialValues: { remember: true }, onFinish: onFinish, autoComplete: "off", children: [_jsx(Form.Item, { label: "email", name: "email", rules: [{ required: true, message: 'Please input your email!' }], children: _jsx(Input, {}) }), _jsx(Form.Item, { label: "Password", name: "password", rules: [{ required: true, message: 'Please input your password!' }], children: _jsx(Input.Password, {}) }), _jsx(Form.Item, { label: "Clinic code", name: "clinic_code", rules: [{ required: true, message: 'Please input your clinic code!' }], children: _jsx(Input, {}) }), _jsx(Form.Item, { wrapperCol: { offset: 8, span: 16 }, children: _jsx(Button, { type: "primary", htmlType: "submit", children: "Submit" }) })] }) }));
};
export default Login;
