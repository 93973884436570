var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { instanceToPlain, plainToInstance } from 'class-transformer';
import queries from 'constants/queries';
import { JobTitle } from 'models';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import ApiService from './ApiService';
var JobTitleService = /** @class */ (function (_super) {
    __extends(JobTitleService, _super);
    function JobTitleService() {
        var _this = _super.call(this) || this;
        _this.jobTitlesRoot = 'job_titles';
        _this.successMessage = '';
        return _this;
    }
    JobTitleService.prototype.useJobTitles = function () {
        var _this = this;
        return useQuery(queries.jobTitles, function () {
            return _this.get(_this.jobTitlesRoot);
        }, {
            select: function (response) {
                var data = response.data;
                return plainToInstance(JobTitle, data);
            }
        });
    };
    JobTitleService.prototype.getJobTitleDataByID = function (jobTitles, id) {
        return jobTitles === null || jobTitles === void 0 ? void 0 : jobTitles.find(function (jobTitle) { return jobTitle.getId() === id; });
    };
    JobTitleService.prototype.useCreateJobTitle = function () {
        var _this = this;
        var queryClient = useQueryClient();
        return useMutation(function (_a) {
            var jobTitle = _a.jobTitle, successMessage = _a.successMessage;
            _this.successMessage = successMessage;
            var data = { job_title: instanceToPlain(jobTitle) };
            return _this.post(_this.jobTitlesRoot, { data: data });
        }, {
            retry: false,
            onSuccess: function () {
                queryClient.invalidateQueries(queries.jobTitles);
                _this.dipatachCreationSuccess({ successMessage: _this.successMessage });
            }
        });
    };
    JobTitleService.getInstance = function () {
        if (!JobTitleService.jobTitleService) {
            JobTitleService.jobTitleService = new JobTitleService();
        }
        return JobTitleService.jobTitleService;
    };
    return JobTitleService;
}(ApiService));
export default JobTitleService;
