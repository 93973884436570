// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.empty-user-image {
  background-color: var(--success-color-state) !important;
}

.tag {
  border-radius: 14px;
  margin: 11px 8px 43px 8px;
  padding: 4px 17.1px 4px 15.1px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.job-tag-color {
  background-color: rgba(var(--profile-job-tag-background-color-hex), 0.07);
  color: var(--profile-job-tag-color);
}

.availability-tag-color {
  background-color: rgba(var(--profile-availability-background-color-hex), 0.07);
  color: var(--profile-availability-color);
  fill: var(--profile-availability-color);
}

.profile-header-container {
  display: flex;
  align-items: center;
  padding-inline: 36px;
}

.ant-layout-rtl .container {
  right: 325px;
  left: 0;
}

.text-container {
  margin-top: 40px;
}
`, "",{"version":3,"sources":["webpack://./src/components/PageHeader/ProfileHeader/style.css"],"names":[],"mappings":"AAAA;EACE,uDAAuD;AACzD;;AAEA;EACE,mBAAmB;EACnB,yBAAyB;EACzB,8BAA8B;EAC9B,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,yEAAyE;EACzE,mCAAmC;AACrC;;AAEA;EACE,8EAA8E;EAC9E,wCAAwC;EACxC,uCAAuC;AACzC;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,oBAAoB;AACtB;;AAEA;EACE,YAAY;EACZ,OAAO;AACT;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".empty-user-image {\n  background-color: var(--success-color-state) !important;\n}\n\n.tag {\n  border-radius: 14px;\n  margin: 11px 8px 43px 8px;\n  padding: 4px 17.1px 4px 15.1px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.job-tag-color {\n  background-color: rgba(var(--profile-job-tag-background-color-hex), 0.07);\n  color: var(--profile-job-tag-color);\n}\n\n.availability-tag-color {\n  background-color: rgba(var(--profile-availability-background-color-hex), 0.07);\n  color: var(--profile-availability-color);\n  fill: var(--profile-availability-color);\n}\n\n.profile-header-container {\n  display: flex;\n  align-items: center;\n  padding-inline: 36px;\n}\n\n.ant-layout-rtl .container {\n  right: 325px;\n  left: 0;\n}\n\n.text-container {\n  margin-top: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
