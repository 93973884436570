// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-reconstruction {
  padding: 20px;
  padding-top: 36px;
  position: absolute;
  top: 0;
  right: 0;
  left: 10%;
}
`, "",{"version":3,"sources":["webpack://./src/components/PageHeader/LandingHeader/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,iBAAiB;EACjB,kBAAkB;EAClB,MAAM;EACN,QAAQ;EACR,SAAS;AACX","sourcesContent":[".header-reconstruction {\n  padding: 20px;\n  padding-top: 36px;\n  position: absolute;\n  top: 0;\n  right: 0;\n  left: 10%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
