// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-column *,
.card-column *:before,
.card-column *:after {
    -webkit-box-sizing: content-box !important;
    -moz-box-sizing: content-box !important;
    box-sizing: content-box !important;
}

.normal-card-color {
  background-color: white;
}

.selected-card-color {
  background-color: rgba(var(--primary-color-hex), 0.08);
}
`, "",{"version":3,"sources":["webpack://./src/components/forms/packages/PackageCardGroup/style.css"],"names":[],"mappings":"AAAA;;;IAGI,0CAA0C;IAC1C,uCAAuC;IACvC,kCAAkC;AACtC;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,sDAAsD;AACxD","sourcesContent":[".card-column *,\n.card-column *:before,\n.card-column *:after {\n    -webkit-box-sizing: content-box !important;\n    -moz-box-sizing: content-box !important;\n    box-sizing: content-box !important;\n}\n\n.normal-card-color {\n  background-color: white;\n}\n\n.selected-card-color {\n  background-color: rgba(var(--primary-color-hex), 0.08);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
